import CustomerTaskViewService from "../../customer/customer-tasks/CustomerTasksViewService";
import { CustomerTaskDtoReadExtended } from "../../customer/model/CustomerTaskDtoRead";
import TaskApi from "../../customer/services/TaskApi";
import { LookupDetailConstants } from "../../shared/util/LookupDetailConstants";

class TaskDashboardService {

    public getTasks = async (userId : string) : Promise<CustomerTaskDtoReadExtended[]> => {
        let customerTaskViewService = new CustomerTaskViewService();
        const taskApi  = new TaskApi();

        let tasks = await taskApi.fetchTasks(null, null, LookupDetailConstants.CUSTOMER_TASK_STATUS.INCOMPLETE, null, userId);

        tasks.forEach(item => {
            customerTaskViewService.enrichCalculatedFields(item);
        });

        return tasks;
    }
}

export default TaskDashboardService;