export default class FormattedInputService {

    formatPhoneNumber = (value: string) => {
        if (!value){
            return value;
        }
        
        // Remove all non-numeric characters
        const numericValue = value.replace(/\D/g, "");
    
        // Format the number as xxx-xxx-xxxx
        if (numericValue.length <= 3) {
          return numericValue;
        } else if (numericValue.length <= 6) {
          return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
        } else {
          return `${numericValue.slice(0, 3)}-${numericValue.slice(
            3,
            6
          )}-${numericValue.slice(6, 10)}`;
        }
    };

    getRawPhoneNumber = (formattedValue : string) => {
      return formattedValue?.replaceAll('-','');
    }
};