import _ from 'lodash'
import * as constant from '../../shared/util/constant'
import validator from 'validator';

class ValidateCustomerNote
{
    validateDescription=(description:string)=>{
        if(_.isEmpty(description?.trim())){
            return {isValid : false, warningText : constant.CustomerNotesConstant.BLANK_DESCRIPTION }
        }

        else if(description?.trim().length < 3){
            return {isValid : false, warningText : constant.CustomerNotesConstant.DISCRIPTION_LENGTH }
        }

        else {
            return {isValid : true, warningText : "" }
        }

    }
    validateNoteDate = (noteDate : Date) => {
        if(_.isNull(noteDate)){
            return {isValid : false, warningText : constant.CustomerNotesConstant.NOTE_DATE_EMPTY }
        }

       else if(!validator.isDate(noteDate)) {
            return {isValid : false, warningText : constant.CustomerNotesConstant.INVALID_NOTE_DATE }
        }

        else {
            return {isValid : true, warningText : "" }
        }
    }
}
export default ValidateCustomerNote;