import { Select, Label, Button, Panel, Spinner, Row, Column, Modal, DateTimePicker, TextInput, Checkbox } from '../../components'
import { useContext, useEffect, useState } from 'react'
import DropDownWithLabelInline from '../../shared/components/input/DropDownWithLabelInline'
import { getExternalTemplateOptions, getExternalTemplateCategoriesOptions, getExternalTemplateTypesOptions, getQueryTypeOptions, getFromEmailAddressoptions } from '../utils/dropdownUtils';
import campaignDetailsUtils from '../utils/campaignDetailsUtils'
import { getCampaignQueryFiltersSetup } from "../utils/campaignQueryFiltersSetupUtils"
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline'
import { ErrorBoundary } from 'react-error-boundary'
import '../campaign.scss'
import CampaignDetailUI from '../model/CampaignDetailUI'
import ExternalTemplateSelectOption from '../model/LookUpModel/ExternalTemplateSelectOption'
import * as constant from '../../shared/util/constant'
import * as label from '../../shared/util/label'
import { SelectOption } from '../../shared/components/input';
import CampaignQueryFilterUI from '../model/CampaignQueryFilterUI';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AppContext from '../../shared/components/AppContext'
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { ConfirmModal } from '../../shared/components/confirmModal';
import { useDispatch } from 'react-redux';
import { toggleFormSaved } from '../../shared/components/formSaved-slice';
import TextFieldWithLabelInline from '../../shared/components/input/TextFieldWithLabelInline';
import { AbilityContext } from '../../shared/abilities/AbilityContext';
import { defineAbility, fieldPatternMatcher } from '@casl/ability';

interface Props {
  onShowingResults: (filterRequest: CampaignDetailUI) => void,
  showResults: (showResults: boolean) => void
  isDetailsChangedAfterResults: (isDetailsChangedAfterResults: boolean) => void
  isRequestValidated: (isRequestValidaed: boolean, campaignDetails: CampaignDetailUI) => void
  campaignId: string
  history: any
  requestButtonClickCounter: number
  makeSendOnDateEmpty: boolean,
  updatedCampaignId:(id?:string)=>void
  setCompleteSave:(status?:boolean)=>void
  setSaveAsTriggered:(status?:boolean)=>void
  onFieldChange: (name : string, value : string | Date) => void
}
const CampaignSettings = (props: Props) => {

  const initialCampaignDetailsInput: CampaignDetailUI = {
    id: '',
    campaignName: '',
    description: '',
    templateType: constant.TEMPLATE_TYPE_MARKETING_ID.toLowerCase(),
    templateCategory: '',
    status: '',
    sendOn: null,
    changedDate: '',
    changedBy: '',
    templateId: '',
    queryId: '',
    isCampaignInactive: false,
    campaignFilters: null,
    fromEmail: ''
  }
  toast.configure();
  const campaignDetailsContext = useContext(AppContext);

  const [campaignDetailsInput, setCampaignDetailsInput] = useState<CampaignDetailUI>(initialCampaignDetailsInput);

  const [campaignQueryFilterSetup, setCampaignQueryFilterSetup] = useState<CampaignQueryFilterUI[]>();

  const [externalTemplateOptions, setExternalTemplateOptions] = useState<ExternalTemplateSelectOption[]>([]);

  const [externalTemplateCategoriesOptions, setExternalTemplateCategoriesOptions] = useState<SelectOption[]>([]);

  const [externalTemplateTypesOptions, setExternalTemplateTypesOptions] = useState<SelectOption[]>([]);

  const [queryNameOptions, setQueryNameOptions] = useState<SelectOption[]>([]);

  const [fromEmailOptions, setFromEmailOptions] = useState<SelectOption[]>([]);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [selectedQuery, setSelectedQuery] = useState(null);

  const [selectedTemplateType, setSelectedTemplateType] = useState(null)

  const [selectedTemplateCategory, setSelectedTemplateCategory] = useState(null)

  // const[activeExternalTemplateOptions, setActiveExternalTemplateOptions] = useState<ExternalTemplateSelectOption[]>([])

  // const[activeQueryNameOptions, setActiveQueryNameOptions] = useState<SelectOption[]>([])

  const [isCampaignInactive, setIsCampaignInactive] = useState<boolean>(false);

  const [zipNumberCurrecnyValues, setZipNumberCurrecnyValues] = useState<string[]>([]);

  //Disabling for save/Delete/Results/Inactive start
  const [isSaveDisbaled, setIsSaveDisabled] = useState(false)

   const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)

  const [isResultsDisabled, setIsResultDisabled] = useState(false)

  const [isInactiveDisabled, setIsInactiveDisabled] = useState(false)

  const campaignStatusEligibleForSave = [constant.SAVED, constant.REJECTED, constant.SENT]

  const campaignStatusEligibleForDelete = [constant.SAVED, constant.REJECTED,constant.INACTIVE, constant.Waiting_Approval]

  const campaignStatusEligibleForInactive = [constant.SAVED, constant.SENT, constant.REJECTED]
  //Disabling for save/Delete end

  //Validations start

  const [isCampaignNameEmpty, setIsCampaignNameEmpty] = useState(false);

  const [isCampaignNameTooShort, setIsCampaignNameTooShort] = useState(false);

  const [isCampaignNameTooLong, setIsCampaignNameTooLong] = useState(false);

  const [isCampaignDescriptionEmpty, setIsCampaignDescriptionEmpty] = useState(false);

  const [isCampaignDescriptionTooShort, setIsCampaignDescriptionTooShort] = useState(false);

  const [isCampaignDescriptionTooLong, setIsCampaignDescriptionTooLong] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [showInactiveCampaignConfirmation, setShowInactiveCampaignConfirmation] = useState(false);

  const [isExternalTemplateTypeEmpty, setIsExternalTemplateTypeEmpty] = useState(false)

  const [isExternalTemplateEmpty, setIsExternalTemplateEmpty] = useState(false)

  const [isInvalidExternalTemplate, setIsInvalidExternalTemplate] = useState(false)

  const [isQueryEmpty, setisQueryEmpty] = useState(false)

  const [isInvalidQueryName, setIsInvalidQueryName] = useState(false)

  const [isValidationFromResultButton, setIsValidationFromResultButton] = useState(false)

  const [showSendOnDateEmptyError, setShowSendOnDateEmptyError] = useState(false)

  const [isSendOnDateInPast, setIsSendOnDateInPast] = useState(false)

  const [isSendOnDateInvalid, setIsSendOnDateInvalid] = useState(false);

  const [showOverrideSendOnDateModel, setShowOverrideSendOnDateModel] = useState(false)

  const [isInvalidZipNumbersCurrency, setIsInvalidZipNumbersCurrency] = useState<boolean[]>([]);

  const [isFromEmailEmpty,setIsFromEmailEmpty] = useState(false)

  const [prevCampaignName, setPrevCampaignName] = useState("");

  const [isCampaignNameSame, setIsCampaignNameSame] = useState(false)

  const [blackoutDates, setBalckoutDates] = useState<Date[]>();

  const [isFormSaved, setIsFormSaved] = useState(true);

  const [showScreenLeavingConfirmation, setShowScreenLeavingConfirmation] = useState(false)

  const dispatch = useDispatch();

  const [isFilterConditionsEmpty, setisFilterConditionsEmpty] = useState<boolean[]>([false]);

  const [isFilterValuesEmpty, setIsFilterValuesEmpty] = useState<boolean[]>([]);

  const [isFilterConditionsOrValuesEmpty,setIsFilterConditionsOrValuesEmpty] = useState(false)

  const issendOnDateOrFromEmailValidationError = isSendOnDateInvalid || showSendOnDateEmptyError || isSendOnDateInPast || isFromEmailEmpty

  //Validations end

  const [isDetailsPageFirstLoad, setIsDetailsPageFirstLoad] = useState(true);

  // Get the current date
  const currentDate = new Date();

  // Create a new Date object for tomorrow
  const tomorrowDate = new Date();
  tomorrowDate.setDate(currentDate.getDate() + 1);

  const ability = useContext(AbilityContext);
  const ab_subject = "Campaign";
  const screenFieldPermission =[null];
  const isUserPermitToCopyCampaign = ability.can('read', ab_subject,constant.CampaignPrivileges.SAVE_AS)
  const isUserPermitToViewCopyCampaign = ability.can('view', ab_subject,constant.CampaignPrivileges.SAVE_AS)
  const isUserPermitToCreateCampaign = ability.can('create', ab_subject,constant.ScreenName.CAMPAIGN)
  const isUserPermitToUpdateCampaign = ability.can('update',ab_subject,constant.ScreenName.CAMPAIGN)
  //const isUserPermitToViewSaveCampaign = ability.can('view', ab_subject,'Save')
  //const isUserPermitToViewDeleteCampaign = ability.can('view', ab_subject,'Delete')
  
  const isUserPermitToDeleteCampaign = ability.can('delete', ab_subject,constant.ScreenName.CAMPAIGN)
  const isUserPermitToInactiveCampaign = ability.can('read', ab_subject,constant.CampaignPrivileges.INACTIVE)
  const isUserPermitToViewInactiveControl = ability.can('view', ab_subject,constant.CampaignPrivileges.INACTIVE)
  const isUserPermitToViewResultBtn = ability.can('view', ab_subject,constant.CampaignPrivileges.RESULT)
  const isResultBtnEnabled = ability.can('read', ab_subject,constant.CampaignPrivileges.RESULT)
  const userPermitToSave = (isUserPermitToCreateCampaign && !props.campaignId?true:
                          isUserPermitToUpdateCampaign && props.campaignId!==null && props.campaignId!=="" && props.campaignId!== undefined?true:false);
  const userRestrictToDelete =(isUserPermitToDeleteCampaign && !props.campaignId?true:
    isUserPermitToDeleteCampaign && props.campaignId!==null && props.campaignId!=="" && props.campaignId!== undefined?true:false);
  useEffect(()=>{
  setIsDeleteDisabled(userRestrictToDelete)
  setIsSaveDisabled(!isUserPermitToCreateCampaign)
 },[])

  const BackToCampaignList = () => {
    history.back()
    // campaignDetailsContext.formSavedStatus= true
  }

  const upArrowClick = () => {
    if (isFormSaved && campaignDetailsContext.noteSavedStatus) {
      setShowScreenLeavingConfirmation(false)
      BackToCampaignList()
    }
    else {
      setShowScreenLeavingConfirmation(true)
    }

  }

  const validateForSave = () => {
    const isCampaignNameEmpty = !campaignDetailsInput.campaignName || campaignDetailsInput.campaignName.length === 0;
    const isCampaignDescriptionEmpty = !campaignDetailsInput.description || campaignDetailsInput.description.length === 0;
    const isCampaignNameTooShort = !isCampaignNameEmpty && campaignDetailsInput.campaignName.length < 3;
    const isCampaignDescriptionTooShort = !isCampaignDescriptionEmpty && campaignDetailsInput.description.length < 3;
    const isCampaignNameTooLong = !isCampaignNameEmpty && !isCampaignNameTooShort && campaignDetailsInput.campaignName.length > 250;
    const isCampaignDescriptionTooLong = !isCampaignDescriptionEmpty && !isCampaignDescriptionTooShort && campaignDetailsInput.description.length > 250;
    const isTemplateNameEmpty =
      campaignDetailsInput.templateId === null ||
      campaignDetailsInput.templateId === undefined ||
      campaignDetailsInput.templateId.trim() === "";
    const isTemplateTypeEmpty =
      campaignDetailsInput.templateType === null ||
      campaignDetailsInput.templateType === undefined ||
      campaignDetailsInput.templateType.trim() === "";
    const isSendOnDateInPastIfSendOnDateEntered = campaignDetailsInput.sendOn ? campaignDetailsInput.sendOn < currentDate ? true : false : false
    const isInvalidExternalTemplate= selectedTemplate?.isInactive
    const isInvalidQueryName = selectedQuery?.isInactive


    //apply here required for filter criteria
 
    const conditionEmpty = campaignQueryFilterSetup ? campaignDetailsInput?.campaignFilters?.map((filter) =>
      filter.filterConditionSelectedId === '' || filter.filterConditionSelectedId === undefined ||
      filter.filterConditionSelectedId === null) : [false];

    const valueEmpty = campaignQueryFilterSetup ? campaignDetailsInput?.campaignFilters?.map((filter, index) => {     
      const filterType = campaignQueryFilterSetup[index].filterType
      // Access the corresponding filter value from campaignDetailsInput
      const filterValue = campaignDetailsInput.campaignFilters[index].filterValue;
      const updatedArrayZipNumberValidation = [...isInvalidZipNumbersCurrency];
      
      // Check for general emptiness conditions
      if (filterValue === null || filterValue === undefined || filterValue === '' || (Array.isArray(filterValue) && filterValue.length === 0)) {
        return true; // Consider empty
      }
     
      // Perform additional checks based on filterType
      switch (filterType) {
        case constant.Data_Type_Currency:
          updatedArrayZipNumberValidation[index] = false;
          setIsInvalidZipNumbersCurrency(updatedArrayZipNumberValidation);
          return false

        case constant.Data_Type_ZipCode:
          updatedArrayZipNumberValidation[index] = false;
          setIsInvalidZipNumbersCurrency(updatedArrayZipNumberValidation);
          return false

        case constant.Data_Type_Number:
          updatedArrayZipNumberValidation[index] = false;
          setIsInvalidZipNumbersCurrency(updatedArrayZipNumberValidation);
          return false

        case constant.Data_Type_Currency_Range:
          return Array.isArray(filterValue) && (filterValue.length === 1 || !filterValue[0]
            ||  (typeof filterValue[0] === 'number' && isNaN(filterValue[0]))
            || (typeof filterValue[1] === 'number' && isNaN(filterValue[1])))

        case constant.Data_Type_Number_Range:
          return Array.isArray(filterValue) && (filterValue.length === 1 || !filterValue[0] ||
            (typeof filterValue[0] === 'number' && isNaN(filterValue[0]))
            || (typeof filterValue[1] === 'number' && isNaN(filterValue[1])))

        case constant.Data_Type_Date_Range:
          return Array.isArray(filterValue) && (filterValue.length === 1 || !filterValue[0]
            // || isNull(filter.filterValue[0]) || isNull(filter.filterValue[1]))
            || filterValue[0] === null || filterValue[1] === null)

        case constant.Data_Type_Date:
          // return Array.isArray(filterValue) && isNull(filterValue[0])
          return Array.isArray(filterValue) && filterValue[0] === null

        case constant.Data_Type_Dropdown_List:
          return false
        
        case constant.Data_Type_Dropdown_SQL:
          return false
        
          case constant.DATA_TYPE_FREEFORM:
            return false

      }
    }) : [false];

    setIsFilterConditionsOrValuesEmpty(!conditionEmpty.every((value) => !value) || !valueEmpty.every((value) => !value))  
   
    setisFilterConditionsEmpty(conditionEmpty);
    setIsFilterValuesEmpty(valueEmpty);
    setIsCampaignNameEmpty(isCampaignNameEmpty);
    // setIsCampaignDescriptionEmpty(isCampaignDescriptionEmpty);
    setIsCampaignNameTooShort(isCampaignNameTooShort);
    setIsCampaignDescriptionTooShort(isCampaignDescriptionTooShort);
    setIsCampaignNameTooLong(isCampaignNameTooLong);
    setIsCampaignDescriptionTooLong(isCampaignDescriptionTooLong);
    setIsExternalTemplateEmpty(isTemplateNameEmpty)
    setIsExternalTemplateTypeEmpty(isTemplateTypeEmpty)
    setIsSendOnDateInPast(isSendOnDateInPastIfSendOnDateEntered)
    setIsInvalidExternalTemplate(isInvalidExternalTemplate)
    setIsInvalidQueryName(isInvalidQueryName)
    setShowSendOnDateEmptyError(false)
    setIsFromEmailEmpty(false)


    if (
      !isCampaignNameEmpty &&
      !isCampaignNameTooShort &&
      !isCampaignNameTooLong &&
      !isCampaignDescriptionTooShort &&
      !isCampaignDescriptionTooLong &&
      !isInvalidExternalTemplate &&
      !isInvalidQueryName &&
      !isSendOnDateInvalid &&
      !isSendOnDateInPastIfSendOnDateEntered &&
      (
        // Check if both template type and name are empty or both have a value
        (isTemplateTypeEmpty && isTemplateNameEmpty) ||
        (!isTemplateTypeEmpty && !isTemplateNameEmpty)
      )
      &&
      conditionEmpty.every((value) => !value) &&
      valueEmpty.every((value) => !value)

    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateForResults = () => {
    if (validateForSave() && campaignDetailsInput.templateId != '' && campaignDetailsInput.templateId != undefined && campaignDetailsInput.templateId != null && campaignDetailsInput.queryId != "" && campaignDetailsInput.queryId != undefined && campaignDetailsInput.queryId != null) {
      setIsCampaignNameSame(false)
      setIsExternalTemplateEmpty(false);
      setisQueryEmpty(false);
      return true;
    }
    else {
      setIsExternalTemplateEmpty(campaignDetailsInput.templateId === "" || campaignDetailsInput.templateId === undefined || campaignDetailsInput.templateId === null ? true : false)
      setisQueryEmpty(campaignDetailsInput.queryId === "" || campaignDetailsInput.queryId === undefined || campaignDetailsInput.queryId === null ? true : false)
      return false;
    }
  }

  const getQueryFilterSetup = (queryId: string) => {
    setisFilterConditionsEmpty([false])
    setIsFilterValuesEmpty([false])
    setIsFilterConditionsOrValuesEmpty(false)
    if (queryId != undefined && queryId != null && queryId != '') {
      getCampaignQueryFiltersSetup(queryId).then(res => {
        setCampaignQueryFilterSetup(res)
      })
    }
    else {
      setCampaignQueryFilterSetup(null)
    }
  }

  const handleZipNumbeCurrencyValueMerge=() => {
    if(campaignQueryFilterSetup){
    const initialArrayLength = campaignQueryFilterSetup.length;
    const defaultInvalidValue = false;
    const initialInvalidArray = Array.from({ length: initialArrayLength }, () => defaultInvalidValue);
    setIsInvalidZipNumbersCurrency(initialInvalidArray)

    const defaultZipNumberCurrencyValue = '';
    const initialZipNumberCurrencyValue = Array.from({ length: initialArrayLength }, () => defaultZipNumberCurrencyValue);
    setZipNumberCurrecnyValues(initialZipNumberCurrencyValue)
    if (campaignDetailsInput.campaignFilters) {
      const updatedArrayForZipNumberCurrencyValue = initialZipNumberCurrencyValue
      var index = 0
      campaignDetailsInput.campaignFilters.forEach(campaignFilter => {
        if (campaignDetailsInput.campaignFilters[index].filterValue) {
          updatedArrayForZipNumberCurrencyValue[index] = campaignFilter.filterValue.join(';');
        }
        index += 1
      });
    }
  }
}

  useEffect(() => {
    getExternalTemplateTypesOptions().then(res => {
      setExternalTemplateTypesOptions(res);
    })

    getQueryTypeOptions().then(res => {
      setQueryNameOptions(res);
    })

    getFromEmailAddressoptions().then(res => {
      setFromEmailOptions(res)
    })

    campaignDetailsUtils.getBlackoutDatesUtils().then(res => {
      setBalckoutDates(res)
    })

    if (props.campaignId !== null && props.campaignId !== undefined) {
      try {
        campaignDetailsUtils.getCampaignDetails(props.campaignId).then(res => {
          setCampaignDetailsInput(res);
          setPrevCampaignName(res.campaignName)
          getQueryFilterSetup(res.queryId)
          if (res.templateId) {
            getExternalTemplateOptions(res.templateType, res.templateCategory)?.then(response => {
              setExternalTemplateOptions(response)
            })
          }
        })
      } catch (error) {
        toast.error(constant.ERROR_MESSAGE_LOAD, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
        console.log("error", error) // Catch any additional errors
      }
    }
    else {
      setCampaignDetailsInput({
        ...campaignDetailsInput,
        templateType: constant.TEMPLATE_TYPE_MARKETING_ID.toLowerCase()
      })
    }
  }, [])

  useEffect(() => {
    campaignDetailsContext.formSavedStatus = isFormSaved
    }, [isFormSaved])


  //Handling template name , type &category start

  useEffect(() => {
    setCampaignDetailsInput({
      ...campaignDetailsInput,
      templateId: '',
      templateCategory: '',
    })
    if (campaignDetailsInput.templateType !== undefined && campaignDetailsInput.templateType != '' && campaignDetailsInput.templateType != null) {
      getExternalTemplateCategoriesOptions(campaignDetailsInput.templateType)?.then(res => {
        setExternalTemplateCategoriesOptions(res)
      })

      getExternalTemplateOptions(campaignDetailsInput.templateType, '')?.then(res => {
        setExternalTemplateOptions(res)
      })

    }

    else {
      setExternalTemplateCategoriesOptions([])
      setExternalTemplateOptions([])
    }

  }, [selectedTemplateType])
  //[campaignDetailsInput.templateType])


  useEffect(() => {
    setCampaignDetailsInput({
      ...campaignDetailsInput,
      templateId: '',
    })

    if (campaignDetailsInput.templateCategory != undefined && campaignDetailsInput.templateCategory !== null && campaignDetailsInput.templateCategory !== '') {
      getExternalTemplateOptions(campaignDetailsInput.templateType, campaignDetailsInput.templateCategory)?.then(res => {
        setExternalTemplateOptions(res)
      })
    }
    else if (campaignDetailsInput.templateType !== undefined && campaignDetailsInput.templateType != '' && campaignDetailsInput.templateType != null) {
      getExternalTemplateOptions(campaignDetailsInput.templateType, '')?.then(res => {
        setExternalTemplateOptions(res)
      })
    }
    else {
      setExternalTemplateOptions([])
    }

  }, [selectedTemplateCategory])
  // [campaignDetailsInput.templateCategory])


  useEffect(() => {
    if (selectedTemplate) {
      setCampaignDetailsInput({
        ...campaignDetailsInput,
        templateCategory: selectedTemplate.externalTemplateCategoryId,
      })
    }

  }, [selectedTemplate])
  //  [campaignDetailsInput.templateId])


  //Handling template name , type &category end

  useEffect(() => {
    // setIsInvalidQueryName(selectedQuery && selectedQuery.isInactive);
    if (campaignQueryFilterSetup != undefined && campaignQueryFilterSetup != null) {
      setCampaignDetailsInput({
        ...campaignDetailsInput,
        campaignFilters: campaignQueryFilterSetup.map((filter) => ({
          filterId: filter.filterId,
          filterLabel: filter.filterLabel,
          filterConditionSelectedId:
            filter.filterType.toLocaleLowerCase() === constant.Data_Type_Currency_Range || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Date_Range
            || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Number_Range ? filter.filterConditionOptions[0].campaignFilterConditionId : "",
          filterValue: [],
        }))
      })
    }
    else {
      setCampaignDetailsInput({
        ...campaignDetailsInput,
        campaignFilters: null
      })
    }
  }, [selectedQuery])


  useEffect(() => {
    if (campaignQueryFilterSetup) {
      if (campaignDetailsInput.id.length !== 0 && isDetailsPageFirstLoad) {
        setCampaignDetailsInput({
          ...campaignDetailsInput,
          campaignFilters: campaignDetailsInput.campaignFilters,
        });
      }
      else {
        setCampaignDetailsInput({
          ...campaignDetailsInput,
          campaignFilters: campaignQueryFilterSetup.map((filter) => ({
            filterId: filter.filterId,
            filterLabel: filter.filterLabel,
            filterConditionSelectedId:
              filter.filterType.toLocaleLowerCase() === constant.Data_Type_Currency_Range || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Date_Range
              || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Number_Range ? filter.filterConditionOptions[0].campaignFilterConditionId : "",
            filterValue: [],
          }))
        })
      }

      setIsDetailsPageFirstLoad(false)

      handleZipNumbeCurrencyValueMerge()
    }
    else {
      setCampaignDetailsInput({
        ...campaignDetailsInput,
        campaignFilters: null
      })
    }

  }, [campaignQueryFilterSetup])

  useEffect(() => {
     if(userPermitToSave){
    setIsSaveDisabled(campaignDetailsInput.status && !campaignStatusEligibleForSave.includes(campaignDetailsInput.status))
     }
    else{
      setIsSaveDisabled(true)
    }
    if(userRestrictToDelete){
     setIsDeleteDisabled(campaignDetailsInput.status && !campaignStatusEligibleForDelete.includes(campaignDetailsInput.status))
    }
    else{
      setIsDeleteDisabled(true)
    }
    if(isUserPermitToInactiveCampaign){
      setIsInactiveDisabled(!campaignStatusEligibleForInactive.includes(campaignDetailsInput.status))
    }
    else{
      setIsInactiveDisabled(true)
    }
     if(isResultBtnEnabled){
    setIsResultDisabled(campaignDetailsInput.status && campaignDetailsInput.status === constant.SENT)
    }
    else{
      setIsResultDisabled(true)
    }

  }, [campaignDetailsInput?.status])

  useEffect(() => {

    setShowInactiveCampaignConfirmation(isCampaignInactive)

  }, [isCampaignInactive]
  )

  useEffect(() => {
    if (props.requestButtonClickCounter > 0) {
      // props.onShowingResults(campaignDetailsInput)
      setIsFormSaved(true);   
      requestCampaign();
    }

  }, [props.requestButtonClickCounter])

  useEffect(() => {
    if (props.makeSendOnDateEmpty) {
      campaignDetailsInput.sendOn = null
    }

  }, [props.makeSendOnDateEmpty])

  const handleFilterConditionChange = (filterIndex: number, value: string) => {
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      updatedFilters[filterIndex].filterConditionSelectedId = value;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };




  const handleDropDownFilterValueChange = (filterIndex: number, value?: string[]) => {
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      // updatedFilters[filterIndex].filterDropdownValueSelectedId = value;
      updatedFilters[filterIndex].filterValue = value;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };


  const handleNumericCurrencyZipFilterValuesChange = (filterIndex: number, values?: string) => {

    const filteredNumbers = values.split(';').filter(str => str != '').map(Number).filter(x=>!Number.isNaN(x))

    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      // updatedFilters[filterIndex].filterNumeric_Currency_Zip_Values = numbersArray;
      updatedFilters[filterIndex].filterValue = filteredNumbers;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };

  const handleNumericCurrencyInitialFilterValueChange = (filterIndex: number, initialValue?: number) => {
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      updatedFilters[filterIndex].filterValue[0] = initialValue;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };

  const handleNumericCurrencyFinalFilterValueChange = (filterIndex: number, finalValue?: number) => {
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      updatedFilters[filterIndex].filterValue[1] = finalValue;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };

  const handleInitialDateFilterValueChange = (filterIndex: number, startDate?: Date) => {
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      if (!updatedFilters[filterIndex].filterValue) {
        updatedFilters[filterIndex].filterValue = [];
      }
      updatedFilters[filterIndex].filterValue[0] = startDate != null ? startDate : null;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };

  const handleFinalDateFilterValueChange = (filterIndex: number, endDate?: Date) => {
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      if (!updatedFilters[filterIndex].filterValue) {
        updatedFilters[filterIndex].filterValue = [];
      }
      updatedFilters[filterIndex].filterValue[1] = endDate != null ? endDate : null;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  };

  const handleFreeformChange =(filterIndex: number, value?: string) =>{
    setCampaignDetailsInput((prevDetails) => {
      const updatedFilters = [...prevDetails.campaignFilters];
      updatedFilters[filterIndex].filterValue[0] = value;
      return {
        ...prevDetails,
        campaignFilters: updatedFilters,
      };
    });
  }

  const onSendOnDateSelectedOrTemplateTypeChange = (sendOnDate: Date, templateType: string) => {
    var isSendOnDateInBlackoutDates = false;
    if (sendOnDate != null) {
      setShowSendOnDateEmptyError(false)
      isSendOnDateInBlackoutDates = blackoutDates.some(blackoutDate => {
        return (
          blackoutDate.toDateString().substring(0, 15) === sendOnDate.toDateString().substring(0, 15)
        );
      });

      if (isSendOnDateInBlackoutDates) {
        setIsSendOnDateInvalid(true)
        if (templateType != null) {
          if (externalTemplateTypesOptions.find(option => option.value === templateType).label.toLocaleLowerCase() === "marketing") {
          }
          else {
            setShowOverrideSendOnDateModel(true)

          }
        }
      }
      else if (!isSendOnDateInBlackoutDates) {
        setIsSendOnDateInvalid(false)
      }
    }
  }

  const onFieldChange = (name: string, value: string | Date) => {
    props.onFieldChange(name, value);
    setCampaignDetailsInput({
      ...campaignDetailsInput,
      [name]: value,
    })
    if (name === 'sendOn' || name === 'fromEmail') {
      props.isDetailsChangedAfterResults(false)
    }
    else {
      setIsResultDisabled(true)
      props.isDetailsChangedAfterResults(true)
    }
    setIsFormSaved(false)
  }

  const onFilterChange = () => {
    setIsResultDisabled(true)
    props.isDetailsChangedAfterResults(true)
  }


  const submitCampaignDetail = async (action: string) => {

    setIsValidationFromResultButton(false)
    if (validateForSave()) {
      if (action === label.SAVE) {
        setIsCampaignNameSame(false)
        if (!campaignDetailsInput.id) {
          try {
            const saveCampaignResposne = await campaignDetailsUtils.saveCampaignDetailsUtils(campaignDetailsInput)
            // console.log(saveCampaignResposne)
            try {
              toast.success(constant.SUCCESS_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
              });
              setCampaignDetailsInput(saveCampaignResposne)
              handleZipNumbeCurrencyValueMerge()
              setPrevCampaignName(saveCampaignResposne.campaignName)
              if(isResultBtnEnabled){
              setIsResultDisabled(false)
              }
              else{
                setIsResultDisabled(true)
              }
              setIsFormSaved(true)
              dispatch(toggleFormSaved());
              props.history.replace({pathname:'',state:{entityId:saveCampaignResposne.id,type:'1'}})
              props.updatedCampaignId(saveCampaignResposne.id)
              setTimeout(() => {
                props.setCompleteSave(false);
                props.setSaveAsTriggered(false);
                },5000)
            }
            catch (error) {
              toast.error(constant.ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
              });
              console.log("error", error)
              setIsFormSaved(false)
              props.setCompleteSave(false);
              props.setSaveAsTriggered(false);
            }
          } catch (error) {
            toast.error(error.data, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
            console.log("error", error) // Catch any additional errors
            setIsFormSaved(false)
            props.setCompleteSave(false);
            props.setSaveAsTriggered(false);
          }
        }
        else {
          try {
            const updateCampaignResposne = await campaignDetailsUtils.updateCampaignDetailsUtils(campaignDetailsInput)
            try {
              toast.success(constant.UPDATE_SUCCESS_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
              });
              setCampaignDetailsInput(updateCampaignResposne)
              handleZipNumbeCurrencyValueMerge()
              setPrevCampaignName(updateCampaignResposne.campaignName)
              setIsResultDisabled(false)
              setIsFormSaved(true)
              props.setCompleteSave(true);
              dispatch(toggleFormSaved());
              props.updatedCampaignId(updateCampaignResposne.id)
              setTimeout(() => {
               props.setCompleteSave(false);
                props.setSaveAsTriggered(false);
                },5000)
            }
            catch (error) {
              toast.error(constant.ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
              });
              console.log("error", error)
              setIsFormSaved(false)
              props.setCompleteSave(false);
              props.setSaveAsTriggered(false);
            }
          } catch (error) {
            toast.error(error.data, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
            console.log("error", error) // Catch any additional errors
            setIsFormSaved(false)
            props.setCompleteSave(false);
            props.setSaveAsTriggered(false);
          }
        }
      }

      else if (action === label.SAVE_AS) {
        if (campaignDetailsInput.campaignName !== prevCampaignName) {
          setIsCampaignNameSame(false)
          campaignDetailsInput.id = ''
          campaignDetailsInput.status = ''
          campaignDetailsInput.isCampaignInactive = false
          try {
            const saveCampaignResposne = await campaignDetailsUtils.saveCampaignDetailsUtils(campaignDetailsInput)
            try {
              toast.success(constant.SUCCESS_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
              });
              props.history.replace({pathname:'',search:"?id="+saveCampaignResposne.id,state:{entityId:saveCampaignResposne.id,type:'1'},})
              props.setSaveAsTriggered(true)
              setCampaignDetailsInput(saveCampaignResposne)
              handleZipNumbeCurrencyValueMerge()
              setPrevCampaignName(saveCampaignResposne.campaignName)
              props.updatedCampaignId(saveCampaignResposne.id)
              setIsResultDisabled(false)
              setIsFormSaved(true)
              dispatch(toggleFormSaved());
              setTimeout(() => {
                props.setCompleteSave(false);
                props.setSaveAsTriggered(false);
                },5000)

            }
            catch (error) {
              toast.error(constant.ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
              });
              console.log("error", error)
              setIsFormSaved(false)
              props.setCompleteSave(false);
              props.setSaveAsTriggered(false);
            }
          } catch (error) {
            toast.error(error.data, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
            console.log("error", error) // Catch any additional errors
            setIsFormSaved(false)
            props.setCompleteSave(false);
            props.setSaveAsTriggered(false);
          }
        }
        else {
          setIsCampaignNameSame(true)
        }

        //new record in db., Axios Create
        //check campaign name is unique or not from db
      }
      return 200;
    }
    else {
      console.log("not validated")
      return null;
    }

  }

  const deleteCampaign = async () => {
    try {
      await campaignDetailsUtils.deleteCampaignUtils(campaignDetailsInput.id)
      try {
        toast.success(constant.DELETE_CAMPAIGN_SUSSESS, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
      }
      catch (error) {
        toast.error(constant.ERROR_MESSAGE, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
        console.log("error", error)
      }
    } catch (error) {
      toast.error(error.data, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: constant.AUTO_CLOSE
      });
      console.log("error", error) // Catch any additional errors
    }
    BackToCampaignList()
  }

  const showCampaignResult = () => {
    setIsValidationFromResultButton(true)
    if (validateForResults()) {
      props.isDetailsChangedAfterResults(false)
      props.showResults(true)
      props.onShowingResults(campaignDetailsInput)
    }
    else {
      props.showResults(false);
    }
  }

  const requestCampaign = () => {
    const isFromEmailEmpty = !campaignDetailsInput.fromEmail
    const isSendOnDateEmpty = !campaignDetailsInput.sendOn
    const isSendOnDateInPast = campaignDetailsInput.sendOn && campaignDetailsInput.sendOn < currentDate
  
    setIsFromEmailEmpty(isFromEmailEmpty)
    setShowSendOnDateEmptyError(isSendOnDateEmpty)
    setIsSendOnDateInPast(isSendOnDateInPast)

    if(isFromEmailEmpty || isSendOnDateEmpty || isSendOnDateInPast)
    {
      props.isRequestValidated(false, campaignDetailsInput)
    }
    else
    {
      props.isRequestValidated(
        validateForResults() &&
          !isSendOnDateInvalid
          ? true
          : false
        , campaignDetailsInput)
    }

  }

  const handleInactiveChecboxChange = () => {
    setIsCampaignInactive(!isCampaignInactive)
  }

  const getSelectedFilterCondition =(filter, index) => {
    return campaignDetailsInput?.campaignFilters[index]?.filterConditionSelectedId !== null ?
      filter.filterConditionOptions.map((option) => ({
        label: option.campaignFilterConditionValue,
        value: option.campaignFilterConditionId,
        isInactive:option.isInactive
      }))?.filter(({ value }) => value === campaignDetailsInput?.campaignFilters[index]?.filterConditionSelectedId
      )
      : null
    }

  const getSelectedFilterValue = (filter, index) => {
    return campaignDetailsInput?.campaignFilters[index]?.filterValue !== null ?
      filter.filterOptions.map((option) => ({
        label: option.campaignFilterOptionsValue,
        value: option.campaignFilterOptionsId,
        isInactive:option.isInactive
      }))?.filter(({ value }) => campaignDetailsInput?.campaignFilters[index]?.filterValue?.includes(value)
      )
      : null
  }

  return (

    <>
      <Row>
        <Column lg={12} md={12}>
          <Button icon='up-indication' outlined={true} onClick={upArrowClick} color="black">
          </Button>
          {campaignDetailsInput.campaignName}</Column>
      </Row>
      <Row className="bg-light cstCampaignSetting">

        <Column lg={12} md={12}>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Panel
              id={'PanelDetails'}
              collapsible={false}
              title={label.CAMPAIGN_DETAILS}
              className={'cstPanel'}
              collapsed={false}>
              <Column lg={12} md={12} className='cstDetailSection'>
                <Row>
                  <Column lg={12} className={isCampaignNameEmpty || isCampaignNameTooShort || isCampaignNameTooLong || isCampaignNameSame ? 'feedback-row' : ''}>
                    <TextInputWithLabelInline
                      label={label.CAMPAIGN_NAME}
                      placeholder={label.NAME}
                      name="campaignName"
                      className='camapigns-texbox-input'
                      value={campaignDetailsInput.campaignName}
                      isRequired
                      isEditable={true}
                      lglabel={2}
                      mdlabel={2}
                      lgText={10}
                      mdText={10}
                      isInvalid={isCampaignNameEmpty || isCampaignNameTooShort || isCampaignNameTooLong || isCampaignNameSame}
                      onChange={(event) => onFieldChange('campaignName', event.currentTarget.value)}
                      // isEmpty={isCampaignNameEmpty}
                      // isTooShort={isCampaignNameTooShort}  
                      labelClass='inlineLable'
                      // isTooLong={isCampaignNameTooLong}
                      // isSame={isCampaignNameSame}
                      invalidWarning={isCampaignNameEmpty?constant.FIELD_BLANK: isCampaignNameTooShort|| isCampaignNameTooLong ? constant.NAME_LEN_ERROR: isCampaignNameSame? constant.UNIQUE_CAMPAIGN_ERROR:''}
                      maxLength={150} />
                  </Column>
                </Row>
                <Row>
                  <Column lg={12} className={isCampaignDescriptionEmpty && isValidationFromResultButton || isCampaignDescriptionTooShort || isCampaignDescriptionTooLong ? 'feedback-row' : ''}>
                              <TextFieldWithLabelInline
                                label={label.DESCRIPTION}
                                name='campaignDescription'
                                title={campaignDetailsInput.description}
                                className='camapigns-texbox-input'
                                labelClass='inlineLable'                              
                                value={campaignDetailsInput.description}
                                isEditable={true}
                                placeholder={label.DESCRIPTION}
                                onChange={(event) => onFieldChange('description', event.currentTarget.value)}
                                tabIndex={2}
                                lglabel={2}
                                mdlabel={2}
                                lgText={10}
                                mdText={10}
                                isInvalid={isCampaignDescriptionEmpty && isValidationFromResultButton || isCampaignDescriptionTooShort || isCampaignDescriptionTooLong}
                                invalidWarning= {isCampaignDescriptionEmpty && isValidationFromResultButton ?constant.FIELD_BLANK: isCampaignDescriptionTooShort|| isCampaignDescriptionTooLong ? constant.DESCRIPTION_LEN_ERROR:''}
                                maxLength={250}
                              />
                  </Column>
                </Row>
                <Row>
                  <Column lg={2} md={2} style={{ padding: 0 }}><Label className='inlineLable' text={label.TEMPLATE_TYPE} /></Column>
                  <Column lg={10} md={10}>
                    <Row>
                      <Column lg={4} md={4} className='nopadding-left'>
                        <Select
                          id='templateType'
                          placeholder={label.PLEASE_SELECT}
                          options={externalTemplateTypesOptions}
                          defaultSelected={externalTemplateTypesOptions.filter(({ value }) => value?.toLowerCase() === campaignDetailsInput.templateType?.toLowerCase())}
                          onChange={
                            (values) => {
                              const selectedOption = externalTemplateTypesOptions.find((option) => option.value?.toLowerCase() === values[0]?.toLowerCase())
                              setSelectedTemplateType(selectedOption)
                              onFieldChange('templateType', values[0])
                              onSendOnDateSelectedOrTemplateTypeChange(campaignDetailsInput.sendOn, values[0])
                            }
                          }
                        // disabled={disableExternalTemplateType} 
                        />
                      </Column>
                      <Column lg={8} md={8} style={{ paddingLeft: "70px" }}>
                        <DropDownWithLabelInline
                          label={label.TEMPLATE_CATEGORY}
                          labelClass='inlineLable'
                          id='templateCategory'
                          placeholder={label.PLEASE_SELECT}
                          // options={filteredExternalTemplateCategoriesOptions}
                          options={externalTemplateCategoriesOptions}
                          defaultSelected={externalTemplateCategoriesOptions.filter(({ value }) => value?.toLowerCase() === campaignDetailsInput.templateCategory?.toLowerCase())}
                          onChange={
                            (values) => {
                              const selectedOption = externalTemplateCategoriesOptions.find((option) => option.value?.toLowerCase() === values[0]?.toLowerCase())
                              setSelectedTemplateCategory(selectedOption)
                              onFieldChange('templateCategory', values[0])
                            }
                          }
                          labelSize={4}
                          dropDownSize={8}
                        // multiple={true}
                        // disabled={disableExternalTemplateCategory}
                        />
                      </Column>
                    </Row>
                  </Column>

                </Row>

                <Row>
                  <Column lg={12} className={isInvalidExternalTemplate || (isExternalTemplateEmpty) ? 'feedback-row' : ''}>
                    <DropDownWithLabelInline
                      label={label.EXTERNAL_TEMPLATE}
                      labelClass='inlineLable'
                      id='externalTemplateSelect'
                      placeholder={label.PLEASE_SELECT}
                      // options={showFilteredExternalTemplateOptions ? activeFilteredExternalTemplateOptions : activeExternalTemplateOptions}
                      options={externalTemplateOptions}
                      // options={activeExternalTemplateOptions}
                      defaultSelected={externalTemplateOptions.filter(({ value }) => value?.toLowerCase() === campaignDetailsInput.templateId?.toLowerCase())}
                      onChange={(values) => {
                        const selectedOption = externalTemplateOptions.find((option) => option.value?.toLowerCase() === values[0]?.toLowerCase())
                        setSelectedTemplate(selectedOption)
                        onFieldChange('templateId', values[0])
                      }}
                      labelSize={2}
                      dropDownSize={10}
                      isInvalid={isInvalidExternalTemplate || (isExternalTemplateEmpty && isValidationFromResultButton) || (isExternalTemplateEmpty && !isExternalTemplateTypeEmpty)}
                      invalidWarning={(isExternalTemplateEmpty && isValidationFromResultButton) || (isExternalTemplateEmpty && !isExternalTemplateTypeEmpty) ? constant.FIELD_BLANK : isInvalidExternalTemplate ? constant.INACTIVE_TEMPLATE_SELECTED_WARNING : ""} />
                  </Column>
                </Row>
                <Row>
                  <Column lg={12} className={isInvalidQueryName || (isQueryEmpty && isValidationFromResultButton) ? 'feedback-row' : ''}>
                    <DropDownWithLabelInline
                      label={label.QUERY_NAME}
                      id='queryNameOptions'
                      placeholder={label.PLEASE_SELECT}
                      labelClass='inlineLable'
                      // options={activeQueryNameOptions}
                      options={queryNameOptions}
                      defaultSelected={queryNameOptions.filter(({ value }) => value?.toLowerCase() === campaignDetailsInput.queryId?.toLowerCase())}
                      onChange={(values) => {
                        getQueryFilterSetup(values[0])
                        const selectedOption = queryNameOptions.find((option) => option.value?.toLowerCase() === values[0]?.toLowerCase())
                         setSelectedQuery(selectedOption)
                        //  setInvalidQueryName(selectedQuery && selectedQuery.status === "Inactive" ? true : false)
                        onFieldChange('queryId', values[0])
                        // handleQueryNameChange(values[0])
                      }}
                      labelSize={2}
                      dropDownSize={10}
                      isInvalid={isInvalidQueryName || (isQueryEmpty && isValidationFromResultButton)}
                      invalidWarning={isQueryEmpty && isValidationFromResultButton ? constant.FIELD_BLANK : isInvalidQueryName ? constant.INACTIVE_QUERY_SELECTED_WARNING : ""} />
                  </Column>
                </Row>

                {/* Filter criteria start */}
                {campaignQueryFilterSetup != null && campaignQueryFilterSetup != undefined && campaignDetailsInput.campaignFilters != null && campaignQueryFilterSetup.length > 0 &&
                  <><Row className={!isFilterConditionsOrValuesEmpty?'filterRow':''}
                  style={{ height: campaignQueryFilterSetup.length * 45 }}>
                    <Column lg={2} md={2} style={{ borderBottom: "0px" }}><Label text={label.FILTER_CRITERIA} /></Column>
                    <Column lg={10} md={10} style={{ borderBottom: "0px" }}>
                      {campaignQueryFilterSetup.map((filter, index) => (
                        <Row style={{ height: "45px" }}>
                          <Column lg={2} md={2} className='nopadding-left'>
                            <Label text={filter.filterLabel} />
                          </Column>
                          {/* Filter  conditions Dropdown started */}
                          {Array.isArray(filter.filterConditionOptions) && filter.filterConditionOptions.length > 0
                            && filter.filterType.toLocaleLowerCase() != constant.Data_Type_Currency_Range && filter.filterType.toLocaleLowerCase() != constant.Data_Type_Date_Range
                            && filter.filterType.toLocaleLowerCase() != constant.Data_Type_Number_Range &&
                            (
                              <Column lg={3} md={3} className='nopadding-left'>
                                <Select
                                  id={`filter-condition-${index}`}
                                  placeholder={label.SELECT_CONDITION}
                                  isRequired={true}
                                  isInvalid={isFilterConditionsEmpty[index]}
                                  options={filter.filterConditionOptions.map((option) => ({
                                    label: option.campaignFilterConditionValue,
                                    value: option.campaignFilterConditionId,
                                    isInactive:option.isInactive
                                  }))}
                                  defaultSelected={getSelectedFilterCondition(filter,index)}
                                  inputProps={{ className: `form-control texbox-input cstCollection `, multiple: true,
                                    title:(
                                      getSelectedFilterCondition(filter,index)?.map((option)=>option.label).toString().replaceAll(',','\r\n')
                                    )          
                                }}
                                  onChange={(selectedOptions) => {
                                    onFilterChange();
                                    if (selectedOptions) {
                                      handleFilterConditionChange(index, selectedOptions[0]);

                                    }
                                  }} />
                              </Column>
                            )}
                          {/* Filter  conditions Dropdown ended */}
                          {/* Filter values start */}
                          {/* Filter Dropdown start */}
                          {(filter.filterType.toLowerCase() === constant.Data_Type_Dropdown_SQL || filter.filterType.toLowerCase() === constant.Data_Type_Dropdown_List)
                            && (
                              <Column lg={6} md={6} className='nopadding-left mt-1 cst-dynamic-ddl'>
                                <Select
                                  id={`filter-value-dropdown-${index}`}
                                  options={filter.filterOptions.map((option) => ({
                                    label: option.campaignFilterOptionsValue,
                                    value: option.campaignFilterOptionsId,
                                    isInactive:option.isInactive
                                  }))}
                                  placeholder={label.SELECT_VALUE}
                                  multiple={true}
                                  isInvalid={isFilterValuesEmpty[index]}
                                  defaultSelected={getSelectedFilterValue(filter,index)}
                                  inputProps={{ className: `form-control cstCollection dynamic-query-filter `, multiple: true,
                                    title:(
                                      getSelectedFilterValue(filter,index)?.map((option)=>option.label).toString().replaceAll(',','\r\n')
                                    )          
                                }}
                                  onChange={(selectedOptions) => {
                                    onFilterChange();
                                    if (selectedOptions) {
                                      handleDropDownFilterValueChange(index, selectedOptions);
                                    }
                                  }} />
                              </Column>
                            )}

                          {/* Filter Dropdown end */}

                          {/* Filter currency/numeric start */}
                          {(
                            filter.filterType.toLowerCase() === constant.Data_Type_Number || filter.filterType.toLowerCase() === constant.Data_Type_ZipCode || filter.filterType.toLowerCase() === constant.Data_Type_Currency
                          )
                            && (
                              <><Column lg={6} md={6} className='nopadding-left'>
                                <TextInput
                                  id={`filter-initial-value-text-${index}`}
                                  placeholder={constant.FILTER_CRITERIA_ZIP_NUMBER_CURRENCY_PLACEHOLDER}
                                  isInvalid={!isFilterValuesEmpty[index] ? isInvalidZipNumbersCurrency[index] ? true : false : true}
                                  value={zipNumberCurrecnyValues[index]}
                                  title={zipNumberCurrecnyValues[index]}
                                  onKeyPress={(event)=>{
                                    if(event.key === 'e' || event.key === '-' || event.key === '+'){
                                        event.preventDefault()
                                    }
                                    else{
                                        return true;
                                    }
                                    return false
                                }}
                                  onChange={(event) => {
                                    onFilterChange();
                                    if ((/^[0-9;]*$/.test(event.target.value)) || ((filter.filterType.toLocaleLowerCase() === constant.Data_Type_Currency || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Number) && (/^[0-9;.]*$/.test(event.target.value)))) {
                                      const updatedArrayZipNumberValidation = [...isInvalidZipNumbersCurrency];
                                      updatedArrayZipNumberValidation[index] = false;
                                      setIsInvalidZipNumbersCurrency(updatedArrayZipNumberValidation);

                                      const updatedArrayForZipNumberCurrencyValue = [...zipNumberCurrecnyValues];
                                      updatedArrayForZipNumberCurrencyValue[index] = event.currentTarget.value;
                                      setZipNumberCurrecnyValues(updatedArrayForZipNumberCurrencyValue);
                                      handleNumericCurrencyZipFilterValuesChange(index, event.currentTarget.value);
                                    }

                                    else {
                                      const updatedArray = [...isInvalidZipNumbersCurrency];
                                      updatedArray[index] = true;
                                      // Set the state with the updated array
                                      setIsInvalidZipNumbersCurrency(updatedArray);
                                    }
                                  }} />
                              </Column>
                              </>
                            )}

                          {(filter.filterType.toLowerCase() === constant.Data_Type_Currency_Range ||
                            filter.filterType.toLowerCase() === constant.Data_Type_Number_Range)
                            && (
                              <>
                                <Column lg={4} md={4} className='nopadding-left'>
                                  <TextInput
                                    id={`filter-initial-value-text-${index}`}
                                    type='number'
                                    value={
                                      campaignDetailsInput.campaignFilters[index]?.filterValue[0]
                                    }
                                    onKeyPress={(event)=>{
                                      if(event.key === 'e' || event.key === '-' || event.key === '+'){
                                          event.preventDefault()
                                      }
                                      else{
                                          return true;
                                      }
                                      return false
                                  }}
                                    isInvalid={isFilterValuesEmpty[index] ? !campaignDetailsInput.campaignFilters[index]?.filterValue[0] ? true : false : false}
                                    onChange={(event) => {
                                      onFilterChange();
                                      handleNumericCurrencyInitialFilterValueChange(index, event.currentTarget.valueAsNumber);
                                    }} />
                                </Column>
                                <Column lg={1} md={1} style={{ borderBottom: "0px" }} className="d-flex align-items-stretch justify-content-center">
                                  <Label text={label.TO.toLocaleUpperCase()} style={{ fontWeight: 'normal' }} />
                                </Column><Column lg={4} md={4} className='nopadding-left'>
                                  <TextInput
                                    id={`filter-final-value-text-${index}`}
                                    type='number'
                                    value={campaignDetailsInput.campaignFilters[index]?.filterValue != undefined ? campaignDetailsInput.campaignFilters[index]?.filterValue[1] : 0}
                                    onKeyPress={(event)=>{
                                      if(event.key === 'e' || event.key === '-' || event.key === '+'){
                                          event.preventDefault()
                                      }
                                      else{
                                          return true;
                                      }
                                      return false
                                  }}
                                    isInvalid={isFilterValuesEmpty[index] ? !campaignDetailsInput.campaignFilters[index]?.filterValue[1] ? true : false : false}
                                    onChange={(event) => {
                                      onFilterChange();
                                      handleNumericCurrencyFinalFilterValueChange(index, event.currentTarget.valueAsNumber);
                                    }} />
                                </Column></>
                            )}

                          {/* Filter currency/numeric end */}
                          {/* Filter  Date Start */}

                          {filter.filterType == constant.Data_Type_Date
                            // filter.filterType == "Date" 
                            && (
                              <><Column lg={6} md={6} className='nopadding-left'>
                                <DateTimePicker
                                  dateFormat="MM/dd/yyyy"
                                  dateFormatCalendar="LLLL yyyy"
                                  dropdownMode="scroll"
                                  selected={campaignDetailsInput.campaignFilters[index]?.filterValue != null &&
                                    typeof (campaignDetailsInput.campaignFilters[index]?.filterValue[0] instanceof Date)
                                    ? campaignDetailsInput.campaignFilters[index]?.filterValue[0]
                                    : null}
                                  // isInvalid={isFilterValuesEmpty[index]}
                                  isInvalid={isFilterValuesEmpty[index]}
                                  onChange={(date) => {
                                    onFilterChange();
                                    handleInitialDateFilterValueChange(index, date);
                                    // handleDateFilterValueChange(index,date)
                                  }}>
                                </DateTimePicker>
                              </Column>
                              </>

                            )}

                          {/* Filter  Date end */}
                          {/* Filter  Date range start */}
                          {filter.filterType == constant.Data_Type_Date_Range && (

                            <>
                              <Column lg={4} md={4} className='nopadding-left'>
                                <DateTimePicker
                                  dateFormat="MM/dd/yyyy"
                                  dateFormatCalendar="LLLL yyyy"
                                  dropdownMode="scroll"
                                  selected={campaignDetailsInput.campaignFilters[index]?.filterValue &&
                                    campaignDetailsInput.campaignFilters[index]?.filterValue[0] instanceof Date
                                    ? campaignDetailsInput.campaignFilters[index]?.filterValue[0]
                                    : null}
                                  // isInvalid={isFilterValuesEmpty[index]}
                                  isInvalid={isFilterValuesEmpty[index] ? !campaignDetailsInput.campaignFilters[index]?.filterValue[0] ? true : false : false}
                                  onChange={(date) => {
                                    onFilterChange();
                                    handleInitialDateFilterValueChange(index, date);
                                  }}>
                                </DateTimePicker>
                              </Column>

                              <Column lg={1} md={1} style={{ borderBottom: "0px" }} className="d-flex align-items-stretch justify-content-center">
                                <Label text={label.TO.toLocaleUpperCase()} style={{ fontWeight: 'normal' }} />
                              </Column>
                              <Column lg={4} md={4} className='nopadding-left'>
                                <DateTimePicker
                                  dateFormat="MM/dd/yyyy"
                                  dateFormatCalendar="LLLL yyyy"
                                  dropdownMode="scroll"
                                  // // selected={campaignDetailsInput.campaignFilters[index]?.endDate}
                                  selected={campaignDetailsInput.campaignFilters[index]?.filterValue &&
                                    campaignDetailsInput.campaignFilters[index]?.filterValue[1] instanceof Date
                                    ? campaignDetailsInput.campaignFilters[index]?.filterValue[1]
                                    : null}
                                  //  isInvalid={isFilterValuesEmpty[index]}
                                  isInvalid={isFilterValuesEmpty[index] ? !campaignDetailsInput.campaignFilters[index]?.filterValue[1] ? true : false : false}
                                  onChange={(date) => {
                                    onFilterChange();
                                    handleFinalDateFilterValueChange(index, date);
                                  }}>
                                </DateTimePicker>
                              </Column>
                            </>
                          )}

                          {/* Filter  Date range end */}

                           {/* Free form start */}
                          {
                          (
                            filter.filterType?.toLowerCase() === constant.DATA_TYPE_FREEFORM
                            )
                            && (
                                <>
                                <Column lg={6} md={6} className='nopadding-left'> 
                                    <TextInput
                                        id={`filter-initial-value-text-${index}`}
                                        placeholder={''}
                                        style={{width:'100%'}}
                                        title={campaignDetailsInput.campaignFilters[index]?.filterValue.toLocaleString()}
                                        value={campaignDetailsInput.campaignFilters[index]?.filterValue.toLocaleString()}
                                        isInvalid={isFilterValuesEmpty[index]}
                                        onChange={(event) => {
                                            handleFreeformChange(index,event.currentTarget.value)
                                            }} 
                                    />
                                </Column>
                                </>
                            )}
                              {/* Free form end */}
                              {
                            index<campaignQueryFilterSetup.length -1 &&                                       
                          <Column lg={1} md={1} className='nopadding-left'>
                            <Label text={label.AND.toLocaleUpperCase()} style={{ fontWeight: 'normal' }} />
                          </Column>
}
                        </Row>

                      ))
                        // ) : ""
                      }
                    </Column>
                   
                  </Row>
                  </>
                }              
             {isFilterConditionsOrValuesEmpty &&        
                <Row>
              <Column lg={12}>
                <Label text={constant.FILTER_CRITERIA_CONSOLIDATED_ERROR} style={{ color: "red"}}></Label>             
                   </Column> 
                   </Row>}

                {/* Filter values end */}

                {/* Filter criteria end */}

                 <Row style={{height:issendOnDateOrFromEmailValidationError?"35px":""}}>
<Column lg={12} className={issendOnDateOrFromEmailValidationError?'no-border-bottom':''}>

<Row>
<Column lg={2} md={2} style={{ padding: 0}} className='no-border-bottom'><Label className='inlineLable' text={label.SEND_ON_DATE}/></Column>
                  <Column lg={10} md={10} className='no-border-bottom'>
                    <Row>
    <Column lg={4} md={4} className='nopadding-left'>
      
      <DateTimePicker
      name='sendOnDate'
      selected={campaignDetailsInput.sendOn}
      onChange={
        (date) => {
          onFieldChange('sendOn', date)
          onSendOnDateSelectedOrTemplateTypeChange(date, campaignDetailsInput.templateType)
        }
      }
      disabled={false}
      style={{width:'95%'}}
      minDate={tomorrowDate}
      // maxDate={maxDates}
      isInvalid={showSendOnDateEmptyError || isSendOnDateInPast || isSendOnDateInvalid}
      highlightedDates={blackoutDates}
       invalidWarning={showSendOnDateEmptyError ? constant.ERROR_MESSAGE_SEND_ON_DATE_EMPTY : isSendOnDateInPast ? constant.ERROR_MESSAGE_SEND_ON_DATE_PAST_DATE : isSendOnDateInvalid ? constant.ERROR_MESSAGE_SEND_ON_DATE_BLACKOUT_DATE : ""}
    />
                  </Column>
                  <Column lg={8} md={8} style={{ paddingLeft: "70px"}} className='no-border-bottom'>
                        <DropDownWithLabelInline
                          label={label.FROM_EMAIL}
                          labelClass='inlineLable'
                          id='fromEmailForRequestCampaign'
                          placeholder={label.CAMPAIGN_FROM_EMAIL_PLACEHOLDER}
                          // options={filteredExternalTemplateCategoriesOptions}
                          options={fromEmailOptions}
                          defaultSelected={fromEmailOptions.filter(({ value }) => value?.toLowerCase() === campaignDetailsInput.fromEmail?.toLowerCase())}
                          onChange={
                            (values) => {
                              onFieldChange('fromEmail', values[0])
                            }
                          }
                          labelSize={4}
                          dropDownSize={8}
                          isInvalid={isFromEmailEmpty}
                           invalidWarning={isFromEmailEmpty && constant.ERROR_MESSAGE_CAMPAIGN_FROM_EMAIL_EMPTY}
                        // multiple={true}
                        // disabled={disableExternalTemplateCategory}
                        />
                      </Column>
                      </Row>
      </Column>
      </Row>
      </Column>
                </Row>

                {(issendOnDateOrFromEmailValidationError) &&        
                <Row style={{height:"40px"}}>
              <Column lg={12}>
                   </Column> 
                   </Row>} 
 
                <Row>
                  <Column lg={2} md={2} style={{ padding: 0 }}><Label className='inlineLable' text={label.UPDATED} /></Column>
                  <Column lg={10} md={10}>
                    <Row>
                        <Column lg={6} md={6} className={'nopadding'}>
                        <Label text={label.UPDATED_BY+ label.COLON} className={'inlineLable nopadding'}  />
                        <Label text={campaignDetailsInput.changedBy} className={'inlineLable'} style={{ fontWeight: 'normal' }}/>
                        </Column>
                         <Column lg={6} md={6}>
                        <Label text={label.UPDATED_ON+ label.COLON} className={'inlineLable'} />
                        <Label text={campaignDetailsInput.changedDate} className={'inlineLable'} style={{ fontWeight: 'normal' }} />
                        </Column>
                    </Row>
                  </Column>

                </Row>

                <Row>
                  <Column lg={2} md={2} style={{ padding: 0 }}><Label className='inlineLable' text={label.STATUS} /></Column>
                  <Column lg={10} md={10}>
                    <Row>
                      <Column lg={6} md={6} className='nopadding-left'>
                        <Label text={campaignDetailsInput.status} />
                      </Column>
                      <Column lg={6} md={6} className='padding-left26'>
                        {isUserPermitToViewInactiveControl && <Checkbox
                          id='InactiveCampaign'
                          label={label.INACTIVE}
                          checked={campaignDetailsInput.status === constant.INACTIVE ? true : isCampaignInactive ? true : false}
                          onChange={handleInactiveChecboxChange}
                          disabled={isInactiveDisabled}
                          checkboxStyle={{ position: "relative", marginRight: "5px" }}
                        />}
                      </Column>
                    </Row>
                  </Column>
                </Row>

                <Row className='campaignSettings-footer'>
                  <Column lg={6} style={{paddingLeft:"0"}}>
                  {<Button color='primary' outlined={true} disabled={isSaveDisbaled} onClick={event => submitCampaignDetail(label.SAVE)}>{label.SAVE.toUpperCase()}</Button>}
                  {isUserPermitToViewCopyCampaign &&<Button color='second' outlined={true} disabled={!isUserPermitToCopyCampaign} onClick={(event) => { submitCampaignDetail(label.SAVE_AS) }}>{label.SAVE_AS.toUpperCase()}</Button>}
                  {<Button color='second' outlined={true} disabled={isDeleteDisabled} onClick={() => { setShowDeleteConfirmation(true) }}>{label.DELETE.toUpperCase()}</Button>}
                  </Column>
                  <Column lg={6} className='right-align' style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.125)" }}>
                    {isUserPermitToViewResultBtn && <Button color='primary' outlined={false} disabled={isResultsDisabled || !campaignDetailsInput.id} onClick={event => showCampaignResult()}>{label.RESULTS.toUpperCase()}</Button>}
                    <Button color='primary' outlined={true} onClick={BackToCampaignList}>{label.CANCEL.toUpperCase()}</Button>
                  </Column>
                </Row>
              </Column>
            </Panel>
            {/* </Column>
              </Row>
            </Column> */}

          </ErrorBoundary>
        </Column>
      </Row>
      <ConfirmModal
        modaltitle={label.DELETE_CAMPAIGN}
        showConfirmation={showDeleteConfirmation}
        setConfirmation={(showDeleteConfirmation) => setShowDeleteConfirmation(showDeleteConfirmation)}
        body={constant.CONFIRM_CAMPAIGN_DELETION}
        closeButtonClick={() => setShowDeleteConfirmation(false)}
        successButtonClick={() => deleteCampaign()}
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />

      <ConfirmModal
        modaltitle={label.OVERIDE_SEND_ON_DATE}
        showConfirmation={showOverrideSendOnDateModel}
        setConfirmation={(showOverrideConfirmation) => {
          setShowOverrideSendOnDateModel(showOverrideConfirmation)
          setIsSendOnDateInvalid(false)
          campaignDetailsInput.sendOn = null;
        }
        }
        body={constant.CONFIRM_BLACKOUT_DATE_SELECTION}
        closeButtonClick={() => {
          setShowOverrideSendOnDateModel(false)
          setIsSendOnDateInvalid(false)
          campaignDetailsInput.sendOn = null;
        }
        }
        successButtonClick={() => {
          setShowOverrideSendOnDateModel(false)
          setIsSendOnDateInvalid(false)
        }
        }
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />

      <ConfirmModal
        modaltitle={label.INACTIVE_CAMPAIGN}
        showConfirmation={showInactiveCampaignConfirmation}
        setConfirmation={(showInactiveCampaignConfirmation) => {
          setShowInactiveCampaignConfirmation(showInactiveCampaignConfirmation)
          setIsCampaignInactive(false)
        }
        }
        body={constant.CONFIRM_INACTIVE_CAMPAIGN_SELECTION}
        closeButtonClick={() => {
          setShowInactiveCampaignConfirmation(false)
          setIsCampaignInactive(false)
          setCampaignDetailsInput({
            ...campaignDetailsInput,
            isCampaignInactive: false
          })
        }
        }
        successButtonClick={() => {
          setShowInactiveCampaignConfirmation(false)
          setIsCampaignInactive(true)
          setCampaignDetailsInput({
            ...campaignDetailsInput,
            isCampaignInactive: true
          })
        }
        }
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />
      {/* {showScreenLeavingConfirmation &&<ScreenLeavingConfirmation/>} */}
      <ConfirmModal
        modaltitle={label.CONFIRM}
        showConfirmation={showScreenLeavingConfirmation}
        setConfirmation={(showExitConfirmation) => setShowScreenLeavingConfirmation(showExitConfirmation)}
        body={constant.CONFIRM_EXIT}
        closeButtonClick={() => setShowScreenLeavingConfirmation(false)}
        successButtonClick={() => BackToCampaignList()}
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />
    </>
  )
}

export default CampaignSettings 
