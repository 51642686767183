import { useState, useEffect } from 'react';
import { SelectOption } from '../../shared/components/input';
import { fetchCampaignExternalCategories, fetchCampaignExternalTemplates, fetchCampaignExternalTemplateTypes, fetchQueryTypes, fetchSendgridTemplate, fetchTemplateStatus, fetchDropdownOptions, fetchFromEmailAddressForRequestCampaign } from '../services/CampaignLookup'
import ExternalTemplateSelectOption from '../model/LookUpModel/ExternalTemplateSelectOption'



export const getExternalTemplateOptions = async (templateTypeId: string, templateCategoryId: string): Promise<ExternalTemplateSelectOption[]> => {
  const externalTemplates = await fetchCampaignExternalTemplates(templateTypeId, templateCategoryId);
  return (externalTemplates).sort((a, b) =>
    a.templateName > b.templateName ? 1 : -1).map((externalTemplate) => ({
      label: externalTemplate.templateName,
      value: externalTemplate.templateId,
      externalTemplateCategoryId: externalTemplate.templateCategoryId,
      isInactive: externalTemplate.inactive
    }));
};


export const getExternalTemplateCategoriesOptions = async (templateTypeId: string): Promise<SelectOption[]> => {

  const externalTemplatesCategories = await fetchCampaignExternalCategories(templateTypeId);
  return (externalTemplatesCategories).sort((a, b) =>
    a.lookupValue > b.lookupValue ? 1 : -1).map((externalTemplatesCategory) => {
      return {
        label: externalTemplatesCategory.lookupValue,
        value: externalTemplatesCategory.id,
        isInactive: externalTemplatesCategory.isInactive
      }
    }
    )
};

export const getExternalTemplateTypesOptions = async (): Promise<SelectOption[]> => {

  const externalTemplateTypes = await fetchCampaignExternalTemplateTypes();
  return (externalTemplateTypes).sort((a, b) =>
    a.lookupValue > b.lookupValue ? 1 : -1).map((externalTemplateType) => {
      return {
        label: externalTemplateType.lookupValue,
        value: externalTemplateType.id,
        isInactive: externalTemplateType.isInactive
      }
    }
    )
};

export const getQueryTypeOptions = async (): Promise<SelectOption[]> => {

  const queries = await fetchQueryTypes();
  return (queries).sort((a, b) =>
    a.queryName > b.queryName ? 1 : -1).map((querie) => {
      return {
        label: querie.queryName,
        value: querie.id,
        isInactive: querie.queryInactive
      }
    }
    )
};

export const getFromEmailAddressoptions = async (): Promise<SelectOption[]> => {

  const emails = await fetchFromEmailAddressForRequestCampaign();
  return (emails).sort((a, b) =>
    a.lookupValue > b.lookupValue ? 1 : -1).map((email) => {
      return {
        label: email.lookupValue,
        value: email.id,
        isInactive: email.isInactive
      }
    }
    )
}



//#region getSendgridTemplateOption
//Created by: Chitranshi Kulkarni 
//Created on: 14-Nov-2023
//Last Updated by:Chitranshi Kulkarni 
//Last Updated on:12-Sep-2023
//Below funtion fetch data from util function, by passing template Id and get the list
//of sendgrid template  and pass it to the another layer
//Param: typeId:string(type of template(marketing/transactional))

export const getSendgridTemplateOptions = async (typeId: string): Promise<[SelectOption[]]> => {
  var sendgridTemplates = await fetchSendgridTemplate(typeId)
  return (sendgridTemplates?.templates).sort((a, b) =>
    a.name > b.name ? 1 : -1).map((template) => {
      return {
        label: template.name,
        value: template.id.toUpperCase(),
        isInactive: template.inactive === undefined ? false : template.inactive
      };
    }
    )
};

async function getDropdownOptions(tableid: string) {
  return await fetchDropdownOptions(tableid);
}

//Created by: Chitranshi Kulkarni 
//Created on: 14-Nov-2023
//Last Updated by:Chitranshi Kulkarni 
//Last Updated on:14-Nov-2023
//Below funtion fetch data from util function, convert it in key value pair, 
//of data and pass it to filter --------------------
//Param: tableID:string(GUID)

export const getDropdownOptionsList = async (tableid: string): Promise<[SelectOption[]]> => {
  var optionList = await getDropdownOptions(tableid);
  //console.log(optionList)
  return (optionList.sort((a, b) =>
    a.lookupValue > b.lookupValue ? 1 : -1).map((option) => {
      return {
        label: option.lookupValue,
        value: option.id.toUpperCase(),
        isInactive: option.inactive
      };
    })
  )
};
















