import { EventApi } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import React, { useEffect, useState } from 'react'
import '../../../cs2common.css';
import './Calendar.scss';

import Event from './interfaces'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import {View as clsView} from'@fullcalendar/core/View'

type View = 'day' | 'month' | 'week'

export interface CalendarProps {
  view: View
  views: View[]
  events: Event[]
  blackoutDates: any[]
  disabled?: boolean
  onDateClick?: (date: Date, allDay: boolean) => void
  onDateRangeSelected?: (startDate: Date, endDate: Date, allDay: boolean) => void
  onEventClick?: (event: Event) => void
  onPrevClick?: () => void
  onNextClick?: () => void
  onTodayClick?: () => void
  onDateRender?:(el?:HTMLElement,view?:clsView)=>void
  onDayRender?:(view?:clsView,date?:Date,allDay?:boolean,el?:HTMLElement)=>void
  setCalendarApi: (api: any) => void,
  highlightedEventId: string,
  eventLimitFromFilter: number
}

const viewToCalendarViewMap = {
  month: 'dayGridMonth',
  week: 'timeGridWeek',
  day: 'timeGridDay',
}

const getEventFromFullCalendarEventApi = (e: EventApi): Event => ({
  id: e.id,
  start: e.start,
  end: e.end,
  title: e.title,
  allDay: e.allDay,
  url:e.url,
})

const getCalendarViewFromViewProp = (view: View) => (viewToCalendarViewMap as any)[view]

const getViewsFromViewsProp = (views: View[]) => {
  let viewsString = ''
  views.forEach((view) => {
    viewsString += `${getCalendarViewFromViewProp(view)},`
  })

  return viewsString.slice(0, viewsString.length - 1)
}

const Calendar = (props: CalendarProps) => {
  const {
    view,
    views,
    events,
    blackoutDates,
    disabled,
    onDateClick,
    onDateRangeSelected,
    onEventClick,
    onPrevClick,
    onNextClick,
    onTodayClick,
    onDateRender,
    onDayRender,
    setCalendarApi,
    highlightedEventId,
    eventLimitFromFilter
  } = props
  const fullCalendarRef = React.createRef<FullCalendar>()

  useEffect(() => {
    if(fullCalendarRef.current) {
      props.setCalendarApi(fullCalendarRef.current.getApi());
    }
  }, [fullCalendarRef])

  const onNavClick = (to: 'prev' | 'next' | 'today') => {
    const calendar = fullCalendarRef?.current?.getApi()
    if (!calendar) {
      return
    }

    const toCalls = {
      prev: {
        nav: () => calendar.prev(),
        callback: onPrevClick,
      },
      next: {
        nav: () => calendar.next(),
        callback: onNextClick,
      },
      today: {
        nav: () => calendar.today(),
        callback: onTodayClick,
      },
    }

    const { nav, callback } = toCalls[to]
    nav()
    if (callback) {
      callback()
    }
  }

  const renderDayCell = (info) => {
    const dateStr = info.date.toISOString().slice(0,10);
    if(blackoutDates.includes(dateStr)) {
      info.el.classList.add('stripe-background');
      // info.el.style.backgroundColor = '#5e5e5d';
      const titleElement = document.createElement('span');
      titleElement.innerText = 'Black Out';
      titleElement.style.color = 'white';
      titleElement.style.fontSize = '14px';
      titleElement.style.position = 'absolute';
      titleElement.style.bottom = '5px';
      titleElement.style.left = '5px';
      info.el.appendChild(titleElement);
    }
  }

  return (
    <FullCalendar
      events={events}
      ref={fullCalendarRef}
      selectable={!disabled}
      navLinks={true}
      allDaySlot={true}
      displayEventTime={false}
      customButtons={{
        customPrev: {
          text: 'previous',
          icon: 'chevron-left',
          click: () => onNavClick('prev'),
        },
        customNext: {
          text: 'next',
          icon: 'chevron-right',
          click: () => onNavClick('next'),
        },
        customToday: {
          text: 'today',
          click: () => onNavClick('today'),
        },
      }}
      header={{
        left: 'customPrev,customNext customToday',
        center: 'title',
        right: getViewsFromViewsProp(views),
      }}
      defaultView={getCalendarViewFromViewProp(view)}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      themeSystem="bootstrap"
      dateClick={(arg) => {
        if (onDateClick) {
          onDateClick(arg.date, arg.allDay)
        }
      }}
      select={(arg) => {
        if (onDateRangeSelected) {
          onDateRangeSelected(arg.start, arg.end, arg.allDay)
        }
      }}
      eventClick={(arg) => {      
        if (!disabled && onEventClick) {
          onEventClick(getEventFromFullCalendarEventApi(arg.event))
        }
      }}
      datesRender={(arg)=>{
        if(onDateRender){
          onDateRender(arg.el,arg.view)
        }
      }}
      dayRender={renderDayCell}
      // dayRender={(arg)=>{
      //   if(onDayRender){
      //     onDayRender(arg.view,arg.date,arg.allDay,arg.el)
      //   }
      // }}
      eventLimit={eventLimitFromFilter}
      eventLimitClick='popover'
      eventRender={info => {
        let title = info.event.title;
        let displayTitle = title.length > 30 ? title.slice(0,30) + '...' : title;
        let newData = `<div style="font-size: 14px;">${displayTitle}<br/><div style="font-size: 12px;">${info.event.extendedProps.statusValue}`;
        info.el.innerHTML = newData;
        info.el.title = title;
        if(info.event.id == highlightedEventId) {
          info.el.style.backgroundColor = 'yellow';
          info.el.style.borderColor = 'orange';
          info.el.style.color = 'black';
        }
        if(disabled){
          info.el.classList.add('disabled-event')
        }
      }}
    />
  )
}

Calendar.defaultProps = {
  view: 'week',
  events: [],
  views: ['day', 'week', 'month'],
  onPrevClick: () => undefined,
  onNextClick: () => undefined,
  onTodayClick: () => undefined,
}

export { Calendar }
