import { AxiosResponse } from "axios";
import { client } from "../../shared/services/client";
import { PolicyDtoRead } from "../model/PolicyDtoRead";

const policyBaseKey : string = "policy";
export default class PolicyApi {

    fetchCustomerPolicies = async (customerIds : string[]) : Promise<PolicyDtoRead[]> => {
        client.setBaseURLForService(policyBaseKey)
        const config ={}
        const res: AxiosResponse<PolicyDtoRead[]> =  await client.post("/Policy/getpoliciesbycustomerids?policyOption=Summary", customerIds, config);
        return res.data;
    }
};