import { useEffect, useState, useRef } from 'react'
import CustomerHistoryModel from '../model/CustomerHistory'
import Grid from '../../components/components/Grid/Grid'
import { toast } from 'react-toastify'
import * as constant from '../../shared/util/constant'
import {CustomerHistoryLabel as cstHistoryLabel}  from '../../shared/util/label'
import CustomerHistoryService from './CustomerHistoryService'
import Logger from '../../shared/services/Logger'
import { ErrorBoundary } from 'react-error-boundary'
import { FallbackComponent } from '../../shared/components/FallbackComponent'
import  './CustomerHistory.scss'

interface Props {
customerId: string
}

const CustomerHistory = (props: Props) => {
  toast.configure();

  const {customerId} = props;

  const [customerHistory, setCustomerHistory] = useState<CustomerHistoryModel[]>([]);

  const [loading, setLoading] = useState(true);

  const customerHistoryService  = new CustomerHistoryService();

  const initializeCustomerHistory = async () => {
    // setCustomerHistory([])
    setLoading(true)
    if (customerId) {
      try {
         let customerHistoryData = await customerHistoryService.getHistory(customerId)
         let modifiedData = customerHistoryData.map(item => ({
          ...item,
          combinedKey: `${item.changedBy}-${item.changedDate}`
         }));
         setCustomerHistory(modifiedData)
      }
      catch (error) {
        toast.error(constant.ERROR_MESSAGE_LOAD, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
        Logger.logError(error.toString());
      }
    } else {
        setCustomerHistory([])
    }

    setLoading(false);
  };

  useEffect(() => {
    initializeCustomerHistory();
  }, [customerId]);

  return (
    <><br></br>
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {loading ?
        (
          <div>Loading...</div>
        ) :
        customerHistory.length > 0 ?
          (
            <div>
              <Grid
                data={customerHistory}
                showFilters={true}
                id={'historyGrid'}
                columns={[
                  {
                    Header: 'Combined Key',
                    accessor: 'combinedKey'
                  },
                  {
                    Header: cstHistoryLabel.USER,
                    accessor: 'changedBy',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:300
                  },
                  {
                    Header: cstHistoryLabel.TIMESTAMP,
                    accessor: 'changedDate',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:300
                  },
                  {
                    Header: cstHistoryLabel.FIELD,
                    accessor: 'fieldName',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:275
                  },
                  {
                    Header: cstHistoryLabel.OLD_VALUE,
                    accessor: 'oldValue',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:300
                  },
                  {
                    Header: cstHistoryLabel.NEW_VALUE,
                    accessor: 'newValue',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:300
                  },
                  {
                    Header: cstHistoryLabel.ACTION,
                    accessor: 'action',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:200
                  },
                  {
                    Header: cstHistoryLabel.ENTITY,
                    accessor: 'entity',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                    width:200
                  }
                ]}
                sortDefault={[{ id: 'changedDate', asc: true }]}
                hiddenColumns={['selection','combinedKey']} 
                groupByColumns={['combinedKey']}
                columnsGroupOn={['changedDate','changedBy']}/>
            </div>) :
          (
            <div>No record found</div>
          )}

    </ErrorBoundary></>
  );
}
export default CustomerHistory
