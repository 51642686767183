import { useQuery } from 'react-query'

import {fetchOccupation, fetchSelfEmployment,fetchStateCodes,fetchCampaigninTimeframe,
    fetchCampaign,fetchFixedStatus,fetchRoofType,fetchRoofShape,fetchRoofMaterial,
    fetchConstructionType,fetchPoolType,fetchTrampoline,fetchDogBreed,fetchCarrier,
    fetchWritingCompany,fetchNumberofYear,fetchAgency,fetchPolicyType,fetchPolicyStatus,
    fetchLastNoteEntered} from '../services/LookupAPI'



export function getOccupationsList() {
   return useQuery(['occupationValue'],fetchOccupation);
   //return {data:testOccupation,isLoading:false}
}

export function getSelfEmploymentList() {
    return useQuery(['SelfEmploymentValue'],fetchSelfEmployment);
    //return {data:testSelfEmployment,isLoading:false}
 }

 export function getStateCodeList() {
    return useQuery(['stateName'],fetchStateCodes);
    //return {data:testStateCode,isLoading:false}
 }

 export function getCampaigninTimeframeList() {
    return useQuery(['MonthName'],fetchCampaigninTimeframe);
    //return {data:testCampaignTimeFrame,isLoading:false}
 }

 export function getCampaignList() {
    return useQuery(['CampaignName'],fetchCampaign);
    //return {data:testCampaign,isLoading:false}
 }

 export function getFixedStatusList() {
    return useQuery(['StatusValue'],fetchFixedStatus);
    //return {data:testFixedStatus,isLoading:false}
 }

 export function getRoofTypeList() {
    return useQuery(['RoofName'],fetchRoofType);
    //return {data:testRoofType,isLoading:false}
 }

 export function getRoofShapeList() {
    return useQuery(['RoofShapeName'],fetchRoofShape);
    //return {data:testRoofShape,isLoading:false}
 }

 export function getRoofMaterialList() {
    return useQuery(['RoofMaterialName'],fetchRoofMaterial);
    //return {data:testRoofMaterial,isLoading:false}
 }


 export function getConstructionTypeList() {
    return useQuery(['ConstructionTypeName'],fetchConstructionType);
    //return {data:testConstructionType,isLoading:false}
 }

 export function getPoolTypeList() {
    return useQuery(['PoolTypeName'],fetchPoolType);
    //return {data:testPoolType,isLoading:false}
 }

 export function getTrampolineList() {
    return useQuery(['TrampolineName'],fetchTrampoline);
    //return {data:testTrampoline,isLoading:false}
 }

 export function getDogBreedList() {
    return useQuery(['DogBreedName'],fetchDogBreed);
    //return {data:testDogBreed,isLoading:false}
 }

 export function getCarrierList() {
    return useQuery(['CarrierName'],fetchCarrier);
    //return {data:testCarrier,isLoading:false}
 }

 export function getWritingCompanyList() {
    return useQuery(['WritingCompanyName'],fetchWritingCompany);
    //return {data:testWritingCompany,isLoading:false}
 }

 export function getNumberofYearList() {
    return useQuery(['YearValue'],fetchNumberofYear);
    //return {data:testNumberofYears,isLoading:false}
 }

 export function getAgencyList() {
    return useQuery(['AgencyName'],fetchAgency);
    //return {data:testAgency,isLoading:false}
 }

 export function getPolicyTypeList() {
    return useQuery(['PolicyTypeName'],fetchPolicyType);
    //return {data:testPolicyType,isLoading:false}
 }

 export function getPolicyStatusList() {
    return useQuery(['PolicyStatusName'],fetchPolicyStatus);
    //return {data:testPolicyStatus,isLoading:false}
 }

 export function getLastNoteEnteredByList() {
    return useQuery(['LastNoteEnetredName'],fetchLastNoteEntered);
    //return {data:testNoteEnteredBy,isLoading:false}
 }

