import { deleteCampaign, fetchCampaignDetails, getBlackoutDates, saveCampaignDetails, updateCampaignDetails, getcsvResultFromCampaignId } from '../services/CampaignAPI'
import CampaignDetailsUI from '../model/CampaignDetailUI'
import moment from 'moment';
import CampaignFilterUI from '../model/CampaignFilterUI';
import { fetchCampaignStatus } from '../services/CampaignLookup';
import { DEFAULT_GUID } from '../../shared/util/constant';
import CampaignDetailsAPIRequest from '../model/CampaignDetailsAPIRequest';
import CampaignFilterAPIResponse from '../model/CampaignFilterAPIResponse';
import CampaignDetailsAPIResponse from '../model/CampaignDetailsAPIResponse';

export default class CampaignDetailsUtils {

private static async  getStatusValueFromCode(
  statusCode: string
): Promise<string> {
  // TODO: Implement this function to get the campaign filter condition options from the admin API.
  var campaignStatus = await fetchCampaignStatus()
  return campaignStatus.find(campaignStatus => campaignStatus.id == statusCode).lookupValue

}

private static async  getStatusCodeFromValue(
  statusValue: string
): Promise<string> {
  // TODO: Implement this function to get the campaign filter condition options from the admin API.
  var campaignStatus = await fetchCampaignStatus()
  return campaignStatus.find(campaignStatus => campaignStatus.lookupValue === statusValue).id

}

private static formatFilterValues = (filterValues) => {

  return filterValues.map((value) => {
    if (typeof value === 'object') {
      return `${moment(value).startOf('day').format('YYYY-MM-DD')}`;
    } else {
      return value;
    }
  });
};


private static async campaignDetailsUIToAPIRequest(campaignDetailsUI: CampaignDetailsUI): Promise<CampaignDetailsAPIRequest> {
  //save
  const sendOnDateForApi = campaignDetailsUI.sendOn ? `${moment(campaignDetailsUI.sendOn).format('YYYY-MM-DD')}` : "";

  const campaignFilterUseModels = campaignDetailsUI.campaignFilters ? campaignDetailsUI.campaignFilters.map(
    (filter: CampaignFilterUI) => ({
      id: filter.id ? filter.id : DEFAULT_GUID,
      filterId: filter.filterId ? filter.filterId : DEFAULT_GUID,
      logic: filter.filterConditionSelectedId ? filter.filterConditionSelectedId : '',
      campaignFilterDataModel: {
        id: filter.campaignFilterDataModelId ? filter.campaignFilterDataModelId : DEFAULT_GUID,
        campaignFilterUseId: filter.campaignFilterDataModelCampaignFilterUseId ? filter.campaignFilterDataModelCampaignFilterUseId : DEFAULT_GUID,
        dataItem: JSON.stringify(
          CampaignDetailsUtils.formatFilterValues(filter.filterValue)
        )
      }
    })
  ) : []

  const serializedCampaignDetails: CampaignDetailsAPIRequest = {
    ...campaignDetailsUI,
    changedDate: campaignDetailsUI.changedDate ? `${moment(campaignDetailsUI.changedDate).format('YYYY-MM-DD')}` : "",
    status: campaignDetailsUI.status ? await CampaignDetailsUtils.getStatusCodeFromValue(campaignDetailsUI.status) : DEFAULT_GUID,
    templateType: campaignDetailsUI.templateType != '' && campaignDetailsUI.templateType != undefined ? campaignDetailsUI.templateType : DEFAULT_GUID,
    templateCategory: campaignDetailsUI.templateCategory != '' && campaignDetailsUI.templateCategory != undefined ? campaignDetailsUI.templateCategory : DEFAULT_GUID,
    templateId: campaignDetailsUI.templateId != '' && campaignDetailsUI.templateId != undefined ? campaignDetailsUI.templateId : DEFAULT_GUID,
    queryId: campaignDetailsUI.queryId != '' && campaignDetailsUI.queryId != undefined ? campaignDetailsUI.queryId : DEFAULT_GUID,
    sentDate: sendOnDateForApi,
    campaignFilterUseModels: campaignFilterUseModels,
    campaignInactive: campaignDetailsUI.isCampaignInactive,
    fromEmail: campaignDetailsUI.fromEmail != '' && campaignDetailsUI.fromEmail != undefined ? campaignDetailsUI.fromEmail : DEFAULT_GUID,
  };

  return serializedCampaignDetails

}

private static async  campaignDetailsAPIResponseToUI(campaignDetailsAPIResponseData: CampaignDetailsAPIResponse): Promise<CampaignDetailsUI> {
  const sendOnDateFromApi = campaignDetailsAPIResponseData._sentDate ? new Date(moment(campaignDetailsAPIResponseData._sentDate).format('YYYY/MM/DD')) : null;
  const campaignFiltersUI = campaignDetailsAPIResponseData._campaignFilterUse ? campaignDetailsAPIResponseData._campaignFilterUse.map(
    (filter: CampaignFilterAPIResponse) => ({
      filterId: filter._filterId,
      filterLabel: filter._filterId,// to ask krishna to give this, as i have to show in UI
      filterConditionSelectedId: filter._logic,// based on what user selects from condition dropdown from UI 
      filterValue: JSON.parse(filter._campaignFilterData._dataItem).map((value) =>
        typeof value === 'string' && /^\d{4}-\d{1,2}-\d{1,2}$/.test(value)
          ?
          new Date(moment(value).format('YYYY/MM/DD'))
          :
          value
      ),
      // extra for api
      id: filter._id,
      campaignFilterDataModelId: filter._campaignFilterData._id,
      campaignFilterDataModelCampaignFilterUseId: filter._campaignFilterData._campaignFilterUseId


    })) : [];


  const campaignDetailsUIResponse: CampaignDetailsUI = {
    id: campaignDetailsAPIResponseData._id,
    campaignName: campaignDetailsAPIResponseData._campaignName,
    description: campaignDetailsAPIResponseData._description,
    templateType: campaignDetailsAPIResponseData._templateType,
    templateCategory: campaignDetailsAPIResponseData._templateCategory,
    templateId: campaignDetailsAPIResponseData._templateId,
    queryId: campaignDetailsAPIResponseData._queryId,
    isCampaignInactive: campaignDetailsAPIResponseData._campaignInactive,// not yet added in backend
    changedDate: moment(campaignDetailsAPIResponseData._changedDate ? campaignDetailsAPIResponseData._changedDate : '').format("MM/DD/YYYY"),
    changedBy: campaignDetailsAPIResponseData._changedBy,
    sendOn: sendOnDateFromApi,
    campaignFilters: campaignFiltersUI,
    status: await CampaignDetailsUtils.getStatusValueFromCode(campaignDetailsAPIResponseData._status),
    fromEmail: campaignDetailsAPIResponseData._fromEmail
  };
  return campaignDetailsUIResponse
}


static  saveCampaignDetailsUtils = async (
  campaignDetails: CampaignDetailsUI
) => {

  const serializedCampaignDetails: CampaignDetailsAPIRequest = await CampaignDetailsUtils.campaignDetailsUIToAPIRequest(campaignDetails)

  try {
    // Make the API request
    const campaignDetailsResponseSave = await saveCampaignDetails(serializedCampaignDetails);
    // var campaignDetailsUIResponse:CampaignDetailsUI

    if (campaignDetailsResponseSave.status === 400) {
      throw new Error(campaignDetailsResponseSave.data.error); // Throw with specific error message
    }

    else {
      const campaignDetailsAPIResponseData: CampaignDetailsAPIResponse = campaignDetailsResponseSave.data
      const campaignDetailsUIResponse: CampaignDetailsUI = await CampaignDetailsUtils.campaignDetailsAPIResponseToUI(campaignDetailsAPIResponseData)
      return campaignDetailsUIResponse
    }

  } catch (error) {
    // Handle errors here
    console.error('Error saving campaign details:', error);
    // return error
    throw error; // Rethrow the error for the caller to handle
  }
};


static updateCampaignDetailsUtils = async (
  campaignDetails: CampaignDetailsUI
) => {

  const serializedCampaignDetails: CampaignDetailsAPIRequest = await CampaignDetailsUtils.campaignDetailsUIToAPIRequest(campaignDetails)


  try {
    // Make the API request
    const campaignDetailsResponseUpdate = await updateCampaignDetails(serializedCampaignDetails);
    // var campaignDetailsUIResponse:CampaignDetailsUI

    if (campaignDetailsResponseUpdate.status === 400) {
      throw new Error(campaignDetailsResponseUpdate.data.error); // Throw with specific error message
    }

    else {
      const campaignDetailsAPIResponseData: CampaignDetailsAPIResponse = campaignDetailsResponseUpdate.data
      const campaignDetailsUIResponse: CampaignDetailsUI = await CampaignDetailsUtils.campaignDetailsAPIResponseToUI(campaignDetailsAPIResponseData)
      return campaignDetailsUIResponse
    }

  } catch (error) {
    // Handle errors here
    console.error('Error updating campaign details:', error);
    // return error
    throw error; // Rethrow the error for the caller to handle
  }

};


static getCampaignDetails = async (campaignId: string): Promise<CampaignDetailsUI | null> => {
  if (!campaignId) {
    return null;
  }

  try {
    // Make the API request
    const campaignDetailsResponse = await fetchCampaignDetails(campaignId);
    // var campaignDetailsUIResponse:CampaignDetailsUI
    if (campaignDetailsResponse.status === 400) {
      throw new Error(campaignDetailsResponse.data.error); // Throw with specific error message
    }

    else {
      const campaignDetailsAPIResponseData: CampaignDetailsAPIResponse = campaignDetailsResponse.data
      const campaignDetailsUIResponse: CampaignDetailsUI = await CampaignDetailsUtils.campaignDetailsAPIResponseToUI(campaignDetailsAPIResponseData)
      return campaignDetailsUIResponse
    }

  } catch (error) {
    // Handle errors here
    console.error('Error getting campaign details:', error);
    // return error
    throw error; // Rethrow the error for the caller to handle
  }
};


static deleteCampaignUtils = async (campaignId: string): Promise<any> => {
  if (campaignId !== null && campaignId !== undefined && campaignId !== '') {
    try {
      const deleteCampaignResponse = await deleteCampaign(campaignId);
      if (deleteCampaignResponse.status === 400) {
        throw new Error(deleteCampaignResponse.data.error); // Throw with specific error message
      }
      else {
        return deleteCampaignResponse
      }

  }
  catch (error) {
    // Handle errors here
    console.error('Error deleting campaign', error);
    // return error
    throw error
  }

}
  else {
      return null
  }
}


static getcsvResultFromId = async (campaignId: string): Promise<any | null> => {
  if (!campaignId) {
    return null;
  }

  try {
    // Make the API request
    const campaignDetailsResponse = await getcsvResultFromCampaignId(campaignId);
   return campaignDetailsResponse.data;

  } catch (error) {
    // Handle errors here
    console.error('Error getting campaign details:', error);
    // return error
    throw error; // Rethrow the error for the caller to handle
  }
};


static getBlackoutDatesUtils = async (): Promise<any> => {
  var blackoutDates: Date[] = [];
  try {
    const blackoutDatesResponse = await getBlackoutDates();
    if (blackoutDatesResponse.status === 400) {
      throw new Error(blackoutDatesResponse.data.error); // Throw with specific error message
    }
    else {
      blackoutDates=blackoutDatesResponse.data?.map(blackoutDate=> {
      return (
        new Date(moment(blackoutDate).format('YYYY/MM/DD'))
      ) 
     });
    }
      return blackoutDates
      // return blackoutDatesResponse.data
    }

catch (error) {
  // Handle errors here
  console.error('Error deleting campaign', error);
  // return error
  throw error
}  
}
}