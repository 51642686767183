
import React, { useEffect } from 'react';
import './ReactPaginator.scss';
import ReactPaginatorService from './ReactPaginatorService';

export interface IReactPaginatorState {
    totalItemsCount : number,
    itemsPerPageCount : number,
    currentPageNumber : number,
    numberOfPageButtonsToShow : number,
    pageButtonIndexes : number[]
}

export interface IReactPaginator {
    paginatorState : IReactPaginatorState,
    onPageChange : (paginatorState : IReactPaginatorState) => void
}

const ReactPaginator : React.FunctionComponent<IReactPaginator> = (props) => {
    const reactPaginatorService = new ReactPaginatorService();

    const { paginatorState } = props;

    const { totalItemsCount, itemsPerPageCount, currentPageNumber, numberOfPageButtonsToShow, pageButtonIndexes } = paginatorState;
    
    const totalPages = reactPaginatorService.getTotalPages(totalItemsCount, itemsPerPageCount);

    const disablePreviousButton = currentPageNumber == 0 ? true : false;
    const disableNextButton = currentPageNumber == totalPages;

    const onFirstPageButtonClick = () => {
        props.onPageChange({...props.paginatorState, currentPageNumber : 0});
    }

    const onLastPageButtonClick = () => {
        props.onPageChange({...props.paginatorState, currentPageNumber : totalPages});
    }

    const onPreviousPageButtonClick = () => {
        let prevPage = currentPageNumber == 0 ? 0 : currentPageNumber -1;
        props.onPageChange({...props.paginatorState, currentPageNumber : prevPage});
    }

    const onNextPageButtonClick = () => {
        let nextPage = currentPageNumber == totalPages ? totalPages : currentPageNumber + 1;
        props.onPageChange({...props.paginatorState, currentPageNumber : nextPage});
    }

    const onPageButtonClick = (goToPageNumber) => {
        if (goToPageNumber == currentPageNumber) {
            return;
        }

        props.onPageChange({...props.paginatorState, currentPageNumber : goToPageNumber});
    }

    useEffect(() => {

        if (totalItemsCount == 0 || pageButtonIndexes.includes(currentPageNumber)) {
            return;
        }

        let pageButtonsToShow = reactPaginatorService.getPaginationButtonIndexes(currentPageNumber, pageButtonIndexes, totalPages, numberOfPageButtonsToShow);
        props.onPageChange({...props.paginatorState, pageButtonIndexes : pageButtonsToShow});

    },[currentPageNumber, totalItemsCount]);

    const pageBtns = pageButtonIndexes.map(pageNumber => {
        let activeLinkClass = pageNumber == props.paginatorState.currentPageNumber ? 'rct-pgn-btn--active' : '';
        return  <button key={`rct-page-btn-${pageNumber}`} className={`btn btn-outline-primary btn-sm rct-pgn-btn ${activeLinkClass}`} onClick={() => onPageButtonClick(pageNumber)}>
            {pageNumber + 1}
        </button>;
    });

    return <div>
        <button className=' btn btn-outline-primary btn-sm rct-pgn-btn rct-pgn-btn--icon' onClick={onFirstPageButtonClick} disabled={disablePreviousButton}>
            {"<<"}
        </button>
        <button className=' btn btn-outline-primary btn-sm rct-pgn-btn rct-pgn-btn--icon' onClick={onPreviousPageButtonClick} disabled={disablePreviousButton}>
            {"<"}
        </button>
        {pageBtns}
        <button className=' btn btn-outline-primary btn-sm rct-pgn-btn rct-pgn-btn--icon' onClick={onNextPageButtonClick} disabled={disableNextButton}>
            {">"}
        </button>
        <button className=' btn btn-outline-primary btn-sm rct-pgn-btn rct-pgn-btn--icon' onClick={onLastPageButtonClick} disabled={disableNextButton}>
            {">>"}
        </button>
    </div>
}

export default ReactPaginator;