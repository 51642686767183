import { fetchCampaignResults,requestCampaignDetails, sendTestMailForCampaignResult } from '../services/CampaignAPI'
import CampaignDetailUI from '../model/CampaignDetailUI'
import CampaignResult from '../model/CampaignResult';
import RequestCampaignRepsonse from '../model/RequestCampaignRepsonse';
import CampaignSendTest from '../model/CampaignSendTest';
import moment from 'moment';
import RequestCampaignApiRequest from '../model/RequestCampaignApiRequest';
import { toast } from 'react-toastify';
import * as constant from '../../shared/util/constant'

export default class CampaignResultsUtils {


static getCampaignResults= async(campaignId:string): Promise<CampaignResult[] | null>=> {
  if (!campaignId) {
    return null;
  }

  try {
    // Make the API request
    const campaignResultsResponse= await fetchCampaignResults(campaignId);
    // var campaignDetailsUIResponse:CampaignDetailsUI
    if (campaignResultsResponse.status === 400) {
      throw new Error(campaignResultsResponse.data.error); // Throw with specific error message
    }

    else {
      return campaignResultsResponse.data
    }

  } catch (error) {
    // Handle errors here
    console.error('Error getting campaign results:', error);
    // return error
    throw error; // Rethrow the error for the caller to handle
  }
};

static sendTestMailForCampaignResultUtils=async(campaignSendTest:CampaignSendTest)=>{
    const sendTestMailResponse = await sendTestMailForCampaignResult(campaignSendTest);

    if (sendTestMailResponse.status === 400) {
      toast.error(constant.CAMPAIGN_SEND_TEST_ERROR_MESSAGE, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: constant.AUTO_CLOSE
      });
    }
    else {
      return sendTestMailResponse.data
    }
}

static requestCampaignDetailsUtils=(campaignDetails:CampaignDetailUI):RequestCampaignRepsonse=>{

  const sendOnDateForApi = campaignDetails.sendOn?`[${moment(campaignDetails.sendOn).format('YYYY-MM-DD')}]`:"";
  const formatFilterValues = (filterValues) => {

    return filterValues.map((value) => {
      if (typeof value === 'object') {
        return `[${moment(value).startOf('day').format('YYYY-MM-DD')}]`;
      } else {
        return value;
      }
    });
  };
  // Serialize filter values before sending to the API
  const serializedCampaignDetails: CampaignDetailsAPI = {
    ...campaignDetails,
    // sendOn:new Date(campaignDetails.sendOn).toISOString().split('T')[0],
    sendOn:sendOnDateForApi,
    campaignFilters: campaignDetails.campaignFilters.map((filter) => ({
      ...filter,
      filterValue: JSON.stringify(
         formatFilterValues(filter.filterValue)
        // filter.filterValue
      ),
    })),
  };

   const requestCampaignResposne = requestCampaignDetails(serializedCampaignDetails)
   return requestCampaignResposne
}

static requestCampaign = (requestCampaignApiRequest: RequestCampaignApiRequest) => {
  // console.log("From utils:" + requestCampaignApiRequest.isForcedRequestPopup)
  const requestCampaignResposne = requestCampaignDetails(requestCampaignApiRequest)
  return requestCampaignResposne
}

}