import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios, { AxiosResponse } from "axios";
import { client } from "../services/client";


interface UserContextState {
    userContext: UserContext
    status: string // to handle loading
    userPrivileges: RolePrivilege[]
}

interface UserContext {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    objectId: string,
    isActive: boolean,
}

interface RolePrivilege {
    appId: string,
    featureId: string,
    featureName: string,
    allowAccess: boolean,
    elementPrivileges: ElementPrivilege[]
}

interface ElementPrivilege {
    featureId: string,
    elementName: string,
    subject: string,
    action: string,
    access: boolean,
    insert: boolean,
    update: boolean,
    delete: boolean
}

const initialState: UserContextState = {
    userContext: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        objectId: '',
        isActive: false
    },
    status: 'idle',
    userPrivileges: []
}

export const fetchUserDetails = createAsyncThunk('userContext/fetchUser', async () => {
    client.setBaseURLForService("security");
    const response: AxiosResponse<any> = await client.get("/api/RolePrivilege/GetUserContextOnLogin");
    return response.data;
})

const userContextSlice = createSlice({
    name: 'userContext',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchUserDetails.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchUserDetails.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if(action.payload) {
                state.userContext = {
                    id: action.payload.userContextDto.id,
                    firstName: action.payload.userContextDto.firstName,
                    lastName: action.payload.userContextDto.lastName,
                    email: action.payload.userContextDto.email,
                    objectId: action.payload.userContextDto.objectId,
                    isActive: action.payload.userContextDto.isActive
                }
                state.userPrivileges = action.payload.userPrivilegesDtos.map(privilege => {
                    return {
                        ...privilege,
                    };
                });
            }
        })
    },
})

export default userContextSlice.reducer;