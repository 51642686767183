import { TextField, Label, Row, Column, TextInput } from '../../../components'
import React from 'react'

interface Props {
  value: string
  label?: string
  name: string
  isEditable?: boolean
  type: 'text' | 'email' | 'number' | 'tel' | 'password'
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  isRequired?: boolean
  feedback?: string
  isInvalid?: boolean
  className?:string
  labelClass?:string
  maxLength?:number
  tabIndex?:number
  lglabel?:number,
  mdlabel?:number,
  lgText?:number,
  mdText?:number,
  isEmpty?:boolean
  isTooShort?:boolean
  errorText?:string
  invalidWarning?:string,
  invalidLableClass?:string,
  errorLableDivClassName? : string
  title?:string
}

const inputStyle = {
  // width:'100%',
  // height:'30px',
  //  fontSize:'small'
 }
 const labelStyle={
  //  fontSize:'small'

 }
const TextFieldWithLabelInline = (props: Props) => {
  const {
    value,
    label,
    name,
    isEditable,
    onChange,
    placeholder,
    isRequired,
    feedback,
    isInvalid,
    className,
    labelClass,
    maxLength,
    tabIndex,
    lglabel,
    mdlabel,
    lgText,
    mdText,
    isEmpty,
    isTooShort,
    errorText,
    invalidWarning,
    invalidLableClass,
    errorLableDivClassName,
    title
  } = props
  const id = `${name}TextField`
  return (
    <Row >
     {label &&<Column lg={lglabel} md={mdlabel} style={{padding:0,borderBottom:0}}> <Label text={label} htmlFor={id} isRequired={isRequired} className={labelClass} style={labelStyle}/></Column>}
     <Column lg={lgText} md={mdText} className='noPaddingLeft'>
     <TextField
        feedback={feedback}
        id={id}
        isInvalid={isInvalid}
        value={value}
        disabled={!isEditable}
        onChange={onChange}
        placeholder={placeholder || label}
        className={className}
        style={inputStyle}
        maxLength={maxLength}
        tabIndex={tabIndex}
        isEmpty={isEmpty}
        isTooShort={isTooShort}
        errorText={errorText}
        invalidWarning={invalidWarning}
        invalidLableClass={invalidLableClass}
        errorLableDivClassName={errorLableDivClassName}
        title={title}
        
      />
     </Column>
      
     
    </Row>
  )
}

TextFieldWithLabelInline.defaultProps = {
  value: '',
  type: 'text',
}


export default TextFieldWithLabelInline