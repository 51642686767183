import { Link } from 'react-router-dom'
import { Button, Row, Column, Label } from '../../../../components'
import Grid from '../../../../components/components/Grid/Grid'
import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import CampaignDetails from '../../../filter/CampaignDetails'
import { useLocation, useHistory } from 'react-router-dom'
import * as label from '../../../../shared/util/label'
import { ErrorBoundary } from 'react-error-boundary'
import * as constant from '../../../../shared/util/constant'
import ScrollTop from '../../../../shared/components/scrollTopButton'
import { AbilityContext } from '../../../../../src/shared/abilities/AbilityContext';
import { actions } from 'react-table'


interface Props {
  // handleSelectedRowsChange?: any
  data?: any
  showFilters?: any
  id?: string
  gridHeader?: string
  gridDesc?: string
  hiddenColumns?:string[]
  subject?:any
}

const CampaignGrid = (props: Props) => {

  const {
    // handleSelectedRowsChange,
    data,
    showFilters,
    id,
    gridHeader,
    gridDesc,
    hiddenColumns,
  } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const path = useLocation()
  const ability = useContext(AbilityContext);
  const canViewNewCampaign=ability.can('create','Campaign',constant.ScreenName.CAMPAIGN)
  const canUserViewCalendar = ability.can('view','Campaign Calendar',constant.ScreenName.Campaign_Calendar);
  const canUserReadCalendar = ability.can('read','Campaign Calendar',constant.ScreenName.Campaign_Calendar);
  const canViewCampaignTemplate=ability.can('view','Campaign Template',constant.ScreenName.CAMAPIGN_TEMAPLATE)
  const canReadCampaignTemplate=ability.can('read','Campaign Template',constant.ScreenName.CAMAPIGN_TEMAPLATE)
  const canUserViewApprovalScreen = ability.can('view','Campaign Approval',constant.ScreenName.CAMPAIGN_APPROVAL);
  const canUserReadApprovalScreen = ability.can('read','Campaign Approval',constant.ScreenName.CAMPAIGN_APPROVAL);
  const canUserViewQuery = ability.can('view','Campaign Query',constant.ScreenName.Campaign_Query);
  const canUserReadQuery = ability.can('read','Campaign Query',constant.ScreenName.Campaign_Query);

  const FallbackComponent = ({ error, resetErrorBoundary }) => {
    console.log("An error occurred:");
    console.log(error.message);
    return (
        <div>
            <h1>An error occurred: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
  };


  const navigateTo = (location: string) => {
    history.push(location)
  }
  const [highlightedMenu, setHighlightedMenu] = useState('Dashboard');
  const [includeInactiveRecords, setIncludeInactiveStatus] = useState(false);
  var tabLink = document.querySelectorAll(".nav-link");

  function highlightMenu(menuItem?) {

    tabLink.forEach(function (item) {
      item.classList.remove("active");
    });
    var activeMenu = '';
    if (menuItem === undefined || menuItem === '' || menuItem === null) {
      activeMenu = location.pathname.slice(location.pathname.lastIndexOf('/') + 1)
    }
    else {
      activeMenu = menuItem
    }

    if (activeMenu === 'CampaignDashboard') {
      activeMenu = 'Dashboard'
    }
    history.push(location)
    setHighlightedMenu(activeMenu)
    document.getElementById(activeMenu).classList.add('active');
  }

  useEffect(() => {
    history.push(location)
    //  console.log('1')
    highlightMenu();
  }, [document.onload])

  const checkInactiveStatus = (event) => {
    if (event.target.checked) {
      setIncludeInactiveStatus(true)

    }
    else {
      setIncludeInactiveStatus(false)
    }
  }
  return (
    <>
    <ErrorBoundary FallbackComponent={FallbackComponent}>
    <div className='outerPanel'>
      <Row>
        <Column lg={8} md={8} className=""><b>{gridHeader.toUpperCase()}</b>
          &nbsp; &nbsp;{gridDesc}
          <Column lg={5} md={5}>
              <input type={'checkbox'} value={'Include inactive'} onChange={(event) => checkInactiveStatus(event)} />&nbsp;<Label text={label.INCLUDE_INACTIVE_CAMPAIGNS} />
            </Column>
        </Column>
        <Column lg={2} md={2} ></Column>
        <Column lg={2} md={2} className='padding-bottom10'>
          {<Button disabled={!canViewNewCampaign} onClick={() => {
            navigateTo('/Campaign/CampaignDetails')
          }}>
            {label.NEW_CAMPAIGN}</Button>}
        </Column>
      </Row>
      <Row className='gridrow'>
        {data != '' ?
          <Grid
            data={data !== undefined && data.length>0 && includeInactiveRecords ? data :
              data !== undefined && !includeInactiveRecords ? data.filter(item =>item.status===(null||''||undefined)?'':item.status.toUpperCase() !== constant.INACTIVE.toUpperCase()) : []}
            showFilters={showFilters}
            id={'tblCampaignList'}
            columns={[
              {
                Header: label.CAMPAIGN_ID,
                accessor: 'campaignId',
              },
              {
                Header: label.NAME,
                accessor: 'campaignName',
                Cell: ({ row }) => {
                  const campaignName = row.original.campaignName;
                  return (
                    <div>
                      <Link
                        to={{
                          pathname: '/Campaign/CampaignDetails',
                          state: { entityId: row.original.campaignId, type: '2' },
                          search: "?id="+row.original.campaignId
                        }}
                        href='#'
                        onClick={event => highlightMenu('Campaign')}
                        title={row.value}
                      >
                        <div>{campaignName}</div>
                      </Link>
                    </div>
                  );
                },
                width:300,
                minWidth:150
              },

              {
                Header: label.CampaignType,
                accessor: 'templateTypeName',
                // useFilters:()=>{getInactiveRecords},
                Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                width:125,
                minWidth:100
              },
              {
                Header: label.CATEGORY,
                accessor: 'categoryName',
                // useFilters:()=>{getInactiveRecords},
                Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                width:125,
                minWidth:100
              },
              {
                Header: label.STATUS,
                accessor: 'status',
                // minWidth: 270,
                // Cell: row => <div><Link to='/Campaign/Approval' onClick={event => highlightMenu('Approval')} href='#' title={row.value}>{row.value}</Link></div>
                Cell: ({ row }) => {
                  const campaignStatus = row.original.status;
                  const isStatusValidForRedirect =
                    campaignStatus === constant.APPROVED ||
                    campaignStatus=== constant.Waiting_Approval ||
                    campaignStatus === constant.SENT
                      ? true
                      : false;
                  return (
                    <><div>{isStatusValidForRedirect}</div><div>
                      {isStatusValidForRedirect && canUserViewApprovalScreen && canUserReadApprovalScreen ?
                        <Link
                          to={{
                            pathname: '/Campaign/ApprovalDetails',
                            state: { entityId: row.original.campaignId, type: '4' },
                            search : "?id="+ row.original.campaignId
                          }}
                          href='#'
                          onClick={event => highlightMenu('Approval')
                            //  routeChange()
                          }
                          title={row.value}
                        >
                          <div>{campaignStatus}</div>
                        </Link>
                        : (campaignStatus)}
                    </div></>
                  );

                },
                width:125,
                minWidth:100
              },
              {
                Header: label.SEND_ON,
                accessor: 'sentDate',
                Cell: row => <div>{(canUserViewCalendar && canUserReadCalendar) ?<Link to='/Campaign/Calendar' href='#' aria-disabled onClick={event => highlightMenu('Calendar')} title={row.value}>
                    <span className = {row.row.original.isSendOnBlackoutDate ? 'highlight-inactive': ''}>{row.value}</span>
                  </Link> : <span title={row.value}>{row.value}</span>}</div>,
                width:125,
                minWidth:100,
                sortType: (a, b) => {
                  return new Date(a.values.sentDate) - new Date(b.values.sentDate);
                },
              },
              {
                Header: label.UPDATED_ON,
                accessor: 'changedDate',
                // width:223,
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                width:125,
                minWidth:100
              },
              {
                Header: label.UPDATED_BY,
                accessor: 'changedBy',
                Cell: row => <div><span title={row.value}>{row.value}</span></div>
              },
              {
                Header: label.RESULTS,
                accessor: 'resultCount',
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                width:125,
                minWidth:100
              },
              {
                Header: label.Query,
                accessor: 'queryName',
                Cell: row => <div> {(canUserViewQuery && canUserReadQuery) ?
                  <Link 
                  href='#' to={{ pathname:'/Campaign/QueryDetail', state: {entityId: row.row.original.queryId, type: "1"},search:"?id="+row.row.original.queryId }} 
                  // onClick={event => highlightMenu('Queries')}
                  title={row.value}>
                    <span className = {row.row.original.queryInactive ? 'highlight-inactive': ''}>{row.value}</span>
                  </Link>: <span title={row.value}>{row.value}</span>}</div>,
                  width:250,
                  minWidth:150
              },
              {
                Header: label.EXTERNAL_TEMPLATE,
                accessor: 'templateName',
                Cell: row =><div>
                {(canReadCampaignTemplate && canViewCampaignTemplate)? <Link
                  href='#'
                  to={{
                  pathname:'/Campaign/3rdPartyTemplateDetail',
                state: { entityId: row.row.original.templateId, type: "3" },
                search : "?id="+row.row.original.templateId
                }}
                 title={row.value}>
                    <span className = {row.row.original.templateInactive ? 'highlight-inactive': ''}>{row.value}</span>
                 </Link>: <span className = {row.row.original.templateInactive ? 'highlight-inactive': ''}>{row.value}</span>}
                 </div>,
                width:250,
                minWidth:150
              },
    
            ]}
            hiddenColumns={hiddenColumns}
            // onSelectedRowsChange={handleSelectedRowsChange}
            sortDefault={[{ id: 'sentDate', desc: true }]}
          />
          : "No records found"}
        {/* <div>
          <h3>Selected Rows:</h3> 
    <ul>
      {referenceIdSelected.map((rowId) => (
        <li key={rowId}>Row {rowId}</li>
      ))}
    </ul>
  </div> */}
        {/* <Button
          className='topButton btn-sm'
          key="BackToTopButton"
          icon='up-arrow'
          style={{
            // position: 'fixed',
            // bottom: '20px',
            // right: '20px',
            // display: showGoTop ? 'block' : 'none',
            // backgroundColor: 'cyan-blue',
            // border: 'none'
          }}
          onClick={() => {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
          }}
        >
        </Button> */}
      </Row>

    </div>
    </ErrorBoundary> 
    </>
  )
}

export default CampaignGrid