import CustomerNavbar from './customernavbar'
import CustomerFilterForm from './filter/CustomerSearch'
import customerSearch from './filter/CustomerFilter'
import { Select, Label, Button, Container, Row, Column,Panel,Spinner } from '../components'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../shared/components/PrivateRoute'
import TextInputWithLabelInline from '../../src/shared/components/input/TextInputWithLabelInline'
import CustomerDashboardView from './customerdashboard' 
import { createContext, useEffect, useState, useRef } from 'react'
import './customer.css'
import '../../src/cs2common.css'
import InputGroup from 'react-bootstrap/InputGroup'
import { Icon } from '../../src/components/components/Icon/index'

export const QuickSearchContext = createContext(null);
interface Props {
  isSideBarCollapsed: boolean
}
interface QuickSearch{
  IsQuickSearch:boolean
  QuickSearchString:string
}

const Customer = (props:Props) => {

  const QuickSearchFilter:QuickSearch={
    IsQuickSearch:false,
    QuickSearchString:''
  }
  useEffect (() => {
    const root =document.documentElement;
    root.style.setProperty('--marginLeftCustomerRow',props.isSideBarCollapsed?'5px':'60px')
  },[props.isSideBarCollapsed]);

  useEffect (() => {
  
  },[]);

  const [quickFilter, setQuickFilter] = useState<QuickSearch>(QuickSearchFilter);
  const [quickFilterStatus, setquickFilterStattus] = useState(QuickSearchContext);
  const onFieldChange = (name: string, value: string) => {

    setQuickFilter({
      ...quickFilter,

      [name]: value,
    })
  }

 // const childRef = useRef(null);

  // const handleClick = () => {
  //   childRef.current.childFunction1();

  //   childRef.current.childFunction2();
  // };
const onQuickSearch=(event)=>{
event.Target.focus;
  if(quickFilter.QuickSearchString.length>3)
  {
    QuickSearchFilter.IsQuickSearch=true;
    QuickSearchFilter.QuickSearchString=quickFilter.QuickSearchString;
    setQuickFilter(QuickSearchFilter);
  }
}



    return (
    
      <QuickSearchContext.Provider value={{quickFilterData:[quickFilter, setQuickFilter]}}>
      
      <div >
      
        
          <Row className="bg-light sidebarToggle">
               <Column lg={9}>
        <CustomerNavbar />
       
        </Column>

        <Column lg={3} className='cstQuickSearch' >
        {/* <div className="icon"></div> */}
        <InputGroup>
        <Column lg={10}>
        <TextInputWithLabelInline
          name='QuickSearch'
         value={quickFilter.QuickSearchString}
          isEditable
         type='text'
         onChange={(event) => onFieldChange('QuickSearchString', event.currentTarget.value)}
         lglabel={5}
                  mdlabel={5}
                  lgText={7}
                  mdText={7}
        />
        </Column>
        <Column lg={2}>
            <InputGroup.Append className='inputGroupSearchAppend'>
          <InputGroup.Text id="inputGroup-sizing-sm" className='inputGroupSearch' onClick={(event)=>onQuickSearch(event)}>
            <Icon icon="search" size="xs"/>
          </InputGroup.Text>
        </InputGroup.Append>
        </Column>
      </InputGroup>
      
           </Column>
      </Row>
        <Row className='bg-light sidebarToggle'>
        <Column lg={12}>
        {/* <CustomerFilterForm/> */}

        <Switch>
      <Route
        exact
        path="/customer/customerdashboard"
        component={CustomerDashboardView}
      />
      <Route
        exact
        path="/customer/customerfilter"
        component={customerSearch}
      />
    </Switch>
    </Column>
    </Row>
    {/* <div className="row">
        <div className="col-lg-12">
        <CustomerDashboardView/>
        </div>
    </div> */}
    </div>
   
     </QuickSearchContext.Provider>
     
    )
   
}

export default Customer