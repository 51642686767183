import React, { useEffect, useState } from "react";
import { CustomerNote } from "../model/CustomerNoteModel";
import { Button, Column, DateTimePicker, Label, Row, TextField } from "../../components";
import * as label from '../../shared/util/label';
import moment from "moment";
import ValidateCustomerNote from "./CustomerNoteValidate";
import commonFunctions from "../../shared/hooks/commonFunction";


const NoteEdit: React.FunctionComponent<any> = (props) => {
    const validate = new ValidateCustomerNote()
    const [customerNote, setNote] = useState<CustomerNote>(props.Note);
    const [validateDescription, setDescriptionValidation]=useState({isValid:true, warningText : ""})
    const [validateNoteDate, setValidateNoteDate]=useState({isValid:true, warningText : ""})
    const [validFlag, setValidFlag]=useState(false)

    const onNoteChange = (name: string, value: string | Date | boolean | number) => {
        if(name === 'noteDate' && value !== null ){
            let func = new commonFunctions();
            let validDate = func.ValidateMaxDate(value)
            if(!validDate){
                value = null;
            }
        }
        setNote({ ...customerNote,[name]: value, })
        props.setFormSaved(false)
      }
     
      useEffect(() => {
      if(validateDescription.isValid && validateNoteDate.isValid){
        setValidFlag(true)
      }
      else{
        setValidFlag(false)
      }
    }, [validateDescription.isValid,validateNoteDate.isValid])

    const validateNoteEdit = () =>{
        var descriptionStatus=validate.validateDescription(customerNote.note)
        let descriptionValidationState = {isValid : descriptionStatus.isValid, warningText :descriptionStatus.warningText};
        setDescriptionValidation(validState =>  descriptionValidationState);

        let date = customerNote.noteDate?new Date(moment(customerNote.noteDate.toString()).format("MM/DD/YYYY")):null
        var dateStatus =validate.validateNoteDate(date)
        let dateValidattionState = {isValid : dateStatus.isValid, warningText :dateStatus.warningText};
        setValidateNoteDate(validState => dateValidattionState);

        return descriptionStatus.isValid && dateStatus.isValid;
    }

    const SaveEditedNote =() => {

        let valStatus = validateNoteEdit();
        if (!valStatus){
            return;
        }
        if(valStatus){
            if (customerNote.id !== undefined)
                props.UpdateEditedNote(customerNote)
            else
            {
                props.AddNote(customerNote)
            }
        }
    }

    // useEffect(() => {
    //     validateNoteEdit();
    //   },[]);
    
    return (
        <>
            <div className="cst-font-col notebox nopadding">
                <Row className="mt-3">
                    <Column lg={6} md={6} className="ml-3">
                        <b> <Label isRequired={true} htmlFor="description" title={label.CustomerNotesLabel.DESCRIPTION} text={label.CustomerNotesLabel.DESCRIPTION}></Label></b>
                    </Column>
                </Row>
                <Row>
                    <Column lg={12} md={12} className="ml-4 pl-1" >
                        <TextField
                            name='noteDescription'
                            title={customerNote.note}
                            disabled={false}
                            style={{ fontSize: "14px", width: "96%", height: "200px", resize: "none" }}
                            placeholder={label.CustomerNotesLabel.DESCRIPTION}
                             onChange={(event) => onNoteChange('note', event.currentTarget.value)}
                            tabIndex={2}
                            className='cstCollection'
                            maxLength={2000}
                            value={customerNote.note}
                            isInvalid={!validateDescription.isValid}
                            invalidWarning={validateDescription.warningText}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column lg={6} md={6}>

                    </Column>
                    <Column lg={6} md={6}>
                        <Row>
                            <Column lg={4} md={4} className="text-right">
                                <b><Label isRequired={true} text={label.CustomerNotesLabel.NOTE_DATE} /></b>
                            </Column>
                            <Column lg={8} md={8} className="nopadding-left ml-n2 cstNoteDate" >
                                <DateTimePicker
                                   // dateFormat="MM/dd/yyyy"
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    //selected={customerNote.noteDate}
                                    selected={customerNote.noteDate === null ? null : customerNote.noteDate !== null ?
                                        new Date(customerNote.noteDate)
                                        : null}
                                    minDate={new Date()}
                                    onChange={(date) => onNoteChange('noteDate', date)}
                                    isInvalid={!validateNoteDate.isValid}
                                    invalidWarning={validateNoteDate.warningText}
                                    //onChangeRaw={(event)=> onNoteChange('noteDate', event.target.value) }
                                    >
                                        
                                    </DateTimePicker>
                            </Column>
                        </Row>
                    </Column>
                </Row>

                <Row className="mb-2">
                    <Column lg={8} md={8}>
                        <Button
                            className='btn btn-outline-tertiary ml-3 action-btn-base'
                            key="filter"
                            onClick={() => props.CancelNote()}
                            tabIndex={21}
                            id="btnNotesCancel"
                            color='primary'
                            outlined={true}
                        >{label.CANCEL.toLocaleUpperCase()}
                        </Button>

                    </Column>
                    <Column lg={4} md={4} className="text-right">
                        <Button
                            className='btn btn-outline-primary mr-2 action-btn-base'
                            key="filter"
                            onClick={() =>SaveEditedNote()}
                            tabIndex={21}
                            id="btnNotesAdd"
                            color='primary'
                            outlined={true}
                        >{label.SAVE.toLocaleUpperCase()}
                        </Button>
                    </Column>
                </Row>
            </div>
        </>
    )

}

export default NoteEdit;