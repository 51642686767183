
import { fetchCampaignQueryFilterSetup } from '../services/CampaignAPI'
 import CampaignQueryFilterUI from '../model/CampaignQueryFilterUI'
import CampaignFilterConditionsUI from '../model/LookUpModel/CampaignFilterConditionsUI';
import DynamicDropDowns from '../model/DynamicDropDownsAPI';
import CampaignFilterOptions from '../model/LookUpModel/CampaignFilterOptions';
import { fetchFilterConditionTypes } from '../services/CampaignLookup';


async function getCampaignQueryFilterConditionOptions(
  campaignLogicOptions: string[]
): Promise<CampaignFilterConditionsUI[]> {
  // TODO: Implement this function to get the campaign filter condition options from the admin API.
var filterConditionOptionsAPI = await fetchFilterConditionTypes(campaignLogicOptions)
  return filterConditionOptionsAPI.map((filterConditionOption) => ({
    campaignFilterConditionId: filterConditionOption.id,
    campaignFilterConditionValue: filterConditionOption.lookupValue,
    isInactive:filterConditionOption.isInactive
  }));
}

async function mappedDynamicDropDowns(
  _dynamicDropDowns: DynamicDropDowns[]
): Promise<CampaignFilterOptions[]> {
 
  return _dynamicDropDowns.map((option) => ({

   campaignFilterOptionsId: option.id,
    campaignFilterOptionsValue: option.value,
    isInactive:option.isInactive

  }));
}

export const getCampaignQueryFiltersSetup = async (
  queryId: string
): Promise<CampaignQueryFilterUI[] | null[]> => {
  var data:CampaignQueryFilterUI[]

  try {
    const campaignQueryFilterSetupAPI =await fetchCampaignQueryFilterSetup(queryId)

    const mappedFilters: CampaignQueryFilterUI[] = [];

    for (const filter of campaignQueryFilterSetupAPI._campaignFilter) {
      var campaignConditionlogics:string[]=[]
      for(const logicOptions of filter._campaignLogicOptions)
      {
        campaignConditionlogics.push(logicOptions._logic)

      }
      const mappedConditionOptions = await getCampaignQueryFilterConditionOptions(
        campaignConditionlogics
        );
        
      const mappedFilterOptions = await mappedDynamicDropDowns(
        filter._dynamicDropDowns
      );  


      const mappedFilter: CampaignQueryFilterUI = {
        filterId:filter._id,
        filterLabel: filter._filterDisplay,
        queryId: campaignQueryFilterSetupAPI._id,
        filterType: filter._dataType,
        filterOptions:  mappedFilterOptions,
        filterConditionOptions:  mappedConditionOptions,
  
      };
      mappedFilters.push(mappedFilter);
    }
    data=mappedFilters
  } catch (error) {
    console.error(error);
  } finally {
  }
    return data

};