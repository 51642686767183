import { useState } from "react";
import SliderContext from "./sliderContext";


const SliderProvider : React.FunctionComponent<any> = (props) => {

    const [detachSlider, setDetachSlider] = useState(true);

    const [isSliderVisible, setIsSliderVisible] = useState(false);
    const [isSliderPinned, setIsSliderPinned] = useState(false);

    const showSlider = () => {
        setDetachSlider(false);
        document.body.classList.add('slider-prevent-scroll')
        setTimeout(() => {
            setIsSliderVisible(true);
        }, 1);
    }

    const hideSlider = () => {
        setIsSliderVisible(false);
        setIsSliderPinned(false);
        setTimeout(() => {
            setDetachSlider(true);
            document.body.classList.remove('slider-prevent-scroll')
        }, 300);
    }

    const pinSlider = () => {
        setIsSliderPinned(true);
    }

    const unpinSlider = () => {
        setIsSliderPinned(false);
    }

    const contextValue = {
        isSliderVisible,
        isSliderPinned,
        showSlider,
        hideSlider,
        pinSlider,
        unpinSlider,
        detachSlider
      };

    return <SliderContext.Provider value={contextValue}>{props.children}</SliderContext.Provider>
}

export default SliderProvider;