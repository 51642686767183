
import React, { useContext, useEffect, useState } from 'react'
import { SelectOption } from '../../../shared/components/input/SelectOption'
import TextInputWithLabelInline from '../../../shared/components/input/TextInputWithLabelInline'
import { ErrorBoundary } from 'react-error-boundary'
import _, { update } from 'lodash'
import Logger from '../../../shared/services/Logger'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../../shared/util/constant'
import { Nav, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as label from '../../../shared/util/label'
import { Card, Collapse } from 'react-bootstrap'
import { Row } from "react-bootstrap";
import { Button, Column, Checkbox } from "../../../components";
import DropDownWithLabelInline from "../../../shared/components/input/DropDownWithLabelInline";
import { CustomerScreentLables as cstScrLables } from "../../../shared/util/label";
import { userSetupUtils } from '../../utils/UserSetupUtils'
import EmployeeSetupService from '../service/EmployeeSetupService'

import * as tableId from '../../../shared/util/tableConstant'

import { useTable } from 'react-table';
import { table } from 'console'
import EmployeeSetupModel from '../../model/EmployeeSetupModel'
import UserDetailModel from '../../model/UserDetailModel'

import { EmployeeContext } from '../EmployeeContext'
import './../UserSetupMiniTabs.scss';

import e from 'express'

export interface IUserDetailView {
    relationships: SelectOption[],
    onFieldChange: (collectionPropName: string, collectionEntityIdentifierPropName: string, collectionEntityIdentifierValue: any, propName: string, value: string | Date | boolean) => void,
    // onDelete : (dependent : CustomerDependent) => void,
    formValidations: any,
    isEditable: boolean,
    isReset: boolean
}

const UserDetails: React.FunctionComponent<IUserDetailView> = ({userdetail, setUserDetail}) => {
    const employeeDetailContext = React.useContext(EmployeeContext);
    useEffect(() => {
        setId(employeeDetailContext.id);
        setUserDetail(prevState => ({ ...prevState, id: employeeDetailContext.id }));
      }, [employeeDetailContext.id]);
    // const empDetailContext  = useContext(employeeDetailContext);
    // const userDetails: UserDetailModel = {
    //     id: employeeDetailContext.id,
    //     firstName: '',
    //     lastName: '',
    //     eMail: '',
    //     roleId: '',
    //     isActive:false
    // }
    
    const employeeSetupService = new EmployeeSetupService()

    const [activeTab, setActiveTab] = useState('tab1');
    const history = useHistory();

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };



    const [employeeStatusOptions, setEmployeeStatus] = useState<SelectOption[]>([]);
    const [agencyGroups, setAgencyGroups] = useState<SelectOption[]>([]);
    const [selectedAgencyGroup, setSelectedAgencyGroup] = useState([]);
    const [agencies, setAgencyList] = useState<SelectOption[]>([]);
    // const [userdetail, setUserDetail] = useState(userDetails);
    const [isEditable, setIsEditable] = useState(true);
    const [employeeList, setEmployeeList] = useState<SelectOption[]>([]);
    const [indicatorList, setIndicators] = useState<SelectOption[]>([]);
    const [roleList, setRoleList] = useState<SelectOption[]>([]);
    const [id, setId] = useState(employeeDetailContext.id);
    const [isActive, SetUserActive]=useState(false)

    useEffect(() => {
        //alert('called')

        userSetupUtils.getAppRoles(tableId.ROLE_APP_ID)?.then(res => {
            setRoleList(res)
        })
    }, []);

   

    useEffect(() => {
        if (selectedAgencyGroup) {
            userSetupUtils.getAgenciesByGroup(selectedAgencyGroup)?.then(res => {
                setAgencyList(res);
                // setEmployeeStatus(res)
            })
        }
    }, [selectedAgencyGroup]);

    const handleAgencyGroupChange = (newValue) => {
        setSelectedAgencyGroup(newValue);
    };
    const onEntityFieldChange = (propName: string, value: string | Date | boolean) => {
        setUserDetail({
            ...userdetail,
            [propName]: value,
        });

        // if (propName == 'firstName' || propName == 'lastName') {
        //     setEmployeeDetail(cst => {
        //         return {
        //         ...cst,
        //         displayName: (cst.firstName ? cst.firstName : '') + ' ' + (cst.lastName ? cst.lastName : ''),
        //         };
        //       });
        // }
    }

    const SaveUser = async () => {
        try {
            if (userdetail.id == constant.DEFAULT_GUID) {
                toast.error("Employee must be created for User", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                let updatedUser = await employeeSetupService.SaveUser(userdetail);
            }

            // if (updatedEmployer) {
            //     userDetails.id = updatedEmployer;
            //     setUserDetail({
            //         ...userDetails
            //     });
            // }
            // else {
            //     toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            // }
        }
        catch (e) {
            Logger.logError(constant.CUSTOMER_ERROR_WHILE_SAVING, e);
            toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const manageActiveToggle=(event)=>{
        if(event.target.checked)
        {
            SetUserActive(true)
            onEntityFieldChange("isActive",event.target.checked)
        }
        else{
            SetUserActive(false)
            onEntityFieldChange("isActive",event.target.checked)
          
        }
      
      }


    return (



        <div className="">
            <Row>
                <Column lg={6} md={6}>&nbsp;</Column>
                <Column lg={6} md={6}>&nbsp;</Column>
            </Row>


            <Row>
                <Column lg={3} md={3}
                    className={''}>
                    <TextInputWithLabelInline
                        name='firstName'
                        label={cstScrLables.FIRST_NAME}
                        placeholder={'First Name'}
                        value={userdetail.firstName}
                        onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                        isEditable={isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
            </Row>
            <Row>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='lastName'
                        label={cstScrLables.LAST_NAME}
                        placeholder={'Last Name'}
                        value={userdetail.lastName ?? ""}
                        onChange={(event) => onEntityFieldChange('lastName', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
            </Row>
            <Row>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='eMail'
                        label={'Email'}
                        placeholder={'Email'}
                        value={userdetail.email ?? ""}
                        onChange={(event) => onEntityFieldChange('email', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
            </Row>

            <Row>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='roleId'
                        label='Role'
                        options={roleList}
                        defaultSelected={roleList?.filter(({ value }) => value === userdetail.roleId)}
                        onChange={
                            (values) => {
                                onEntityFieldChange('roleId', values[0])
                            }
                        }
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        isRequired
                        // disabled ={!props.isEditable}
                        placeholder={' '}
                        multiple={false}
                        tabIndex={9}
                    />
                </Column>
            </Row>


            <Row>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='objectId'
                        label={'Object Id'}
                        placeholder={'Object Id'}
                        value={userdetail.objectId ?? ""}
                        onChange={(event) => onEntityFieldChange('objectId', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
            </Row>

            <Row>
                <Column md={1} lg={1}>

                </Column>
                <Column md={6} lg={6} className='paddingTop1 cstCheckbox'>
                    <Checkbox
                        id='isActive'
                        label={'Active'}
                        labelClassName='inlineLable'
                        checked={userdetail.isActive}
                        onChange={(event) => manageActiveToggle(event)}
                        tabIndex='11'
                        inline />
                </Column>
            </Row>

            <Row className="bottom-stick-actions  button-row">
                <Column lg={3} md={3}>
                    <Button
                        className='btn btn-outline-primary col-3 ml-2'
                        key="filter"
                        onClick={() => CancelEdit()}
                        tabIndex={20}
                        id="btnCancel"
                        color='tertiary'
                        outlined={true}
                        disabled={false}
                        style={{ minWidth: "90px" }}
                    >{label.CANCEL.toLocaleUpperCase()}
                    </Button>
                </Column>
                <Column lg={6} md={6}></Column>

                <Column lg={3} md={3}>
                    <Button
                        className='btn btn-outline-primary col-3 mr-3'
                        key="filter"
                        onClick={() => EditCustomer()}
                        tabIndex={21}
                        id="btnEdit"
                        color='second'
                        disabled={false}
                        outlined={true}
                    >{'DELETE'}
                    </Button>
                    <Button
                        className='btn btn-outline-primary col-3 mr-3'
                        key="filter"
                        onClick={() => SaveUser()}
                        tabIndex={21}
                        id="btnAddCustomer"
                        disabled={false}
                        color='primary'
                        outlined={true}
                    >{label.SAVE.toLocaleUpperCase()}
                    </Button>

                </Column>
            </Row>

        </div>
    );
};
export default UserDetails;