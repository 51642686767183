import { KeyboardEvent, useEffect, useState } from 'react';
import './MatTextInput.scss'
import { Icon, Label } from '../../../../components';
import { IconType } from '../../../../components/components/Icon/interfaces';
import { CommonUtility } from '../../../../Services/CommonUtility';

export interface IMatTextInput 
{
    id? : string
    lable? : string
    text? : string;
    onChange? : (changedValue : string) => void;
    iconName? : IconType;
    mainDivClass? : string;
    lableClass? : string;
    inputClass? : string;
    iconClass? : string;
    isInvalid? : boolean;
    invalidWarning? : string;
    type?: 'text' | 'email' | 'number' | 'tel' | 'password';
    maxLength? : number;
}

const MatTextInput : React.FunctionComponent<IMatTextInput> = (props) => {

    const allwedKeyStrokes = ['Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Control', 'Shift', 'Home', 'End'];

    const [isFocus, setIsFocus ] = useState(false);

    const {text, type, maxLength} = props;

    const [txtInput, setTxtInput] = useState(text);

    useEffect(() => {
        setTxtInput(text);
    }, [text]);

    let mainDivClass = 'main-mat-text-input';
    let lableClass = `mat-txt-input-lable`; 
    let inputClass = 'mat-txt-input';

    if (txtInput?.length > 0 || isFocus) {
        lableClass = `${lableClass} mat-txt-input-lable-focus`;
    }
    else {
        lableClass = `${lableClass} mat-txt-input-lable-default`;
    }

    if (isFocus) {
        mainDivClass = `${mainDivClass} main-mat-text-input-focus`;
    }

    if (props.mainDivClass) {
        mainDivClass = `${mainDivClass} ${props.mainDivClass}`;
    }

    if (props.isInvalid) {
        mainDivClass = `${mainDivClass} main-mat-text-input-error`;
    }

    if (props.lableClass) {
        lableClass = `${lableClass} ${props.lableClass}`;
    }

    if (props.inputClass) {
        inputClass = `${inputClass} ${props.inputClass}`;
    }

    let iconContent = <></>;

    if (props.iconName) {
        let iconClass = `${props.iconClass ? props.iconClass : ''}`;
        iconContent =   <div className='mat-input-icon-div'>
                            <Icon icon={props.iconName} className={iconClass} ></Icon>
                        </div>;
    }

    const handleInputFocus = () => {
        setIsFocus(true);
    }

    const handleInputBlur = () => {
        setIsFocus(false);
    }

    const onTextInput = (changedData) => {
        setTxtInput(changedData);
        if (props.onChange) {
            props.onChange(changedData);
        }
    }

    const onInputKeyDown = (e : KeyboardEvent) => {
        if (type == 'tel' || type == 'number' ) {

            // Allow select all, copy ops.
            if (e.ctrlKey && (e.key == 'a' || e.key == 'c')) {
                return;
            }

            // Dont allow paste directly. validate and paste.
            if (e.ctrlKey && e.key == 'v') {
                e.preventDefault();
                e.stopPropagation();

                CommonUtility.getClipboardText().then(data => {
                    if (type == 'tel') {
                        data = data.replaceAll('-','');
                    }

                    if (CommonUtility.isValidNumber(data)) {
                        onTextInput(data);
                    }
                });

                return;
            }

            if (!CommonUtility.isValidNumber(e.key) && !allwedKeyStrokes.includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }

    let controlContent = <div className={mainDivClass}>
                                <label for={props.id} className={lableClass} onClick={handleInputFocus} >{props.lable}</label>
                                <input 
                                    id={props.id}
                                    className={inputClass}
                                    onFocus={handleInputFocus} 
                                    onBlur={handleInputBlur} 
                                    value={txtInput}
                                    type={type}
                                    maxLength={maxLength ? maxLength : 10000}
                                    onKeyDown={(e) => onInputKeyDown(e)} 
                                    onChange={(e) => onTextInput(e.target.value)}>
                                </input>
                                {iconContent}
                            </div>;

    if (props.isInvalid) {
        controlContent = <div className='mat-txt-input-error-div'>
            {controlContent}
            
            <Label
                    className='lable-error'
                    text={props.invalidWarning}/>
        </div>
    }

    return controlContent;
}

export default MatTextInput;