export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,      
      authority: "https://login.microsoftonline.com/c778c717-89a3-4759-8f50-bee7332c6351", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [process.env.REACT_APP_AZURE_DEFAULT_SCOPE]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //     graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  // };