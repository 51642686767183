import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './DocViewer.scss';
import { Icon } from '../../../components';
import DocuMateScanService from '../../DocuMateScan/DocuMateScanService';
import { getDocumentSample } from '../../services/DocuMateAPI';
import SampleFile from  './../samplePdfs/SampleFile.pdf';
// import 'react-pdf/dist/esm/Page/TextLayer.css'; 

// Set up the worker path for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

interface DocViewerProps {
    // fileUrl: string;
    docId: string;
    handleDocViewerClose: () => void;
}

const DocViewer: React.FC<DocViewerProps> = ({ docId , handleDocViewerClose}) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.2); // Scale for zoom
    const [rotation, setRotation] = useState(0); // Rotation angle
    const [document, setDocument] = useState(null);
    const docuMateScanService = new DocuMateScanService();
    const [fileName, setFileName] = useState<string>('TEMP_2024-03-04.pdf');

    // const fetchDocument = async (docId) => {
    //     try {
    //         // Fetch the document using your updated Axios function
    //         const blob = await getDocumentSample(docId);
    //         // Create an object URL for the Blob
    //         const blobUrl = URL.createObjectURL(blob);
    //         setDocument(blobUrl);  // Set the Blob URL to render the PDF

    //     } catch (error) {
    //         console.error('Error fetching the PDF file:', error);
    //     }
    // };

    // // Fetch PDF when component mounts
    // useEffect(() => {
    //     fetchDocument(docId);

    //     // Cleanup function to revoke blob URL
    //     return () => {
    //         if (document) {
    //             URL.revokeObjectURL(document);
    //         }
    //     };
    // }, [docId]);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setPageNumber(1); // Start with the first page
    };

    // Editing features' functions
    const nextPage = () => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages || 1));
    const prevPage = () => setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
    const zoomIn = () => setScale((prevScale) => prevScale + 0.2);
    const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.5));
    const rotateClockwise = () => {
        setRotation((prevRotation) => (prevRotation + 90) % 360);
      };
    
      const rotateCounterClockwise = () => {
        setRotation((prevRotation) => (prevRotation - 90 + 360) % 360);
      };

    return (
        <div className="doc-viewer-container">
            <div className="viewer-container">
            <div className="doc-viewer-header">
                <span style={{fontWeight: 'bold'}}>{fileName}</span>
                <span onClick={handleDocViewerClose}><Icon icon="close" className='close-viewer'/></span>
            </div>
            <div className="doc-viewer">
            <Document file={SampleFile} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} rotate={rotation} renderTextLayer={false} renderAnnotationLayer={false}/>
            </Document>
            </div>
            </div>
            <div className="toolbar">
                {/* <span className='pageDisplay'>
                Page {pageNumber} of {numPages}
                </span> */}
                {/* Page navigation buttons */}
                <button onClick={prevPage} disabled={pageNumber <= 1}>
                    <span><Icon icon="prevPage" className='image-viewer-icons'/></span>
                </button>
                <span className='pageDisplay'>{pageNumber}/{numPages}</span>
                <button onClick={nextPage} disabled={pageNumber >= (numPages || 1)}>
                    <span><Icon icon="nextPage" className='image-viewer-icons'/></span>
                </button>

                {/* Zoom buttons */}
                <button onClick={zoomIn}>
                <span><Icon icon="zoomIn" className='image-viewer-icons'/></span>
                </button>
                <button onClick={zoomOut} disabled={scale <= 0.8}>
                <span><Icon icon="zoomOut" className='image-viewer-icons'/></span>
                </button>

                {/* Rotation buttons */}
                <button onClick={rotateClockwise}>
                <span><Icon icon="rotateClockwise" className='image-viewer-icons'/></span>
                </button>
                <button onClick={rotateCounterClockwise}>
                <span><Icon icon="rotateCounterClockwise" className='image-viewer-icons'/></span>
                </button>
            </div>
            <div className="timestamp-container">
                        <span>System Scanned By Mailroom</span>
                        <span>July 4, 2024 6:21 PM</span>
            </div>
        </div>
    );
};

export default DocViewer;
