import { Column, Navbar } from  '../components' 
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as label  from '../shared/util/label';
import * as constant from '../shared/util/constant';

//import { logout } from '../../../user/user-slice'
import useTranslator from '../shared/hooks/useTranslator'
import { RootState } from '../shared/store'
//import pageMap, { Page } from  '../../src/shared/components/navbar/pageMap'   
import AppContext from "../shared/components/AppContext";
import { useConfirmationModalContext } from "../shared/components/ModalConfirmationContext";
import CustomerSliderContainerService from './customer-slider-content/CustomerSliderContainerService';
import { cleareTabsInputs } from './customer-slider-content/CustomerSliderContext';
import useSlider from '../shared/components/Slider/useSlider';

const CustomerNavbar = () => {
  
  const customerSliderContainerService = new CustomerSliderContainerService();
  const customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
  const originalCustomerObj = useSelector((state: RootState) => state?.customerSlice).customer;
  const slider = useSlider();

  const dispatch = useDispatch()
  const history = useHistory()
  const modalContext = useConfirmationModalContext();
  const myContext = useContext(AppContext);
  // const { t } = useTranslator()

  // const logout = ()=>{}

  // const user = {
  //   givenName:"",
  //   familyName:""
  // }
  //const { permissions, user } = useSelector((state: RootState) => state.user)
  var tabLink = document.querySelectorAll(".nav-link");
  
  const navigateTo = async(location: string, event) => {
    const changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
    if (Object.keys(changes).length > 0) {
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(!result){
            return;
          }

        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }

    if(!myContext.formSavedStatus){
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(result){
            tabLink.forEach(function (item) {
              item.classList.remove("active");
            });
            event.currentTarget.classList.toggle('active');
            history.push(location)
          }
      }
      else{
        tabLink.forEach(function (item) {
          item.classList.remove("active");
        });
        event.currentTarget.classList.toggle('active');
        history.push(location)
      }

   
  }

  // const dividerAboveLabels = [
  //   'scheduling.appointments.new',
  //   'labs.requests.new',
  //   'medications.requests.new',
  //   'incidents.reports.new',
  //   'imagings.requests.new',
  //   'settings.label',
  // ]

  // function getDropdownListOfPages(pages: Page[]) {
  //   return pages
  //     //.filter((page) => !page.permission || permissions.includes(page.permission))
  //     .map((page) => ({
  //       type: 'link',
  //       label: t(page.label),
  //       icon: `${page.icon}`,
  //       onClick: () => {
  //         console.log(page.path)
  //         navigateTo(page.path)
  //       },
  //       dividerAbove: dividerAboveLabels.includes(page.label),
  //     }))
  // }

  // For Mobile, hamburger menu
  //const hambergerPages = Object.keys(pageMap).map((key) => pageMap[key])

  // For Desktop, add shortcuts menu
  // const addPages = [
  //   pageMap.newPatient,
  //   pageMap.newAppointment,
  //   pageMap.newMedication,
  //   pageMap.newLab,
  //   pageMap.newImaging,
  //   pageMap.newIncident,
  // ]

  
  // const customerPages = [
  //   pageMap.customerdashboard,
  //   pageMap.customerfilter,
  // ]
  // const campaignPages = [
  //   pageMap.newCampaign,
  //   pageMap.dashboardCampaign,
  //   pageMap.findCampaigns,
  // ]
  // const maintenancePages = [
  //   pageMap. maintGeneral,
  //   pageMap. maintAgency,
  //   pageMap. maintCompany,
  //   pageMap. maintMailmerge,
  //   pageMap. maintUser, 
  // ]
  // const adminPages = [
  //   pageMap.adminQueue,
  //   pageMap.adminCampaignSetup, 
  //   pageMap.adminRatingSetup,
  //   pageMap.adminRPAsetup, 
  // ]

  return (
    <div className='row'>
       <Column lg={1} md={1} className='pl-4 mt-3 '>
                    <span className='cmph-header'>{'CUSTOMERS'}</span>
                </Column>
                <Column lg={2} md={2} className='mt-n1 pl-5'>
    <Navbar
      bg='light'
      variant='light'
      className='headerMenu'
      title={''} id={''}
      navItems=
      {
        [
          
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: 'cstHeaderMenu nav-add-new d-none d-md-block',
            iconClassName: 'align-bottom',
            label: 'Dashboard',
            name: 'camp',
            size: 'lg',
            onClick:(event)=>{
              navigateTo('customerdashboard',event)}
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: 'cstHeaderMenu nav-add-new d-none d-md-block',
            iconClassName: 'align-bottom',
            label: 'Filters',
            name: 'maint',
            size: '10x',
            onClick:(event)=>{
              navigateTo('customerfilter',event)}
          }
        ]
      }
    />
    </Column>
    </div>
  )
}
export default CustomerNavbar
