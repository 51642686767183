import React, {useContext, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { ConfirmModal } from "./confirmModal";
import * as label  from '../util/label';
import AppContext from "./AppContext";
import { TextInput } from "../../components";

type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
}

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide,
    }
};

type ModalContextType = {
    showConfirmation: ( message: string | JSX.Element,title?: string, confirmationInput? : string, enableOkOnMatch? : string) => Promise<boolean>;
    hideConfirmation : ()=> Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
    children: React.ReactNode
}

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (props) => {
    const {setShow, show, onHide} = useModalShow();
    const [content, setContent] = useState<{ message: string | JSX.Element, title?: string, confirmationInput? : string, enableOkOnMatch? : string } | null>();
    const resolver = useRef<Function>();
    const myContext = useContext(AppContext);

    const [confirmationInputValue, setConfirmationInputValue] = useState("");

    const handleShow = (message: string | JSX.Element,title?: string, confirmationInput : string = null, enableOkOnMatch : string = null ): Promise<boolean> => {
        setContent({
            title,
            message,
            confirmationInput,
            enableOkOnMatch
        });
        setShow(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const handleOk = ():Promise<boolean>=> {
        //resolver.current && resolver.current(true);
        myContext.formSavedStatus=true
        onHide();
        return new Promise(function (resolve) {
            if (content.confirmationInput) {
                let confirmationInputValueData = confirmationInputValue;
                resolver.current({result : true, data : confirmationInputValueData});
                setConfirmationInputValue("");
            }
            else {
                resolver.current(true);
            }
            resolver.current = resolve;
        });
    };

    const modalContext: ModalContextType = {
        showConfirmation: handleShow,
        hideConfirmation : handleOk
    };

    let modalBody : any = <></>;
    let disableOkButton = false;
    
    if (content) {
        modalBody = content.message.toString();

        if (content.confirmationInput) {
            modalBody = <div>
                <p className="modal-message-paragraph">{content.message.toString()}</p>
                <TextInput placeholder={content.confirmationInput} value={confirmationInputValue} onChange={(e) => setConfirmationInputValue(e.currentTarget.value)}></TextInput>
            </div>;

            if (content.enableOkOnMatch) {
                disableOkButton = content.enableOkOnMatch != confirmationInputValue;
            }
        }
    }

    const handleCancel = () => {
        if (content.confirmationInput) {
            let confirmationInputValueData = confirmationInputValue;
            setConfirmationInputValue("");
            resolver.current && resolver.current( { result : false, data : confirmationInputValueData });
            onHide();
        }
        else {
            resolver.current && resolver.current(false);
            onHide();
        }
    };

    return (
        <ConfirmationModalContext.Provider value={modalContext}>
            {props.children}

            {content &&
<>
                    <ConfirmModal
                    modaltitle={content.title?? label.CONFIRM}
                    showConfirmation={show}
                    setConfirmation={handleCancel}
                    body={modalBody}
                    closeButtonClick={handleCancel}
                    successButtonClick={handleOk}
                    disableOkButton={disableOkButton}
                    closeButtonLabel={label.NO.toUpperCase()}
                    successButtonLabel={label.YES.toUpperCase()}
                    ></ConfirmModal>
                </>
                }
        </ConfirmationModalContext.Provider>
    )
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export {
    useModalShow,
    useConfirmationModalContext,
}

export default ConfirmationModalContextProvider;