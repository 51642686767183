import { Select, Label,  Row, Column, DateTimePicker, TextInput } from '../../components'
import { ErrorBoundary } from 'react-error-boundary'
import { useEffect, useState } from 'react'
import TestQuery from '../model/TestQuery';
import * as constant from '../../shared/util/constant'
import * as label from '../../shared/util/label'
import CampaignQueryInfo from '../model/CampaignQueryInfo'
import campaignFilter from '../model/QueryFilters'
import { campaignQueryUtils } from '../utils/campaignQueryUtils';
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { join } from 'cypress/types/bluebird';

interface Props {
    queryInputs: CampaignQueryInfo
    runTestQueryModel?: (testQueryinputModel: TestQuery) => void
    recordCount?:string
    setRecordCount?:(any)=>void
    setRecordList?:(any) => void
    errorMsg?:string
}



const DynamicFilter = (prop: Props) => {


    var testFilterArr: any;
    testFilterArr = [];
    const testFilter: campaignFilter = {
        id: constant.DEFAULT_GUID,
        fieldName: '',
        campaignLogicOptions: testFilterArr,
        filterDisplay: '',
        dataType: constant.DEFAULT_GUID,
        dataTypeText: '',
        dataTypeLogic: '',
        campaignFilterIds: [],
        expression: '',
        dynamicDropDowns: [],
        logicDropdown:[],
        dataTypeLogicVisible: false
    }
    const testCampaignQueryInfo: CampaignQueryInfo = {
        id: prop.queryInputs.id ?? constant.DEFAULT_GUID,
        queryName: prop.queryInputs.id ?? '',
        description: prop.queryInputs.description ?? '',
        queryStatement: prop.queryInputs.queryStatement ?? '',
        requestedBy: prop.queryInputs.requestedBy ?? '',
        updatedOn: prop.queryInputs.updatedOn ?? null,
        status: prop.queryInputs.status ?? '',
        _campaignFilterList: prop.queryInputs._campaignFilterList ?? testFilterArr
    }

    const testQuery: TestQuery = {
        selectQuery: testCampaignQueryInfo.queryStatement ?? '',
        filterList: []
    }
    const [queryModel, setqueryModel] = useState(testCampaignQueryInfo);
    const [testQueryInput, setTestQueryInput] = useState(testQuery);
    const [filterLogicInput, setFilterLogicInput] = useState(testQueryInput.filterList ?? []);
    const [isInvalidZipNumbersCurrency, setIsInvalidZipNumbersCurrency] = useState<boolean[]>([]);
    const [zipNumberCurrecnyValues, setZipNumberCurrecnyValues] = useState<string[]>([]);
    const [isLoadCalled, setLoadCalled]=useState(false)
    //const [dynamicOptions, setDynamicOptions] = useState([]);
    //const [QueryFilterSelect, setQueryFilterSelect] = useState([]);

    useEffect(() => {
        var logicList = campaignQueryUtils.setFilterModel(testCampaignQueryInfo)
        setFilterLogicInput(logicList.filterList)
        setTestQueryInput(logicList)
        setLoadCalled(true)
        prop.runTestQueryModel(testQueryInput)
    }, [isLoadCalled])

    const handleFilterConditionChange = (filterIndex: number, value: string) => {
        const testQueryModel = { ...testQueryInput }
        var selectedLogic = [...testQueryModel.filterList]
        selectedLogic[filterIndex]['logic'] = value;
        testQueryModel.filterList = selectedLogic
        setTestQueryInput(testQueryModel)
        prop.runTestQueryModel(testQueryInput)
        prop.setRecordCount("0")
        prop.setRecordList([])
    };

    const handleDataItemChange = (filterIndex: number, value?: string) => {
        const testQueryModel = { ...testQueryInput }
        var selectedLogic = [...testQueryModel.filterList]
        selectedLogic[filterIndex]['dataItem'] = value;
        testQueryModel.filterList = selectedLogic
        setTestQueryInput(testQueryModel)
        //console.log('dataItem1',testQueryModel)
        prop.runTestQueryModel(testQueryInput)
        prop.setRecordCount("0")
        prop.setRecordList([])
    };

    const handleFreeformChange = (filterIndex: number, value?: string) => {
        const testQueryModel = { ...testQueryInput }
        var selectedLogic = [...testQueryModel.filterList]
        if(!selectedLogic[filterIndex]['dataItem']){
            selectedLogic[filterIndex]['dataItem'] = [];
        }
        selectedLogic[filterIndex]['dataItem'][0] = value;
        testQueryModel.filterList = selectedLogic
        setTestQueryInput(testQueryModel)
        prop.runTestQueryModel(testQueryInput)
        prop.setRecordCount("0")
        prop.setRecordList([])
    };

    const handleRangeChange = (filterIndex: number, value?: any,range?:any,) => {
        setTestQueryInput((testQueryInput) => {
            const updatedFilters = [...testQueryInput.filterList];
            //console.log('Date',moment(value).format('MM/DD/yyyy'))
            if(!updatedFilters[filterIndex]['dataItem']){
                updatedFilters[filterIndex]['dataItem'] = [];
            }
            if(range===label.FROM ){
                updatedFilters[filterIndex]['dataItem'][0]=value
            }
            if(range===label.TO){
                updatedFilters[filterIndex]['dataItem'][1]=value
            }
            return {
              ...testQueryInput,
              filterList: updatedFilters,
            };
          });
          prop.runTestQueryModel(testQueryInput)
          prop.setRecordCount("0")
         prop.setRecordList([])
    };
    const handleNumericCurrencyZipFilterValuesChange = (filterIndex: number, values?: string) => {
        
        //const numbersArray = values.split(';').map(String)
        const filteredNumbers = values.split(';').filter(str => str != '').map(Number).filter(x=>!Number.isNaN(x))
        const testQueryModel = { ...testQueryInput }
        var selectedLogic = [...testQueryModel.filterList]
        selectedLogic[filterIndex]['dataItem'] = filteredNumbers;
        setTestQueryInput(testQueryModel)
        prop.runTestQueryModel(testQueryInput)
        prop.setRecordCount("0")
        prop.setRecordList([])
      };
      
      const handleNumericCurrencyInitialFilterValueChange = (filterIndex: number, initialValue?: number) => {
        setTestQueryInput((testQueryModel) => {
          const updatedFilters = [...testQueryModel.filterList];
          var i=isNaN(initialValue)?true:false
            updatedFilters[filterIndex].dataItem[0] = i===true?"":initialValue;
          return {
            ...testQueryModel,
            filterList: updatedFilters,
          };
        });
        prop.runTestQueryModel(testQueryInput)
        prop.setRecordCount("0")
        prop.setRecordList([])
      };
    
      const handleNumericCurrencyFinalFilterValueChange = (filterIndex: number, finalValue?: number) => {
        setTestQueryInput((testQueryModel) => {
          const updatedFilters = [...testQueryModel.filterList];
          var i=isNaN(finalValue)?true:false
          updatedFilters[filterIndex].dataItem[1] = i===true?"":finalValue;;
          //e.log('Final',updatedFilters)
          return {
            ...testQueryModel,
            filterList: updatedFilters,
          };
        });
        prop.runTestQueryModel(testQueryInput)
        prop.setRecordCount("0")
        prop.setRecordList([])
      };

 
    return (
        <>
         <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Column lg={12} md={12}>
                {testCampaignQueryInfo != null && testCampaignQueryInfo != undefined && testCampaignQueryInfo._campaignFilterList != null && testCampaignQueryInfo._campaignFilterList.length > 0 &&
                    <Row className={"filter-row-height"} style={{ height: testCampaignQueryInfo._campaignFilterList.length * 50 }}>
                        <Column lg={12} md={12} style={{ borderBottom: "0px" }}>
                            {testCampaignQueryInfo._campaignFilterList.map((filter, index) => (
                                <Row style={{ height: "45px" }}>
                                    <Column lg={2} md={2} className='nopadding-left'>
                                        <Label className='font-weight-bold mt-2' text={filter.filterDisplay} />
                                    </Column>
                                    {/* Filter  conditions Dropdown started */}
                                    
                                    {Array.isArray(filter.campaignFilterIds.toString().split(",")) && filter.campaignFilterIds.toString().split(",").length > 0
                                       
                                        && (
                                            <>
                                            <Column lg={4} md={4} className={(testQueryInput?.filterList[index]!==undefined&&(testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Date_Range||testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Number_Range || testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Currency_Range))?'nopadding-left hidden':'nopadding-left'}>
                                                <Select
                                                    id={`filter-condition-${index}`}
                                                    placeholder={label.PLEASE_SELECT}
                                                    options={filter.logicDropdown[0]??[]}
                                                    inputProps={{className:"form-control texbox-input"}}
                                                    isInvalid={filter.isTestLogicEmpty}
                                                    onChange={(selectedOptions) => {
                                                        if (selectedOptions) {
                                                            handleFilterConditionChange(index, selectedOptions[0]);
                                                        }
                                                    }}
                                                    className={(testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Date_Range||testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Number_Range || testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Currency_Range)?'hidden':''}
                                                    disabled={(testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Date_Range||testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Number_Range || testQueryInput?.filterList[index]?.type.toLowerCase()===constant.Data_Type_Currency_Range)?true:false}
                                                    defaultSelected={
                                                        filter.logicDropdown[0]?.filter(({ value }) => value === (testQueryInput?.filterList[index]?.logic?.toUpperCase()))
                                                    }
                                                />
                                            </Column>
                                           
                                            </>
                                        )                                   
                                         }
                                   
                                    {/* Filter  conditions Dropdown ended */}
                                    {/* Filter values start */}
                                    {/* Filter Dropdown start */}
                                    {
                                        (filter.dataType?.toLowerCase() === constant.Data_Type_Dropdown_List && filter.expression !== undefined && filter.expression !== null)
                                        && (
                                            <>
                                            <Column lg={4} md={4} className='nopadding mr-3' >
                                                <Select
                                                    id={`filter-value-dropdown-${index}`}
                                                    options={queryModel._campaignFilterList[index].dynamicDropDowns[0]??[]}
                                                    placeholder={label.PLEASE_SELECT}
                                                    inputProps={{className:"form-control texbox-input",
                                                title:(queryModel._campaignFilterList[index].dynamicDropDowns[0]===undefined?''
                                                        :queryModel._campaignFilterList[index].dynamicDropDowns[0].filter(({ value }) => testQueryInput.filterList === undefined ? []
                                                                                                                         : testQueryInput.filterList=== null ? [] 
                                                                                                                         : testQueryInput.filterList[index]?.dataItem?.toString()
                                                                                                                         .includes(value))).map((option)=>option.label).toString().replaceAll(',','\r\n')
                                                }}
                                                    multiple={true}
                                                    style={{width:'100%'}}
                                                    isInvalid={filter.isTestDataItemEmpty[0]??false}
                                                    defaultSelected={queryModel._campaignFilterList[index].dynamicDropDowns[0]===undefined?'':queryModel._campaignFilterList[index].dynamicDropDowns[0].filter(({ value }) => testQueryInput.filterList === undefined ? [] : testQueryInput.filterList=== null ? [] : testQueryInput.filterList[index]?.dataItem?.toString().includes(value))}
                                                    onChange={(selectedOptions) => {
                                                        if (selectedOptions) {
                                                            handleDataItemChange(index, selectedOptions);
                                                        }
                                                    }}
                                                />
                                            </Column>
                                            
                                         </>
                                        )
                                    }
                                   
                                    
                                    {
                                       
                                        (filter.dataType?.toLowerCase() === constant.Data_Type_Dropdown_SQL && filter.expression !== undefined && filter.expression !== null)
                                       && (
                                           <>
                                            <Column lg={4} md={4} className='nopadding mr-3'>
                                                <Select
                                                id={`filter-value-dropdown-${index}`}
                                                options={queryModel._campaignFilterList[index].dynamicDropDowns[0]??[]}
                                                placeholder={label.PLEASE_SELECT}
                                                isInvalid={filter.isTestDataItemEmpty[0]??false}
                                                multiple={true}
                                                inputProps={{className:"form-control texbox-input",style:{width:'100%'},
                                                title:(queryModel._campaignFilterList[index].dynamicDropDowns[0]?.filter(({ value }) => testQueryInput.filterList === undefined ? [] 
                                                                                    : testQueryInput.filterList=== null ? [] 
                                                                                    : testQueryInput.filterList[index]?.dataItem?.toString().includes(value)))
                                                                                    .map((option)=>option.label).toString().replaceAll(',','\r\n')
                                            }}
                                                defaultSelected={queryModel._campaignFilterList[index].dynamicDropDowns[0]?.filter(({ value }) => testQueryInput.filterList === undefined ? [] : testQueryInput.filterList=== null ? [] : testQueryInput.filterList[index]?.dataItem?.toString().includes(value))}
                                                onChange={(selectedOptions) => {
                                                    if (selectedOptions) {
                                                        handleDataItemChange(index, selectedOptions);
                                                    }
                                                }}
                                            />
                                            </Column>
                                            </>
                                        )
                                       
                                    }
                                    {/* Filter Dropdown end */}

                                    {/* Free form start */}

                                    {
                                        (
                                           filter.dataType?.toLowerCase() === constant.DATA_TYPE_FREEFORM
                                        )
                                        && (
                                            <>
                                            <Column lg={4} md={4} className='nopadding mr-3'>
                                                <TextInput
                                                    id={`filter-initial-value-text-${index}`}
                                                    placeholder={''}
                                                    style={{width:'100%'}}
                                                    value={testQueryInput.filterList[index]?.dataItem[0]}
                                                    isInvalid={filter.isTestDataItemEmpty[0]??false}
                                                    onChange={(event) => {
                                                        handleFreeformChange(index,event.currentTarget.value)
                                                        }} 
                                                />
                                            </Column>
                                            </>
                                        )}
                                          {/* Free form end */}
                                    {/* Filter currency/numeric start */}
                                    {
                                        (
                                            filter.dataType?.toLowerCase() === constant.Data_Type_Number || filter.dataType?.toLowerCase() === constant.Data_Type_ZipCode || filter.dataType?.toLowerCase() === constant.Data_Type_Currency
                                        )
                                        // (filter.filterType.toLowerCase() === "currency" ||
                                        //   filter.filterType.toLowerCase() == "numeric" ||
                                        //   filter.filterType.toLowerCase() === "zip code")

                                        && (
                                            <>
                                            <Column lg={4} md={4} className='nopadding mr-3'>
                                                <TextInput
                                                    id={`filter-initial-value-text-${index}`}
                                                    placeholder={(filter.dataType?.toLowerCase() === constant.Data_Type_Number || filter.dataType?.toLowerCase() === constant.Data_Type_ZipCode || filter.dataType?.toLowerCase() === constant.Data_Type_Currency)?constant.FILTER_CRITERIA_ZIP_NUMBER_CURRENCY_PLACEHOLDER:''}
                                                    value={zipNumberCurrecnyValues[index]===undefined?'':zipNumberCurrecnyValues[index]}
                                                    isInvalid={isInvalidZipNumbersCurrency[index]||(filter.isTestDataItemEmpty[0]??false)}
                                                    style={{width:'100%'}}
                                                    onKeyPress={(event)=>{
                                                        if(event.key === 'e' || event.key === '-' || event.key === '+'){
                                                            event.preventDefault()
                                                        }
                                                        else{
                                                            return true;
                                                        }
                                                        return false
                                                    }}
                                                    title={zipNumberCurrecnyValues[index]===undefined?'':zipNumberCurrecnyValues[index]}
                                                    onChange={(event) => {
                                                        if ((/^[0-9;]*$/.test(event.target.value))|| ((testQueryInput.filterList[index].type === constant.Data_Type_Currency||testQueryInput.filterList[index].type === constant.Data_Type_Number) && (/^[0-9;.]*$/.test(event.target.value)))) {
                                                            const updatedArrayZipNumberValidation = [...isInvalidZipNumbersCurrency];
                                                            updatedArrayZipNumberValidation[index] = false;
                                                            setIsInvalidZipNumbersCurrency(updatedArrayZipNumberValidation);
                                                            const updatedArrayForZipNumberCurrencyValue = [...zipNumberCurrecnyValues];
                                                            updatedArrayForZipNumberCurrencyValue[index] = event.currentTarget.value;
                                                            setZipNumberCurrecnyValues(updatedArrayForZipNumberCurrencyValue);
                                                            handleNumericCurrencyZipFilterValuesChange(index, event.currentTarget.value);
                                                          }
                      
                                                          else {
                                                            const updatedArray = [...isInvalidZipNumbersCurrency];
                                                            updatedArray[index] = true;
                                                            // Set the state with the updated array
                                                            setIsInvalidZipNumbersCurrency(updatedArray);
                                                          }
                                                }} 
                                                onBlur={(event)=>{

                                                    const testQueryModel = { ...testQueryInput }
                                                    var selectedLogic = [...testQueryModel.filterList]
                                                    let filteredNumbers=selectedLogic[index]['dataItem'];
                                                    var filteredstring=filteredNumbers.join(';').toString()
                                                    const updatedArrayForZipNumberCurrencyValue = [...zipNumberCurrecnyValues];
                                                    updatedArrayForZipNumberCurrencyValue[index] = filteredstring;
                                                    setZipNumberCurrecnyValues(updatedArrayForZipNumberCurrencyValue);
                                                    //console.log('zipNumberCurrecnyValues[index]',zipNumberCurrecnyValues[index])
                                                }}
                                                />
                                            </Column>
                                            </>
                                        )}

                                    {(filter.dataType?.toLowerCase() === constant.Data_Type_Currency_Range ||
                                        filter.dataType?.toLowerCase() === constant.Data_Type_Number_Range)
                                        && (
                                            <>
                                                <Column lg={4} md={4} className='nopadding-left'>
                                                    <TextInput
                                                        id={`filter-initial-value-text-${index}`}
                                                        type='number'
                                                        isInvalid={filter.isTestDataItemEmpty[0]??false}
                                                        placeholder={filter.dataType?.toLowerCase() === constant.Data_Type_Currency_Range?constant.PLACEHOLDER_CURRENCY_RANGE_FROM:constant.PLACEHOLDER_NUMBER_RANGE_FROM}
                                                        maxLength={20}
                                                        value={testQueryInput.filterList[index]?.dataItem[0]!==undefined?testQueryInput.filterList[index]?.dataItem[0]:null}
                                                        onChange={(event) => {
                                                            handleNumericCurrencyInitialFilterValueChange(index, event.currentTarget.valueAsNumber)
                                                        }
                                                    }
                                                    onKeyPress={(event)=>{
                                                        if(event.key === 'e' || event.key === '-' || event.key === '+'){
                                                            event.preventDefault()
                                                        }
                                                        else{
                                                            return true;
                                                        }
                                                        return false
                                                    }}
                                                    />
                                                </Column>
                                                {/* <Column lg={1} md={1} style={{ borderBottom: "0px" }} className="nopadding  mr-n5 ml-n2 mt-2">
                                                    <Label text={"TO"} style={{ fontWeight: 'normal' }} />
                                                </Column> */}
                                                <Column lg={4} md={4} className='nopadding-left nopadding-right mr-3'>
                                                    <TextInput
                                                    id={`filter-final-value-text-${index}`}
                                                    value={testQueryInput.filterList[index]?.dataItem[1]!==undefined?testQueryInput.filterList[index]?.dataItem[1]:null}
                                                    maxLength={100}
                                                    placeholder={filter.dataType?.toLowerCase() === constant.Data_Type_Currency_Range?constant.PLACEHOLDER_CURRENCY_RANGE_TO:constant.PLACEHOLDER_NUMBER_RANGE_TO}
                                                    isInvalid={filter.isTestDataItemEmpty[1]??false}
                                                    type='number'
                                                    style={{width:'100%'}}
                                                    onChange={(event) =>{
                                                        handleNumericCurrencyFinalFilterValueChange(index, event.currentTarget.valueAsNumber)
                                                    }
                                                    }
                                                    onKeyPress={(event)=>{
                                                        if(event.key === 'e' || event.key === '-' || event.key === '+'){
                                                            event.preventDefault()
                                                        }
                                                        else{
                                                            return true;
                                                        }
                                                        return false
                                                    }}
                                                    />
                                                </Column></>
                                        )}

                                    {/* Filter currency/numeric end */}
                                    {/* Filter  Date Start */}

                                    {filter.dataType?.toLowerCase() == constant.Data_Type_Date
                                        // filter.filterType == "Date" 
                                        && (
                                            <>
                                            <Column lg={4} md={4} className='nopadding mr-3'>
                                                <DateTimePicker
                                                    dateFormat="MM/dd/yyyy"
                                                    dateFormatCalendar="LLLL yyyy"
                                                    isInvalid={filter.isTestDataItemEmpty[0]??false}
                                                    dropdownMode="select"
                                                    name="datePicker"
                                                    value={testQueryInput.filterList[index]?.dataItem[0]}
                                                    style={{width:'100%'}}
                                                selected={
                                                    testQueryInput.filterList[index]?.dataItem[0] &&
                                                    testQueryInput.filterList[index]?.dataItem[0] 
                                                        ? testQueryInput.filterList[index]?.dataItem[0]
                                                        :  null
                                                }
                                                onChange={(date) => {
                                                    handleRangeChange(index,date ,label.FROM);
                                                }}
                                                >
                                                </DateTimePicker>
                                            </Column>
                                            </>

                                        )}

                                    {/* Filter  Date end */}
                                    {/* Filter  Date range start */}
                                    {filter.dataType?.toLowerCase() == constant.Data_Type_Date_Range && (

                                        <>
                                            <Column lg={4} md={4} className='nopadding-left'>
                                                <DateTimePicker
                                                    dateFormat="MM/dd/yyyy"
                                                    dateFormatCalendar="LLLL yyyy"
                                                    dropdownMode="scroll"
                                                    placeholderText={constant.PLACEHOLDER_DATE_RANGE_FROM}
                                                    isInvalid={filter.isTestDataItemEmpty[0]??false}
                                                selected={
                                                     testQueryInput.filterList[index]?.dataItem[0] &&
                                                    testQueryInput.filterList[index]?.dataItem[0] instanceof Date
                                                    ? testQueryInput.filterList[index]?.dataItem[0]
                                                    : null
                                                }
                                                onChange={(date) => {
                                                    handleRangeChange(index, date, label.FROM);
                                                }}
                                                >
                                                </DateTimePicker>
                                            </Column>

                                            {/* <Column lg={1} md={1} style={{ borderBottom: "0px" }} className="nopadding mr-n5 ml-n2 mt-2">
                                                <Label text={"TO"} style={{ fontWeight: 'normal' }} />
                                            </Column> */}
                                            <Column lg={4} md={4} className='nopadding-left mr-3'>
                                                <DateTimePicker
                                                    dateFormat="MM/dd/yyyy"
                                                    dateFormatCalendar="LLLL yyyy"
                                                    dropdownMode="scroll"
                                                    placeholderText={constant.PLACEHOLDER_DATE_RANGE_TO}
                                                    style={{width:'105%'}}
                                                    isInvalid={filter.isTestDataItemEmpty[1]??false}
                                                selected={
                                                    testQueryInput.filterList[index]?.dataItem[1] &&
                                                    testQueryInput.filterList[index]?.dataItem[1] instanceof Date
                                                        ? testQueryInput.filterList[index]?.dataItem[1]
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    handleRangeChange(index, date, label.TO);
                                                }}
                                                >
                                                </DateTimePicker>
                                            </Column>
                                        </>
                                    )}

                                    {/* Filter  Date range end */}
                                    <Column lg={1} md={1} className='nopadding-left mt-2'>
                                        {/* check to hide last And*/}
                                        {
                                        index < testCampaignQueryInfo._campaignFilterList.length-1 &&
                                        <Label text={label.AND.toUpperCase()} style={{ fontWeight: 'normal' }} />
                                        }
                                    </Column>

                                </Row>

                            ))
                                // ) : ""
                            }


                        </Column>
                    </Row>
                }
                <Row>
                    <Column lg={2} md={2} className='nopadding-left'><Label className='font-weight-bold mt-2' text={label.RECORD_COUNT} /></Column>
                    <Column lg={2} md={2} className='nopadding-left'><Label className='mt-2' text={prop.recordCount} /></Column>
                    <Column lg={8} md={8}>&nbsp;</Column>
                </Row>

                <Row>
                    <Column lg={12} md={12} className='nopadding-left'><Label className='text-danger font-weight-bold'  text={prop.errorMsg} /></Column>

                </Row>

            </Column>
            </ErrorBoundary>
        </>
    )
}
export default DynamicFilter 