import { AxiosResponse } from "axios";
import { CommonDataDto } from "../customer/model/CommonDataDto";
import { client } from "../shared/services/client";


class CommonDataService {

    static getEmployeeList = async () : Promise<CommonDataDto[]> => {
        client.setBaseURLForService("admin")
        const config ={}
        const res: AxiosResponse<CommonDataDto[]> =  await client.get(`/api/CommonData/GetEmployeeList`, config);
        return res.data;
    }

    static getCompanyList = async () : Promise<CommonDataDto[]> => {
        client.setBaseURLForService("admin")
        const config ={}
        const res: AxiosResponse<CommonDataDto[]> =  await client.get(`/api/CommonData/GetCompanyList`, config);
        return res.data;
    }
}

export default CommonDataService;