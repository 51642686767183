import React, { useEffect, useState } from "react";
import './CustomerPolicyList.scss';
import CustomerPolicySummary from "./CustomerPolicySummary";
import CustomerPolicyListService from "./CustomerPolicyListService";
import { PolicyDtoRead } from "../model/PolicyDtoRead";
import Logger from "../../shared/services/Logger";
import * as constant from '../../shared/util/constant';

export interface ICustomerPolicyListComponent
{
    customerId : string
};

const CustomerPolicyList : React.FunctionComponent<ICustomerPolicyListComponent> = (props) => {
    const customerPolicyListService  = new CustomerPolicyListService();

    const [viewLoaded, setViewLoaded] = useState(false);
    const [customerPolicies, setCustomerPolicies] = useState<PolicyDtoRead[]>([]);

    useEffect(() => {
        InitializeComponent();
    },[]);

    const InitializeComponent = async () => {
        try {
            const cusotomerPolicies = await customerPolicyListService.fetchCustomerPolicyDetails(props.customerId);
            setCustomerPolicies(cusotomerPolicies);
        }
        catch(ex) {
            Logger.logError(`${constant.CUSTOMER_POLICY_LIST_ERROR_WHILE_FETCHING_POLICIES} customer id - ${props.customerId}`, ex);
        }

        setViewLoaded(true);
    }

    let policiesPanels = customerPolicies.map(item => <CustomerPolicySummary policySummary={item}></CustomerPolicySummary>);
    if (policiesPanels.length == 0 && viewLoaded) {
        policiesPanels = [<div className="temp-component-place-holder">No Policies found for customer.</div>]
    }

    if (!viewLoaded) {
        policiesPanels = [<div className="temp-component-place-holder">Fetching policy details.</div>]
    }

    return (
        <div>
            {policiesPanels}
        </div>
    );
}

export default CustomerPolicyList;