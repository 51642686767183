import {AxiosResponse} from 'axios'
import { client } from "../../../shared/services/client"; 
import { Promise} from 'cypress/types/bluebird';
import CampaignDashboardSendonDate from '../../model/CampainDashboardSendonDate';

class CampaignDashboardAPI {
    getCampaignDataonSendonDate = async (): Promise<CampaignDashboardSendonDate[]> => {
        client.setBaseURLForService("campaign")
          const res: AxiosResponse<CampaignDashboardSendonDate[]> =  await client.get<CampaignDashboardSendonDate[]>("/api/Campaign/GetDashboardCampaigns");
          //console.log('getCampaignList:',res.data)
          return res.data;
        };
}

export const campaignDashboardAPI = new CampaignDashboardAPI();