import React, { CSSProperties } from 'react'
import Form from 'react-bootstrap/Form'
import * as constant from '../../../shared/util/constant'
import { getControlSize } from '../../helpers/controlSize'
import { Label } from '../Label'

interface Props {
  /** A unique identifier for the componentchrome
   *
   */
  id?: string
  /** Determines whether the TextField should be disabled or not. By default, it is false. */
  disabled?: boolean
  /** Determines whether the TextField should be rendered as invalid or not. By default, it is false. */
  isInvalid?: boolean
  /** Determines whether the Select should be rendered as valid or not. Default is false. */
  isValid?: boolean
  /** The name of the text field */
  name?: string
  /** The number of rows to render */
  rows?: number
  /** Determines whether to render a small or large TextField. By default, it is undefined. */
  size?: 'small' | 'large'
  /** The value of the text field */
  value?: string
  /** Defines the default value */
  defaultValue?: string | Array<string>
  /** Handles the onChange event for the TextField */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  /**
   * Defines the class of the textfield.
   */
  className?: string
  /**
   * Defines the style of the textfield.
   */
  style?: CSSProperties
  /** Defines the custom feedback of the input. */
  feedback?: string
    /** Defines the placeholder of the input. */
  placeholder?: string
  isRequired?:boolean
  isEmpty?:boolean
  isTooShort?:boolean
  tabIndex?:number
  maxLength?:number
  errorText?:string
  title?:string
  invalidWarning?:string
  invalidLableClass? : string
  errorLableDivClassName? : string
}

/**
 * A customizable text field component. It's a wrapper component built upon react's form controls.
 */
const TextField = (props: Props) => {
  const {
    id,
    disabled,
    isValid,
    isInvalid,
    feedback,
    name,
    rows,
    size,
    value,
    onChange,
    className,
    style,
    defaultValue,
    placeholder,
    isRequired,
    isEmpty,
    isTooShort,
    tabIndex,
    maxLength,
    errorText,
    invalidWarning,
    invalidLableClass,
    errorLableDivClassName,
    title
  } = props

  let invalidLable = `cst-Feedback-Linebreak${invalidLableClass ? ' ' + invalidLableClass : ''}`;

  return (
    <>
    <div>
    <Form.Group>
      <Form.Control
        id={id}
        as="textarea"
        disabled={disabled}
        isInvalid={isInvalid}
        isValid={isValid}
        name={name}
        rows={rows}
        placeholder={placeholder}
        size={getControlSize(size)}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        className={className}
        style={style}
        required={isRequired}
        tabIndex={tabIndex}
        maxLength={maxLength}
        title={title}
      />
      <Form.Control.Feedback
        className={isValid ? 'text-success' : isInvalid ? 'text-danger' : undefined}
        type={isValid ? 'valid' : 'invalid'}
      >
        {feedback}
      </Form.Control.Feedback>
    </Form.Group>
    </div>
    <div  className={isEmpty||isTooShort?`upmargin20 ${errorLableDivClassName ? errorLableDivClassName : ''}`:"" }>
    {isTooShort  && <Label className='cst-Feedback-Linebreak'
          text={errorText==""?constant.FIELD_MIN_CHAR.replace('{}','5'):errorText==null?constant.FIELD_MIN_CHAR.replace('{}','5'):errorText}
          style={{ color: "red"}} />}
          {isTooShort && <br/>}
          
        {isEmpty && <Label className='cst-Feedback-Linebreak, errLabel'
          text={errorText==""?constant.FIELD_BLANK:errorText==null?constant.FIELD_BLANK:errorText}
          //style={{ color: "red", fontWeight:"700", marginTop:"10px",fontSize:"14px"}} 
          />
        }
           {isEmpty && <br/>}
        
           {isInvalid &&
            <div 
            className='upmargin20'
            // style={{marginTop:-25}}
            >
                 <Label
                 className={invalidLable}
                  text={invalidWarning}
                  style={{ color: "red" }} />
              </div>}

      </div>
    </>
  )
}

export { TextField }
