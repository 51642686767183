enum LookupTable { 
    PropertyType='a1',
LoanStatus='a10', 
SolicitingCompany='a101',
LeadStatus='a12',
NoteType='a13',
PhoneTypes='a14',
Salutations='a15',
LoanPurpose='a16',
ImpoundTypes='a17',
PaymentMethod='a18',
CreditCard='a19',
GarageType='a2',
HomeType='a20',
UserCategoryFactors='a200',
PriorPolicyHistory='a21',
PolicyHistoryReason='a22',
FireStation='a23',
PoolType='a24',
PrimaryHeating='a25',
SecondaryHeating='a26',
PetType='a27',
RenovationType='a28',
RenovationExtent='a29',
PolicyType='a3',
MiscPolicyTypes='a30',
PolicyFormType='a33',
TaskType='a34',
TaskStatus='a35',
CoverageType='a36',
RatingType='a37',
ConstructionType='a4',
FactorsStatic='a40',
AdjustmentType='a42',
ScheduleFactors='a43',
CompensationType='a47',
MapType='a48',
MapSource='a5',
RatingAppForm='a50',
ScheduleUnscheduled='a51',
OccupancyType='a52',
Factor='a53',
DeclarationLocation='a54',
ParentCompany='a55',
ExcludeFromMarketing='a56',
RatingConstructionTypes='a57',
Vesting='a6',
Language='a7',
NoQuoteReason='a8',
LoanType='a9',
ExcludeFromMarketingStatic='a58',
PolicyCategory='a31',
FormTypes='formtype',
MessageDetailType='detailtype',
QualityOfConstruction='a201',
RoofType='a202',
Slope='a203',
FoundationType='a204',
DistanceToCoast='a205',
Shape='a206',
Sprinkler='a207',
CommissionType='a208',
Sex='a301',
Race='a302',
MaritalStatus='a303',
RelationtoInsured='a304',
DriverStatus='a305',
CreditRating='a306',
LicenseStatus='a307',
AutoAccidentType='a308',
AutoViolationType='a309',
AutoLossType='a310',
AirBag='a311',
AntiTheftDevice='a312',
VehicleUsage='a313',
AutoRatingSoftware='a314',
AutoAdditionalRatingItem='a315',
AutoBILimits='a316',
AutoPDLimits='a317',
AutoUMUIMLimits='a318',
AutoUMPDLimits='a319',
AutoMedPayLimits='a320',
AutoComprehensiveDeduct='a321',
AutoCollisionDeduct='a322',
AutoRentalLimits='a323',
AutoTowingLimits='a324',
AutoPIPLimits='a325',
AutoPerformance='a326',
AntiLocBreaks='a328',
HomeCreditRanking='a327',
AutoNoQuoteReason='a80',
BCEGCode='a239',
CancelReasons='a240',
LossTypes='a500',
CreditCheckApproval='a510',
RiskTypes='a241',
RiskResults='a242',
AutoLapse='a329',
AutoOccupations='a330',
OpeningProtection='a243',
RoofShape='a244',
MergeDocGroups='a399',
GateGuarded='a398',
Terrain='a397',
WindBornDebrisRegion='a395',
FBCWindSpeed='a396',
StormShutters='a245',
ImpactGlass='a246',
GarageDoorReinforced='a247',
VinylWindow='a248',
Shingle110MPH='a249',
SWR='a250',
WindPoolArea='a251',
FireAlarmTypes='a252',
BurglarAlarmTypes='a253',
PropertyDetails='a300',
RCVFoundationType='F6FB34EAB3174FAAAD4FA356B789189C',
RCVFraming='5B110223537745C0B65CFA4FCC8E31DC',
RCVExteriorWalls='8A113203BED54366AA154B48C8F9B11F',
RCVWallHeight='AE7532D4A5514594BE1166FF18CA67E4',
RCVCelingHeight='8B1AD1C6659B4202B1F2EE1744AE4F1F',
RCVRoofType='79101CDE77B7441E964CFD30F8D84D5A',
RCVWallCoveragings='49EE094F9E174447A81D20A39D81B0B5',
RCVCeilings='FD5B893E46AF4440BC65F66D5BF08E6B',
RCVFlooring='A9630C28FC604C05BCE0557A77B15C58',
RCVHeatType='5CEFC500955447048DF1A6BF19961D8D',
RCVAirConditioning='F73027C165164F778C5BB05EC7A8619C',
RCVKitchen='B1AF6F2F4BA54459865EA6A8632FD4BF',
RCVBaths='EC4DB5F889EA42409547FED2CC429139',
RCVFireplaces='C2EE3612AA7843D9ACACA5CEA08945A6',
RCVGarageType='3B7A0ADD243F44F68C5F17ABB0436832',
RCVInteriorFeatures='516A5D68681D4C30841D89E3A98CC85F',
StructureStyle='a340',
SiteAccess='a341',
RCVMiscellaneousSystems='BF8AAD7BB8684691B9C0C45BAF6FB8E1',
RCVPartitions='C97DD53AA2AD4A72BDDF56BE9C0A1E6D',
ExteriorSpecialItems='9DAE61D4F22E41269BE4A2FB33D9E574',
SumpPump='a600',
ValueBracket='a410',
AutoPaymentMethod='auto18',
AutoSolicitation='auto19',
AdditionalProducts='a601',
ResidenceType='a100',
BedLength='a102',
RCVAttachedStructures='C8CBD8FF108644E5A9A0D057B01254C5',
RCVFoundationMaterial='3861C45B8CD14E2EB1A84878AAC912F4',
Unknown='Unknown',
WaterSensor='8988EDF3182D43569148B2237DD2372A',
DeclineReasonsHome='0BE0F4582B664C139F077C3C98A03BB6',
DeclineReasonsAuto='FA35EA35CE654F3CB39089260813354A',
Country='EFCB95B5E3024305BA94D59D8544D97A',
AdditionalInterest='68710BEDA40A42EA85DA3AB877BB4B2F',
PhoneTypesSourceContact='E742B7F9B600410C943FA8A213EB2D4C',
PhoneTypesCarrier='5414E5FBA8834F1CA58FCE450563504C',
PhoneTypesHomeAuto='1D4BBF1BB4834C168EDEA90862D89AED',
PhoneTypesSourceContactInformation='5FD153184EA04EBBB996279B1A5FE5D0',
TaskTypeTableId='BA6F37C8-FFE7-44E1-8F54-6432E1D21B64',
TransactionType='63032C80-D112-4910-B416-DD343905EF1F'
}
  
  export default LookupTable