import { AxiosResponse } from "axios";
import { client } from "../../shared/services/client";
import { GetDocumentRequestModel } from "../model/GetDocumentRequestModel";
import { GetDocumentResponseDto } from "../model/GetDocumentResponseDto";
import { RefiTodoListDto } from "../model/RefiiTodoListDto";
import { RefiDocument } from "../model/RefiDocument";


export const getDocument = async (getDocumentRequestModel : GetDocumentRequestModel): Promise<GetDocumentResponseDto> => {
    // client.setBaseURLForService("customer");
    const config ={params: getDocumentRequestModel};
    const res: AxiosResponse<GetDocumentResponseDto> = await client.get("https://localhost:7208/api/DocStorage/GetDocumentSampleApi", config);
    return res.data;
}

export const getDocumentSample = async (docId: string ): Promise<Blob> => {
    const res: AxiosResponse<Blob> = await client.get("https://localhost:7208/api/DocStorage/GetDocumentSampleApi?StorageAccountUrl=aaaa&BlobContainer=aaaaa", {
        responseType: 'blob',  // This tells Axios to treat the response as a Blob
    });
    return res.data;
}

export const getRefiTodoListFromAPI = async() => {
    const res: AxiosResponse<RefiTodoListDto[]> = await client.get<RefiTodoListDto[]>("https://localhost:7208/api/DocStorage/GetDocumentSampleApi");
    return res.data;
}

export const getRefiDocument = async(id: string) => {
    const res: AxiosResponse<RefiDocument> = await client.get<RefiDocument>(`https://localhost:7208/api/DocStorage/GetDocumentSampleApi/${id}`);
    return res.data;
}