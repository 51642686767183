import { useContext, useEffect, useState } from "react";
import { IMenuItem } from "../../shared/components/menubar/MenuBar";
import { AdminScreenTabs } from "../../shared/util/constant";
import { useHistory, Route } from "react-router-dom";
import { Row, Column, Label } from "../../components";
import './AdminMenuBar.scss'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from "react-bootstrap";
import { UserSetupGrid } from '../UserSetup/UserSetupGrid';
import RolePrivilegeMain from '../RolePrivilegeSetup/RolePrivilegeSetupMain/RolePrivilegeMain'
import * as constant from '../../shared/util/constant';
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import AppContext from "../../shared/components/AppContext";

interface Props {
  isSideBarCollapsed: boolean
}

const adminMenuItems: IMenuItem[] = [
  { menuKey: AdminScreenTabs.general.key, menuText: AdminScreenTabs.general.text },
  { menuKey: AdminScreenTabs.usersetup.key, menuText: AdminScreenTabs.usersetup.text },
  { menuKey: AdminScreenTabs.roleprivilege.key, menuText: AdminScreenTabs.roleprivilege.text },
  { menuKey: AdminScreenTabs.security.key, menuText: AdminScreenTabs.security.text },
  { menuKey: AdminScreenTabs.company.key, menuText: AdminScreenTabs.company.text },
  { menuKey: AdminScreenTabs.agency.key, menuText: AdminScreenTabs.agency.text }
];

const AdminMenuBar = (props: Props) => {

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--marginLeftCustomerRow', props.isSideBarCollapsed ? '5px' : '60px')
  }, [props.isSideBarCollapsed]);

  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('');
  const [currentSubMenus, setCurrentSubMenus] = useState([]);
  const [activeMenuKey, setActiveMenuKey] = useState(null);
  const [activeMenuText, setActiveMenuText] = useState("ADMIN");
  const modalContext = useConfirmationModalContext();
  const adminMenuContext = useContext(AppContext);

  const handleSubmenuClick = async (menuKey: string, menuText: string) => {
    setActiveMenuKey(menuKey);
    setActiveMenuText(menuText)
    history.push(`/admin/${currentTab}/${menuKey}`)
  }

  const onMenuItemClick = async (menuKey: string, menuText: string) => {
    if (AdminScreenTabs[menuKey].submenus === undefined) {
      setCurrentSubMenus([]);
    } else {
      setCurrentSubMenus(AdminScreenTabs[menuKey].submenus);
    }

    if (!adminMenuContext.formSavedStatus) {
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
      if (result) {
        setCurrentTab(menuKey);
        setActiveMenuKey(menuKey);
        setActiveMenuText(menuText)
        history.push(`/admin/${menuKey}`)
        adminMenuContext.formSavedStatus = true
      }
    }
    else {
      setCurrentTab(menuKey);
      setActiveMenuKey(menuKey);
      setActiveMenuText(menuText)
      history.push(`/admin/${menuKey}`)
    }
  }

  let otherPages = <>
    {currentTab == AdminScreenTabs.general.key}
    {currentTab == AdminScreenTabs.company.key}
    {currentTab == AdminScreenTabs.usersetup.key}
    {currentTab == AdminScreenTabs.roleprivilege.key}
    {currentTab == AdminScreenTabs.security.key}
    {currentTab == AdminScreenTabs.agency.key}
  </>;

  let menusToRender = adminMenuItems;

  let adminMenuBarContent = <>
    <Navbar bg="primary" data-bs-theme="dark">
      {menusToRender.map(menu => (
        AdminScreenTabs[menu.menuKey].submenus ? (
          <Dropdown className="AdminDropdown" key={menu.menuKey}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" onClickCapture={() => { onMenuItemClick(menu.menuKey, menu.menuText) }} className={(menu.menuKey === activeMenuKey ? "active" : "")}>
              {menu.menuText}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {currentSubMenus.map((submenu) => (
                <Dropdown.Item eventKey={submenu.menuKey} href={''} onClick={() => handleSubmenuClick(submenu.menuKey, submenu.menuText)}>
                  {submenu.menuText}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Nav.Link onClick={() => onMenuItemClick(menu.menuKey, menu.menuText)} key={menu.menuKey} href={''} className={"adminNavLink " + (menu.menuKey === currentTab ? "active" : "")}>
            {menu.menuText}
          </Nav.Link>
        )
      ))}
    </Navbar>
  </>;

  return (
    <>
      <div className="bg-light sidebarToggle">
        <Label title="admin" text={activeMenuText} className="adminHeader"></Label>
      </div>
      <div style={{
        marginLeft: props.isSideBarCollapsed ? '0' : '50px',
      }}>
        {adminMenuBarContent}
      </div>
      <Route
        exact
        path="/admin/usersetup"
        // component={UserSetupGrid}
        render={() => <UserSetupGrid isSideBarCollapsed={props.isSideBarCollapsed} />}
      />
      <Route
        exact
        path="/admin/roleprivilege/:roleId?"
        render={() => <RolePrivilegeMain />}
      />
    </>
  );

}

export default AdminMenuBar;