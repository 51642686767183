import React, { useState , useEffect, useCallback, useRef, useContext} from 'react'
import { Card, Collapse} from 'react-bootstrap'
import {Select, Button, Label,Row,Column} from '../../../../components'

import { ColorVariant } from '../../../../components/interfaces'
import * as constant from '../../../util/constant'
import { Navbar} from  '../../../../components' 
import { useHistory } from 'react-router-dom'
import NoteCard from './NoteCard'
import HistoryCard from './HistoryCard'
import { useLocation } from 'react-router-dom';
import { constants } from 'buffer'
import * as label from '../../../util/label'
import { AbilityContext } from '../../../../shared/abilities/AbilityContext';

// import { ErrorBoundary } from 'react-error-boundary'

interface Props {
  /** Defines the color of the panel */
  color?: ColorVariant
  /** The body for the panel */
  children?: React.ReactNode
  /** The title for the panel */
  title?: string
  /** The footer for the panel */
  footer?: string
  /** Determines if the panel can be collapsible */
  collapsible?: boolean
  /** Determines if the panel should be collapsed */
  collapsed?: boolean
  /** The className for the panel card parent element */
  className?: string
    selectId?:string
    subject?:string
  //  onSearch?:(filterRequest: CustomerFilterRequest) => void
  //  onAllowingSearch?:(isSearchingAllowed: boolean) => void
  // isCompleteSave?:boolean
  // isSaveAsTriggered?:boolean
}

const NotesPanel = (props) => {
  const { color, children, footer, title, collapsible, collapsed, className,selectId,subject} = props
  const [open, setOpen] = useState(!collapsed || !collapsible)
  const location = useLocation()
  const urlParam = new URLSearchParams(location.search)
  const stateCampaign=useLocation<{ dataToPassToDetails: { campaignId: string,id:string,type:string } }>();
  const state = location.state as { entityId: string,type:string };
  const queryId = state?.entityId??stateCampaign.state?.dataToPassToDetails.id??selectId??urlParam.get('id');
  const [LoadNoteCard, SetNoteCard]=useState(true)
  const [LoadHistoryCard, SetHistoryCard]=useState(false)
  const [isNoteCardActive, setNoteCardActive] = useState(true);
  const [isHistoryCardActive, setHistoryCardActive] = useState(false);
  const [isAddNewActive, setAddNewActive] = useState(false);
  const [isNoteEmpty, setNoteEmptyStatus] = useState(false);
  const [isAddNewVisible, setAddNewVisible] = useState(true);
  const [isAddButtonActive, setIsAddButtonActive] = useState(true);
  const [campaignId,setCampaignId]=useState(state?.entityId ?? urlParam.get('id'))
  //User Permissions
  const ability = useContext(AbilityContext);
  const ab_subject = subject;
  const isUserPermitToViewNote = ability.can('view',ab_subject,constant.ScreenName.NOTE)
  const isUserPermitToReadNote = ability.can('read',ab_subject,constant.ScreenName.NOTE)
  const isUserPermitToCreateNote = ability.can('create',ab_subject,constant.ScreenName.NOTE)
 
  const isUserPermitToReadHistory = ability.can('read',ab_subject,constant.ScreenName.HISTORY)
  const isUserPermitToViewHistory = ability.can('view',ab_subject,constant.ScreenName.HISTORY)
  
  const canAddNote = campaignId===undefined?false:campaignId===null?false:campaignId===constant.DEFAULT_GUID?false:isUserPermitToCreateNote?true:!isUserPermitToReadNote?false:false
  const canReadNote = isUserPermitToReadNote?true:false
  const canReadHistory =isUserPermitToReadHistory?true:false
  const isNotePanelVisible=isUserPermitToViewNote?true:isUserPermitToViewHistory?true:false
  // const [triggerSaveFromParent, setTriggerSaveFromParent] = useState(isCompleteSave);
  // const[isSaveAsEvent, setSaveAsEvent]=useState(isSaveAsTriggered)
  //const noteRef = useRef(ref);
  
//console.log('Panel', isCompleteSave,isSaveAsTriggered)
  const history = useHistory()
  const navigateTo = (location: string) => {
    history.push(location)
  }

  useEffect(() => {
      setIsAddButtonActive(canAddNote)
      if(!canReadNote){
        setHistoryCardForm(true)
      }
  },[LoadHistoryCard,LoadNoteCard,queryId])
  
const setHistoryCardForm=(status: boolean)=>{
  if(canReadHistory){
 SetHistoryCard(status)
  SetNoteCard(!status)
  setNoteCardActive(!status)
  setHistoryCardActive(status)
  setAddNewActive(!status)
  setNoteEmptyStatus(true)
  setAddNewVisible(false)
}
  
}

const setNoteCardForm=(status: boolean)=>{
  if(canReadNote){
  SetHistoryCard(!status)
   SetNoteCard(status)
   setNoteCardActive(status)
   setHistoryCardActive(!status)
   setAddNewActive(!status)
   setNoteEmptyStatus(false)
   setAddNewVisible(true)
  }
  else{
    setNoteCardActive(false)
  }
 }

 const setAddNewForm=(status: boolean)=>{
  setAddNewActive(status)
  setHistoryCardActive(!status)
  setNoteCardActive(!status)
  setNoteEmptyStatus(true)
  setAddNewVisible(true)
 }
const setAddNewButtonActive=(status:boolean)=>{
    setIsAddButtonActive(!canAddNote)
  }
// const cancelNoteSection={status:boolean}=>{

// }
 



const FallbackComponent = ({ error, resetErrorBoundary }) => {
  console.log("An error occurred:");
  console.log(error.message);
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

  const collapseIcon = (
   isNotePanelVisible && <span className='col-lg-12, col-md-12 note-span'>
       <Row style={{marginBottom:'6px'}}>
          <Column md={12} lg={12} style={{paddingLeft:'0px'}}>
          
            <Navbar
            style={{borderBottom:'grey 1px'}} 
            navItems=
            {
              [
                
                isUserPermitToViewNote && {
                  type: 'link',
                  alignRight: false,
                  className:isNoteCardActive && canReadNote?'nav-add-new d-none d-md-block margin-Left-15 highlightTab':'nav-add-new d-none d-md-block margin-Left-15 normalTab',
                  label: label.NOTES,
                  name: 'Notes',
                  size: 'sm',
                  disabled:!canReadNote ,
                  onClick:()=>{
                  setNoteCardForm(true)
                  }                
                },
                isUserPermitToViewNote &&{
                  type: 'link',
                  alignRight: true,
                  children: null,
                  className:`${isAddNewActive?'highlightTab':'normalTab'} ${!isAddNewVisible?'visibility':''} nav-add-new d-none d-md-block add-New-Note`,
                  label: label.ADD_NEW_NOTE,
                  name: 'AddNew',
                  disabled: !isAddButtonActive,
                  size: 'sm',
                  onClick:()=>{
                    //  navigateTo('#')
                    setAddNewForm(true)
                    }
                },
                isUserPermitToViewHistory &&{
                  type: 'link',
                  alignRight: false,
                  children: null,
                  className: `${isHistoryCardActive?'highlightTab ':'normalTab '} ${isUserPermitToViewNote?'margin-Left22 ':'margin-Left-15 '}nav-add-new d-none d-md-block`,
                  label: label.HISTORY,
                  name: 'History',
                  disabled: !isUserPermitToReadHistory,
                  size: 'sm',
                  onClick:()=>{
                    //  navigateTo('#')
                    setHistoryCardForm(true)
                    }
                },
                
              ]
            }
            />
          </Column>
        </Row>
    </span>
  )

  return (
    // <><SaveFilter showSaveFilter={saveFilterModal} handleClose={handleClose} />
    <>
    <Card className={className} border={color}>
      {title && (
        <Card.Header
          style={collapsible ? { cursor: 'pointer', textAlign: 'left' } : { textAlign: 'left' }}
          onClick={() => collapsible && setOpen(!open)}
        >
          {/* {title} */}
          {/* {collapsible && collapseIcon} */}
          {collapseIcon}
        </Card.Header>
      )}
      <Card.Body className='notes-body-card'>
        {collapsible && !title && collapseIcon}
        <Collapse in={open}>
          <div id="collapse-body">
            {children}
            {/* <ErrorBoundary FallbackComponent={FallbackComponent}> ref={noteRef}*/}
               {LoadNoteCard &&isUserPermitToViewNote && isUserPermitToReadNote && <NoteCard addNewStatus={isAddNewActive} setNoteCardForm={setNoteCardForm} isNoteEmpty={isNoteEmpty} setAddNewButtonActive={setAddNewButtonActive} subject={ab_subject}  />}
              {LoadHistoryCard && isUserPermitToViewHistory && <HistoryCard />} 
              <br />
            {/* </ErrorBoundary> */}
            {/* : <p>You are not authorised user for this application. Please contact admin</p> */}
          </div>
        </Collapse>
      </Card.Body>
      {footer && (
        <Card.Footer style={{ textAlign: 'left', fontSize: 'smaller' }}>{footer}</Card.Footer>
      )}
    </Card>
    </>
  )
}
 export default NotesPanel 
