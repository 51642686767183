
class ReactPaginatorService {

    public getPaginationButtonIndexes = (currentPageNumber, pageButtonIndexes : number[], totalPages : number, numberOfPageButtonsToShow : number) : number[] => {
        if (pageButtonIndexes.includes(currentPageNumber)) {
            return pageButtonIndexes;
        }

        let pageButtonsToShow = [];
        pageButtonsToShow.push(currentPageNumber);

        for (let btnIndx = currentPageNumber + 1; btnIndx <= totalPages && pageButtonsToShow.length < numberOfPageButtonsToShow; btnIndx++) {
            pageButtonsToShow.push(btnIndx);
        }

        for (let btnIndx = currentPageNumber - 1; btnIndx >= 0 && pageButtonsToShow.length < numberOfPageButtonsToShow; btnIndx--) {
            pageButtonsToShow.push(btnIndx);
        }

        pageButtonsToShow.sort();
        return pageButtonsToShow;
    }

    public getTotalPages = (totalItemsCount : number, itemsPerPageCount : number) : number => {
        let totalPages = Math.ceil(totalItemsCount / itemsPerPageCount) - 1;
        totalPages = totalPages < 0 ? 0 : totalPages;
        return totalPages;
    }
}

export default ReactPaginatorService;