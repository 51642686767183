import { Spinner, Toaster } from './components'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom'
import Customer from './customer/customer'
import Campaign from './Campaign/campaign'

import Breadcrumbs from './page-header/breadcrumbs/Breadcrumbs'
import { ButtonBarProvider } from './page-header/button-toolbar/ButtonBarProvider'
import { useTitle } from './page-header/title/TitleContext'

import { NetworkStatusMessage } from './shared/components/network-status'
import Sidebar from './shared/components/Sidebar/Sidebar'
import { useState, useEffect } from 'react'

import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "./authconfig";
import { useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { fetchUserDetails } from './shared/components/userContext-slice'
import CustomerMainPortal from './customer/customer-main-portal/CustomerMainPortal'
import React, { useMemo, useContext } from 'react';
import { AbilityContext } from './shared/abilities/AbilityContext';
import { ability } from './shared/abilities/ability'
import { Can } from '@casl/react';
import { ConfirmModal } from './shared/components/confirmModal'
import SliderProvider from './shared/components/Slider/sliderProvider'
import AdminMenuBar from './Admin/AdminMenu/AdminMenuBar'
import DocuMateHeader from './DocuMate/DocuMateHeader'
import MainDashboard from './dashboard/main-dashboard'
import * as constant from './shared/util/constant';


const CS2 = () => {
  const { title } = useTitle()

  const [idTokenClaims, setIdTokenClaims] = useState(null);
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [showFavicon, setShowFavicon] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const name = accounts[0] && accounts[0].name;
  const [isSideBarCollapsed, setIsSideharCollapsed] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const path = history.location.pathname;
  const isAdminPath = path.includes('admin');
  

  const userDetails = useSelector(state => state.userContextSlice);
  const isLoading = useSelector(state => state.userContextSlice.status === "loading" || state.userContextSlice.status === "idle");

  

  useEffect(() => {
    if(isAuthenticated) {
      dispatch(fetchUserDetails());
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if(!isLoading && userDetails.userContext.objectId === '') {
      handleLogout();
    }
  }, [isLoading]);
  
  if(userDetails.userPrivileges.length > 0)
    {
      ability.update(userDetails.userPrivileges);
      }
  const request = {
    ...loginRequest,
    account: accounts[0]
  }
  const ab_subject = "CS2 Main Menu"
  const isUserPermitToViewAdmin = ability.can('view', ab_subject ,constant.SidebarPrivileges.Admin_Management)
  const isAdminMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Admin_Management)

  const isUserPermitToViewCampaign = ability.can('view', ab_subject ,constant.SidebarPrivileges.Campaign_Management)
  const isCampaignMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Campaign_Management)

  const isUserPermitToViewCustomer = ability.can('view', ab_subject ,constant.SidebarPrivileges.Customer_Management)
  const isCustomerMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Customer_Management)

  const canUserCreateCustomer = ability.can('create',"Customer",constant.ScreenName.CUSTOMER);
  // useEffect(() => {
  //   if (accounts.length > 0) {
  //     instance.acquireTokenSilent({
  //       account: accounts[0],
  //       scopes: [process.env.REACT_APP_AZURE_DEFAULT_SCOPE] // replace with your API scopes
  //     }).then((response) => {
  //       localStorage.setItem("accessToken",response.accessToken);
  //         localStorage.setItem("idtoken",response.idToken);
  //         localStorage.setItem("userRole",(response.idTokenClaims["roles"]));
  //     });
  //   }
  // }, [accounts, instance]);
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    }
    if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
      setShowFavicon(true);
    }
    else
    {
      setShowFavicon(false);
      loadTokens();
    }
    
  })

  const OurFallbackComponent = ({ error, resetErrorBoundary }) => {
    console.log("An error occurred:");
    console.log(error.message);
    return (
      <div>
        <h1>An error occurred: {error.message}</h1>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  };

  function CallBack(childData: boolean) {
    setIsSideharCollapsed(childData)
  }

  function loadTokens()
  {
    const accToken = localStorage.getItem("accessToken");
    instance.acquireTokenSilent(request).then((response) => {
      setAccessToken(response.accessToken);
      setIdToken(response.idToken);
      setIdTokenClaims(response.idTokenClaims["roles"]);
      localStorage.setItem("accessToken",response.accessToken);
      localStorage.setItem("idtoken",response.idToken);
      localStorage.setItem("userRole",(response.idTokenClaims["roles"]));
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        setAccessToken(response.accessToken);
      });
    });
  }

  function ProfileContent() {
    useEffect(() => {
      function RequestAccessToken() {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
          setAccessToken(response.accessToken);
          setIdToken(response.idToken);
          setIdTokenClaims(response.idTokenClaims["roles"]);
          localStorage.setItem("accessToken",response.accessToken);
          localStorage.setItem("idtoken",response.idToken);
          localStorage.setItem("userRole",(response.idTokenClaims["roles"]));

        }).catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setAccessToken(response.accessToken);
          });
        });
      }
      if (accessToken == null) {
        RequestAccessToken();
      }
    }, [])


   
  };

  const getUserName = user => {
    return `${user.firstName} ${user.lastName}`;
  };

  function handleLogout() {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      // account: accounts[0],
      // postLogoutRedirectUri: ''
    };
    instance.logoutRedirect(logoutRequest)
            .catch(ex => {
              console.error(ex);
            });
    // const account = accounts[0];
    // instance.logout({account});
  }

  const onNaviagate = (path : string) => {
    history.push(path);
  }

  return (
    <>
      {isLoading && <div id='LoadingDiv' className={'hidden'}><Spinner color="blue" loading={true} size={20} type="FadeLoader" /></div>}
      {!isLoading &&
    <div>
    {showFavicon && 
    <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
     {/* <img src="/CallRecording.png" alt="image" style={{ width: 100, height: 100 }}/> */} 
     {/* Check on this one */}
     </div>
    }
    <AuthenticatedTemplate>
    <div>
      <NetworkStatusMessage />
      <AbilityContext.Provider value={ability}>
      <div className="container-fluid main-bg-white">
        {/* <div className="col-md-2 col-lg-2"> */}
        
        <Sidebar handleCallBack={CallBack} userName={getUserName(userDetails.userContext)} onLogout={handleLogout}/>
        
        {/* </div> */}

        <ButtonBarProvider>
          <div className={`${isAdminPath ? '' : 'col-lg-12'}`}>
            <main
              role="main"
            //  className={`${isSideBarCollapsed ? 'col-md-12 col-lg-12' : 'col-md-12 col-lg-2'} ml-sm-auto px-4`}
            >
              <Breadcrumbs />
              <div>
                <Switch>
                  <Route path="/"
                    exact={true}
                    render={() => <MainDashboard isSideBarCollapsed={isSideBarCollapsed} navigateTo={onNaviagate}></MainDashboard>}
                  />

                {isUserPermitToViewCustomer && isCustomerMenuEnableForUser &&<Route path="/customer/"
                    render={() => <Customer isSideBarCollapsed={isSideBarCollapsed} /> }
                  />}

                 {isUserPermitToViewCampaign && isCampaignMenuEnableForUser && <Route path="/Campaign/"
                    render={() =><Campaign isSideBarCollapsed={isSideBarCollapsed} />}
                  />}
                  
                {isUserPermitToViewAdmin && isAdminMenuEnableForUser &&<Route
                  path="/admin" 
                  render={() => <AdminMenuBar isSideBarCollapsed={isSideBarCollapsed} />}
                  />}
                  
                {isUserPermitToViewCustomer && isCustomerMenuEnableForUser &&<Route path="/customer-view/:view/:id" 
                   render={(props) => <CustomerMainPortal {...props}/>}
                  />} 
                  
                {isUserPermitToViewCustomer && isCustomerMenuEnableForUser && canUserCreateCustomer && <Route path="/customer-new" 
                  render={(props) => <CustomerMainPortal {...props}/>}
                  />}

                {isUserPermitToViewCustomer && isCustomerMenuEnableForUser &&<Route path="/customer-view/" component={CustomerMainPortal}/>}                
                  <Route path="/documate-scan"
                    render={() => <DocuMateHeader isSideBarCollapsed={isSideBarCollapsed} />}
                  />
                  <Route path="/documate-indexing"
                    render={() => <DocuMateHeader isSideBarCollapsed={isSideBarCollapsed} />}
                  />
                  <Route path="/documate-indexing/processing"
                    render={() => <DocuMateHeader isSideBarCollapsed={isSideBarCollapsed} />}
                  />
                  <Route path="/documate-search"
                    render={() => <DocuMateHeader isSideBarCollapsed={isSideBarCollapsed} />}
                  />
                  <Route path="/documate-refi"
                    render={() => <DocuMateHeader isSideBarCollapsed={isSideBarCollapsed} />}
                  />
                <Redirect to={"/"} >{MainDashboard}</Redirect>
                </Switch>
              </div>
              <Toaster autoClose={5000} hideProgressBar draggable />
            </main>
          </div>
        </ButtonBarProvider>
       
      </div>
      </AbilityContext.Provider>
    </div>

    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      {"Please wait while we redirect you to the login page for secure access"}
    </UnauthenticatedTemplate>
  </div>
      }

      
    </>
  );
}

export default CS2
