import { approveRejectCampaign, fetchCampaignApprovalDetails } from '../services/CampaignAPI'
import CampaignApprovalUI from '../model/CampaignApprovalUI';
import moment from 'moment';
import CampaignApprovalAPI from '../model/CampaignApprovalAPI';
import CampaignFilterAPIResponse from '../model/CampaignFilterAPIResponse';
import Logger from '../../shared/services/Logger'
import ApproveRejectCampaign from '../model/ApproveRejectCampaign';


export default class CampaignApprovalDetailsUtils {

  private static async  campaignApprovalDetailsAPIResponseToUI(campaignApprovalDetailsAPIResponseData: CampaignApprovalAPI): Promise<CampaignApprovalUI> {
    const sendOnDateFromApi = campaignApprovalDetailsAPIResponseData._sentDate ? new Date(moment(campaignApprovalDetailsAPIResponseData._sentDate).format('YYYY/MM/DD')) : null;
    const campaignFiltersUI = campaignApprovalDetailsAPIResponseData._campaignFilterUse ? campaignApprovalDetailsAPIResponseData._campaignFilterUse.map(
      (filter: CampaignFilterAPIResponse) => ({
        filterId: filter._filterId,
        filterLabel: filter._filterId,// to ask krishna to give this, as i have to show in UI
        filterConditionSelectedId: filter._logic,// based on what user selects from condition dropdown from UI 
        filterValue: JSON.parse(filter._campaignFilterData._dataItem).map((value) =>
          typeof value === 'string' && /^\d{4}-\d{1,2}-\d{1,2}$/.test(value)
            ?
            new Date(moment(value).format('YYYY/MM/DD'))
            :
            value
        ),
        // extra for api
        id: filter._id,
        campaignFilterDataModelId: filter._campaignFilterData._id,
        campaignFilterDataModelCampaignFilterUseId: filter._campaignFilterData._campaignFilterUseId
  
  
      })) : [];
  
  
    const campaignApprovalDetailsUIResponse: CampaignApprovalUI = {
      id: campaignApprovalDetailsAPIResponseData._id,
      campaignName: campaignApprovalDetailsAPIResponseData._campaignName,
      description: campaignApprovalDetailsAPIResponseData._description,
      templateType: campaignApprovalDetailsAPIResponseData._templateType,
      templateCategory: campaignApprovalDetailsAPIResponseData._templateCategory,
      templateId: campaignApprovalDetailsAPIResponseData._templateId,
      templateName:campaignApprovalDetailsAPIResponseData._templateName,
      queryId: campaignApprovalDetailsAPIResponseData._queryId,
      queryName:campaignApprovalDetailsAPIResponseData._queryName,
      isCampaignInactive: campaignApprovalDetailsAPIResponseData._campaignInactive,
      changedDate: campaignApprovalDetailsAPIResponseData._changedDate ? moment( campaignApprovalDetailsAPIResponseData._changedDate).format("MM/DD/YYYY") : '',
      changedBy: campaignApprovalDetailsAPIResponseData._changedBy,
      sendOn: sendOnDateFromApi,
      campaignFilters: campaignFiltersUI,
      status:campaignApprovalDetailsAPIResponseData._status,
      approvalDate: campaignApprovalDetailsAPIResponseData._approvedDate ? moment( campaignApprovalDetailsAPIResponseData._approvedDate).format("MM/DD/YYYY"):'',
      approvedBy:campaignApprovalDetailsAPIResponseData._approvedBy,
      results:campaignApprovalDetailsAPIResponseData._resultCount,
      fromEmailName:campaignApprovalDetailsAPIResponseData._fromEmailName
    };
    return campaignApprovalDetailsUIResponse
  }
  

static getCampaignApprovalDetails = async (campaignId: string): Promise<CampaignApprovalUI | null> => {
  if (!campaignId) {
    return null;
  }

  try {
    // Make the API request
    const campaignApprovalDetailsResponse = await fetchCampaignApprovalDetails(campaignId);
    // var campaignDetailsUIResponse:CampaignDetailsUI
    if (campaignApprovalDetailsResponse.status === 400) {
      throw new Error(campaignApprovalDetailsResponse.data.error); // Throw with specific error message
    }

    else {
      const campaignApprovalDetailsAPIResponseData: CampaignApprovalAPI = campaignApprovalDetailsResponse.data
      const campaignDetailsUIResponse: CampaignApprovalUI = await CampaignApprovalDetailsUtils.campaignApprovalDetailsAPIResponseToUI(campaignApprovalDetailsAPIResponseData)
      return campaignDetailsUIResponse
    }

  } catch (error) {
    // Handle errors here
    Logger.logError(error.toString());
    // return error
    throw error; // Rethrow the error for the caller to handle
  }
};

static approveRejectCampaignUtils = async (approveRejectDetails: ApproveRejectCampaign): Promise<boolean> => {
  try {
    // Make the API request
    const rejectApproveCampaignResponse = await approveRejectCampaign(approveRejectDetails);
    // var campaignDetailsUIResponse:CampaignDetailsUI
    if (rejectApproveCampaignResponse.status === 400) {
      throw new Error(rejectApproveCampaignResponse.data.error); // Throw with specific error message
    }

    else {
      return rejectApproveCampaignResponse.data
    }

  } catch (error) {
    // Handle errors here
    Logger.logError(error.toString());
    // return error
    throw error; // Rethrow the error for the caller to handle
  }
}
}