import {Select, Button, Label,Row,Column, Spinner} from '../../components'
//import Grid from '../../components/components/Grid/Grid'
//import {GetAllCampaignList} from '../hooks/GetCampaignList'
import Campaign from '../model/CampaignList'
import CampaignGrid from '../shared/components/grid/CampaignGrid'
import {useState, useEffect} from 'react'
import * as label from '../../shared/util/label'
import * as constant from '../../shared/util/constant'
import { getAllTemplates } from '../utils/CampaignListUtils'

interface Props {
    filterRequest: Campaign
  }

const CampaignList = (props: Props) => {
    //const { data, isLoading } = GetAllCampaignList()
    const [data, setData] = useState([]);
    const [showFilters,setShowFilters] = useState(true);
    const ab_subject = constant.ScreenName.CAMPAIGN;
    // const handleSelectedRowsChange = (selectedRows: number[]) => {
    //     //const idsSelected = selectedRows.map(x => data[x].campaignId);
    //   }
      // if (data === undefined || isLoading) {
      //   return <Spinner type="DotLoader" loading />
      // }


      async function fetchTemplateList() {
        //setLoading(true)
          await getAllTemplates()
          .then(response => {
            const sortedData = sortData(response)
            setData(sortedData)
          })
          .catch(error => console.error(error))
          //.finally(()=> setLoading(false));
      }

      const sortData = (data) => {
        const statusOrder = [constant.Waiting_Approval, constant.APPROVED, constant.SAVED, constant.REJECTED, constant.SENT, constant.INACTIVE]
          return data.sort((a,b) => statusOrder.indexOf(b.status) - statusOrder.indexOf(a.status));
      }
    
      useEffect(() => {
        //alert('called')
        fetchTemplateList();
      },[]);   
    return(
      <div>
        <CampaignGrid 
        // handleSelectedRowsChange={handleSelectedRowsChange} 
        data={data!=undefined?data:""} 
        showFilters={showFilters}
        gridHeader={label.CAMPAIGN_LIST}
        hiddenColumns={['campaignId', 'selection']}
        />
      </div>
   )
    }
export default CampaignList