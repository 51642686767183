import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import Customer from '../model/Customer'



interface CustomerState{
    customer:Customer
}

const initialState : CustomerState = {
    customer:{}
}


const customerSlice = createSlice({
    name : "customers",
    initialState,
    reducers : {
        updateCustomers(state, action: PayloadAction<Customer>){
           const customers= action.payload;
            state.customer = customers;
          
        }
    }
})

export const { updateCustomers } = customerSlice.actions;
export default customerSlice.reducer;