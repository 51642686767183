import moment from "moment";
import { getCustomerHistory } from "../services/CustomerAPI";

class CustomerHistoryService
{
    getHistory = async (customerId : string) => {
        var history =  await getCustomerHistory(customerId)
        return history.map((item) => {
            return {
              ...item,
              changedDate: moment(item.changedDate).format("MM/DD/YYYY, h:mm:ss a")+ " ET",
            };
          });
    }
}
export default CustomerHistoryService;