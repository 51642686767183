import { Button, Column, Row } from '../../../components'
import { useContext, useEffect, useState } from 'react';
import { RolePrivilegeSetupLabels } from '../../../shared/util/label'
import RolesSideBar from '../RolesSideBar/RolesSideBar';
import { FallbackComponent } from '../../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'
import ScreenPrivilege from '../ScreenPrivilegeSetup/ScreenPrivilege';
import './RolePrivilegeMain.scss';
import { useHistory, useParams } from 'react-router-dom';
import AppContext from '../../../shared/components/AppContext';
import * as constant from '../../../shared/util/constant'
import { useConfirmationModalContext } from '../../../shared/components/ModalConfirmationContext';

const RolePrivilegeMain = () => {

    const { roleId } = useParams<{roleId : string | undefined}>();

    const [selectedRoleId, setSelectedRoleId] = useState<string>(null);

    const [showRoleDetailsOnLoad, setShowRoleDetailsOnLoad] = useState<boolean>(false);

    const modalContext = useConfirmationModalContext();

    const privielgeContext = useContext(AppContext);

    const [reloadSidebar, setReloadSidebar] = useState(false)

    const history = useHistory();

    const handleNewRoleClick = async () => {
        if (!privielgeContext.formSavedStatus) {
            const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if (result) {
                setSelectedRoleId(null);
                 setShowRoleDetailsOnLoad(true);
                 privielgeContext.formSavedStatus = true
                 history.push(`/admin/roleprivilege`);
            }
        }
        else {
            setSelectedRoleId(null);
            setShowRoleDetailsOnLoad(true);
            history.push(`/admin/roleprivilege`);
        }
    };

    const handleCancelClick = async () => {
        setSelectedRoleId(null);
        setShowRoleDetailsOnLoad(false);
        history.push(`/admin/roleprivilege`);
    };

    const handleSave = async (newRoleId) => {
        history.push(`/admin/roleprivilege/${newRoleId}`);
        setReloadSidebar(!reloadSidebar)

    };
   

    useEffect(() => {
        if (roleId) 
        {
            setSelectedRoleId(roleId);
            setShowRoleDetailsOnLoad(true);            
        }
    }, [roleId]);

    return (
        <><Row className="bg-light role-privilege-main">
            <Column lg={12} md={12}>
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                    <Row className='new-role-border'>
                        <Column lg={10}>
                        </Column>
                        <Column lg={2}>

                            <div>
                                <Button color='primary' outlined={true} onClick={handleNewRoleClick}>{RolePrivilegeSetupLabels.NEW_ROLE} </Button>
                            </div>
                        </Column>
                    </Row>
                    <br></br>
                    <Row>
                        <Column lg={12} className='role-details'>
                            <Row>
                                <Column lg={3} className='roles-sidebar'>
                                    <ErrorBoundary FallbackComponent={FallbackComponent}>
                                        <div className={`container active`}>
                                            <RolesSideBar reload = {reloadSidebar}/>
                                        </div>
                                    </ErrorBoundary>
                                </Column>
                                {showRoleDetailsOnLoad  &&
                                    <Column lg={9} className={'screen-privilege'}>
                                        <ErrorBoundary FallbackComponent={FallbackComponent}>
                                            <div>
                                                <ScreenPrivilege roleId={selectedRoleId} onCancel={handleCancelClick} onSave = {handleSave}></ScreenPrivilege>
                                            </div>
                                        </ErrorBoundary>
                                    </Column>
                                }
                            </Row>
                        </Column>
                    </Row>
                </ErrorBoundary>
            </Column>
        </Row>
        </>
    )
}

export default RolePrivilegeMain;