import {AxiosResponse} from 'axios'
import { client } from "../../shared/services/client"; 
import RoleDetailsModel from '../model/RoleDetailsModel'
import FeatureDropDown from '../model/DropdownModel/FeatureDropDown'
import FeatureElementDropdown from '../model/DropdownModel/FeatureElementDropdown'
import RoleDetailsAPIModel from '../model/RoleDetailsAPIModel';
import * as tableConstant from '../../shared/util/tableConstant';

const securityBaseKey  = "security"

export default class RolePrivilegeSetupAPI {
    getRoles = async (): Promise<RoleDetailsModel[]> => {
        client.setBaseURLForService(securityBaseKey)
          const res: AxiosResponse<RoleDetailsModel[]> =  await client.get<RoleDetailsModel[]>("api/RolePrivilege/GetRoles?appId=" + tableConstant.ROLE_APP_ID);
          return res.data;       
        };

    getfeatures = async (): Promise<FeatureDropDown[]> => {
      client.setBaseURLForService(securityBaseKey)
      const res: AxiosResponse<FeatureDropDown[]> =  await client.get<FeatureDropDown[]>("api/RolePrivilege/GetFeatures?appId=" + tableConstant.ROLE_APP_ID);
      return res.data;    
    };

    getFeatureElements = async (featureId:string): Promise<FeatureElementDropdown[]> => {
      client.setBaseURLForService(securityBaseKey)
      const res: AxiosResponse<FeatureElementDropdown[]> =  await client.get<FeatureElementDropdown[]>("api/RolePrivilege/GetFeatureElements?featureId=" + featureId);
      return res.data;    
    };

    getRoleDetails = async (roleId: string) : Promise<RoleDetailsAPIModel> => {
      client.setBaseURLForService(securityBaseKey)
      const res: AxiosResponse<RoleDetailsAPIModel> =  await client.get<RoleDetailsAPIModel>("api/RolePrivilege/GetPrivilesByAppRole?appRoleId=" + roleId);
      return res.data;  
    }

   async saveRoleDetails(roleDetails:RoleDetailsAPIModel) : Promise<RoleDetailsAPIModel> {
      client.setBaseURLForService(securityBaseKey)
      const res: AxiosResponse<any> = (await client.post("api/RolePrivilege",roleDetails));
      return res.data 
    }

    async updateRoleDetails(roleDetails:RoleDetailsAPIModel) : Promise<RoleDetailsAPIModel> {
      client.setBaseURLForService(securityBaseKey)
      const res: AxiosResponse<any> = (await client.put("api/RolePrivilege",roleDetails));
      return res.data 
    }
}
