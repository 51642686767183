import React from 'react';
import './main-dashboard.scss';
import TaskDashboard from './tasks-dashboard/taskdashboard';

export interface IMainDashboard {
    navigateTo : (path : string) => void
}

const MainDashboard : React.FunctionComponent<IMainDashboard> = (props) => {

    return (
        <div className='dashboard-main-container'>
            {/* Place holder for now */}
            <TaskDashboard navigateTo={props.navigateTo}></TaskDashboard>
        </div>
    )
}

export default MainDashboard