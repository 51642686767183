import * as label from '../../shared/util/label'
import * as constant from '../../shared/util/constant'

export const FallbackComponent = ({ error, resetErrorBoundary }) => {
    console.log("An error occurred:");
    console.log(error.message);
    return (
      <div>
        <h1>{constant.ERROR_OCCURED}{error.message}</h1>
        <button onClick={resetErrorBoundary}>{label.TRY_AGAIN}</button>
      </div>
    );
  };

