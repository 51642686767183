import React, { useEffect, useState } from 'react';
import DocViewer from '../shared/components/DocViewer';
import { Button, Column, Icon, Label, Panel, Radio, Row, TextInput } from '../../components';
import './DocuMateIndexing.scss';
import * as documateLabel from '../shared/util/docuMateLabel';
import { toast } from 'react-toastify';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import DataTable from '../../components/components/Table/DataTable';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

interface DocuMateIndexingProps {
    id?: string;
    scanId?: string;
}

const sampleData = [
    {
        cs2RefNum: '564987412',
        advRefNum: '445488896',
        status: 'Active',
        fullName: 'Howard Zeimann'
    }
]

const samplePolicies = [
    {
        policyId: '3f2504e0-4f89-11d3-9a0c-0305e82c3301',
        policyType: 'Home',
        policyNumber: 'H123456',
        terms: [
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3302', expirationDate: '2023-12-31 12:00:00 AM', expirationTime: '' },
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3303', expirationDate: '2024-12-31 12:00:00 AM', expirationTime: '' }
        ]
    },
    {
        policyId: '3f2504e0-4f89-11d3-9a0c-0305e82c3304',
        policyType: 'Auto',
        policyNumber: 'A123456',
        terms: [
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3305', expirationDate: '2023-06-30 12:00:00 AM', expirationTime: '12:00:00 AM' },
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3306', expirationDate: '2024-06-30 12:00:00 AM', expirationTime: '12:00:00 AM' }
        ]
    },
    {
        policyId: '3f2504e0-4f89-11d3-9a0c-0305e82c3307',
        policyType: 'Umbrella',
        policyNumber: 'U123456',
        terms: [
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3308', expirationDate: '2023-09-30 12:00:00 AM', expirationTime: '12:00:00 AM' },
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3309', expirationDate: '2024-09-30 12:00:00 AM', expirationTime: '12:00:00 AM' },
            { termId: '3f2504e0-4f89-11d3-9a0c-0305e82c3310', expirationDate: '2025-09-30 12:00:00 AM', expirationTime: '12:00:00 AM' }
        ]
    }
];

const DocuMateIndexing: React.FC<DocuMateIndexingProps> = (props) => {

    const [showCustomerGrid, setShowCustomerGrid] = useState(false);
    const [showPolicyDetails, setShowPolicyDetails] = useState(false);
    const [selectedRefNum, setSelectedRefNum] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [isSearchValid, setIsSearchValid] = useState(true);
    const [customerRecord, setCustomerRecord] = useState([]);
    const [custPolicies, setCustPolicies] = useState(samplePolicies);
    const [isPolicyOpen, setIsPolicyOpen] = useState(false);
    const [expandedPolicy, setExpandedPolicy] = useState(null);
    const [selectedTerm, setSelectedTerm] = useState(null);

    const location = useLocation();
    const state = location.state as { scanId: string, id: string, docStatus: string };
    //const campaignId = state?.entityId;
    const urlParam = new URLSearchParams(location.search)
    const [scanId,setScanId]=useState(state?.scanId);
    const [id,setId]=useState(state?.id || urlParam.get('id'));
    const docStatus = state?.docStatus;

    function handleDocViewerClose(): void {
        throw new Error('Function not implemented.');
    }

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchValue(event.target.value);
        setIsSearchValid(true);
    }

    const validateSearch = (): boolean => {
        if(selectedRefNum === '' || selectedRefNum === null || selectedRefNum === undefined) {
            toast.warn('Please select a reference number');
            return false;
        }
        if(searchValue === '' || searchValue === null || searchValue === undefined) {
            setIsSearchValid(false);
            return false;
        }
        return true;
    }

    const handleSearchButtonClick = (): void => {
        if(validateSearch()) {
            setCustomerRecord(sampleData);
            setShowPolicyDetails(true);
            setShowCustomerGrid(true);
        }
    }

    const onRadioButtonChange = (event) => {
        setSelectedRefNum(event.target.id);
    }

    const onEnterClick =(event) => {
        console.log(event.target.id);
        if(event.target.id!=="btnStandardSearchClear" && event.key === 'Enter'){
            handleSearchButtonClick();
        }
      }
      
      useEffect(() => {
        window.addEventListener("keydown", onEnterClick);
          return () => {
              window.removeEventListener("keydown", onEnterClick);
          };
      
        },[onEnterClick]);

        const handleTogglePolicy = (policyId) => {
            setExpandedPolicy(expandedPolicy === policyId ? null : policyId);
        }

        const handleTermSelect = (termId) => {
            setSelectedTerm(termId);
            console.log(termId);
        }

        const getPolicyIcon = (policyType) => {
            switch(policyType) {
                case 'Home':
                    return 'home';
                case 'Auto':
                    return 'auto';
                case 'Umbrella':
                    return 'dollar';
                default:
                    return 'home';
            }
        }

        const getStatus = () => {
            switch(docStatus) {
            case 'working':
                return 'userPen';
            default:
                return 'lock';
            }
        }

        const getStatusIcon = (
            <span>
            {docStatus !== '' && <Icon icon={getStatus()} outline={false}></Icon>}
            </span>
        );

        const renderPoliciesPanel = (policies) => {
            return (
                <div>
                {policies.map(policy => (
                    <div key={policy.policyId}>
                        <div 
                        className={`policiesList ${expandedPolicy === policy.policyId ? 'clicked' : ''}`} 
                        onClick={() => handleTogglePolicy(policy.policyId)}>
                            {expandedPolicy === policy.policyId ? <FaChevronDown /> : <FaChevronRight />}
                                <span style={{paddingLeft: '5px'}}><Icon icon={getPolicyIcon(policy.policyType)} className='policyIcons'></Icon>{policy.policyType} Policy #{policy.policyNumber}</span>
                        </div>
                        {expandedPolicy === policy.policyId && policy.terms.map(term => (
                            <div key={term.termId} onClick={() => handleTermSelect(term.termId)}>
                                <span className={`termsList ${selectedTerm === term.termId ? 'selectedTerm' : ''}`}><Icon icon='calendar2' className='policyIcons' outline='false'></Icon>Term Ending On {moment(term.expirationDate.toString()).format("MM/DD/YYYY")} at  {moment(term.expirationDate.toString()).format("h:mm:ss A")}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            );
    }

    return (
        <div className='parent-container'>
            <div className='indexingPanel'>
                <Row>
                    <Column lg={6} md={6}>
                        <Panel title={scanId} panelHeaderItems={getStatusIcon}>
                            <Row style={{paddingLeft: '15px', paddingBottom: '10px'}}>
                                <Radio
                                label={documateLabel.SEARCH_RADIO_LABELS.advRefNum}
                                id='advRefNum'
                                value='advRefNum'
                                name='refno'
                                checked={selectedRefNum === 'advRefNum'}
                                onChange={onRadioButtonChange}
                                className='refNumberRadio inlineLable'>
                                </Radio>
                                <Radio
                                label={documateLabel.SEARCH_RADIO_LABELS.cs2RefNum}
                                id='cs2RefNum'
                                value='cs2RefNum'
                                name='refno'
                                checked={selectedRefNum === 'cs2RefNum'}
                                onChange={onRadioButtonChange}
                                className='refNumberRadio inlineLable'>
                                </Radio>
                                <Radio
                                label={documateLabel.SEARCH_RADIO_LABELS.polRefNum}
                                id='polRefNum'
                                value='polRefNum'
                                name='refno'
                                checked={selectedRefNum === 'polRefNum'}
                                onChange={onRadioButtonChange}
                                className='refNumberRadio inlineLable'>
                                </Radio>
                            </Row>
                            <Row style={{marginTop: '10px'}}>
                                <Column lg={9} md={9}>
                                <TextInput 
                                type='search'
                                size='sm'
                                onChange={handleSearchInputChange}
                                placeholder={'Reference Number'}
                                isInvalid={!isSearchValid}></TextInput>
                                </Column>
                                <Column lg={3} md={3}>
                                <Button
                                    className='btn btn-primary bt-main-action'
                                    size='small'
                                    key="filter"
                                    onClick={handleSearchButtonClick}
                                    tabIndex={21}
                                    id="searchIndexing"
                                    disabled={false}
                                    outlined={true}>
                                    {"SEARCH"}
                                </Button>
                                </Column>
                            </Row>
                            <Row>
                                <Column lg={12} md={12} sm={12}>
                                    {showCustomerGrid && <DataTable
                                    data={customerRecord}
                                    columns={[
                                        {
                                            header: documateLabel.INDEXING_GRID_LABELS.cs2RefNum,
                                            field: 'cs2RefNum',
                                            width: '5em',
                                            maxWidth: '7em'
                                        },
                                        {
                                            header: documateLabel.INDEXING_GRID_LABELS.advRefNum,
                                            field: 'advRefNum',
                                            width: '5em',
                                            maxWidth: '7em'
                                        },
                                        {
                                            header: documateLabel.INDEXING_GRID_LABELS.status,
                                            field: 'status',
                                            width: '5em',
                                            maxWidth: '7em',
                                            cellTdClassName: customerRecord[0].status === 'Active' ? 'Active' : 'Inactive'
                                        },
                                        {
                                            header: documateLabel.INDEXING_GRID_LABELS.fullName,
                                            field: 'fullName',
                                            width: '10em',
                                            maxWidth: '12em'
                                        }
                                    ]}
                                    tableClassName='gridCell'
                                    theadClassName='gridHeader'
                                    >
                                    </DataTable>}
                                </Column>
                            </Row>
                            <Row>
                                <Label text='INDEXED INFORMATION' title='IndexedInfo' className='indexingLabel'></Label>
                            </Row>
                            <Row>
                                <Column lg={6} md={6}>
                                <strong style={{color: '#6E6E6E', fontWeight: '400'}}>CS2 #</strong>
                                <div>{sampleData[0].cs2RefNum}</div>
                                </Column>
                                <Column lg={6} md={6}>
                                <strong style={{color: '#6E6E6E', fontWeight: '400'}}>A+ #</strong>
                                <div>{sampleData[0].advRefNum}</div>
                                </Column>
                            </Row>
                        </Panel>
                            {/* <Panel title='IMAGING TASK'></Panel>
                            <Panel title='LEGACY FIELDS'></Panel> */}
                    </Column>
                    <Column lg={6} md={6}>
                        {showPolicyDetails && <Panel title='POLICY AND TERM'>
                            <div className=''>{renderPoliciesPanel(custPolicies)}</div>
                        </Panel>}
                    </Column>
                </Row>
            </div>
            {/* <div className='doc-view-container'>
                <DocViewer docId='' handleDocViewerClose={handleDocViewerClose}/>
            </div> */}
        </div>
    );
};

export default DocuMateIndexing;