import { Button } from '../../components'
import React from 'react'
import * as label from '../../shared/util/label'

interface Props {
  isDisplay?:boolean
}

const ScrollTop = (props: Props) => {
  const {
    isDisplay
  } = props
  return (
      <>
      <Button
            className='topButton btn-sm'
            key="BackToTopButton"
           // icon='up-arrow'
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              display: isDisplay ? 'block' : 'none',
              backgroundColor: 'cyan-blue',
              border: 'none'
            }}
            onClick={() => {
              window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
            }}
          >{label.SCROLL_TOP}
          </Button>
      </>
    
  )
}

ScrollTop.defaultProps = {
  isDisplay:false
}


export default ScrollTop
