import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import './SliderContact.scss';
import useSlider from "../../../shared/components/Slider/useSlider";
import { Button, Checkbox, Column, Row } from "../../../components";
import * as lables from "../../../shared/util/label";
import MatTextInput from "../../../shared/components/input/mat-text-input/MatTextInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/store";
import { useConfirmationModalContext } from "../../../shared/components/ModalConfirmationContext";
import CustomerDetailService from "../../customer-details/CustomerDetailService";
import { updateCustomers } from "../../customer-slice/Customer-slice";
import * as constant from "../../../shared/util/constant";
import Logger from "../../../shared/services/Logger";
import FormattedInputService from "../../../shared/components/input/formatted-input/FormattedInputService";
import { cleareTabsInputs, SliderDataAction, updateSliderData } from "../CustomerSliderContext";
import CustomerSliderContainerService from "../CustomerSliderContainerService";
import { AbilityContext } from "../../../shared/abilities/AbilityContext";

export interface ISliderContact {
    customerId? : string
}

const SliderContact : React.FunctionComponent<ISliderContact> = (props) => {
    const customerDetailService = new CustomerDetailService();
    const formattedInputService = new FormattedInputService();
    const customerSliderContainerService = new CustomerSliderContainerService();
    const dispatch = useDispatch();
    const slider = useSlider();
    
    const customerInfo = useSelector((state: RootState) => state?.customerSlice);
    const customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);

    const customerObjInStore = customerInfo.customer;
    const modalContext = useConfirmationModalContext();

    const customerDetail = customerSliderState.contactPage.customer;
    const formValidation = customerSliderState.contactPage.formValidation;

    const ability = useContext(AbilityContext);
    const canUserEditCustomer = ability.can('update',"Customer",constant.ScreenName.CUSTOMER);

    useEffect(() => {
        initializeComponent();
    },[customerObjInStore]);

    const initializeComponent = async () => {

        // set contact page data only if customer info in main store chagnes.
        if (!customerDetail || !customerDetail.id) {

            if (customerObjInStore?.id) {
                dispatch(updateSliderData({ type : SliderDataAction.ContactUpdate, data : {...customerObjInStore}}));
            }
            else {
                let cst = await customerDetailService.fetchCustomerById(props.customerId);
                if (cst) {
                    dispatch(updateCustomers(cst));
                }
            }
        
            dispatch(updateSliderData({ type : SliderDataAction.CustomerFormValidationUpdate, data : {}}));
        }
    };

    const toggleEntityField = (propName: string) => {

         let updatedCustomerData = {
          ...customerDetail,
          [propName]: !customerDetail[propName],
        };
        dispatch(updateSliderData({ type : SliderDataAction.ContactUpdate, data : updatedCustomerData}));

    }

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        let updatedCustomerData = {
            ...customerDetail,
            [propName]: value,
          };

        dispatch(updateSliderData({ type : SliderDataAction.ContactUpdate, data : updatedCustomerData}));
    }

    const onCancelClick = async () => {
        let isAnyChange = customerSliderContainerService.isAnyUnsavedChangeInContacts(customerSliderState, customerObjInStore);

        if (isAnyChange) {
            const result = await modalContext.showConfirmation(constant.SliderActivityConstant.ARE_YOU_SURE_TO_EXIT);
            if(!result){
                return;
            }
        }
        
        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }

    const onSaveClick = async () => {
        try {

            let isAnyChange = customerSliderContainerService.isAnyUnsavedChangeInContacts(customerSliderState, customerObjInStore);

            if (!isAnyChange) {
                toast.warn(constant.SliderContactConstants.NO_CHANGES_TO_SAVE, {
                    position: toast.POSITION.TOP_RIGHT
                });

                return;
            }

            let validationError = customerDetailService.validateCustomer(customerDetail);
            dispatch(updateSliderData({ type : SliderDataAction.CustomerFormValidationUpdate, data : validationError}));
            if (Object.keys(validationError).length > 0) {
                toast.warn(constant.CUSTOMER_FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            let updatedCustomer = await customerDetailService.saveCustomer(customerDetail);
            if (updatedCustomer) {
                toast.success(constant.CUSTOMER_SAVED_SUCCESSFULLY, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(updateCustomers(updatedCustomer));
            }
            else {
                toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        catch (e) {
            Logger.logError(constant.CUSTOMER_ERROR_WHILE_SAVING, e);
            toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    let checkColumnLg = 12;
    let checkColumnMd = 12;

    let phoneInputColumnLg = 12;
    let phoneInputColumnMd = 12;

    let emailInputColumnLg = 12;
    let emailInputColumnMd = 12;

    const mainContennt = <>
        <Row>
            <Column lg={12} md={12} className="sld-col-section-header">
                <span className="sld-act-section-header">{"Main Contact"}</span>
            </Column>
        </Row>
        <Row>
           <Column lg={checkColumnLg} md={checkColumnMd}>
                <Row>
                    <Column  lg={6} md={6}>
                        <Checkbox
                            id='sldcnctchkmailOptOut'
                            className="form-chkebox"
                            label={lables.CustomerScreentLables.DO_NOT_MAIL}
                            checked={customerDetail.mailOptOut}
                            onChange={() => toggleEntityField('mailOptOut')}
                            checkboxStyle={{ position: "relative", marginRight: "5px" }}/>
                    </Column>
                    <Column  lg={6} md={6}>
                        <Checkbox
                            id='sldcnctchkcallOptOut'
                            className="form-chkebox"
                            label={lables.CustomerScreentLables.DO_NOT_CALL}
                            checked={customerDetail.callOptOut}
                            onChange={() => toggleEntityField('callOptOut')}
                            checkboxStyle={{ position: "relative", marginRight: "5px" }}/>
                    </Column>
                </Row>
                <Row>
                    <Column  lg={6} md={6}>
                        <Checkbox
                            id='sldcnctchkemailOptOut'
                            className="form-chkebox"
                            label={lables.CustomerScreentLables.DO_NOT_EMAIL}
                            checked={customerDetail.emailOptOut}
                            onChange={() => toggleEntityField('emailOptOut')}
                            checkboxStyle={{ position: "relative", marginRight: "5px" }}/>
                    </Column>
                    <Column  lg={6} md={6}>
                        <Checkbox
                            id='sldcnctchkeddOptIn'
                            className="form-chkebox"
                            label={lables.CustomerScreentLables.EDD_OPT_IN}
                            checked={customerDetail.eddOptIn}
                            onChange={() => toggleEntityField('eddOptIn')}
                            checkboxStyle={{ position: "relative", marginRight: "5px" }}/>
                    </Column>
                </Row>
                <Row>
                    <Column  lg={6} md={6}>
                        <Checkbox
                            id='sldcnctchksmsOptOut'
                            className="form-chkebox"
                            label={lables.CustomerScreentLables.SMS_OPT_OUT}
                            checked={customerDetail.smsOptOut}
                            onChange={() => toggleEntityField('smsOptOut')}
                            checkboxStyle={{ position: "relative", marginRight: "5px" }}/>
                    </Column>
                    <Column  lg={6} md={6}>
                     
                    </Column>
                </Row>
           </Column>
        </Row>
        <Row className="sld-ctc-txt-input-row">
            <Column lg={phoneInputColumnLg} md={phoneInputColumnMd}>
                <MatTextInput 
                            id="sldcnctmobile"
                            lable={lables.CustomerScreentLables.CELL_PHONE}
                            text={formattedInputService.formatPhoneNumber(customerDetail.mobile)}
                            onChange={(changedValue) => onEntityFieldChange('mobile',formattedInputService.getRawPhoneNumber(changedValue))}
                            type="tel"
                            maxLength={12}
                            iconName="mobile"
                            iconClass="mat-text-input-icon"
                            isInvalid={formValidation['Phone']?.isValid == false}
                            invalidWarning={formValidation['Phone']?.warningText}></MatTextInput>
            </Column>
        </Row>
        <Row className="sld-ctc-txt-input-row">
            <Column lg={phoneInputColumnLg} md={phoneInputColumnMd}>
                <MatTextInput 
                    id="sldcncthome" 
                    lable={lables.CustomerScreentLables.HOME_PHONE}
                    text={formattedInputService.formatPhoneNumber(customerDetail.homePhone)}
                    onChange={(changedValue) => onEntityFieldChange('homePhone',formattedInputService.getRawPhoneNumber(changedValue))}
                    type="tel"
                    maxLength={12}
                    iconName="home"
                    iconClass="mat-text-input-icon"
                    isInvalid={formValidation['homePhone']?.isValid == false}
                    invalidWarning={formValidation['homePhone']?.warningText}></MatTextInput>
            </Column>
        </Row>
        <Row className="sld-ctc-txt-input-row">
            <Column lg={phoneInputColumnLg} md={phoneInputColumnMd}>
                <MatTextInput 
                    id="sldcnctphone" 
                    lable={lables.CustomerScreentLables.WORK_PHONE}
                    text={formattedInputService.formatPhoneNumber(customerDetail.workPhone)}
                    onChange={(changedValue) => onEntityFieldChange('workPhone', formattedInputService.getRawPhoneNumber(changedValue))}
                    type="tel"
                    maxLength={12}
                    iconName="phone"
                    iconClass="mat-text-input-icon"
                    isInvalid={formValidation['workPhone']?.isValid == false}
                    invalidWarning={formValidation['workPhone']?.warningText}
                    ></MatTextInput>
            </Column>
        </Row>
        <Row className="sld-ctc-txt-input-row">
            <Column lg={emailInputColumnLg} md={emailInputColumnMd}>
                <MatTextInput 
                    id="sldcnctemail"
                    lable={lables.CustomerScreentLables.EMAIL_1}
                    text={customerDetail.eMail}
                    onChange={(changedValue) => onEntityFieldChange('eMail',changedValue)}
                    maxLength={150}
                    isInvalid={formValidation['eMail']?.isValid == false}
                    invalidWarning={formValidation['eMail']?.warningText}
                    iconName="emailAt"
                    iconClass="mat-text-input-icon"></MatTextInput>
            </Column>
        </Row>
        <Row className="sld-ctc-txt-input-row">
            <Column lg={emailInputColumnLg} md={emailInputColumnMd}>
                <MatTextInput 
                    id="sldcnctemail2"
                    lable={lables.CustomerScreentLables.EMAIL_2}
                    text={customerDetail.eMail2}
                    onChange={(changedValue) => onEntityFieldChange('eMail2', changedValue)}
                    maxLength={150}
                    isInvalid={formValidation['eMail2']?.isValid == false}
                    invalidWarning={formValidation['eMail2']?.warningText}
                    iconName="emailAt"
                    iconClass="mat-text-input-icon"></MatTextInput>
            </Column>
        </Row>

        <Row className="mb-2 sld-ctc-action-row">
            <Column lg={6} md={6}>
                <Button
                    className='btn btn-outline-tertiary sld-action-btn-base'
                    key="btnActivityCancel"
                    onClick={() => onCancelClick()}
                    id="btnActivityCancel"
                    color='primary'
                    outlined={true}
                >{lables.CANCEL.toLocaleUpperCase()}
                </Button>

            </Column>
            <Column lg={6} md={6} className="text-right">
                <Button
                    className='sld-action-btn-base'
                    key="btnActivitySave"
                    onClick={() => onSaveClick()}
                    tabIndex={21}
                    id="btnActivitySave"
                    color='primary'
                    outlined={true}
                    disabled={!canUserEditCustomer}
                >{lables.SAVE.toLocaleUpperCase()}
                </Button>
            </Column>
        </Row>
    </>;

    return <div className="slider-tab-content-main-div">
                {mainContennt}
            </div>;
}

export default SliderContact;