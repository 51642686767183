import { Link } from "react-router-dom";
import { Icon } from "../../components";
import { TODOLIST_GRID_LABELS } from "../shared/util/docuMateLabel";

export const getRefiGridColumns = (handleDocumentClick, onLockDocument, onDeleteDocument) => {
    let gridcolumns = [
        {
            Header: TODOLIST_GRID_LABELS.scanId,
            accessor: 'scanId',
            Cell: row => {
              return (
                  <div className="todoList-grd-cell">
                      <Link
                      to={{
                          pathname: '',
                          state: { scanId: row.row?.original?.scanId, id: row.row?.original?.id }                            
                      }}
                      onClick={(event) => {
                        event.preventDefault(); 
                        handleDocumentClick(row.row?.original?.id)
                      }}
                      title="row.value"
                      style={{ marginRight: '0.5em' }}
                      ><span title={row.row?.original?.scanId}>{row.row?.original?.scanId}</span></Link>
                      <div>
                      {
                        (() => {
                            const data = row.row?.original;
                            switch (true) {
                                case data?.isLocked:
                                    return <Icon icon="lock" className='grd-cell-icon--hardlock' />;
                                default:
                                    return <span>
                                              <Icon icon="lock" className='grd-cell-icon--lock' 
                                              onClick = {(event) => {
                                                event.preventDefault();
                                                onLockDocument(data?.id)
                                              }}/>
                                          </span>;
                            }
                        })()
                    }
                      </div>
                  </div>
              )
            },
            width:350
        },
        {
          Header: TODOLIST_GRID_LABELS.docDescription,
          accessor: 'docDesc',
          Cell: row => {
            return (
              <div className='todoList-grd-cell'>
                  <span title={row.row?.original?.docDesc}>{row.row?.original?.docDesc}</span>
              </div>
            )
          },
          width:675
        },
        {
          Header: TODOLIST_GRID_LABELS.dateInitiated,
          accessor: 'dateInitiated',
          Cell: row => {
            return (
              <div className='todoList-grd-cell'>
                  <span title={row.row?.original?.dateInitiated}>{row.row?.original?.dateInitiated}</span>
              </div>
            )
          },
          width:250
        },
        {
          Header: TODOLIST_GRID_LABELS.dateAvailable,
          accessor: 'dateAvailable',
          Cell: row => {
            return (
              <div className='todoList-grd-cell'>
                  <span title={row.row?.original?.dateAvailable}>{row.row?.original?.dateAvailable}</span>
              </div>
            )
          },
          width:250
        }
      ];
    return gridcolumns;
}
