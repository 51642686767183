class Logger {

    public static logError = (errorMessage : string, exception : any = null) => {
        console.log(errorMessage);
        console.log(exception);
    }

    public static logWarning = (errorMessage : string, exception : any = null) => {
        console.log(errorMessage);
        console.log(exception);
    }
}

export default Logger;