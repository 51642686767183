import { GetDocumentRequestModel } from "../model/GetDocumentRequestModel";
import { getDocument, getDocumentSample } from "../services/DocuMateAPI";

class DocuMateScanService {
    getDocument = async (getDocRequestModel: GetDocumentRequestModel) => {
        var doc =  await getDocument(getDocRequestModel);
        return doc;
    }
}

export default DocuMateScanService;