import { useQuery } from 'react-query'
import {campaignTemplateAPI } from '../services/CampaignAPI/3rdPartyTemplateAPI'
import { useEffect, useState } from 'react';
import ExternalTemplate from '../model/3rdPartyTemplate'


class CampaignTemplatesUtils {
async getAllThirdPartyTemplateListUtils() {
    var response = await campaignTemplateAPI.getAllExternalTemplateList();
    //console.log('utils',response)
    return response;
}

    async getThirdPartyTemplateUtils(templateId: string) {
        var templateDetail = await campaignTemplateAPI.getExternalTemplateById(templateId);
        return templateDetail;
    }

    async submitThirdPartyTemplateUtils(externalTemplateDetail: ExternalTemplate) {
        var response = await campaignTemplateAPI.saveExternalTemplate(externalTemplateDetail);
        return response;
    }

    async updateThirdPartyTemplateUtils(externalTemplateDetail: ExternalTemplate) {
        var response = await campaignTemplateAPI.updateExternalTemplate(externalTemplateDetail);
        return response;
    }

    async  deleteThirdPartyTemplateUtils(id: string) {
        var response = await campaignTemplateAPI.deleteExternalTemplateById(id);
        return response;
    }


     getThirdPartyTemplate = async (templateId: string): Promise<ExternalTemplate> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        if (templateId !== null && templateId !== undefined && templateId !== '') {
            var response = await this.getThirdPartyTemplateUtils(templateId);
            return response.data
        }
        else {
            return null
        }

    }

    submitThirdPartyTemplate = async (externalTemplateDetail: ExternalTemplate): Promise<any> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.submitThirdPartyTemplateUtils(externalTemplateDetail);
        return response
    }

  updateThirdPartyTemplate = async (externalTemplateDetail: ExternalTemplate): Promise<any> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.updateThirdPartyTemplateUtils(externalTemplateDetail);
        //console.log(response)
        return response
    }

    deleteThirdPartyTemplate = async (templateId: string): Promise<any> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        if (templateId !== null && templateId !== undefined && templateId !== '') {
            var response = await this.deleteThirdPartyTemplateUtils(templateId);
            return response
        }
        else {
            return null
        }

    }

getAllThirdPartyTemplateList = async (): Promise<ExternalTemplate[]> => {
        var response=await this.getAllThirdPartyTemplateListUtils();
        //console.log('Util',response.data)
        return response.data
    }
    
   setTemplateDataModel=(response)=>{
        var externalTemplate:ExternalTemplate={
            id:response!==null && response!==undefined && response.data!==null?response.data._id:'',
            category:response!==null && response!==undefined && response.data!==null?response.data. _category:'',
            changedBy:response!==null && response!==undefined && response.data!==null?response.data._changedBy:'',
            changedDate:response!==null && response!==undefined && response.data!==null?response.data._changedDate:'',
            description:response!==null && response!==undefined && response.data!==null?response.data._description:'',
            enteredDate:response!==null && response!==undefined && response.data!==null?response.data._enteredDate:'',
            sendGridTemplateId:response!==null && response!==undefined && response.data!==null?response.data._sendGridTemplateId:'',
            status:response!==null && response!==undefined && response.data!==null?response.data._status:'',
            templateName:response!==null && response!==undefined && response.data!==null?response.data._templateName:'',
            type:response!==null && response!==undefined && response.data!==null?response.data._type:'',
            //summary:response!==null && response!==undefined && response.data!==null?response.data._description:'',
            //enteredBy:response!==null && response!==undefined && response.data!==null?response.data._changedBy:'',
        }
        return externalTemplate;
    }

}
export const campaignTemplatesUtils = new CampaignTemplatesUtils();

