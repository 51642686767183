import { useContext, useEffect, useState } from 'react';
import RoleDetailsModel from '../../model/RoleDetailsModel'
import RolesSideBarService from './RolesSideBarService';
import { toast } from 'react-toastify';
import * as constant from '../../../shared/util/constant'
import Logger from '../../../shared/services/Logger';
import './RolesSideBar.scss';
import { useConfirmationModalContext } from '../../../shared/components/ModalConfirmationContext';
import AppContext from '../../../shared/components/AppContext';
import  { useHistory, useParams } from 'react-router-dom';

interface Props {
    reload: boolean
}

const RolesSideBar = (props: Props) => {

    const [roles, setRoles] = useState<RoleDetailsModel[]>([]);

    const rolesSideBarService = new RolesSideBarService();

    const modalContext = useConfirmationModalContext();

    const rolesContext = useContext(AppContext);

    const history = useHistory();

    const { roleId } = useParams<{roleId : string | undefined}>();

    const initializeRoles = async () => {
        try {
            let response = await rolesSideBarService.getRoles();
            setRoles(response.sort((a, b) => a.roleName.localeCompare(b.roleName)));
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
            Logger.logError(error.toString());
        }
    }

    const handleRoleClick = async (roleId: string) => {
        if (!rolesContext.formSavedStatus) {
            const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if (result) {
                rolesContext.formSavedStatus = true
                history.push(`/admin/roleprivilege/${roleId}`)
            }
        }
        else {
            history.push(`/admin/roleprivilege/${roleId}`)
        }

    }

    useEffect(() => {
        initializeRoles();
    }, []);

    useEffect(() => {
        initializeRoles();
    }, [props.reload]);

    return (
        <div className='roles-sidebar'>
            {
                roles.map((role) => (
                    <div
                        key={role.id}
                        className={`role-item ${role.id === roleId ? 'active' : ''}`}
                        onClick={() => {
                            handleRoleClick(role.id)
                        }
                        }
                    >
                        {role.roleName}
                    </div>
                )
                )
            }
        </div>
    );
};

export default RolesSideBar;