import { PolicyDtoRead } from "../model/PolicyDtoRead";
import PolicyApi from "../services/PolicyAPI";


class CustomerPolicyListService {
    policyAPI : PolicyApi = new PolicyApi();

    fetchCustomerPolicyDetails = async (customerId : string) : Promise<PolicyDtoRead[]> => {
        return await this.policyAPI.fetchCustomerPolicies([customerId]);
    }
}

export default CustomerPolicyListService;
