import React from "react";
import './CustomerPolicySummary.scss';
import { PolicyDtoRead } from "../model/PolicyDtoRead";
import { Button, Column, Icon, Panel, Row } from "../../components";
// import TextInputWithLabelFormGroup from "../../shared/components/input/TextInputWithLabelFormGroup";
import MaterialLable, { MaterialLableDisplayType } from "../../shared/components/labels/material-label";
import moment from "moment";
import { CustomerPolicyListScreenLables } from "../../shared/util/label";
import { IconType } from "../../components/components/Icon/interfaces";
import { LookupDetailConstants } from "../../shared/util/LookupDetailConstants";
import { CustomerPolicy } from "../../shared/util/constant";

export interface ICustomerPolicySummary
{
    policySummary : PolicyDtoRead
};

const policyStatusMap = {
    [LookupDetailConstants.POLICY_STATUS.ACTIVE] : { backgroundColor : '#00FF00'},
    [LookupDetailConstants.POLICY_STATUS.CANCELLED] : { backgroundColor : '#FF0000'},
    [LookupDetailConstants.POLICY_STATUS.EXPIRED] : { backgroundColor : '#FF0000'},
    [LookupDetailConstants.POLICY_STATUS.INCLUDE] : { },
    [LookupDetailConstants.POLICY_STATUS.NON_RENEWED] : { backgroundColor : '#FF0000'},
    [LookupDetailConstants.POLICY_STATUS.NOT_TAKEN] : { backgroundColor : '#FF69B4'},
    [LookupDetailConstants.POLICY_STATUS.QUOTE] : { backgroundColor : '#800080'},
    [LookupDetailConstants.POLICY_STATUS.RENEWED] : { backgroundColor : '#00FF00'},
    [LookupDetailConstants.POLICY_STATUS.REWRITTEN] : { backgroundColor : '#00FF00'},
};

const CustomerPolicySummary : React.FunctionComponent<ICustomerPolicySummary> = (props) => {

    const dateFormat = "MM/DD/YYYY"

    const policyTermValue = `${moment(props.policySummary.polEffDate).format(dateFormat)} - ${moment(props.policySummary.polExpDate).format(dateFormat)}`;

    const websiteLauncherIcon = <Icon icon={'arrow-right' as IconType} className="icn-website-launcher"/>;
    const aplusRefLink = <a href={props.policySummary.policyAPlusUrl} target="_blank" >{props.policySummary.aPlusReferenceNumber} {props.policySummary.aPlusReferenceNumber? websiteLauncherIcon : ''}</a>
    const insuranceCompanyLink = <a href="#" >{props.policySummary.companyName} {props.policySummary.companyName ? websiteLauncherIcon : ''}</a>

    const fullLg = 12;
    const fullMd = 12;

    let policyBarStyle = {};
    if (props.policySummary.policyTermStatusId && policyStatusMap[props.policySummary.policyTermStatusId]) {
        policyBarStyle = policyStatusMap[props.policySummary.policyTermStatusId];
    }

    const getPropValue = (value) => {
        if (value) {
            return value;
        }

        return CustomerPolicy.UNKNOWN;
    }

    return (
        <div className="panel-container">
            <Panel cardBodyClassName={'item-panel'}>
                <div className="container-summary-main">
                    <div className="fixed-width"  style={policyBarStyle}></div>
                    <div className="fill-width">
                        <div className="summary-dv-row">
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable
                                            // lableCaption={props.policySummary.lob} 
                                            lableCaption={CustomerPolicyListScreenLables.POLICY_NUMBER}
                                            lableValue={props.policySummary.policyNumber}                                            
                                            lableValueClass='highlighted-value'
                                            // lableCaptionClass="lob-caption"
                                            displayType={MaterialLableDisplayType.HorizontalFlex}></MaterialLable>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.POLICY_TYPE_LOB}
                                            lableValue={getPropValue(props.policySummary.polTypeLobValue)}
                                            displayType={MaterialLableDisplayType.Horizontal}
                                            captionLgCol={4}
                                            captionMdCol={4}
                                            valueLgCol={8}
                                            valueMdCol={8}></MaterialLable>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.LINE_OF_BUSINESS}
                                            lableValue={getPropValue(props.policySummary.lobCode)}
                                            displayType={MaterialLableDisplayType.Horizontal}
                                            captionLgCol={4}
                                            captionMdCol={4}
                                            valueLgCol={8}
                                            valueMdCol={8}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.PROPERTY_ADDRESS}
                                            lableValue={'29035 Gumtree PI<br/> Santa Clarita Ca 91390'}>
                                        </MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.SOURCE}
                                            lableValue={props.policySummary.source}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.AGENCY_NOTATION}
                                            lableValue={props.policySummary.legacyNotationCode}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.APLUS_REFERENFCE}
                                            valueSectionTemplate={aplusRefLink}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>

                        </div>
                        <div className="summary-dv-row subsequent-item-row">
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.FORM_TYPE}
                                            lableValue={getPropValue(props.policySummary.formTypeValue)}
                                            displayType={MaterialLableDisplayType.Horizontal}
                                            captionLgCol={4}
                                            captionMdCol={4}
                                            valueLgCol={8}
                                            valueMdCol={8}></MaterialLable>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.POLICY_TYPE}
                                            lableValue={getPropValue(props.policySummary.policyType)}
                                            displayType={MaterialLableDisplayType.Horizontal}
                                            captionLgCol={4}
                                            captionMdCol={4}
                                            valueLgCol={8}
                                            valueMdCol={8}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.INSURANCE_COMPANY}
                                            valueSectionTemplate={insuranceCompanyLink}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                                lableCaption={CustomerPolicyListScreenLables.TERM}
                                                lableValue={policyTermValue}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.PREMIUM}
                                            lableValue={'$' + props.policySummary.fullTermPremium?.toString()}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.STATUS}
                                            lableValue={props.policySummary.polTermStatus}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                        </div>
                        <div className="summary-dv-row subsequent-item-row">
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <Button color='primary' outlined={true} >COMPARE</Button>
                                        <Button color='primary' outlined={true} additionalClassName="dropdown-toggle btn-execpt-first">ACTIONS</Button>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                       
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.PRODUCER}
                                            lableValue={props.policySummary.producerName}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                        <MaterialLable 
                                            lableCaption={CustomerPolicyListScreenLables.DESCRIPTION}
                                            lableValue={props.policySummary.policyType}></MaterialLable>
                                    </Column>
                                </Row>
                            </div>
                            <div className="summary-dv-column">
                                <Row>
                                    <Column lg={fullLg} md={fullMd}>
                                      
                                    </Column>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default CustomerPolicySummary;