import React, { useEffect, useState } from "react";
import { Button, ITableColumn, Icon } from "../../components";
import { toast } from "react-toastify";
import './CustomerLoss.scss';
import CustomerLossService from "./CustomerLossService";
import Logger from "../../shared/services/Logger";
import DataTable from "../../components/components/Table/DataTable";
import { CustomerLossHistoryConstant, CUSTOMER_NOT_FOUND } from "../../shared/util/constant";
import CustomerLossForm from "./customer-loss-form/CustomerLossForm";
import { CustomerLossReadDto } from "../model/CustomerLossDto";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import CustomerLossFormService from "./customer-loss-form/CustomerLossFormservice";

export interface ICustomerLoss {
    customerId? : string
};

export interface LossPageState {
    isAddingNewLoss : boolean;
    isEditingLoss : boolean;
    editingNoteId? : string;
}

const initialPageState : LossPageState = {
    isAddingNewLoss : false,
    isEditingLoss : false,
    editingNoteId : null
};

const CustomerLoss : React.FunctionComponent<ICustomerLoss> = (props) => {
    const customerLossService  = new CustomerLossService();
    const customerLossFormService  = new CustomerLossFormService();
    const {customerId} = props;
    const [customerLosses, setCustomerLosses ] = useState<CustomerLossReadDto[]>([]);
    const [lossFormState, setLossFormState] = useState<LossPageState>(initialPageState);
    const [currentCustomerLossData, setCurrentCustomerLossData] = useState<CustomerLossReadDto>({});
    
    const modalContext = useConfirmationModalContext();

    useEffect(() => {
        initializeComponent();
    },[]);

    const initializeComponent = async () => {
        try  {
            await fetchLossHistoryData();
        }
        catch (ex) {
            toast.error(CustomerLossHistoryConstant.ERROR_OCCURED_WHILE_INIT_COMPONENT, {
                position: toast.POSITION.TOP_RIGHT
            });
            Logger.logError(CustomerLossHistoryConstant.ERROR_OCCURED_WHILE_INIT_COMPONENT, ex);
        }
    };

    const fetchLossHistoryData = async () => {
        try {
            let lossData = await customerLossService.fetchCustomerLosess(customerId);
            setCustomerLosses(lossData);
        }
        catch(ex){
            toast.error(CustomerLossHistoryConstant.ERROR_OCCURED_WHILE_FETCHING_LOSS_HISTORY, {
                position: toast.POSITION.TOP_RIGHT
            });
            Logger.logError(CustomerLossHistoryConstant.ERROR_OCCURED_WHILE_FETCHING_LOSS_HISTORY, ex);
        }
    }

    const onAddNewLossClick = (e) => {
        if (lossFormState.isAddingNewLoss || lossFormState.isEditingLoss) {
            return;
        }

        setCurrentCustomerLossData({});
        setLossFormState({...lossFormState, isAddingNewLoss : true});
    }

    let AddNewLossButton = <></>;

    if (!lossFormState.isAddingNewLoss && !lossFormState.isEditingLoss) {
        AddNewLossButton = <>
                                <Button
                                    className='btn btn-primary bt-main-action mb-2 ml-n5'
                                    key="filter"
                                    onClick={(e) => onAddNewLossClick(e)}
                                    tabIndex={21}
                                    id="btnLosssAdd"
                                    outlined={true}>
                                        <b>
                                            <span>
                                                <Icon icon={"add"} className="fa fa-add icon-color-blue"></Icon>&nbsp;
                                            </span>
                                            {CustomerLossHistoryConstant.ADD_LOSS}</b>
                                </Button>
                            </>;
    }

    const onLossFormCancelClick = () => {
        setLossFormState({...lossFormState, isAddingNewLoss : false, isEditingLoss : false});
    }

    const onLoassHistoryCreated = (lossDto : CustomerLossReadDto) => {
        resetPage();
    }

    const onLoassHistoryUpdated = (lossDto : CustomerLossReadDto) => {
        // Handle on update.........
        resetPage();
    }

    const resetPage = () => {
        setCurrentCustomerLossData({});
        setLossFormState({...lossFormState, isEditingLoss : false, isAddingNewLoss : false});
        fetchLossHistoryData();
    }

    const customerLossFormView = <CustomerLossForm 
                                            customerLoss={currentCustomerLossData}
                                            customerId={customerId}
                                            onCancelClick={onLossFormCancelClick}
                                            onLossHistoryCreated={onLoassHistoryCreated}
                                            onLossHistoryUpdated={onLoassHistoryUpdated}></CustomerLossForm>

    const onRowEditClick = (rowData : any, column : ITableColumn) => {

        if (lossFormState.isAddingNewLoss || lossFormState.isEditingLoss) {
            return;
        }

        setCurrentCustomerLossData(rowData);
        setLossFormState({...lossFormState, isEditingLoss : true});
    }

    const onRowDeleteClick = async (rowData: any, column: ITableColumn) => {
        try {

            if (lossFormState.isAddingNewLoss || lossFormState.isEditingLoss) {
                return;
            }

            const result = await modalContext.showConfirmation(CustomerLossHistoryConstant.LOSS_HISTORY_DELETE_CONFIRMATION)
            if(!result){
                return;
            }

            await customerLossFormService.removeCustomerLossData(rowData);
            resetPage();
        }
        catch (ex) {
            if (ex.status == 404) {
                toast.error(CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(CustomerLossHistoryConstant.DELETE_CUSTOMER_LOSS_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const tableColumns : ITableColumn[] = customerLossService.getLossHistoryTableColumns(onRowEditClick, onRowDeleteClick);

    return (
        <div className="cst-dv-loss-main">
            <div className="cst-dv-actions">
                {AddNewLossButton}
            </div>
            {(lossFormState.isAddingNewLoss || lossFormState.isEditingLoss ) && 
            <div>
                {customerLossFormView}
            </div>
            }
            <DataTable
                    columns={tableColumns} 
                    data={customerLosses}
                    keyColumnName={'id'}>
            </DataTable>
        </div>
    );
};

export default CustomerLoss;