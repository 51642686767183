import { DEFAULT_GUID } from "../../../shared/util/constant";
import { CustomerNote } from "../../model/CustomerNoteModel";


class SliderNoteService {
    public getCustomerNoteRequest = (customerNote : CustomerNote, customerId : string) : CustomerNote => {
        let noteData : CustomerNote = JSON.parse(JSON.stringify(customerNote));

        noteData.custId = customerId;
        noteData.noteType = DEFAULT_GUID;
        noteData.changedDate = new Date();
        noteData.enteredDate = new Date();

        return noteData;
    }
};

export default SliderNoteService;