
import { useQuery } from 'react-query'
import campaignFilter from '../model/QueryFilters'
import CampaignFilterLogic from '../model/CampaignFilterLogic'
import CampaignQueryInfo from '../model/CampaignQueryInfo'
import Query from '../model/QueryList'
import {campaignQueryAPI} from '../services/CampaignAPI/QueryAPI'
import * as constant from '../../shared/util/constant'
import TestQuery from '../model/TestQuery'
import { SelectOption } from '../../shared/components/input'

class CampaignQueryUtils {
    private async SaveQueryDetailUtil(queryDetail: CampaignQueryInfo) {
        var response = await campaignQueryAPI.saveQueryToDatabase(queryDetail)
        return response
    }
    private async UpdateQueryDetailUtil(queryDetail: CampaignQueryInfo) {
        var response = await campaignQueryAPI.updateQueryToDatabase(queryDetail)
        return response
    }

    private async GetAllQueryListUtil() {
        var response = await campaignQueryAPI.getAllQueryList()
        return response
    }

    private async GetQueryByIdUtil(queryId: string) {
        var response = campaignQueryAPI.getQueryByID(queryId)
        return response;
    }

    private async DeleteQueryByIdUtil(queryId: string) {
        var response = campaignQueryAPI.deleteQueryByID(queryId)
        return response;
    }
    private async getDynamicDropdownOptions(query:string) {
        return await campaignQueryAPI.fetchDynamicDropdownOptions(query);
      }

    private async getDynamicTestResult(filterData:TestQuery) {
    return await campaignQueryAPI.GetTestQueryResult (filterData);
    }
    SaveQueryDetail = async (queryDetail: CampaignQueryInfo): Promise<CampaignQueryInfo> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.SaveQueryDetailUtil(queryDetail);
        return response
    }

    UpdateQueryInfo = async (queryDetail: CampaignQueryInfo): Promise<CampaignQueryInfo> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.UpdateQueryDetailUtil(queryDetail);
        return response
    }
    GetAllQueryList = async (): Promise<Query[]> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.GetAllQueryListUtil();
        return response;
    }

    GetQueryById = async (queryId: string): Promise<CampaignQueryInfo> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        

        var response = await this.GetQueryByIdUtil(queryId);
        //console.log('Util Query:',response)
        // if(response!==undefined ||response!==null){
        // campaignQueryInfo={
        // id:response.data._id,
        // queryName:response.data._queryName,
        // description:response.data._description,
        // UpdatedOn:response.data.changedDate,
        // RequestedBy:response.data._changedBy,
        // Status:'',
        // campaignFilter:response.data._campaignFilter,
        // queryStatement:response.data._queryStatement
        // }
    //}
    //console.log('campaignQueryInfo', response)
        return response
    }
    DeleteQueryById=async (queryId: string): Promise<CampaignQueryInfo> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.DeleteQueryByIdUtil(queryId);
        return response;
    }

    setQueryDataModel=(response)=>{
        //console.log('utils',response)
       // var filterList=this.setFilterModel(response!==null && response!==undefined &&response.data!==null?response.data._campaignFilter:[])
        var campaignQueryInfo:CampaignQueryInfo={
            id:response!==null && response!==undefined &&response.data!==null?response.data._id:'',
            queryName:response!==null&& response!==undefined &&response.data!==null?response.data._queryName:'',
            description:response!==null && response!==undefined &&response.data!==null?response.data._description:'',
            updatedOn:response!==null && response!==undefined &&response.data!==null?response.data._changedDate:new Date(),
            requestedBy:response!==null && response!==undefined &&response.data!==null?response.data._changedBy:'',
            status:response!==null && response!==undefined &&response.data!==null?response.data._status:'',
            queryStatement:response!==null && response!==undefined &&response.data!==null?response.data._queryStatement:'',
            _campaignFilterList:[]//response!==null && response!==undefined &&response.data!==null?response.data._campaignFilter:[],
        }
        
            if(response!==null && response!==undefined &&response.data!==null &&(response.data._campaignFilter!==undefined && response.data._campaignFilter!==null)){
                //console.log('loop1',response.data._campaignFilter.length)
                response.data._campaignFilter.forEach(e => {
                   // console.log('e',e)
                   let data=[]
                   let filterdata=[]
                   let logicIds=[]
                   if(response.data._campaignFilter!==undefined && response.data._campaignFilter!==null){
                    //console.log('length loop 1', response.data._campaignFilter.length)
                   // response.data._campaignFilter.forEach(e => {
                        //console.log('loop1',response.data._campaignFilter.length)
                        if(e._campaignLogicOptions!==undefined && e._campaignLogicOptions!==null){
                            data=[]
                            logicIds=[]
                            e._campaignLogicOptions.forEach(f => {
                           // console.log('loop2')
                            let filterLogic={id:f._id,logic:f._logic,filterId:f._filter}
                            data.push(filterLogic)
                            logicIds.push(f._logic.toUpperCase())
                       });
                    }
                   // console.log('data',data)
                      filterdata=[]
                      //console.log('e.dataType',constant.DROPDOWN_SQL_ID.toLowerCase()?true:e._dataType===constant.DROPDOWN_LIST_ID.toLowerCase()?true:false)
                       let filterList={
                           id:e._id,
                           fieldName:e._fieldName,
                           filterDisplay:e._filterDisplay,
                           expression:e._expression,
                           dataType:e._dataType,
                           campaignFilterLogic:data,
                           campaignFilterIds:logicIds.join(','),
                           dataTypeLogicVisible:(e._dataType===constant.DROPDOWN_SQL_ID.toLowerCase()?true:e._dataType===constant.DROPDOWN_LIST_ID.toLowerCase()?true:false)
                        }
                       //console.log('filterList',filterList)
                       filterdata.push(filterList)
                       campaignQueryInfo._campaignFilterList.push(filterList)  
                       //console.log('filterdata',filterdata)
                   // });
                   }
            });  
            }
        //console.log('modal',campaignQueryInfo)
        return campaignQueryInfo;
    }
    setFilterModel=(queryModel)=>{
        //console.log('queryModel',queryModel)
        var filterLogicModel:TestQuery={
            selectQuery:queryModel!==null && queryModel!==undefined && queryModel.selectQuery!==null?queryModel.queryStatement:'',
            filterList:[]
        }
        if(queryModel!==null && queryModel!==undefined &&(queryModel._campaignFilterList!==undefined && queryModel._campaignFilterList!==null)){
            queryModel._campaignFilterList.forEach(e => {
                let filterLogic={field:e.fieldName,dataItem:[],logic:'',type:e.dataType??'',isLogicEmpty:false,isFieldEmpty:false,isDataItemEmpty:false}
                if(e.dataType===constant.Data_Type_Number_Range||e.dataType===constant.Data_Type_Currency_Range ||e.dataType===constant.Data_Type_Date_Range){
                    //console.log('logic:',e.logicDropdown[0][0].value)
                    filterLogic.logic=e.logicDropdown[0][0].value
                }
               // console.log('filterLogic',filterLogic)
                filterLogicModel.filterList.push(filterLogic);
            })
        }
        //console.log('filterLogicModel',filterLogicModel) 
        return filterLogicModel;
    }

    getDataForDynamicQuery=async(query:string):Promise<[SelectOption[]]> => {
        //console.log('Util query',query)
        var optionList=await this.getDynamicDropdownOptions(query);
        //console.log(optionList)
        return  (optionList??optionList.sort((a, b) =>
        a. lookupValue> b.lookupValue ? 1 : -1).map((option) => {
          return {
            label: option.lookupValue,
            value: option.id.toUpperCase()
          };
        })
        )}; 

    getTestResultFromQuery=async(filterData:TestQuery):Promise<any>=>{
    var dataModel=  this.modifyFilterData(filterData)
    console.log('dataModel',dataModel)
    var filterResult=await this.getDynamicTestResult(dataModel)
    return filterResult
   
    }

    modifyFilterData=(filterData:TestQuery)=>{
        var filterLogicModel:TestQuery={
            selectQuery:filterData!==null && filterData!==undefined && filterData.selectQuery!==null?filterData.selectQuery:'',
            download:filterData.download,
            filterList:[]
        }
        filterData.filterList.forEach(e => {
            let filterLogic={field:e.field,dataItem:JSON.stringify(e.dataItem),logic:e.logic,type:e.type}
            filterLogicModel.filterList.push(filterLogic);
        });
        return filterLogicModel;
    }
}
export const campaignQueryUtils = new CampaignQueryUtils();