import { TextField } from "../TextField";
import { DateTimePicker } from "../DateTimePicker";
import './Grid.scss';
import { Select } from "../Select";
import { TextInput } from "../TextInput";
import { PLEASE_SELECT } from "../../../shared/util/constant";
import { Icon } from "../Icon";
import { IconType } from "../Icon/interfaces";

export class GridDefaultCellTemplates {

  private static defaultErrorHanlder =  cell => {
    if (cell?.row?.original?.editingRow?.errors && Object.keys(cell?.row?.original?.editingRow?.errors).length > 0) {
        let colError = cell.row.original.editingRow.errors[cell.column.fieldName];
        if (colError) {
          // inputProps is required for select edit templates as tooltip are shown on div in select component. 
            return { isInvalid : true, title : colError.warningText, inputProps : { title : colError.warningText } };
        } 
    }

    return {};
  }

  private static defaultCellViewTemplate = cell => <div><span title={cell.value}>{cell.value}</span></div>;

  static EditableGridColumn = {
    CellTemplate : cell => {
        if (cell?.row?.original?.enableGridRowEditing == true
            && cell.column.CellEditTemplate) {
            return  cell.column.CellEditTemplate(cell);
        }

        if (cell.column.CellViewTemplate) {
          return cell.column.CellViewTemplate(cell);
        }

        return this.defaultCellViewTemplate(cell);
    }
  }

  static SelectFieldGridColumn = {
    CellTemplate : cell => {
      let cellTemplateParameters = cell.column.CellEditTemplateParameters ? cell.column.CellEditTemplateParameters : {};
      let cellDynamicParameters = {};
      if (cell.column.CellEditTemplateErrorHandler) {
        cellDynamicParameters = cell.column.CellEditTemplateErrorHandler(cell);
      }

      // Do edting on editing row (copy of original row) only.
      let editingRow : any = {};
      if (cell.row?.original?.editingRow) {
        editingRow = cell.row.original.editingRow;
      }

      return  <Select
                    id={`selectGridRow${editingRow.id}${cell?.column?.id}`}
                    key={`selectGridRow${editingRow.id}${cell?.column?.id}`}
                    placeholder={PLEASE_SELECT}
                    options={[]}
                    defaultSelected={cellTemplateParameters.options?.filter(({ value }) => value === editingRow[cell.column.fieldName])}
                    onChange={
                        (values) => {
                            if (cellTemplateParameters.cellDataChangeHandler && values.length > 0) {
                                cellTemplateParameters.cellDataChangeHandler(editingRow, cell.column, values[0], null);
                            }
                            else {
                              cellTemplateParameters.cellDataChangeHandler(editingRow, cell.column, null, null);
                            }
                        }
                    }
                    {...cellTemplateParameters}
                    {...cellDynamicParameters}/>
    },
    ErrorHandler : this.defaultErrorHanlder
  }

  static DateFieldGridColumn = {
    CellTemplate : cell => {
      let cellTemplateParameters = cell.column.CellEditTemplateParameters ? cell.column.CellEditTemplateParameters : {};
      let cellDynamicParameters = {};
      if (cell.column.CellEditTemplateErrorHandler) {
        cellDynamicParameters = cell.column.CellEditTemplateErrorHandler(cell);
      }

      // Do edting on editing row (copy of original row) only.
      let editingRow : any = {};
      if (cell.row?.original?.editingRow) {
        editingRow = cell.row.original.editingRow;
      }

      return <div className="grd-datetime-picker-div">
         <DateTimePicker
                    id={`gridDateTimePicker${editingRow.id}${cell?.column?.id}`}
                    key={`gridDateTimePicker${editingRow.id}${cell?.column?.id}`}
                    dateFormatCalendar="LLLL yyyy"
                    dropdownMode="scroll"
                    selected={editingRow[cell.column.fieldName]}
                    popperClassName="grd-date-time-picker-popper"
                    onChange={(date) => {
                      if (cellTemplateParameters.cellDataChangeHandler) {
                        cellTemplateParameters.cellDataChangeHandler(editingRow, cell.column, date, null)
                      }
                    }}
                    {...cellTemplateParameters}
                    {...cellDynamicParameters}/>
      </div>
    },
    ErrorHandler : this.defaultErrorHanlder
  }

  static TextInputGridColumn = {
    CellTemplate : cell => {
        let cellTemplateParameters = cell.column.CellEditTemplateParameters ? cell.column.CellEditTemplateParameters : {};
        let cellDynamicParameters = {};
        if (cell.column.CellEditTemplateErrorHandler) {
          cellDynamicParameters = cell.column.CellEditTemplateErrorHandler(cell);
        }

        // Do edting on editing row (copy of original row) only.
        let editingRow : any = {};
        if (cell.row?.original?.editingRow) {
          editingRow = cell.row.original.editingRow;
        }

        return <TextInput 
                    value={editingRow[cell.column.fieldName]}
                    onChange={(event) => {
                      if (cellTemplateParameters.cellDataChangeHandler) {
                        cellTemplateParameters.cellDataChangeHandler(editingRow, cell.column, event.currentTarget.value, null)
                      }
                    }}
                    
                    {...cellTemplateParameters}
                    {...cellDynamicParameters}>
                </TextInput>;
    },
    ErrorHandler : this.defaultErrorHanlder
  }

  static TextFieldGridColumn = {
    CellTemplate : cell => {
        let cellTemplateParameters = cell.column.CellEditTemplateParameters ? cell.column.CellEditTemplateParameters : {};
        let cellDynamicParameters = {};
        if (cell.column.CellEditTemplateErrorHandler) {
          cellDynamicParameters = cell.column.CellEditTemplateErrorHandler(cell);
        }

        // Do edting on editing row (copy of original row) only.
        let editingRow : any = {};
        if (cell.row?.original?.editingRow) {
          editingRow = cell.row.original.editingRow;
        }

        return <TextField 
                    value={editingRow[cell.column.fieldName]}
                    onChange={(event) => {
                      if (cellTemplateParameters.cellDataChangeHandler) {
                        cellTemplateParameters.cellDataChangeHandler(editingRow, cell.column, event.currentTarget.value, null)
                      }
                    }}
                    
                    {...cellTemplateParameters}
                    {...cellDynamicParameters}>
                </TextField>;
    },
    ErrorHandler : this.defaultErrorHanlder
  }

  static SaveRowGridColumn  = {
    CellTemplate : (cell : any) => {
      let templateToRender = <div className="grd-center-content  grd-center-content--edit-action">
         <Icon icon={'save' as IconType} 
              className="grd-cell-icon grd-cell-icon--save" 
              title="Save"  
              onClick={() => {
              if (cell.column.CellEditTemplateParameters?.cellClickHandler) {
                cell.column.CellEditTemplateParameters?.cellClickHandler(cell.row.original, cell.column, 'save_row');
              }
            }}/>
            
          <Icon icon={'close' as IconType} 
             className="grd-cell-icon grd-cell-icon--cancel" 
             title="Cancel"
             onClick={() => {
                if (cell.column.CellEditTemplateParameters?.cellClickHandler) {
                  cell.column.CellEditTemplateParameters?.cellClickHandler(cell.row.original, cell.column, 'cancel_row_editing');
                }
              }}/>
      </div>;
        
      return templateToRender;
    },
  }

  static EditRowGridColumn  = {
    CellTemplate : (cell : any) => {
      let disableButton = false;
      if (cell?.row?.original?.isGridInEditMode) {
        disableButton  = true;
      }

      let templateToRender = <div className="grd-center-content">
        <Icon icon={'edit' as IconType} 
            className="grd-cell-icon grd-cell-icon--edit" 
            title="Edit"
            onClick={() => {
              if (cell.column.CellEditTemplateParameters?.cellClickHandler && !disableButton) {
                cell.column.CellEditTemplateParameters?.cellClickHandler(cell.row.original, cell.column, 'edit_row');
              }
            }}/>
      </div>;
        
      return templateToRender;
    },
  }
}