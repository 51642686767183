import { useEffect, useState } from "react";
import { approveDocument, getRefiTodoList, handleHardLockClick } from "./DocuMateRefiService";
import { getRefiGridColumns } from "./RefiTodoListService";
import { FallbackComponent } from "../../shared/components/FallbackComponent";
import { ErrorBoundary} from 'react-error-boundary';
import Grid from "../../components/components/Grid/Grid";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../shared/components/confirmModal";
import * as documateLabel from '../shared/util/docuMateLabel';

const RefiTodoList = () => {
    const [todoList, setTodoList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [counter, setCounter] = useState(2);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalBody, setModalBody] = useState(null);
    const [currentId, setCurrentId] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getRefiTodoList().then((data) => {
            setTodoList(data);
            setIsLoading(false);
        });
    }, []);

    const onHardLockClick = (id: string) => {
        if(counter >= 10) {
            toast.warn('You have reached the maximum number of documents to be reviewed for Refi Automated Process.');
            return;
            
        }
        setCounter(counter + 1);
        setIsLoading(true);
        handleHardLockClick(id).then(() => {
            getRefiTodoList().then((data) => {
                setTodoList(data);
                setIsLoading(false);
            });
        });
    }

    const handleClosePopup = () => {
        setShowConfirmModal(false);
    }

    const handleConfirmModal = () => {
        if(currentId) {
            history.push({
                pathname: `/documate-refi/processing`,
                state: { id: currentId, scanId: todoList.find((doc) => doc.id === currentId)?.scanId }
            });
        }
        setShowConfirmModal(false);
      }

      const getConfirmModalBody = (dateTime) => (
        <>   
        The image is <strong>locked</strong> by user(s): <strong>"{'John Doe'}"</strong> on <strong>"{dateTime}."</strong> Would you like to open?
        </>
      );

    const handleDocumentClick = (id: string) => {
        let currentDocument = todoList.find((doc) => doc.id === id);
        if( currentDocument && currentDocument?.isLocked) {
            setModalBody(getConfirmModalBody(currentDocument?.dateInitiated));
            setCurrentId(id);
            setShowConfirmModal(true);
        }
        else {
            history.push({
                pathname: `/documate-refi/processing`,
                state: { id: id, scanId: currentDocument?.scanId }
        });
    }
    }

    const onDeleteDocument = (id: string) => {
    }

    const gridColumns = getRefiGridColumns(handleDocumentClick, onHardLockClick, onDeleteDocument);
    
    return (
        <>
        <ErrorBoundary FallbackComponent={FallbackComponent}>
            <div className="outerPanel">
                <div className='documate-todoList-main-div'>
                {isLoading ? <div>Loading...</div> : 
                <Grid
                data={todoList}
                showFilters={true}
                id={'refiToDoListGrid'}
                retainGridState={true}
                columns={gridColumns}
                hiddenColumns={['selection']}
                sortDefault={[]}
                grdTableContainerClass="grd-table-container"
                grdTabelClass="grd-table todolist-grd-row"
                zindexHeader={10000}/>
                }
                </div>
            </div>
            <ConfirmModal
                  modaltitle={documateLabel.CONFIRM}
                  showConfirmation={showConfirmModal}
                  setConfirmation={(isModalOpen) => setShowConfirmModal(isModalOpen)}
                  body={modalBody}
                  closeButtonClick={() => handleClosePopup()}
                  successButtonClick={() => handleConfirmModal()}
                  closeButtonLabel={documateLabel.CONFIRM_NO.toUpperCase()}
                  successButtonLabel={documateLabel.CONFIRM_YES.toUpperCase()}
                  />
        </ErrorBoundary>
        </>
    );
}
export default RefiTodoList;