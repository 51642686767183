import React, { useEffect, useState } from "react";
import { useTable, useFilters, useSortBy, usePagination, useResizeColumns, useBlockLayout, useRowSelect, useGroupBy, useExpanded } from "react-table";
import { TextInput } from "../TextInput";
import './Grid.scss';
import {Icon} from '../../components/Icon'


type GridProps = {
  columns: any;
  data: any;
  hiddenColumns?: string[];
  sortDefault?: any[];
  showFilters?: boolean;
  highlightColumn?: string;
  selectedColumn?: (selected: boolean, columnValue: string) => void;
  id?:string,
  checkboxColumWidth?:string,
  groupByColumns?:string[],
  columnsGroupOn?:string[];
  disableSorting? : boolean;
  paginationDisabled? :boolean;
  zindexHeader?:number;
  grdTableContainerClass? : string;
  grdTabelClass? : string;
  retainGridState? : boolean;
  defaultPageSize? : number;
  grdFilterInputClassName? : string;
  grdThClassName? : string;
  onSelectionChange?: (count: number) => void;
  navigateToFirstPage? : boolean;
  onNavigateToFirstPage? : () => void;
};

export default React.forwardRef(function Grid({ columns, data, hiddenColumns =[], sortDefault, showFilters, highlightColumn, selectedColumn,id,checkboxColumWidth, groupByColumns = [], columnsGroupOn =[], disableSorting, paginationDisabled, zindexHeader, grdTableContainerClass, grdTabelClass, retainGridState, defaultPageSize, grdFilterInputClassName, grdThClassName, onSelectionChange, navigateToFirstPage, onNavigateToFirstPage}: GridProps, ref: React.Ref<any>) {
  const [filterInput, setFilterInput] = useState({});
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number | null>(null);
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null);
  const upArrow = <Icon icon='up-arrow' />
  const downArrow = <Icon icon='down-arrow' />
  const handleRowClick = (pageIndex: number, localIndex: number) => {
    const globalIndex = pageIndex * pageSize + localIndex;
    const originalRow = rows[globalIndex].original;
    const columnValue = originalRow[highlightColumn];

    if (clickedRowIndex === globalIndex) {
      setHighlightedRowIndex(null);
      setClickedRowIndex(null);
      if (selectedColumn) selectedColumn(false, "");
    } else {
      setHighlightedRowIndex(globalIndex);
      setClickedRowIndex(globalIndex);
      if (selectedColumn) selectedColumn(true, columnValue);
    }
  };

  const defaultColumn = React.useMemo(
    () => ({
      //  width:'100%'
      width: '155',
      // minWidth: 30,
      // width: 100,
      // maxWidth: 400
     // overflow:'visible'
    }),
    []
  );

  React.useImperativeHandle(ref, () => ({
    getSelectedRows: () => {
      const selectedRows = rows.filter((row) => row.isSelected);
      return selectedRows;
    },
  }));

  const updatedHiddenColumns = React.useMemo(() => {
    const shouldHideExpandedColumn  = groupByColumns.length ===0;
    return shouldHideExpandedColumn ? [...hiddenColumns, 'expanded'] : hiddenColumns;
  },[groupByColumns,hiddenColumns])

  const renderPagination = () => (
    <div className='paddingTo10'>
    <button className=' btn btn-primary btn-sm searchBtnMargin labelStyle' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      {"<<"}
    </button>{" "}
    <button className=' btn btn-primary btn-sm searchBtnMargin labelStyle' onClick={() => previousPage()} disabled={!canPreviousPage}>
      Previous
    </button>{" "}
    <button className=' btn btn-primary btn-sm searchBtnMargin labelStyle' onClick={() => nextPage()} disabled={!canNextPage}>
      Next
    </button>{" "}
    <button className=' btn btn-primary btn-sm searchBtnMargin labelStyle' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      {">>"}
    </button>{" "}
    <span className='labelStyle'>
      Page{" "}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{" "}
    </span>
    <span className='labelStyle'>
      | Go to page:{" "}
      <input
        type="number"
        defaultValue={pageIndex + 1}
        max={pageOptions.length}
        min={1}
        onChange={(e) => {
          const pageNumber = e.target.value
            ? Number(e.target.value) - 1
            : 0;
          gotoPage(pageNumber);
        }}
        className='labelStyle pagingSelect nopadding'
        style={{ width: "50px" }}
      />
    </span>{" "}
    <select
      className='labelStyle'
      value={pageSize}
      onChange={(e) => setPageSize(Number(e.target.value))}
    >
      {[10, 20, 50].map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          Show {pageSize}
        </option>
      ))}
    </select>
  </div>
  );

  const [gridState, setGridState] = useState({ pageIndex: 0, pageSize : defaultPageSize ? defaultPageSize : 10, hiddenColumns: updatedHiddenColumns, sortBy: sortDefault, groupBy:groupByColumns});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    resetResizing,
    setFilter,
    setGroupBy,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      initialState: gridState,
      autoResetSelectedRows: false,
    },
    useFilters,
    useResizeColumns,
    useBlockLayout,
    useGroupBy,
    !disableSorting && useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          
          id:'expanded',
          width:50,
          Header :  ({getToggleAllRowsExpandedProps}) => (
            <span {...getToggleAllRowsExpandedProps()}>
              {page.length ? (page[0].isExpanded? upArrow : downArrow ):null}
            </span>
          ),
          Cell: ({row}) => (
            row.canExpand? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? upArrow  : downArrow}
            </span>
            ) : null
          ),
        },
        {
          id: 'selection',
          width: checkboxColumWidth!==undefined?checkboxColumWidth:'50',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <input className="cstGridCheckBox" type="checkbox" {...getToggleAllRowsSelectedProps()}
                onClick={(event) => event.stopPropagation()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <input className="cstGridCheckBox" type="checkbox" {...row.getToggleRowSelectedProps()}
                onClick={(event) => event.stopPropagation()}
              />
            </div>
          ),
        },
        ...columns
      ])
    }
  );

  useEffect(() => {

    if (onNavigateToFirstPage){
      onNavigateToFirstPage();
      gotoPage(0);
    }

  },[navigateToFirstPage]);

  const handleFilterChange = e => {
    const { name, value } = e.target;
    setFilter(name, value);
    setFilterInput({
      ...filterInput,
      [name]: value,
    });
  };

  const { filters, sortBy, pageIndex, pageSize, selectedRowIds } = state;

  useEffect(() => {
    if (retainGridState) {
      setGridState((grdState) => {
        return {...grdState, ...state};
      });
    }
  }, [filters, sortBy, pageIndex, pageSize]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(Object.keys(selectedRowIds).length);
    }
  }, [selectedRowIds]);

  const rowsToDisplay = paginationDisabled? rows : page

  useEffect(() => {
    if (groupByColumns.length>0) {
      setGroupBy(groupByColumns)
    }
  },[setGroupBy, groupByColumns]
);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--table-header-height', showFilters ? '60px' : '30px')
  }, [showFilters]);

  useEffect(() => {
    setHighlightedRowIndex(null)
  }, [data]);
  return (
    <>
      {/* <div className="cstGrid container-fluid">
      <div className='row'>
        <div className='col-lg-12 col-ld-12 col-sm-12 col-sx-12'> */}


      <div className={`table-container ${grdTableContainerClass ? grdTableContainerClass : ''}`}>
        <table id={id} {...getTableProps()} className={`${grdTabelClass ? grdTabelClass : ''}`}>
          <thead>
          {/* style={{zIndex:zindexHeader}}> */}
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps( !disableSorting && column.getSortByToggleProps())}
                    className={(column.isSorted && !disableSorting
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : "") + (grdThClassName ? ' ' + grdThClassName : '')}

                  >
                    {column.render("Header")}

                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? "isResizing" : ""
                        }
`}
                      onClick={(event) => event.stopPropagation()} />
                    {showFilters && column.canFilter && (!(column as any)?.hideColumnFilter) ? (
                      <>
                        <div onClick={(event) => event.stopPropagation()}>
                          <TextInput
                            style={{ height: "30px" }}
                            className={grdFilterInputClassName ? grdFilterInputClassName : ''}
                            name={column.id}
                            value={filterInput[column.id] || ''}
                            onChange={handleFilterChange} />
                        </div>
                      </>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rowsToDisplay.map((row, localIndex) => {
              prepareRow(row);
              const globalIndex = paginationDisabled ? localIndex : pageIndex * pageSize + localIndex;
              const isHighlighted = globalIndex === highlightedRowIndex;
              return (
                <React.Fragment key = {row.id}>
                <tr
                  {...row.getRowProps()}
                  className={`
    ${row.isSelected && highlightColumn !== undefined ? "selected-row" : ""}  
    ${isHighlighted && highlightColumn !== undefined ? "highlighted-row" : ""}
  `}
                  onClick={(event) => {
                    // handleRowClick(i)
                    handleRowClick(pageIndex, localIndex);
                    event.stopPropagation();
                  }}
                >
                  {row.cells.map((cell) => {
                    const isColumnGroupByOn = columnsGroupOn.includes(cell.column.id);
                    const originalValue = row.subRows && row.subRows[0] && row.subRows[0].original[cell.column.id];
                    let tdProps = {};
                    if (cell 
                      && cell.row 
                      && cell.row.original 
                      && cell.column 
                      && cell.row.original['enableGridRowEditing'] 
                      && cell.column['CellEditTDProperties']) {
                      tdProps = cell.column['CellEditTDProperties'];
                    }

                   return (
                   <td {...cell.getCellProps()} {...tdProps}>
                    {/* <div 
                      style={{
                          zIndex: zindexHeader - (globalIndex +1),
                          position: "relative",
                          width: '100%',
                          boxSizing: 'border-box'
                      }}
                      > */}
                    {cell.column.id === 'expanded' ? ( 
                      cell.render("Cell")
                    ) : (
                      (row.canExpand ? ( 
                        isColumnGroupByOn ? originalValue:''
                      ) : ( 
                        isColumnGroupByOn ? '': cell.render("Cell")
                      )
                    ))}
                    {/* </div> */}
                    </td>
                   );
                  })}
                </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        {!paginationDisabled && renderPagination()}
      </div>
      {/* </div>
      </div>
    </div> */}
    </>
  );
})