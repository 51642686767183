import { configureStore, combineReducers, Action } from '@reduxjs/toolkit'
import ReduxThunk, { ThunkAction } from 'redux-thunk' 
import components from '../components/component-slice'
import breadcrumbs from '../../page-header/breadcrumbs/breadcrumbs-slice'
import formSavedSlice from '../components/formSaved-slice'
import userContextSlice from '../components/userContext-slice'
import customerSlice from '../../customer/customer-slice/Customer-slice'
import LookupState from '../../customer/customer-slice/Customer-lookup-slice'
import noteSlice from '../../customer/customer-slice/Customer-note-slice'
import eventState from './component-notification/ComponentNotification';
import CustomerSliderState from '../../customer/customer-slider-content/CustomerSliderContext'
// import user from '../../user/user-slice'
const reducer = combineReducers({
  breadcrumbs,
  // user, 
  components,
 formSavedSlice,
 userContextSlice,
 customerSlice,
 LookupState,
 eventState,
 CustomerSliderState,
 noteSlice
}) 

const store = configureStore({
  reducer,
  middleware: [ReduxThunk],
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export type RootState = ReturnType<typeof reducer>

export default store
