import React from "react"
import { Modal } from "../../components/components/Modal"

interface Props{
modaltitle:string,
showConfirmation?:boolean
setConfirmation?:(boolean) => void
body?:string,
closeButtonClick?:()=>void,
successButtonClick?:()=>void
closeButtonLabel:string,
successButtonLabel:string
showHeaderCloseButton?: boolean,
disableOkButton? : boolean
}
const ConfirmModal = (props: Props) => {
    const{
        modaltitle, 
        showConfirmation,
        setConfirmation,
        body,
        closeButtonClick,
        successButtonClick,
        closeButtonLabel,
        successButtonLabel,
        showHeaderCloseButton,
        disableOkButton
    }=props
    return(
<Modal
        verticallyCentered={true}
        title={modaltitle}
        show={showConfirmation}
        //backdrop={'static'}
        toggle={() =>{setConfirmation(!showConfirmation) }}
        showHeaderCloseButton={showHeaderCloseButton}

        body={body}
        closeButton={{
          size: "small",
          icon: 'cancel',
          iconStyle: { color: "gray" },
          outlined: true,
          color: 'link',
          onClick(){closeButtonClick()},
          tabIndex:62,
          children: <span style={{ color: "gray" }}>{closeButtonLabel.toUpperCase()}</span>
        }}
        successButton={{
          size: "small",
          icon: "check",
          iconStyle: { color: "177db8" },
          outlined: true,
          color: 'link',
          tabIndex:63,
          disabled : disableOkButton ? true : false,
          onClick(){successButtonClick()},
          children: <span style={{ color: "#177db8" }}>{successButtonLabel.toUpperCase()}</span>
        }}
        buttonsAlignment={"right"} />
    )
    }

    export { ConfirmModal }