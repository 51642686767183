import { Link } from "react-router-dom";
import { Checkbox, Icon } from "../../components";
import * as documateLabel from '../shared/util/docuMateLabel';
import DocuMateIndexing from "./DocuMateIndexing";

class DocuMateTodoListService {
    static async getTodoList() {
        // const todoList = await getTodoList();
        // return todoList;
    }

    public getTodoListGridColumns(onDeleteDocument: (id: any) => void, onHardLockDocument: (id: any) => void, handleOnHoldChange: (id: any) => void, handleDocumentClick: (id: any) => void) {
        let gridColumns = [
            {
              id: 'selectionTodoList',
              width: '50',
              Cell: ({ row }) => (
                <div>
                  <input className="cstGridCheckBox" type="checkbox" {...row.getToggleRowSelectedProps()}
                    onClick={(event) => event.stopPropagation()}
                  />
                </div>
              )
            },
            {
              Header: documateLabel.TODOLIST_GRID_LABELS.scanId,
              accessor: 'scanId',
              Cell: row => {
                return (
                    <div className="todoList-grd-cell">
                        <Link
                        to={{
                            pathname: '',
                            state: { scanId: row.row?.original?.scanId, id: row.row?.original?.id, docStatus: row.row?.original?.isWorking ? 'working' : row.row?.original?.isHardLocked ? 'hardLocked' : row.row?.original?.canHardLock ? 'softLocked' : '' }                            
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          handleDocumentClick(row.row?.original?.id)}
                        }
                          title="row.value"
                        style={{ marginRight: '0.5em' }}
                        ><span title={row.row?.original?.scanId}>{row.row?.original?.scanId}</span></Link>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                          (() => {
                              const data = row.row?.original;
                              switch (true) {
                                  case data?.isWorking:
                                      return <Icon icon="userPen" className='grd-cell-icon--working' />;
                                  case data?.canHardLock:
                                      return <Icon icon="lock" className='grd-cell-icon--lock' onClick={() => onHardLockDocument(data?.id)}/>;
                                  case data?.canDelete:
                                      return <Icon icon="trash" className='grd-cell-icon--delete' onClick={() => onDeleteDocument(data?.id)}/>;
                                  case data?.isHardLocked:
                                      return <Icon icon="lock" className='grd-cell-icon--hardlock' />;
                                  default:
                                      return null;
                              }
                          })()
                      }
                        </div>
                    </div>
                )
              },
              width: 260,
              sortType: 'string'
            },
            {
              Header: documateLabel.TODOLIST_GRID_LABELS.onHold,
              accessor: 'onHold',
              Cell: row => {
                return (
                  <div className='todoList-grd-cell--checkbox'>
                  <Checkbox
                    id={`onHoldCheckbox-${row.row?.original?.id}`}
                    label={""}
                    checked={row.row.original?.onHold ? true : false}
                    onChange={(event) => handleOnHoldChange(row.row?.original?.id)}            
                  />
                  </div>
                )
              },
              width: 75
            },
            {
              Header: documateLabel.TODOLIST_GRID_LABELS.fileName,
              accessor: 'fileName',
              Cell: row => {
                return (
                  <div className='todoList-grd-cell'>
                    <span title={row.row?.original?.fileName}>{row.row?.original?.fileName}</span>
                  </div>
                )
              },
              width: 200
            },
            {
                Header: documateLabel.TODOLIST_GRID_LABELS.CS2Id,
                accessor: 'cs2Id',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.cs2Id}>{row.row?.original?.cs2Id}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.AdvId,
                accessor: 'advId',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.advId}>{row.row?.original?.advId}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.policyNumber,
                accessor: 'policyNo',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.policyNumber}>{row.row?.original?.policyNumber}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.docDescription,
                accessor: 'docDescription',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.docDescription}>{row.row?.original?.docDescription}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.fromUser,
                accessor: 'fromUser',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.fromUser}>{row.row?.original?.fromUser}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.fromFolder,
                accessor: 'fromFolder',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.fromFolder}>{row.row?.original?.fromFolder}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.redirectReason,
                accessor: 'redirectReason',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.redirectReason}>{row.row?.original?.redirectReason}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.dateRedirected,
                accessor: 'dateRedirected',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.dateRedirected}>{row.row?.original?.dateRedirected}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.dateInitiated,
                accessor: 'dateInitiated',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.dateInitiated}>{row.row?.original?.dateInitiated}</span>
                    </div>
                  )
                },
                width: 200
              },
              {
                Header: documateLabel.TODOLIST_GRID_LABELS.dateAvailable,
                accessor: 'dateAvailable',
                Cell: row => {
                  return (
                    <div className='todoList-grd-cell'>
                        <span title={row.row?.original?.dateAvailable}>{row.row?.original?.dateAvailable}</span>
                    </div>
                  )
                },
                width: 200
              }
          ]
        return gridColumns;
    }

}

export default DocuMateTodoListService;