import { AxiosResponse } from "axios";
import { client } from "../shared/services/client";
import { LookupData } from "../Models/LoookupData";
import  * as tblConsts from "../shared/util/tableConstant";
import { AgencyDto } from "../Models/AgencyDto";

class LookupService
{
    static fetchClaimStatusList = async () : Promise<LookupData[]> => 
    {
        return await LookupService.getLookupData(tblConsts.CLAIM_STATUS_TABLE_ID);
    }
    
    static fetchLossTypeList = async () : Promise<LookupData[]> => 
    {
        return await LookupService.getLookupData(tblConsts.LOSS_TYPE_TABLE_ID);
    }

    static fetchTaskPriorityList = async () : Promise<LookupData[]> => {
        return await LookupService.getLookupData(tblConsts.TASK_PRIORITY_TABLE_ID);
    }

    static fetchTaskStatusList = async () : Promise<LookupData[]> => {
        return await LookupService.getLookupData(tblConsts.TASK_STATUS_TABLE_ID);
    }

    static fetchTaskTypeList = async () : Promise<LookupData[]> => {
        return await LookupService.getLookupData(tblConsts.TASK_TYPE_TABLE_ID);
    }
        
    static fetchMaritalStatuses = async () : Promise<LookupData[]> => 
    {
        return await LookupService.getLookupData(tblConsts.MARITAL_STATUS_TABLE_ID);
    }

    static fetchIndustries = async () : Promise<LookupData[]> => 
    {
        return await LookupService.getLookupData(tblConsts.INDUSTRIES_TABLE_ID);
    }

    static fetchOccupancies = async () : Promise<LookupData[]> => 
    {
        return await LookupService.getLookupData(tblConsts.OCCUPANCIES_TABLE_ID);
    }

    static fetchRelationships = async () : Promise<LookupData[]> => 
    {
        return await LookupService.getLookupData(tblConsts.RELATIONSHIP_TABLE_ID);
    }

    static fetchState = async () : Promise<LookupData[]> => 
        {
            return await LookupService.getLookupData(tblConsts.STATE_TABLE_ID);
        }

    // static fetchAgencies = async () : Promise<AgencyDto[]> => 
    // {
    //     client.setBaseURLForService("admin");
    //     const config ={};
    //     const res: AxiosResponse<LookupData[]> =  await client.get(`/api/Agencies`, config);
    //     return res.data;
    // }

    static fetchAgencies = async () : Promise<LookupData[]> => 
        {
            return await LookupService.getLookupData(tblConsts.AGENCY_TABLE_ID);
        }

    static getLookupData = async (tableId : string) : Promise<LookupData[]> => 
    {
        client.setBaseURLForService("admin")
        const config ={}
        const res: AxiosResponse<LookupData[]> =  await client.get(`/api/LookupDetail/GetAllLookupDetails?tableId=${tableId}`, config);
        return res.data;
    }
}

export default LookupService;