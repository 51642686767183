import moment from 'moment';
import * as constant from '../../shared/util/constant';

export default class commonFunctions {
    ValidateMaxDate = (date : Date) =>{
        let maxYear = constant.MAX_YEAR;
        let dateYear = moment(date, "DD/MM/YYYY").year();
        if(dateYear > maxYear ){
            return false;
        }
        else
        return true;
    }
}