import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomerNoteRead } from "../model/CustomerNoteReadDto";

interface NoteState{
    notes:CustomerNoteRead[]
}
const initialState : NoteState = {
    notes:[]
}

const noteSlice = createSlice({
    name : "notes",
    initialState,
    reducers : {
        updateNoteList(state, action: PayloadAction<CustomerNoteRead[]>){
           const notes= action.payload;
            state.notes = notes;
          
        }
    }
})

export const { updateNoteList } = noteSlice.actions;
export default noteSlice.reducer;