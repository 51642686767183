import { createContext } from "react";

const SliderContext = createContext({
    isSliderVisible : false,
    isSliderPinned : false,
    detachSlider : true,
    showSlider : () => {},
    hideSlider : () => {},
    pinSlider : () => {},
    unpinSlider : () => {}
});

export default SliderContext;