import { useQuery } from 'react-query'

import {getBlackoutDateList, getCampaignEventList, getCampaignCalendarList}  from '../services/CampaignAPI/CalendarAPI'
//import Customer from '../model/Customer'
 //import Campaign from '../model/CampaignList'

// function fetchIncidents(_: string, searchRequest: CustomerFilterRequest): Promise<Customer[]> {
//   return filterCustomers(searchRequest)
// }


export function GetBlackoutDateList() {
  return useQuery(['BlackoutDate'], getBlackoutDateList)
 // return getBlackoutDateList;
   //return {dateList:getBlackoutDateList,isLoading:false}
}

export function GetCampaignEventList(fromDate: string, toDate: string) {
  return useQuery(['id', fromDate, toDate], () => getCampaignEventList(fromDate, toDate));
   //return {data:testCustomers,isLoading:false}
}

export function GetCampaignCalendarList(name:string) {
  return useQuery([name], getCampaignCalendarList)
   //return {data:testCustomers,isLoading:false}
}


