import CampaignList from '../model/CampaignList'
import {getAllCampaignList}  from '../services/CampaignAPI'
async function getAllTemplateListUtils() {
    var response = await getAllCampaignList();
    //console.log('utils',response)
    return response;
}

export const getAllTemplates = async (): Promise<CampaignList[]> => {
    var response=await getAllTemplateListUtils();
    return response;
}