import moment from "moment";
import { DataTableDefaultCellTemplates, DataTableEventArgs, Icon, ITableColumn } from "../../components";
import Logger from "../../shared/services/Logger";
import { CustomerLossHistoryConstant } from "../../shared/util/constant";
import { fetchCustomerLossHistory } from "../services/CustomerAPI";
import { CustomerLossReadDto } from "../model/CustomerLossDto";
import { IconType } from "../../components/components/Icon/interfaces";

class CustomerLossService {
    private dateFormat = "MM/DD/YYYY";

    public getLossHistoryTableColumns = (onRowEdit : (rowData : any, column : ITableColumn) => void, onRowDelete : (rowData : any, column : ITableColumn) => void) : ITableColumn[] => {
        const tableHeaders : ITableColumn[] = [
            { 
                width : '5%',
                maxWidth : '5%',
                cellTemplate : DataTableDefaultCellTemplates.RowExpander.CellTemplate,
                cellDataChangeHandler : DataTableDefaultCellTemplates.RowExpander.CellTemplateEventHandler,
                cellTdClassName : 'expander-cell-td'
            },
            {
                width : '20em',
                maxWidth : '20em', 
                header : CustomerLossHistoryConstant.COMPANY_COLUMN_HEADER,
                field : "company"
            },
            {
                width : '20em',
                maxWidth : '20em', 
                header : CustomerLossHistoryConstant.DATE_OF_LOSS_COLUMN_HEADER,
                field : "dateOfLoss",
                cellDataFormatter : (dateOfLoss) => moment(dateOfLoss).format(this.dateFormat)
            },
            {
                width : '20em',
                maxWidth : '20em', 
                header : CustomerLossHistoryConstant.STATUS_COLUMN_HEADER,
                field : "claimStatusValue"
            },
            {
                width : '20em',
                maxWidth : '20em', 
                header : CustomerLossHistoryConstant.KIND_OF_LOSS_COLUMN_HEADER,
                field : "lossTypeValue"
            },,
            {
                width : '5em',
                maxWidth : '5em', 
                cellTemplate : DataTableDefaultCellTemplates.EditCellTemplate.CellTemplate,
                cellClickHandler : (dtEventArgs : DataTableEventArgs) => onRowEdit(dtEventArgs.rowData, dtEventArgs.column)
            },
            {
                width : '5em',
                maxWidth : '5em', 
                cellTemplate : DataTableDefaultCellTemplates.DeleteCellTemplate.CellTemplate,
                cellClickHandler : (dtEventArgs : DataTableEventArgs) => onRowDelete(dtEventArgs.rowData, dtEventArgs.column)
            }];

        return tableHeaders;
    }

    public fetchCustomerLosess = async (customerId : string) : Promise<CustomerLossReadDto[]> => {
        try {
            let lossData = await fetchCustomerLossHistory(customerId);
            lossData.forEach(item => {
                if (item.dateOfLoss) {
                    item.dateOfLoss = new Date(item.dateOfLoss);
                }

                if (item.closedDate) {
                    item.closedDate = new Date(item.closedDate);
                }

                if (item.reportDate) {
                    item.reportDate = new Date(item.reportDate);
                }

                if (item.policyEffDate) {
                    item.policyEffDate = new Date(item.policyEffDate);
                }

                if (item.policyExpDate) {
                    item.policyExpDate = new Date(item.policyExpDate);
                }
            })
            return lossData;
        }
        catch(ex) {
            if (ex.status == 404) {
                Logger.logWarning(`No data found for customer ${customerId}`, ex);
                return [];
            }

            throw ex;
        }
    }
};

export default CustomerLossService;