import React, { useState , useEffect, useCallback} from 'react'
import { Card, Collapse} from 'react-bootstrap'
import {Select, Button, Label,Row,Column} from '../../components'
import { SelectOption } from '../../shared/components/input/SelectOption'
import { ColorVariant } from '../../components/interfaces'
import { Icon } from '../../components/components/Icon'
import { Navbar} from  '../../../src/components' 
import { useHistory } from 'react-router-dom'
import CustomerStandardFilterForm from './CustomerStandardSearch'
import CustomerAdvanceSearchForm from './CustomerAdvanceSearch'
import SaveFilter from './SaveFilter'
import CustomerFilterRequest from '../model/CustomerFilterRequest'
import { ErrorBoundary } from 'react-error-boundary'

interface Props {
  /** Defines the color of the panel */
  color?: ColorVariant
  /** The body for the panel */
  children?: React.ReactNode
  /** The title for the panel */
  title?: string
  /** The footer for the panel */
  footer?: string
  /** Determines if the panel can be collapsible */
  collapsible?: boolean
  /** Determines if the panel should be collapsed */
  collapsed?: boolean
  /** The className for the panel card parent element */
  className?: string
    selectId?:string
  //  onSearch?:(filterRequest: CustomerFilterRequest) => void
  //  onAllowingSearch?:(isSearchingAllowed: boolean) => void
}

const SearchPanel = (props: Props) => {
 
  const { color, children, footer, title, collapsible, collapsed, className} = props
  const [open, setOpen] = useState(!collapsed || !collapsible)
  const [SearchOption, setsearchOptions] = useState<SelectOption[]>([{ label: "", value: "" }]);

  const [StandardSearch, SetStandardSearch]=useState(true)
  const [AdvanceSearch, SetAdvanceSearch]=useState(false)
  const [isStandardSearchActive, setStandardActive] = useState(true);
  const [isAdvanceSearchActive, setAdvanceActive] = useState(false);
  const [saveFilterModal, setSaveFilterModal] = useState(false);

  const history = useHistory()
  const navigateTo = (location: string) => {
    history.push(location)
  }

  useEffect(() => {

  },[AdvanceSearch,StandardSearch])
const setAdvanceSearchForm=(status: boolean)=>{
 SetAdvanceSearch(status)
  SetStandardSearch(!status)
  setStandardActive(!status)
  setAdvanceActive(status)

}

const setStandardSearchForm=(status: boolean)=>{
  /* TODO : No action need to be taken, as currently only Standard Search is visible on UI. Once we enable advance search, disable below code.
  SetAdvanceSearch(!status)
   SetStandardSearch(status)
   setStandardActive(status)
  setAdvanceActive(!status)
  */
 }

 const saveFilter=()=> {
  setSaveFilterModal(true);
    }

    const handleClose =() => {
      setSaveFilterModal(false);
    }
  

//  const [showGrid, setShowGrid] = useState(false);
//  const [filter, setfilter] = useState<CustomerFilterRequest>();


//  const onSearch = useCallback((newFilter: CustomerFilterRequest) => {
//   console.log('Search Panel')
//    setfilter(newFilter)
//  }, [])

//  const onAllowingSearchChange = useCallback((showGrid: boolean) => {
//   console.log('Search Panel')
//    setShowGrid(showGrid);
//  }, [])
const FallbackComponent = ({ error, resetErrorBoundary }) => {
  console.log("An error occurred:");
  console.log(error.message);
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

  const collapseIcon = (
    <span style={{ float: 'left', cursor: 'pointer', borderBottom:'gray solid' }} className='col-lg-12, col-md-12'>
       <Row style={{marginBottom:'0px'}}>
         <Column md={2} lg={2}>
         {/* <Icon
        icon={open ? 'up-arrow' : 'down-arrow'}
        onClick={() => setOpen(!open)}
        aria-controls="collapse-body"
        aria-expanded={open}
      />&nbsp; */}
      <Label text='Customer Search' title="Customer Search" className='labelStyle labelPanelHeading noPaddingLeft' />
      
         </Column>
         
      <Column md={3} lg={3}>
      {/* <Select
        options={SearchOption}
        id="ddlSearchFilter"
        inputProps={{ className: 'inputStyle', tabIndex:'1' }}
        tabIndex='1'
        /> */}
      </Column>
 <Column md={1} lg={1} className='nopadding'>
  {/* <Button onClick={saveFilter} className='btn-primary searchBtnMargin labelStyle' icon='save' iconLocation='left' >Save</Button> */}
  </Column>
 <Column md={1} lg={1} className='nopadding'>
  {/* <Button className='btn-light-grey searchBtnMargin labelStyle' icon='trash' iconLocation='left' >Delete</Button> */}
  </Column>
        
        <Column md={5} lg={5}>
        <Row>
        <Column md={4} lg={4}>&nbsp;</Column>
          <Column md={8} lg={8}>
          {/* <Tabs
            id="controlled-tab-example"
            className="lg-12"
          >
            <Tab eventKey="Standard" title="Standard Searcg" >
            <CustomerStandardFilterForm/>
            </Tab>
            <Tab eventKey="Advance" title="Advance Search">
              <CustomerAdvanceSearchForm />
            </Tab>
    </Tabs> */}
            <Navbar 
            navItems=
            {
              [
                
                {
                  type: 'link',
                  alignRight: false,
                  className:isStandardSearchActive?'nav-add-new d-none d-md-block highlightTab':'nav-add-new d-none d-md-block normalTab',
                  label: 'Standard Search',
                  name: 'standard',
                  size: 'sm',
                  onClick:()=>{
                  //  navigateTo('#')
                  setStandardSearchForm(true)
                  }                
                                      }
                /* disabled advance serach for now.
                 ,
                 {
                   type: 'link',
                   alignRight: false,
                   children: null,
                   className: isAdvanceSearchActive?'nav-add-new d-none d-md-block highlightTab':'nav-add-new d-none d-md-block normalTab',
                   label: 'Advance Search',
                   name: 'advance',
                   size: 'sm',
                   onClick:()=>{
                     //  navigateTo('#')
                    setAdvanceSearchForm(true)
                     }
                 },
                 */
              ]
            }
            />
          </Column>
          {/* <Column md={3} lg={3} style={{padding:'0',textAlign:'right'}}><Label text='Advance Search' /></Column>
          <Column md={3} lg={3} style={{padding:'0',textAlign:'right'}}><Label text='Standard Search'/></Column> */}
          </Row>
          
          
        </Column>
        
        
        </Row>
    </span>
  )

  return (
    <>
    {/* <SaveFilter showSaveFilter={saveFilterModal} handleClose={handleClose} /> */}
    <Card className={className} border={color}>
      {/* {title && (
        <Card.Header
          style={collapsible ? { cursor: 'pointer', textAlign: 'left' } : { textAlign: 'left' }}
          onClick={() => collapsible && setOpen(!open)}
        >
          {title}
          {collapsible && collapseIcon}
        </Card.Header>
      )} */}
      <Card.Body style={{ textAlign: 'left' }}>
        {collapsible && !title && collapseIcon}
        {/* <Collapse in={open}>
          <div id="collapse-body">
            {children}
            
              {StandardSearch && <CustomerStandardFilterForm />}
              {AdvanceSearch && <CustomerAdvanceSearchForm />}
              <br />
            
          </div>
        </Collapse> */}
        {StandardSearch && <CustomerStandardFilterForm  navigateTo={navigateTo}/>}
      </Card.Body>
      {footer && (
        <Card.Footer style={{ textAlign: 'left', fontSize: 'smaller' }}>{footer}</Card.Footer>
      )}
    </Card>
    </>
  )
}
 export default SearchPanel 
