import { useQuery } from 'react-query'
import NoteDetail from '../model/NotesDetail'

import { saveNote, getNoteByQueryID, deleteNoteByID, updateNote, getHistoryByIdType } from '../service/NoteAPI'

class NotesUtils {
  private async saveNotes(noteDetail: NoteDetail) {
    var response = await saveNote(noteDetail)
    return response
  }

  private async updateNotes(noteDetail: NoteDetail) {
    var response= await updateNote(noteDetail)
    return response
  }
  private async getAllNotesbyId(queryId: string) {
    var response= await getNoteByQueryID(queryId)
    return response
  }

  private async deleteNote(queryId: string) {
    var response= await deleteNoteByID(queryId)
    return response
  }

  private async notesHistory(entityId: string) {
    var response= await getHistoryByIdType(entityId)
    return response
  }

  SaveNoteDetails = async (noteDetail: NoteDetail): Promise<NoteDetail> => {
    var response = await this.saveNotes(noteDetail)
    return response
  }

  UpdateNotesDetail= async(noteDetail: NoteDetail): Promise<NoteDetail> => {
    var response = await this.updateNotes(noteDetail)
    return response
  }

  GetNoteByQueryId=async(queryId: string): Promise<NoteDetail[]> => {
    var response = await this.getAllNotesbyId(queryId)
    return response
  }

  DeleteNoteById=async(id: string): Promise<NoteDetail> => {
    var response = await this.deleteNote(id)
    return response
  }
 
GetHistoryByIdType=async(id: string): Promise<NoteDetail> => {
  var response = await this.notesHistory(id)
  return response
}

}

export const notesUtils = new NotesUtils();

