import { TextInput, Label, Row, Column } from '../../../../components'
import React, { CSSProperties } from 'react'
import FormattedInputService from './FormattedInputService'

export enum InputFormatType {
  Phone
}


interface FormattedInputWithLableInlineProps {
  value: string
  label?: string
  name: string
  isEditable?: boolean
  type: 'text' | 'email' | 'number' | 'tel' | 'password'
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onValueChange?: (newVal: string) => void
  isRequired?: boolean
  feedback?: string
  isInvalid?: boolean
  className?:string
  labelClass?:string
  maxLength?:number
  tabIndex?:number
  lglabel?:number,
  mdlabel?:number,
  lgText?:number,
  mdText?:number,
  isEmpty?:boolean
  isTooShort?:boolean
  isTooLong?:boolean,
  isSame?:boolean
  errorText?:string
  invalidWarning?:string,
  textInputContainerClass? : string,
  inputFormatType : InputFormatType
  inputstyle?: CSSProperties
}


 const labelStyle={
  //  fontSize:'small'

 }
const FormattedInputWithLableInline = (props: FormattedInputWithLableInlineProps) => {
  const {
    value,
    label,
    name,
    isEditable,
    onValueChange,
    placeholder,
    type,
    isRequired,
    feedback,
    isInvalid,
    className,
    labelClass,
    maxLength,
    tabIndex,
    lglabel,
    mdlabel,
    lgText,
    mdText,
    isEmpty,
    isTooShort,
    isTooLong,
    isSame,
    errorText,
    invalidWarning,
    textInputContainerClass,
    inputFormatType,
    inputstyle
  } = props;
  const id = `${name}TextInput`;
  const formattedInputService = new FormattedInputService();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch(inputFormatType) {
      case InputFormatType.Phone:
        onValueChange(formattedInputService.getRawPhoneNumber(e.target.value));
        break;
      default:
        break;
    }
  };

  let formattedValue = value;
  switch(inputFormatType) {
    case InputFormatType.Phone:
      formattedValue = formattedInputService.formatPhoneNumber(value);
      break;
    default:
      break;
  }

  return (
    <Row >
     {label &&<Column lg={lglabel} md={mdlabel} style={{padding:0,borderBottom:0}}> <Label text={label} htmlFor={id} isRequired={isRequired} className={labelClass} style={labelStyle}/></Column>}
     <Column lg={lgText} md={mdText} className={`noPaddingLeft ${textInputContainerClass}`}>
      <TextInput
          feedback={feedback}
          id={id}
          isInvalid={isInvalid}
          value={formattedValue}
          disabled={!isEditable}
          onChange={handleChange}
          type={type}
          placeholder={placeholder || label}
          className={className}
          style={inputstyle}
          maxLength={maxLength}
          tabIndex={tabIndex}
          isEmpty={isEmpty}
          isTooShort={isTooShort}
          isTooLong={isTooLong}
          isSame={isSame}
          errorText={errorText}
          invalidWarning={invalidWarning}
          
        />
     </Column>
      
     
    </Row>
  )
}

export default FormattedInputWithLableInline;
