import CampaignDashboardSendonDate from "../model/CampainDashboardSendonDate";
import { campaignDashboardAPI } from "../services/CampaignAPI/CampaignDashboard";

class CampaignDashboardUtils {
    private async GetCampaignDataonSendonDateUtil() {
        var response = await campaignDashboardAPI.getCampaignDataonSendonDate()
        return response
    }

    GetCampaignDataonSendonDate = async (): Promise<CampaignDashboardSendonDate[]> => {
        //const[templateDetail, setTemplateDetail]=useState<ExternalTemplate>(null)
        var response = await this.GetCampaignDataonSendonDateUtil();
        return response
    }
}
export const campaignDashboardUtils = new CampaignDashboardUtils();