import {AxiosRequestConfig, AxiosResponse} from 'axios'
import { client } from '../../../shared/services/client'
import CalendarEvent from '../../model/Calendar'
import BlackoutDates from '../../model/BlackoutDates'
import CampaignList from '../../model/CampaignList';



export const getBlackoutDateList = async (): Promise<BlackoutDates[]> => {
   
    const res: AxiosResponse<any> =  await client.get<BlackoutDates[]>("http://localhost:5000/BlackoutDates");
    return res.data;
  
  };

  export const getCampaignEventList = async (fromDate: string, toDate: string): Promise<any> => {
    client.setBaseURLForService("campaign");
    const res: AxiosResponse<any> =  await client.get<any>("/api/Campaign/GetCampaignsForCalendar?fromDate=" + fromDate + "&toDate=" + toDate);
    return res.data;
  };

  export const getCampaignCalendarList = async (name): Promise<CampaignList[]> => {
    //const [_, name] = queryKey;
   // alert(name)
        const res: AxiosResponse<CampaignList[]> =  await client.get<CampaignList[]>("http://localhost:5000/CampaignList?campaignName="+name);
    return res.data;
  
  };
  // export async function getCampaignCalendarList(name:string){
  //   //console.log('API '+id);
  //   //console.log('API '+type);
  //   const res: AxiosResponse<any> =  await client.get("http://localhost:5000/CampaignList?campaignName="+name);
  //   //console.log(res.data)
  //   return res;
  // }

  
  
    