import { LookupData } from "../../../Models/LoookupData";
import LookupService from "../../../Services/LookupService";
import { SelectOption } from "../../../shared/components/input";

import * as constant from '../../../shared/util/constant';
import { createCustomer, fetchCustomer, updateCustomer } from "../services/CustomerAPI";
import { CommonUtility } from "../../../Services/CommonUtility";
import validator from 'validator';
import EmployeeSetupModel from "../../model/EmployeeSetupModel";
import { userSetupAPI } from "../../Services/AdminAPI/UserSetupAPI";
import UserDetailModel from "../../model/UserDetailModel";

class EmployeeSetupService
{
    public saveEmployee = async (employeeSetup : EmployeeSetupModel, update : boolean) : Promise<string> => {
        // let custToUpdate = this.getCustomerToUpdate(customerData);
        let apiResponse : string = null;
        if (update) {
            apiResponse = await userSetupAPI.updateEmployee(employeeSetup);
        }
        else {
            apiResponse = await userSetupAPI.createEmployee(employeeSetup);
        }
        
        return apiResponse;
    }

    public SaveUser = async (userDetail : UserDetailModel) : Promise<string> => {
        // let custToUpdate = this.getCustomerToUpdate(customerData);
        let apiResponse : string = null;
            apiResponse = await userSetupAPI.createUser(userDetail);
        return apiResponse;
    }

    public validateEmployee = (employeeSetup : EmployeeSetupModel) : any => {
        let validationErrors = {};

        if (!employeeSetup) {
            validationErrors['rootObject'] = {isValid : false, warningText : constant.CUSTOMER_SPECIFY_CUSTOMER_DETAILS }
        }

        if ((!employeeSetup.firstName || employeeSetup.firstName?.trim().length == 0)) {
            validationErrors['firstName'] = {isValid : false, warningText :  constant.ADMIN_EMPLOYEE_MANDATORY};
        }

        if ((!employeeSetup.lastName || employeeSetup.lastName?.trim().length == 0)) {
            validationErrors['lastName'] = {isValid : false, warningText : constant.ADMIN_EMPLOYEE_MANDATORY };
        }

        if ((!employeeSetup.email || employeeSetup.email?.trim().length == 0)) {
            validationErrors['email'] = {isValid : false, warningText : constant.ADMIN_EMPLOYEE_MANDATORY };
        }


       

        if ((!employeeSetup.legacyEmpCode || employeeSetup.legacyEmpCode?.trim().length == 0)) {
            validationErrors['legacyEmpCode'] = {isValid : false, warningText : constant.ADMIN_EMPLOYEE_MANDATORY };
        }

        if (!employeeSetup.agencyId || employeeSetup.agencyId?.length == 0) {
            validationErrors['agencyId'] = {isValid : false, warningText : constant.CUSTOMER_AGENCY_MANDATORY };
        }
        if (!employeeSetup.employeeStatusId || employeeSetup.employeeStatusId?.length == 0) {
            validationErrors['employeeStatusId'] = {isValid : false, warningText : constant.CUSTOMER_AGENCY_MANDATORY };
        }
        if (employeeSetup.firstName?.trim().length > 0 && employeeSetup.firstName?.trim().length < 2) {
            validationErrors['firstName'] = {isValid : false, warningText : constant.CUSTOMER_FIRST_NAME_MIN_LENGTH };
        }

        if (employeeSetup.lastName?.trim().length > 0 && employeeSetup.lastName?.trim().length < 2) {
            validationErrors['lastName'] = {isValid : false, warningText : constant.CUSTOMER_LAST_NAME_MIN_LENGTH };
        }
        if (employeeSetup.legacyEmpCode?.trim().length > 0 && employeeSetup.legacyEmpCode?.trim().length < 2) {
            validationErrors['legacyEmpCode'] = {isValid : false, warningText : constant.CUSTOMER_LAST_NAME_MIN_LENGTH };
        }
        

        if (!employeeSetup.agencyId || employeeSetup.agencyId?.length == 0) {
            validationErrors['agencyId'] = {isValid : false, warningText : constant.CUSTOMER_AGENCY_MANDATORY };
        }


        if (employeeSetup.email?.trim().length > 0 && !validator.isEmail(employeeSetup.email?.trim())) {
            validationErrors['eMail'] = {isValid : false, warningText : constant.CUSTOMER_INVALID_EMAIL };
        }

        
        if (employeeSetup.workPhone && employeeSetup.workPhone?.trim().length <10) {
            validationErrors['workPhone'] = {isValid : false, warningText : constant.CONTACT_NO_RANGE };
        }


        return validationErrors;
    }
}

export default EmployeeSetupService;