import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { CommonUtility } from "../../Services/CommonUtility";
import LookupService from "../../Services/LookupService";
import { CustomerTaskDtoReadExtended } from "../model/CustomerTaskDtoRead";
import TaskApi from "../services/TaskApi";
import CommonDataService from "../../Services/CommonDataService";
import { TaskScreenLables } from "../../shared/util/label";
import { CustomerFilterViewMoel } from "../model/CustomerFilterViewModel";
import { GridDefaultCellTemplates } from "../../components/components/Grid/grid-template";
import { SelectOption } from "../../shared/components/input";
import { Link } from "react-router-dom";
import { Icon, TextInput } from "../../components";
import { LookupDetailConstants } from "../../shared/util/LookupDetailConstants";
import { IconType } from "../../components/components/Icon/interfaces";
import moment from "moment";
import { DATA_IDs } from "../../shared/util/constant";

class CustomerTaskViewService {
    
    public getTaskHistory = async (taskId : string) => {
        const taskApi  = new TaskApi();
        var history =  await taskApi.getTaskHistory(taskId);
        return history.map((item) => {
            let chnDate = moment(item.changedDate).format("MM/DD/YYYY, hh:mm:ss a") + " ET";
            return {
              ...item,
              changedDate: chnDate,
              combinedKey : `${chnDate}  |  ${item.changedBy}`
            };
          });
    }

    public getHistoryGridColumns = () => {
        return [
            {
                Header: TaskScreenLables.dateTime,
                accessor: 'changedDate',
                width:250
            },
            {
              Header: TaskScreenLables.field,
              accessor: 'fieldName',
              Cell: row => <div><span title={row.value}>{row.value}</span></div>,
              width:250
            },
            {
              Header: TaskScreenLables.oldValue,
              accessor: 'oldValue',
              Cell: row => <div><span title={row.value}>{row.value}</span></div>,
              width:300
            },
            {
              Header: TaskScreenLables.newValue,
              accessor: 'newValue',
              Cell: row => <div><span title={row.value}>{row.value}</span></div>,
              width:300
            },
            {
              Header: TaskScreenLables.user,
              accessor: 'changedBy',
              Cell: row => <div><span title={row.value}>{row.value}</span></div>,
              width:250
            }
          ];
    }

    public getTasks = async (customerId : string, taskTypeId : string, taskStatusId : string, taskPriorityId : string) : Promise<CustomerTaskDtoReadExtended[]> => {
        const taskApi  = new TaskApi();

        let typeId = null;
        if (taskTypeId && taskTypeId.trim().length > 0) {
            typeId = taskTypeId;
        }

        let statusId = null;
        if (taskStatusId && taskStatusId.trim().length > 0) {
            statusId = taskStatusId;
        }

        let priorityId = null;
        if (taskPriorityId && taskPriorityId.trim().length > 0) {
            priorityId = taskPriorityId;
        }

        // Do not pass user id while searching task for customer. 
        let tasks = await taskApi.fetchTasks(customerId, typeId, statusId, priorityId, null);

        tasks.forEach(item => {
            this.enrichCalculatedFields(item);
        });

        return tasks;
    }

    public enrichCalculatedFields = (itemRow : CustomerTaskDtoReadExtended) => {
        itemRow.dueDate = CommonUtility.getLocalDateFromDateOnly(itemRow.dueDate);
        itemRow.dueDaysFormatted = CommonUtility.formateDate(itemRow.dueDate);
        itemRow.dueDaysDetail = itemRow.dueDays ? `in ${itemRow.dueDays} days` : (itemRow.dueDays == 0 ? 'Today' : '');

        itemRow.completionDaysFormatted = CommonUtility.formateDate(itemRow.completedDate);
        itemRow.completionDaysDetail = itemRow.completionDays ? `${Math.abs(itemRow.completionDays)} days` : (itemRow.completionDays == 0 ? 'On same day' : '');

        itemRow.descriptionShort = itemRow.description;
        if (itemRow.description?.length > 50) {
            itemRow.descriptionShort = CommonUtility.truncateString(itemRow.description, 50);
        }
    }

    public getTaskPriorityList = async () => {
        let taskPriorityList = await LookupService.fetchTaskPriorityList();
        let options = CommonUtility.mapToSelectOptions(taskPriorityList);
        return options;
    }

    public getTaskStatusList = async () => {
        let taskStatusList = await LookupService.fetchTaskStatusList();
        let options = CommonUtility.mapToSelectOptions(taskStatusList);
        return options;   
    }

    public getTaskTypeList = async () => {
        let taskTypeList = await LookupService.fetchTaskTypeList();
        let options = CommonUtility.mapToSelectOptions(taskTypeList);
        return options;
    }

    public getEmployeeList = async () => {
        let empList = await CommonDataService.getEmployeeList();
        let options = CommonUtility.mapCommonDataDtoToSelectOptions(empList);
        return options;   
    }

    public getCompanyList = async () => {
        let empList = await CommonDataService.getCompanyList();
        let options = CommonUtility.mapCommonDataDtoToSelectOptions(empList);
        return options;   
    }

    private TaskGridEditRowColumn  = {
        
        CellTemplate : (cell : any, canUserEditCustomerTask : any) => {
          let disableButton = false;
          if (cell?.row?.original?.isGridInEditMode) {
            disableButton  = true;
          }

          let isTaskCompleted = false;
          if (cell.row?.original?.statusId == LookupDetailConstants.CUSTOMER_TASK_STATUS.COMPLETED) {
            isTaskCompleted = true;
          }

          let iconClassForHistory = `grd-cell-icon ${disableButton ? ' grd-cell-icon--disabled' : 'grd-cell-icon--edit'}` ;
          let iconClassForEdit = `grd-cell-icon ${(disableButton || !canUserEditCustomerTask) ? ' grd-cell-icon--disabled' : 'grd-cell-icon--edit'}` ;
          
    
          let templateToRender = <div className="grd-center-content">
            {
            !isTaskCompleted &&
            <Icon icon={'edit' as IconType} 
                className={iconClassForEdit}
                title="Edit"
                onClick={() => {
                  if (cell.column.CellEditTemplateParameters?.cellClickHandler && !disableButton && canUserEditCustomerTask) {
                    cell.column.CellEditTemplateParameters?.cellClickHandler(cell.row.original, cell.column, 'edit_row');
                  }
                }}/>
            }
            <Icon icon={'history' as IconType} 
                className={iconClassForHistory}
                title="History"
                onClick={() => {
                  if (cell.column.CellEditTemplateParameters?.cellClickHandler && !disableButton) {
                    cell.column.CellEditTemplateParameters?.cellClickHandler(cell.row.original, cell.column, 'show_task_history');
                  }
                }}/>
          </div>;
            
          return templateToRender;
        },
      }

    public getTaskGridColumns = (
        onTaskGridOriginalRowDataChange : (row : any, column : any, value : any, additionalInfo : any) => void
        , onEditingCellDataChange : (row : any, column : any, value : any, additionalInfo : any) => void
        , onRouteChange : (tabKey : string) => void
        , taskGridCellClickHandler : (row : CustomerTaskDtoReadExtended, column : any, additionalData : any) => void
        , tskStatusList : SelectOption[]
        , tskPriorityList : SelectOption[]
        , tskTaskTypeList : SelectOption[]
        , emplSelectList : SelectOption[]
        , policiesForSelect : SelectOption[]
        , compSelectList : SelectOption[]
        , custOptions : SelectOption[]
        , canUserEditCustomerTask : any) => {
        let taskGridcolumns : any[] = [
            {
                Header: TaskScreenLables.taskDescription,
                accessor: 'description',
                fieldName : 'description',
                CellViewTemplate: cell => {
                    let isRowExpanded = cell.row?.original?.isRowExpanded;
                    let detailClassName = 'tsk-grd-descr-cell--detail';
                    let rowExpander = <></>;
                    let textToShow = "";
                    if (isRowExpanded) {
                        rowExpander = <FaChevronDown className='grid-expander-icon' onClick={() => onTaskGridOriginalRowDataChange(cell.row.original, cell.column, false, TaskScreenLables.rowexpander)}/>
                        textToShow= cell.value;
                        detailClassName = 'tsk-grd-descr-cell--detail-expanded'
                    }
                    else {
                        rowExpander = <FaChevronRight className='grid-expander-icon' onClick={() => onTaskGridOriginalRowDataChange(cell.row.original, cell.column, true, TaskScreenLables.rowexpander)}/>;
                        textToShow= cell.row?.original?.descriptionShort;
                    }

                    return <div className="tsk-grd-descr-cell"><div className="tsk-grd-descr-cell--expander">{rowExpander}</div> <div className={detailClassName}>{textToShow}</div></div>;
                },
                CellEditTemplate : GridDefaultCellTemplates.TextFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { cellDataChangeHandler : onEditingCellDataChange, maxLength : 1000, className : "description-cell-edit" },
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.TextFieldGridColumn.ErrorHandler,
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate, // configure if making cell editable when using default editing templates
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:350,
                minWidth : 360,
                sortType : 'string'
            },
            {
                Header: TaskScreenLables.dueDate,
                accessor: 'dueDaysFormatted',
                fieldName : 'dueDate',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate: cell =>  <div className="cell-view-multiline-component"><span title={cell.row?.original?.dueDaysFormatted}>{cell.row?.original?.dueDaysFormatted}</span><span className={`cell-view-multiline-component--detail ${cell.row?.original?.dueDays < 0 ? 'task-due-date-past' : ''}`} title={cell.row?.original?.dueDaysFormatted}>{cell.row?.original?.dueDaysDetail}</span></div>,
                CellEditTemplate : GridDefaultCellTemplates.DateFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { cellDataChangeHandler : onEditingCellDataChange },
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.DateFieldGridColumn.ErrorHandler,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:140
            },
            {
                Header: TaskScreenLables.taskType,
                accessor: 'taskTypeDescr',
                fieldName : 'taskTypeId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate: cell => <div><span title={cell.row?.original?.taskTypeDescr}>{cell.row?.original?.taskTypeDescr}</span></div>,
                CellEditTemplate: GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : tskTaskTypeList, cellDataChangeHandler : onEditingCellDataChange, dropDownIconClassName : 'grd-edit-cell-select-icon', selectMainDivInvalidClassName : 'custom-dropdown--error-select-div' },
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.SelectFieldGridColumn.ErrorHandler,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:240
            },
            {
                Header: TaskScreenLables.priority,
                accessor: 'priorityDescr',
                fieldName : 'priorityId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate : cell => <div className="cell-view-badge-div"><span className={`badge cell-view-badge cell-view-badge--priority-${cell.row?.original?.priorityCode}`} title={cell.row?.original?.priorityDescr}>{cell.row?.original?.priorityDescr}</span></div>,
                CellEditTemplate: GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : tskPriorityList, cellDataChangeHandler : onEditingCellDataChange, dropDownIconClassName : 'grd-edit-cell-select-icon', selectMainDivInvalidClassName : 'custom-dropdown--error-select-div' },
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.SelectFieldGridColumn.ErrorHandler,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:160
            },
            {
                Header: TaskScreenLables.status,
                accessor: 'statusDescr',
                fieldName : 'statusId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate : cell => <div className="cell-view-badge-div"><span className={`badge cell-view-badge cell-view-badge--status-${cell.row?.original?.statusCode}`} title={cell.row?.original?.statusDescr}>{cell.row?.original?.statusDescr}</span></div>,
                CellEditTemplate : GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : tskStatusList, cellDataChangeHandler : onEditingCellDataChange, dropDownIconClassName : 'grd-edit-cell-select-icon', selectMainDivInvalidClassName : 'custom-dropdown--error-select-div' },
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.SelectFieldGridColumn.ErrorHandler,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:210
            },
            {
                Header: TaskScreenLables.completionDate,
                accessor: 'completionDaysFormatted',
                Cell: cell => <div className="cell-view-multiline-component"><span title={cell.value}>{cell.value}</span><span className="cell-view-multiline-component--detail" title={cell.value}>{cell.row?.original?.completionDaysDetail}</span></div>,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:130
            },
            {
                Header: TaskScreenLables.createdBy,
                accessor: 'byUserDescr',
                fieldName : 'byUserId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate : cell => <div className="cell-view-multiline-component"><span title={cell.value}>{cell.value}</span><span className="cell-view-multiline-component--detail" title={cell.value}>{cell.row?.original?.byUserTitle}</span></div>,
                CellEditTemplate : cell => <TextInput value={cell.value} disabled={true}></TextInput>,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width: 200
            },
            {
                Header: TaskScreenLables.assignedTo,
                accessor: 'toUserDescr',
                fieldName : 'toUserId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate: cell => {
                    let meContent = <></>;
                    if (cell.row?.original?.isAssignedToSelf) {
                        meContent = <span className={`badge badge-primary cell-view-badge tsk-cell-view-created-by-icon`} title={cell.row?.original?.toUserDescr}>Me</span>;
                    }

                    return <div><span title={cell.row?.original?.toUserDescr}>{cell.row?.original?.toUserDescr}</span>{meContent}</div>
                },
                CellEditTemplate : GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : emplSelectList, cellDataChangeHandler : onEditingCellDataChange, dropDownIconClassName : 'grd-edit-cell-select-icon' , selectMainDivInvalidClassName : 'custom-dropdown--error-select-div'},
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.SelectFieldGridColumn.ErrorHandler,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width: 240
            },
            {
                Header: TaskScreenLables.cc,
                accessor: 'ccUserDescr',
                fieldName : 'ccUserId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate : cell => <div><span title={cell.row?.original?.ccUserDescr}>{cell.row?.original?.ccUserDescr}</span></div>,
                CellEditTemplate : GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : emplSelectList, cellDataChangeHandler : onEditingCellDataChange, dropDownIconClassName : 'grd-edit-cell-select-icon' },
                CellEditTemplateErrorHandler : GridDefaultCellTemplates.SelectFieldGridColumn.ErrorHandler,
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width:240
            },
            {
                Header: TaskScreenLables.policyNumber,
                accessor: 'policyNumber',
                fieldName : 'polId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate: cell => 
                    <div>
                        <Link
                            to={{
                                pathname: `/customer-view/policies/${cell.row?.original?.custId}`
                            }}
                            title={cell.value}
                            onClick={() => onRouteChange('policies')}>{cell.row?.original?.policyNumber}
                        </Link>
                    </div>,
                CellEditTemplate : GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : policiesForSelect, cellDataChangeHandler : onEditingCellDataChange , dropDownIconClassName : 'grd-edit-cell-select-icon'},
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width: 170
            },
            {
                Header: TaskScreenLables.claimNumber,
                accessor: 'claimId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate: cell => <div><span title={cell.value}>{cell.value}</span></div>,
                CellEditTemplate : GridDefaultCellTemplates.TextInputGridColumn.CellTemplate,
                CellEditTemplateParameters : { cellDataChangeHandler : onEditingCellDataChange },
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width: 150
            },
            {
                Header: TaskScreenLables.company,
                accessor: 'companyDescr',
                fieldName : 'companyId',
                Cell: GridDefaultCellTemplates.EditableGridColumn.CellTemplate,
                CellViewTemplate : cell => <div><span title={cell.row?.original?.companyDescr}>{cell.row?.original?.companyDescr}</span></div>,
                CellEditTemplate : GridDefaultCellTemplates.SelectFieldGridColumn.CellTemplate,
                CellEditTemplateParameters : { options : compSelectList, cellDataChangeHandler : onEditingCellDataChange , dropDownIconClassName : 'grd-edit-cell-select-icon'},
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width: 270
            },
            {
                Header: TaskScreenLables.tasks,
                accessor: 'enableGridRowEditing', 
                hideColumnFilter : true,
                Cell: (cell : any)  => {
                    if (cell.row?.original?.enableGridRowEditing) {
                        return GridDefaultCellTemplates.SaveRowGridColumn.CellTemplate(cell);
                    }
                    
                    return this.TaskGridEditRowColumn.CellTemplate(cell, canUserEditCustomerTask);
                },
                CellEditTemplateParameters : { cellClickHandler : taskGridCellClickHandler,  maxLength : 20, className : "description-cell-edit" },
                CellEditTDProperties : { className : "grd-edit-cell-td"},
                width: 110
            }
        ];

        return taskGridcolumns;
    }

    public getNewTaskRow = (customerSummary : CustomerFilterViewMoel, userDetails : any) : CustomerTaskDtoReadExtended => {
        let userContext = userDetails?.userContext;
        let taskRow : CustomerTaskDtoReadExtended = {
            description : '',
            byUserId : userDetails?.userContext?.id,
            byUserDescr : `${userContext?.firstName ? userContext?.firstName : ''} ${userContext?.lastName ? userContext?.lastName : ''}`.trim(),
            custId : customerSummary?.id,
            custDescr : `${customerSummary?.firstName ? customerSummary.firstName : ''} ${customerSummary?.lastName ? customerSummary.lastName : ''}`.trim(),
            dueDate: new Date(),
            statusId: LookupDetailConstants.CUSTOMER_TASK_STATUS.INCOMPLETE,
            priorityId : LookupDetailConstants.CUSTOMER_TASK_PRIORITY.LOW,
            toUserId : userDetails?.userContext?.id,
            companyId : DATA_IDs.COMPANIES.Westwood_Insurance_Agency
        };

        this.enrichCalculatedFields(taskRow);

        /// Keeping structure consistent.
        return { 
            ...taskRow
            , enableGridRowEditing : true
            , editingRow : taskRow 
        };
    }
}

export default CustomerTaskViewService;