import { AxiosResponse } from 'axios'
import { client } from "../../shared/services/client";
import CampaignExternalTemplates from '../model/LookUpModel/CampaignExternalTemplates';
import QueryTypes from '../model/LookUpModel/QueryTypes';
import AdminDropDownAPI from '../model/LookUpModel/AdminDropDownAPI';
import * as tableConstant from '../../shared/util/tableConstant';
// import { TEMPLATE_TYPE,CAMPAIGN_FILTER_CONDITIONS,CAMPAIGN_STATUS, REQUEST_CAMPAIGN_FROM_EMAIL_ADDRESS } from '../../shared/util/tableConstant';


export const fetchCampaignExternalCategories = async (templateTypeId:string): Promise<AdminDropDownAPI[]> => {
  client.setBaseURLForService("campaign")
  const res: AxiosResponse<AdminDropDownAPI[]> = await client.get<any>("/api/Campaign/GetCategoryValuesForTemplateType?templateType="+templateTypeId);
  return res.data;
};


export const fetchCampaignExternalTemplates = async (templateTypeId:string, templateCategoryId:string): Promise<CampaignExternalTemplates[]> => {
  client.setBaseURLForService("campaign")
  const res: AxiosResponse<CampaignExternalTemplates[]> = await client.get<CampaignExternalTemplates[]>("/api/Campaign/GetTemplateValuesForCampaign?templateType="+templateTypeId +"&templateCategory="+templateCategoryId);
  return res.data;
};

export const fetchCampaignExternalTemplateTypes = async (): Promise<AdminDropDownAPI[]> => { 
    client.setBaseURLForService("admin")
    const res: AxiosResponse<AdminDropDownAPI[]> = await client.get<any>("/api/LookupDetail/GetAllLookupDetails?tableId=" + tableConstant.TEMPLATE_TYPE);
    return res.data;
};

export const fetchQueryTypes = async (): Promise<QueryTypes[]> => {
  client.setBaseURLForService("campaign")
  const res: AxiosResponse<QueryTypes[]> = await client.get<QueryTypes[]>("/api/Campaign/GetDropdownQueriesForCampaign");
  return res.data;
};

export const fetchTemplateStatus = async (): Promise<any> => {
  const res: AxiosResponse<any> = await client.get<any>("http://localhost:5000/TemplateStatus");
  return await res;
};

export const fetchSendgridTemplate = async (typeId): Promise<any> => {
  client.setBaseURLForService("campaign")
  try {
    const res: AxiosResponse<any> = await client.get<any>("/api/CampaignTemplate/GetSendGridEmailTemplates?sendGridTemplateType=" + typeId);
    //console.log('test')
    //console.log('Sendgrid:',res) 
    return await res.data;
  }
  catch (exception) {
    console.log("error: ", exception)
  }

};

export const fetchDropdownOptions = async (tableId: string): Promise<any> => {
  try {
    client.setBaseURLForService("admin")
    const res: AxiosResponse<any> = await client.get<any>("/api/LookupDetail/GetAllLookupDetails?tableId=" + tableId);
    return await res.data;
  }
  catch (exception) {
    console.log("error: ", exception)
  }

};

export const fetchFilterConditionTypes = async (ids: string[]): Promise<AdminDropDownAPI[]> => {
  client.setBaseURLForService("admin")
  const res: AxiosResponse<AdminDropDownAPI[]> = await client.get<AdminDropDownAPI[]>("/api/LookupDetail/GetAllLookupDetails?tableId=" + tableConstant.CAMPAIGN_FILTER_CONDITIONS);
  const completeFilterConditions = res.data;
  return completeFilterConditions.filter((filterCondition) => ids.includes(filterCondition.id))
};


export const fetchCampaignStatus = async (): Promise<AdminDropDownAPI[]> => { 
  client.setBaseURLForService("admin")
  const res: AxiosResponse<AdminDropDownAPI[]> = await client.get<any>("/api/LookupDetail/GetAllLookupDetails?tableId=" + tableConstant.CAMPAIGN_STATUS);
  return res.data;
};

export const fetchFromEmailAddressForRequestCampaign = async (): Promise<AdminDropDownAPI[]> => { 
  client.setBaseURLForService("admin")
  const res: AxiosResponse<AdminDropDownAPI[]> = await client.get<any>("/api/LookupDetail/GetAllLookupDetails?tableId=" + tableConstant.REQUEST_CAMPAIGN_FROM_EMAIL_ADDRESS);
  return res.data;
};