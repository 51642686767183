import { CommonUtility } from "../../../Services/CommonUtility";
import LookupService from "../../../Services/LookupService";
import { CustomerLossHistoryConstant } from "../../../shared/util/constant";
import { CustomerLossDto, CustomerLossReadDto } from "../../model/CustomerLossDto";
import { createCustomerLossHistory, deleteCustomerLossHistory, updateCustomerLossHistory } from "../../services/CustomerAPI";


class CustomerLossFormService {

    public validateCustomerLossData = (customerLossDto : CustomerLossReadDto) : any => {
        let validationErrors = {};

        if (!customerLossDto) {
            validationErrors['rootObject'] = {isValid : false, warningText : CustomerLossHistoryConstant.DETAIL_REQURIED }
        }

        if ((!customerLossDto.policyNumber || customerLossDto.policyNumber?.trim().length == 0)) {
            validationErrors['policyNumber'] = {isValid : false, warningText : CustomerLossHistoryConstant.FIELD_CANNOT_BE_BLANK };
        }

        return validationErrors;
    }

    public saveCustomerLossData = async (customerLossDto : CustomerLossDto)  : Promise<CustomerLossReadDto> => {
        if (customerLossDto.id) {
            /// Call Update API endpoint.......
            let updatedCustomerLoss = await updateCustomerLossHistory(customerLossDto);
            return updatedCustomerLoss;
        }
        else {
            let customerLossCreated = await createCustomerLossHistory(customerLossDto);
            return customerLossCreated;
        }
    }

    public removeCustomerLossData = async (customerLossDto : CustomerLossDto)  : Promise<boolean> => {
        await deleteCustomerLossHistory(customerLossDto.custId, customerLossDto.id);
        return true;
    }

    public getCustomerLossRequestDto = (customerLossDto : CustomerLossReadDto, customerId : string) : CustomerLossDto => {
        let requestDto : CustomerLossDto = {};

        if (customerLossDto.id) {
            requestDto.id = customerLossDto.id; // for updating existing records. 
        }

        requestDto.custId = customerLossDto.custId ? customerLossDto.custId : customerId; // for new record put the customer id.
        requestDto.company = customerLossDto.company;
        requestDto.lossDescription = customerLossDto.lossDescription;
        requestDto.policyNumber = customerLossDto.policyNumber;
        requestDto.claimNo = customerLossDto.claimNo;
        requestDto.lossType = customerLossDto.lossType;
        requestDto.dateOfLoss = customerLossDto.dateOfLoss;
        requestDto.policyEffDate = customerLossDto.policyEffDate;
        requestDto.policyExpDate = customerLossDto.policyExpDate;
        requestDto.reportDate = customerLossDto.reportDate;
        requestDto.amountPaid = customerLossDto.amountPaid;
        requestDto.claimStatusId = customerLossDto.claimStatusId;
        requestDto.closedDate = customerLossDto.closedDate;

        return requestDto;
    }
    
    public fetchLossTypes = async () => {        
        let lossTypeList = await LookupService.fetchLossTypeList();
        return CommonUtility.mapToSelectOptions(lossTypeList);
    }

    public fetchClaimStatusList = async () => {     
        let claimStatusList = await LookupService.fetchClaimStatusList();
        return CommonUtility.mapToSelectOptions(claimStatusList);
    }
};

export default CustomerLossFormService;