import Grid from "../../../components/components/Grid/Grid";
import EntityHistoryModel from "../../../customer/model/EntityHistoryModel";
import './EntityHistoryGrid.scss';

export interface IEntityHistoryGrid {
    data : EntityHistoryModel[],
    columns : any[],
    showFilters? : boolean,
    columnsGroupOn? : any,
    defaultPageSize? : number,
    hiddenColumns? : string[],
    groupByColumns? : string[],
    paginationDisabled? : boolean,
    entityHistoryMainDivClassName? : string,
    entityHistoryGrdTableContainerClass? : string
};

const EntityHistoryGrid = (props) => {

    let mainDivClassName = `${props.entityHistoryMainDivClassName ? props.entityHistoryMainDivClassName  : 'entity-history-grd-div'}`;
    let grdTableContainerClass = `${props.entityHistoryGrdTableContainerClass ? props.entityHistoryGrdTableContainerClass  : 'grd-entity-history-table-container'}`

    return  <div className={mainDivClassName}>
                <Grid
                    data={props.data}
                    showFilters={props.showFilters ? props.showFilters : false}
                    id={'entityHistoryTasksGrid'}
                    retainGridState={true}
                    columns={props.columns}
                    sortDefault={[]}
                    grdTableContainerClass={grdTableContainerClass}
                    grdTabelClass="grd-entity-history-table"
                    grdFilterInputClassName="entity-history-grid-filter-input"
                    zindexHeader={10000}
                    hiddenColumns={props.hiddenColumns}
                    groupByColumns={props.groupByColumns}
                    columnsGroupOn={props.columnsGroupOn}
                    defaultPageSize={props.defaultPageSize}
                    paginationDisabled={props.paginationDisabled}/>
            </div>;
};

export default EntityHistoryGrid;