import {AxiosResponse} from 'axios'
import { client } from "../../shared/services/client"; 
import Occupations from '../model/Occupations';
import SelfEmployed from '../model/SelfEmployed';
import SolicitedCampaignMonth from '../model/SolicitedCampaignMonthNumber';
import Campaign from '../model/Campaign';
import FixedStatus from '../model/FixedStatus';
import StateCode from '../model/StateCode';
import RoofType from '../model/RoofType';
import RoofShape from '../model/CustomerLookupModel/RoofShape';
import RoofMaterial from '../model/CustomerLookupModel/RoofMaterial';
import ConstructionType from '../model/CustomerLookupModel/ConstrustionType';
import PoolType from '../model/CustomerLookupModel/PoolType';
import Trampoline from '../model/CustomerLookupModel/Trampoline';
import DogBreed from '../model/CustomerLookupModel/DogBreed';
import Carrier from '../model/CustomerLookupModel/Carrier';
import WritingCompany from '../model/CustomerLookupModel/WritingCompany';
import NumberofYear from '../model/CustomerLookupModel/NumberofYears';
import Agency from '../model/CustomerLookupModel/Agency';
import PolicyType from '../model/CustomerLookupModel/PolicyType';
import PolicyStatus from '../model/CustomerLookupModel/PolicyStatus';
import LastNoteEnteredBy from '../model/CustomerLookupModel/LastNoteEnteredBy';
import Lookup from '../model/CustomerLookupModel/Lookup';
import LookupTable from '../model/CustomerLookupModel/LookupTables';

export const fetchStateCodes = async (): Promise<StateCode[]> => {
  const res: AxiosResponse<StateCode[]> =  await client.get<StateCode[]>("http://localhost:5000/statecodes");
  return res.data;
};

// export const fetchLookup = async (table:LookupTable): Promise<Lookup[]> => {
//     const res: AxiosResponse<Lookup[]> =  await client.get<Lookup[]>("/Lookup?"+table);
//     return res.data;
// };
 
export const fetchOccupation = async (): Promise<Occupations[]> => {
  const res: AxiosResponse<Occupations[]> =  await client.get<Occupations[]>("http://localhost:5000/occupations");
   return res.data;
};

export const fetchSelfEmployment = async (): Promise<SelfEmployed[]> => {
  const res: AxiosResponse<SelfEmployed[]> =  await client.get<SelfEmployed[]>("http://localhost:5000/selfemployments");
   return res.data;
};

export const fetchCampaigninTimeframe = async (): Promise<SolicitedCampaignMonth[]> => {
  const res: AxiosResponse<SolicitedCampaignMonth[]> =  await client.get<SolicitedCampaignMonth[]>("http://localhost:5000/solicitedcampaignmonths");
   return res.data;
};

export const fetchCampaign = async (): Promise<Campaign[]> => {
  const res: AxiosResponse<Campaign[]> =  await client.get<Campaign[]>("http://localhost:5000/Campaign");
   return res.data;
};
export const fetchFixedStatus = async (): Promise<FixedStatus[]> => {
  const res: AxiosResponse<FixedStatus[]> =  await client.get<FixedStatus[]>("http://localhost:5000/fixedstatus");
   return res.data;
};
export const fetchRoofType = async (): Promise<RoofType[]> => {
  const res: AxiosResponse<RoofType[]> =  await client.get<RoofType[]>("http://localhost:5000/roofType");
   return res.data;
};
export const fetchRoofShape = async (): Promise<RoofShape[]> => {
  const res: AxiosResponse<RoofShape[]> =  await client.get<RoofShape[]>("http://localhost:5000/roofshape");
   return res.data;
};
export const fetchRoofMaterial = async (): Promise<RoofMaterial[]> => {
  const res: AxiosResponse<RoofMaterial[]> =  await client.get<RoofMaterial[]>("http://localhost:5000/roofmaterial");
   return res.data;
};

export const fetchConstructionType = async (): Promise<ConstructionType[]> => {
  const res: AxiosResponse<ConstructionType[]> =  await client.get<ConstructionType[]>("http://localhost:5000/constructiontype");
   return res.data;
};

export const fetchPoolType = async (): Promise<PoolType[]> => {
  const res: AxiosResponse<PoolType[]> =  await client.get<PoolType[]>("http://localhost:5000/pool");
   return res.data;
};

export const fetchTrampoline = async (): Promise<Trampoline[]> => {
  const res: AxiosResponse<Trampoline[]> =  await client.get<Trampoline[]>("http://localhost:5000/trampoline");
   return res.data;
};

export const fetchDogBreed = async (): Promise<DogBreed[]> => {
  const res: AxiosResponse<DogBreed[]> =  await client.get<DogBreed[]>("http://localhost:5000/dogbreed");
   return res.data;
};
export const fetchCarrier = async (): Promise<Carrier[]> => {
  const res: AxiosResponse<Carrier[]> =  await client.get<Carrier[]>("http://localhost:5000/carrier");
   return res.data;
};

export const fetchWritingCompany = async (): Promise<WritingCompany[]> => {
  const res: AxiosResponse<WritingCompany[]> =  await client.get<WritingCompany[]>("http://localhost:5000/writingcompany");
   return res.data;
};

export const fetchNumberofYear = async (): Promise<NumberofYear[]> => {
  const res: AxiosResponse<NumberofYear[]> =  await client.get<NumberofYear[]>("http://localhost:5000/numberofyear");
   return res.data;
};

export const fetchAgency = async (): Promise<Agency[]> => {
  const res: AxiosResponse<Agency[]> =  await client.get<Agency[]>("http://localhost:5000/agency");
   return res.data;
};

export const fetchPolicyType = async (): Promise<PolicyType[]> => {
  const res: AxiosResponse<PolicyType[]> =  await client.get<PolicyType[]>("http://localhost:5000/policytype");
   return res.data;
};

export const fetchPolicyStatus = async (): Promise<PolicyStatus[]> => {
  // const res: AxiosResponse<PolicyStatus[]> =  await client.get<PolicyStatus[]>("http://localhost:5000/policystatus");
  //  return res.data;
  return Promise.resolve([]);
};

export const fetchLastNoteEntered = async (): Promise<LastNoteEnteredBy[]> => {
  const res: AxiosResponse<LastNoteEnteredBy[]> =  await client.get<LastNoteEnteredBy[]>("http://localhost:5000/lastnoteentered");
   return res.data;
};

export const getActivityTypesForDropdown = async () : Promise<Lookup[]> => {
  client.setBaseURLForService("admin")
  const res: AxiosResponse<Lookup[]> = await client.get<Lookup[]>("/api/LookupDetail/GetAllLookupDetails?tableId=" + LookupTable.TaskTypeTableId);
  return await res.data;
}

export const getTransactionTypesForDropdown = async () : Promise<Lookup[]> => {
  client.setBaseURLForService("admin")
  const res: AxiosResponse<Lookup[]> = await client.get<Lookup[]>("/api/LookupDetail/GetAllLookupDetails?tableId=" + LookupTable.TransactionType);
  return await res.data;
}

