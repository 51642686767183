import { SelectOption } from "../../../shared/components/input/SelectOption";
import AppRolePrivielge from "../../model/AppRolePrivielge";
import FeaturePrivilege from "../../model/FeaturePrivilege";
import RoleDetailsAPIModel from "../../model/RoleDetailsAPIModel";
import RolePermission from "../../model/RolePermissionAPIModel";
import ScreenElementPrivlegeModel from "../../model/ScreenElementPrivlegeModel";
import RolePrivilegeSetupAPI from "../../Services/RolePrivilegeSetupAPI";
import * as constant from '../../../shared/util/constant'
import * as tableConstant from '../../../shared/util/tableConstant';
import Logger from "../../../shared/services/Logger";

class ScreenPrivilegeService
{
    rolePrivilegeSetupAPI :RolePrivilegeSetupAPI= new RolePrivilegeSetupAPI ();
    
    getFeatures = async () : Promise<SelectOption[]> => {

        const features=await this.rolePrivilegeSetupAPI.getfeatures();
        return (features).sort((a, b) =>
        a.featureName > b.featureName ? 1 : -1).map((feature) => {
          return {
            label:feature.featureName,
            value: feature.id,
          }
        }
        )
    }

    getFeatureElements = async (featureId:string) : Promise<SelectOption[]> => {
        
        const featureElements=await this.rolePrivilegeSetupAPI.getFeatureElements(featureId);
        return (featureElements).sort((a, b) =>
            a.elementName > b.elementName ? 1 : -1).map((featureElement) => {
              return {
                label:featureElement.elementName,
                value: featureElement.id,
              }
            }
            )
     }

     getRoleDetails = async (roleId:string) : Promise<AppRolePrivielge | null> => {
      if(!roleId)
        return null;

      try {
        const roleDetailsAPI : RoleDetailsAPIModel =await this.rolePrivilegeSetupAPI.getRoleDetails(roleId);

        const transformedRoleDetailsUI = this.mapRoleDetailsAPIToUI(roleDetailsAPI)

        return transformedRoleDetailsUI;
        } 
        catch (error) {
          // Handle errors here
          Logger.logError(error.toString());
          // return error
          throw error; // Rethrow the error for the caller to handle
        }
      }

      saveRoleDetails = async (appRolePrivielge :AppRolePrivielge) : Promise<AppRolePrivielge | null> => {      
        try {
          const transformedRoleDetailsAPI =  this.mapRoleDetailsUIToAPI(appRolePrivielge);

          const roleDetailsAPIResponse : RoleDetailsAPIModel =await this.rolePrivilegeSetupAPI.saveRoleDetails(transformedRoleDetailsAPI);

          return this.mapRoleDetailsAPIToUI(roleDetailsAPIResponse);
          } 
          catch (error) {
            // Handle errors here
            Logger.logError(error.toString());
            throw error;

          }
        }
       
      updateRoleDetails = async (appRolePrivielge :AppRolePrivielge) : Promise<AppRolePrivielge | null> => {      
        try {
          const transformedRoleDetailsAPI =  this.mapRoleDetailsUIToAPI(appRolePrivielge);

          const roleDetailsAPIResponse : RoleDetailsAPIModel =await this.rolePrivilegeSetupAPI.updateRoleDetails(transformedRoleDetailsAPI);

          return this.mapRoleDetailsAPIToUI(roleDetailsAPIResponse);
          } 
          catch (error) {
            // Handle errors here
            Logger.logError(error.toString());
            throw error;

          }
        }

      private mapRoleDetailsAPIToUI = (roleDetailsAPI: RoleDetailsAPIModel) : AppRolePrivielge => {
       const roleDetailsUI :AppRolePrivielge = {
        roleDetails : {
          id: roleDetailsAPI.id,
          roleName: roleDetailsAPI.roleName,
          roleDescription: roleDetailsAPI.roleDescription
        },
        featurePrivielges: this.mapRolePermissionsToFeaturePrivileges(roleDetailsAPI.rolePermissions)
      }
      return roleDetailsUI;
      }

      private mapRoleDetailsUIToAPI = (roleDetailsUI: AppRolePrivielge) : RoleDetailsAPIModel => {       
        const roleDetailsAPI : RoleDetailsAPIModel = {
          id: roleDetailsUI.roleDetails.id ? roleDetailsUI.roleDetails.id : constant.DEFAULT_GUID,
          appId: tableConstant.ROLE_APP_ID,
          roleName: roleDetailsUI.roleDetails.roleName,
          roleDescription: roleDetailsUI.roleDetails.roleDescription,
          rolePermissions: this.mapFeaturePrivilegesToRolePermissions(roleDetailsUI.featurePrivielges, roleDetailsUI.roleDetails.id)
        }
        return roleDetailsAPI;
       }

      private mapRolePermissionsToFeaturePrivileges = (rolePermissions: RolePermission[]) : FeaturePrivilege[] => {
        // Group rolePermissions by featureId

        const groupByFeature = rolePermissions.reduce((acc: { [key: string] : ScreenElementPrivlegeModel[] } ,permission) => {
          const featureId = permission.featureId;
          const screenElementPrivilege: ScreenElementPrivlegeModel = {
            id: permission.id,
            elementId: permission.featureElementId,
            isView: permission.allowAccess,
            isRead: permission.allowRead,
            isCreate: permission.allowInsert,
            isUpdate: permission.allowUpdate,
            isDelete: permission.allowDelete,
            featureId: permission.featureId
          };

          if(!acc[featureId]) {
            acc[featureId] = [];
          }
          acc[featureId].push(screenElementPrivilege);
          return acc;
        }, {});

        //Convert the grouped object into feature Privlege array

        return Object.entries(groupByFeature).map(([featureId, elementPrivileges]) => {
          const privilegesWithNullElement = elementPrivileges.filter(p => !p.elementId);
          const otherPrivileges = elementPrivileges.filter(p =>p.elementId);
          
          const sortedPrivileges = [...privilegesWithNullElement, ...otherPrivileges];

          return {
            featureId,
            elementPrivilege: sortedPrivileges
          };
        });
      };

      private mapFeaturePrivilegesToRolePermissions = (featurePrivileges: FeaturePrivilege[], roleId: string): RolePermission[] => {
        return featurePrivileges
          .flatMap((featurePrivilege) => {
            // Only process featurePrivilege with valid featureId
            if (!featurePrivilege.featureId) {
              return []; // Return empty array for features without a featureId
            }
      
            // Filter elementPrivileges, keeping only those with valid elementId (except for the first row)
            const filteredElementPrivileges = featurePrivilege.elementPrivilege.filter(
              (element, index) => element.elementId || index === 0 // Keep the first row even if elementId is null/undefined
            );     
            return filteredElementPrivileges.map((element) => ({
              id: element.id ? element.id : constant.DEFAULT_GUID,
              appRoleId: roleId ? roleId : constant.DEFAULT_GUID,
              featureId: featurePrivilege.featureId,
              featureElementId: element.elementId,
              allowAccess: element.isView,
              allowInsert: element.isCreate,
              allowUpdate: element.isUpdate,
              allowDelete: element.isDelete,
              allowRead: element.isRead,
            }));
          });
      };

   }      

export default ScreenPrivilegeService;