import React, { useEffect, useState } from 'react';
import './CustomerCampaignView.scss';
import CustomerCampaignViewService from './CustomerCampaignViewService';
import Logger from '../../shared/services/Logger';
import { toast } from 'react-toastify';
import Grid from '../../components/components/Grid/Grid';
import { CustomerCampaignConstant } from '../../shared/util/constant';

export interface ICustomerCampaignView {
    customerId? : string
}

const CustomerCampaignView : React.FunctionComponent<ICustomerCampaignView> = (props) => {

    const customerCampaignService = new CustomerCampaignViewService();

    const [gridColumns, setGridColumns] = useState([]);
    const [customerCampaigns, setCustomerCampaigns] = useState([]);

    useEffect(() => {
        initializeComponent();
    }, [props.customerId]);

    const initializeComponent = async () => {
        try {

            let gridColumns = customerCampaignService.getCustomerCampaignGridColumns();
            setGridColumns(gridColumns);

            let customerCampaignData = await customerCampaignService.getCustomerCampaigns(props.customerId);
            setCustomerCampaigns(customerCampaignData);
        }
        catch (ex) {
            Logger.logError(CustomerCampaignConstant.ERROR_WHILE_INITIALIZING_VIEW, ex);
            toast.error(CustomerCampaignConstant.ERROR_WHILE_INITIALIZING_VIEW, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (<div className="cust-camp-grd-div">
        <Grid
            data={customerCampaigns}
            showFilters={true}
            id={'customerCampaignsGrid'}
            retainGridState={true}
            columns={gridColumns}
            hiddenColumns={['selection']}
            sortDefault={[]}
            paginationDisabled={true}/>
    </div>);
}

export default CustomerCampaignView;