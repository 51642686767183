import React,{InputHTMLAttributes, useEffect, useState} from 'react'
import { Highlighter, Token, Typeahead,TypeaheadInputSingle,TypeaheadLabelKey } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Form from 'react-bootstrap/Form'
import { Label } from '../Label'
import * as constant from '../../../../src/shared/util/constant'
import { useRef } from 'react'
import { ChangeEvent } from 'react'
import { Button, FormControl } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SelectOption<T> {
  label: string
  value: T
  isInactive:boolean
  count?:number
}

interface Props<T> {
  id: string
  options: SelectOption<T>[]
  defaultSelected?: SelectOption<T>[]
  onChange?: (values: T[]) => void
  placeholder?: string
  multiple?: boolean
  disabled?: boolean
  isValid?: boolean
  isInvalid?: boolean
  feedback?: string
  inputProps?:InputHTMLAttributes<HTMLInputElement>,
  tabIndex?:number
  invalidWarning?:string
  invalidLableClass?:string
  labelKey?:TypeaheadLabelKey<string>
  isRequired?:boolean
  isEmpty?:boolean
  errorText?:string
  className?:string
  errorLableDivClassName? : string
  dropDownIconClassName? : string
  selectMainDivInvalidClassName? : string
  dropup? : boolean
}

function Select<T>(props: Props<T>) {
  const {
    id,
    options,
    defaultSelected,
    onChange,
    placeholder,
    multiple,
    disabled,
    isValid,
    isInvalid,
    feedback,
    inputProps,
    tabIndex,
    invalidWarning,
    invalidLableClass,
    labelKey,
    isRequired,
    className,
    errorLableDivClassName,
    dropDownIconClassName,
    selectMainDivInvalidClassName,
    dropup
    // isEmpty,
    // errorText
  } = props

  let invalidLable = `cst-Feedback-Linebreak${invalidLableClass ? ' ' + invalidLableClass : ''}`;

  const [inputValue, setInputValue] = useState('');
  const [typeaheadKey, setTypeaheadKey] = useState(0);
  let renderTokenTemplate = multiple ? (option, { onRemove }, index) => {
    return (
      <>
      
      {multiple &&<Token key={index} onRemove={onRemove} option={option} className={option.isInactive?"highlight-inactive":""}>
       {`${option.isInactive?"*"+option.label:option.label}`}
     </Token>}
     </>
   )} : null;

  let renderInputTemplate = multiple ? null : ({ inputRef, referenceElementRef, ...inputProps }) => {
    //console.log('defaultSelected:',defaultSelected[0]?.label,inputProps)
    let a=inputProps.value!=="" && defaultSelected!==undefined && !multiple?(defaultSelected[0]?.isInactive?"*"+defaultSelected[0]?.label:defaultSelected[0]?.label):defaultSelected===[]?'':[];
   
 
    return (
    <>
      <FormControl
        {...inputProps}
        ref={(node) => {
          inputRef(node);
          referenceElementRef(node);
        }}
        value={a?a:inputValue}
        className={!multiple?(defaultSelected[0]?.isInactive?"highlight-inactive":""):""}
      isInvalid={isInvalid}
      />
    </>
  )};

  let iconClassName = `dropdown-autocomplete-arrow${dropDownIconClassName ? ' ' + dropDownIconClassName : ''}`;
  let mainSelectDivClassName = `custom-dropdown${selectMainDivInvalidClassName && isInvalid ? ' ' + selectMainDivInvalidClassName : ''}`;
  
  return (
    <>
    <div title={inputProps?.title??''} className={mainSelectDivClassName} >
      <FontAwesomeIcon 
                        icon={'fa-angle-down'}
                        className={iconClassName}
                      />
      <Typeahead<SelectOption<T>>
        key={typeaheadKey}
        id={id}
        dropup={dropup ? dropup : false}
        options={options.sort((a, b) => ((b.isInactive===false)- (a.isInactive===false))||a.label.localeCompare(b.label))}
        selected={defaultSelected}
         renderInput={renderInputTemplate}
         renderToken={renderTokenTemplate}
       
        className={className}
        caseSensitive={false}
        onChange={(selected: SelectOption<T>[]) => {
          if (onChange !== undefined) {
            onChange(selected.map((option) => option.value))
          }
        }}
        renderMenuItemChildren={(option, props, idx) => (
          <>
          {/* <span className={option.isInactive?'highlight-inactive':''}>
          {option.isInactive?"*"+option.label:option.label}
          </span> */}
            <span className={option.isInactive?'highlight-inactive':''}>
            <Highlighter  search={props.text}>
              {option.isInactive?"*"+option.label:option.label}
              </Highlighter>
              </span>
              {option.count && <span className='count'>{option.count}</span>}
          </>
        )}
        
        placeholder={placeholder}
        positionFixed={true}
        flip ={true}
        multiple={multiple}
        disabled={disabled}
        isInvalid={isInvalid}
        inputProps={inputProps}
        tabIndex={tabIndex}
        labelKey={labelKey}
        onInputChange={(text) => setInputValue(text)}
        onBlur={() => {
          const match = options.some((option) => option.label.toLowerCase() === inputValue.toLowerCase());
          if(!match) {
            setInputValue('');
            setTypeaheadKey((prevKey) => prevKey +1);
          }
        }}
        filterBy={(option: SelectOption<T>, selectProps: any) => {
          // change component default behavior
          // multiple - filter-out current selections
          const isMatch = option.length!==0?option.label.toLowerCase().indexOf(selectProps.text.toLowerCase()) !== -1:false
         
          if (selectProps.selected.length && selectProps.multiple) {
            return selectProps.selected.every(
              (selected: any) => selected.label !== option.label && isMatch,
            )
          }
          // single (custom)- display all options
          if (selectProps.selected.length) {
            return true
          }
          // default filter as normal
          return isMatch
        }}
      />
       {/* {defaultSelected[0]?.isInactive && <span className='highlight-inactive'>{defaultSelected[0]?.label}</span>} */}
      {/* <div className={isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined} /> */}
      <Form.Control.Feedback
        className={`text-left ml-3 mt-1 text-small cst-error ${
          isValid ? 'text-success' : isInvalid ? 'text-danger' : undefined
        }`}
        type={isValid ? 'valid' : 'invalid'}
      >
        {feedback}
      </Form.Control.Feedback>
      </div>
      <div className={(isInvalid?`mt-n1 ${errorLableDivClassName ? errorLableDivClassName : ''}`:'')} >
      {(isInvalid) && <Label className={invalidLable}
          text={invalidWarning}
          style={{ color: "red"}} />}
      </div>
      
      </>
  )
}

Select.defaultProps = {
  defaultSelected: [],
  onChange: undefined,
  placeholder: '-- Choose --',
  multiple: false,
  disabled: false,
  isInvalid: false,
}

export { Select }
