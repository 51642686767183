import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import { client } from "../../../services/client"; 
import NoteDetail from '../model/NotesDetail';
import { Promise} from 'cypress/types/bluebird';

  export const getAllNotesList = async (): Promise<NoteDetail[]> => {
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<NoteDetail[]> =  await client.get<NoteDetail[]>("http://localhost:5000/Notes");
    return res.data;
  };

  export async function saveNote(notesDetail:NoteDetail){
    client.setBaseURLForService("campaign")
    const response: AxiosResponse<any> = (await client.post("/api/CampaignNote/AddCampaignNote",notesDetail));
    //console.log(response);
    return response
    
  }

  export async function updateNote(notesDetail:NoteDetail){
    client.setBaseURLForService("campaign")
    const response: AxiosResponse<any> = (await client.post("/api/CampaignNote/UpdateCampaignNote",notesDetail));
    //console.log(response);
    return response
  }

  export async function getNoteByQueryID(id:string){
    //console.log('API '+id);
    //console.log('API '+type);
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<any> =  await client.get("/api/CampaignNote/GetAllCampaignNotes?entityId="+id);
    //console.log(res.data)
    return res;
  }

  export async function deleteNoteByID(id:string){
    //console.log('API '+id);
    const param={"noteId":id}
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<any> =  await client.delete("/api/CampaignNote/DeleteCampaignNote",{data:param});
    //console.log(res.status)
    return res;
    
  }

  export async function getHistoryByIdType(id:string){
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<any> =  await client.get("/api/CampaignHistory/GetCampaignHistory?EntityID="+id);
    // console.log(res.status)
    return res;
  }
  
  