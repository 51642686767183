import React, { useEffect, useState } from "react";
import { CustomerCompanyContact } from "../model/CustomerCompanyContactModel";
import { Row } from "react-bootstrap";
import { Button, Column } from "../../components";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { ConfirmModal } from "../../shared/components/confirmModal";
import * as label  from '../../shared/util/label';
import * as constant from '../../shared/util/constant';
import FormattedInputWithLableInline, { InputFormatType } from "../../shared/components/input/formatted-input/FormattedInputWithLableInline";
import { SelectOption } from "../../shared/components/input";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";

export interface ICustomerCompanyContactView
{
    contact : CustomerCompanyContact,
    state : SelectOption[],
    onFieldChange : (collectionPropName : string, collectionEntityIdentifierPropName : string, collectionEntityIdentifierValue : any, propName: string, value: string | Date|boolean) => void,
    onDelete : (dependent : CustomerCompanyContact) => void,
    formValidations : any
    isEditable : boolean,
    isReset : boolean,
    divToScroll : any
}

const CustomerCompanyContactView : React.FunctionComponent<ICustomerCompanyContactView> = (props) => {

    const cont = props.contact;
    const formValidations = props.formValidations;
    const [contact, setContact] = useState<CustomerCompanyContact>({});
    const [formValidation, setFormValidation] = useState<any>({});
    const state = props.state??[];
    // TODO : Remove once modal handler implemented
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [initialContact, setInitialDependent] = useState<CustomerCompanyContact>(cont);
    const modalContext = useConfirmationModalContext();
    const removeContact = () => {
        setShowDeleteConfirmation(false);
        props.onDelete(contact);
    }

    useEffect(() => {
        setContact(initialContact);
    }, [props.isReset]);

    useEffect(() => {
        setContact(cont);
    }, []);

    useEffect(() => {
        setFormValidation(formValidations ? formValidations : {});
    },[formValidations]);
    
    const states = props.state??[];

    //const isEditable = true;
    

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        if(propName === 'workPhoneExt'){
            if(value.toString().length > 5)
            return false;
            else{
            value= value.toString().replace(/[^0-9]/g, "")
            }
        }
        
        setContact({
            ...contact,
            [propName]: value,
          });
       props.onFieldChange('customerContacts', 'id', contact.id,  propName, value);
    }

    const onDeleteContact = async() => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_CONTACT_DELETE)
        if(result){
            removeContact();
        }

    }

   return (
    <>
    <div className="cd-main-container" ref={props.divToScroll}>
        <Row>
            <Column lg={9} md={9} sm={9} xs={9} className="section-height">
                
                <Row>
                <Column lg={4} md={4} className={formValidation['contactName']?.isValid == false ? 'feedback-row' : ''}>
                <TextInputWithLabelInline
                        name='contactName'
                        label={label.CustomerScreentLables.NAME}
                        placeholder={' '}
                        value={contact.contactName??""}
                        isRequired
                        onChange={(event) => onEntityFieldChange('contactName', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={75}
                        isInvalid={formValidation['contactName']?.isValid == false}
                        invalidWarning={formValidation['contactName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={4} md={4}
                    className={formValidation['title']?.isValid == false ? 'feedback-row' : ''}>
                    <TextInputWithLabelInline
                        name='title'
                        label={label.CustomerScreentLables.TITLE}
                        placeholder={' '}
                        isRequired
                        value={contact.title??""}
                        onChange={(event) => onEntityFieldChange('title', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={20}
                        isInvalid={formValidation['title']?.isValid == false}
                        invalidWarning={formValidation['title']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={4} md={4}></Column>
                
                </Row>
                <Row>
                <Column lg={4} md={4}>
                <TextInputWithLabelInline
                        name='address1'
                        label={label.CustomerScreentLables.ADDRESS_1}
                        placeholder={' '}
                        value={contact.address1??""}
                        onChange={(event) => onEntityFieldChange('address1', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={75}
                        // isInvalid={formValidation['WorkPhoneExt']?.isValid == false}
                        // invalidWarning={formValidation['WorkPhoneExt']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={4} md={4}>
                <TextInputWithLabelInline
                        name='address2'
                        label={label.CustomerScreentLables.ADDRESS_2}
                        placeholder={' '}
                        value={contact.address2??""}
                        onChange={(event) => onEntityFieldChange('address2', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={75}
                        // isInvalid={formValidation['WorkPhoneExt']?.isValid == false}
                        // invalidWarning={formValidation['WorkPhoneExt']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={4} md={4}>
                <TextInputWithLabelInline
                        name='city'
                        label={label.CustomerScreentLables.CITY}
                        placeholder={' '}
                        value={contact.city??""}
                        onChange={(event) => onEntityFieldChange('city', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={30}
                        // isInvalid={formValidation['WorkPhoneExt']?.isValid == false}
                        // invalidWarning={formValidation['WorkPhoneExt']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                </Row>
                <Row>
                <Column lg={4} md={4}>
                <DropDownWithLabelInline
                            id='ddState'
                            label={label.CustomerScreentLables.STATE}
                            options={states}
                            defaultSelected={states?.filter(({ value }) => value === contact.state)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('state', values[0])
                                }
                              }
                            labelSize={4}
                            disabled={!props.isEditable}
                            dropDownSize={8}
                            labelClass='inlineLable'
                            placeholder={' '}
                            multiple={false}
                            tabIndex={9}
                        />
                </Column>
                <Column lg={4} md={4} className={formValidation['zip']?.isValid == false ? 'feedback-row' : ''}>
                <TextInputWithLabelInline
                        name='zip'
                        label={label.CustomerScreentLables.ZIP}
                        placeholder={' '}
                        value={contact.zip??""}
                        onChange={(event) => onEntityFieldChange('zip', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={9}
                         isInvalid={formValidation['zip']?.isValid == false}
                         invalidWarning={formValidation['zip']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={4} md={4} >
                            <TextInputWithLabelInline
                                                name='county'
                                                label={label.CustomerScreentLables.COUNTY}
                                                placeholder={' '}
                                                value={contact.county??""}
                                                onChange={(event) => onEntityFieldChange('county', event.currentTarget.value)}
                                                isEditable={props.isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={30}
                                                className='texbox-input'>
                                            </TextInputWithLabelInline>
                </Column>
                </Row>
                <Row>
                    <Column lg={4} md={4} className={formValidation['email']?.isValid == false ? 'feedback-row' : ''}>
                    <TextInputWithLabelInline
                                                name='email'
                                                label={label.CustomerScreentLables.EMAIL}
                                                placeholder={' '}
                                                value={contact.email??""}
                                                onChange={(event) => onEntityFieldChange('email', event.currentTarget.value)}
                                                isEditable={props.isEditable}
                                                type='email'
                                                labelClass='inlineLable'
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={150}
                                                 isInvalid={formValidation['email']?.isValid == false}
                                                 invalidWarning={formValidation['email']?.warningText}
                                                className='texbox-input'>
                                            </TextInputWithLabelInline>
                    </Column>
                    <Column lg={4} md={4}>
                    <TextInputWithLabelInline
                                                name='webAddr'
                                                label={label.CustomerScreentLables.WEB_ADDRESS}
                                                placeholder={' '}
                                                value={contact.webAddr??""}
                                                onChange={(event) => onEntityFieldChange('webAddr', event.currentTarget.value)}
                                                isEditable={props.isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={150}
                                                // isInvalid={formValidation['WorkPhoneExt']?.isValid == false}
                                                // invalidWarning={formValidation['WorkPhoneExt']?.warningText}
                                                className='texbox-input'>
                                            </TextInputWithLabelInline>
                    </Column>
                    <Column lg={4} md={4}></Column>
                </Row>
            </Column>
                <Column lg={3} md={3} sm={3} xs={3} className="section-height">
                        <Row>
                            <Column lg={9} md={9}>
                            {/* <b>{label.CustomerScreentLables.PHONE_NUMBER}</b> */}
                            </Column>
                                    <Column lg={3} md={3}>
                                            <div className="cst-dp-div-actions">
                                                <Button
                                                    onClick={() => onDeleteContact()}
                                                    className=' btn-md btn-primary'
                                                    key="btnContactDel"
                                                    disabled ={!props.isEditable}
                                                    icon="trash"
                                                    id="btnContactDel">
                                                </Button>
                                            </div>
                                        </Column>
                        </Row>
                        <Row>
                        <Column lg={12} md={12} className={formValidation['mobile']?.isValid == false ? 'feedback-row' : ''}>
                                            <FormattedInputWithLableInline
                                                name='mobile'
                                                label={label.CustomerScreentLables.CELL_PHONE}
                                                placeholder={' '}
                                                value={contact.mobile??""}
                                                onValueChange={(newVal) => onEntityFieldChange('mobile', newVal)}
                                                isEditable={props.isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={15}
                                                className='texbox-input'
                                                inputFormatType={InputFormatType.Phone}
                                                isInvalid={formValidation['mobile']?.isValid == false}
                                            invalidWarning={formValidation['mobile']?.warningText}
                                                >
                                            </FormattedInputWithLableInline>
                                        </Column>
                        </Row>
                        <Row>
                        <Column lg={12} md={12} className={formValidation['homePhone']?.isValid == false ? 'feedback-row' : ''}>
                                            <FormattedInputWithLableInline
                                                name='homePhone'
                                                label={label.CustomerScreentLables.HOME_PHONE}
                                                placeholder={' '}
                                                value={contact.homePhone??""}
                                                onValueChange={(newVal) => onEntityFieldChange('homePhone', newVal)}
                                                isEditable={props.isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={15}
                                                className='texbox-input'
                                                inputFormatType={InputFormatType.Phone}
                                                isInvalid={formValidation['homePhone']?.isValid == false}
                                            invalidWarning={formValidation['homePhone']?.warningText}
                                                >
                                            </FormattedInputWithLableInline>
                                        </Column>
                        </Row>
                        <Row>
                        <Column lg={8} md={8} className={formValidation['workPhone']?.isValid == false ? 'formated_contact_error feedback-row' : 'formated_contact_error'}>
                                            <FormattedInputWithLableInline
                                                name='workPhone'
                                                label={label.CustomerScreentLables.WORK_PHONE}
                                                placeholder={' '}
                                                isRequired
                                                value={contact.workPhone??""}
                                                onValueChange={(newVal) => onEntityFieldChange('workPhone', newVal)}
                                                isEditable={props.isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                lglabel={5}
                                                mdlabel={5}
                                                lgText={7}
                                                mdText={7}
                                                maxLength={15}
                                                className='texbox-input'
                                                inputstyle={{width:"75%", paddingLeft:"9%", marginLeft:"16%"}}
                                                textInputContainerClass={"pr-0"}
                                                isInvalid={formValidation['workPhone']?.isValid == false}
                                            invalidWarning={formValidation['workPhone']?.warningText}
                                                inputFormatType={InputFormatType.Phone}>
                                            </FormattedInputWithLableInline>
                                        </Column>
                                        <Column lg={4} md={4}>
                                        <TextInputWithLabelInline
                                                name='workPhoneExt'
                                                label={label.CustomerScreentLables.EXT}
                                                placeholder={' '}
                                                value={contact.workPhoneExt??""}
                                                onChange={(event) => onEntityFieldChange('workPhoneExt', event.currentTarget.value)}
                                                isEditable={props.isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={5}
                                                // isInvalid={formValidation['WorkPhoneExt']?.isValid == false}
                                                // invalidWarning={formValidation['WorkPhoneExt']?.warningText}
                                                className='texbox-input no-spinner'>
                                            </TextInputWithLabelInline>
                                        </Column>
                        </Row>
                       
                </Column>
        </Row>
        </div>
    <br/>
    </>
   );
}

export default CustomerCompanyContactView;