import React, { useEffect, useState } from "react";
import { CustomerDependent } from "../model/CustomerDependentModel";
import { Row } from "react-bootstrap";
import { Button, Column } from "../../components";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { CustomerScreentLables as cstScrLables } from "../../shared/util/label";
import './CustomerDependentView.scss';
import { SelectOption } from "../../shared/components/input";
import { ConfirmModal } from "../../shared/components/confirmModal";
import * as label  from '../../shared/util/label';
import * as constant from '../../shared/util/constant';
import FormattedInputWithLableInline, { InputFormatType } from "../../shared/components/input/formatted-input/FormattedInputWithLableInline";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";

export interface ICustomerDependentView
{
    dependent : CustomerDependent,
    maritalStatuses : SelectOption[],
    industries : SelectOption[],
    occupancies : SelectOption[],
    relationships : SelectOption[],
    onFieldChange : (collectionPropName : string, collectionEntityIdentifierPropName : string, collectionEntityIdentifierValue : any, propName: string, value: string | Date|boolean) => void,
    onDelete : (dependent : CustomerDependent) => void,
    formValidations : any,
    isEditable : boolean,
    isReset : boolean,
    divToScroll : any
}


const CustomerDependentView : React.FunctionComponent<ICustomerDependentView> = (props) => {

    const dp = props.dependent;
    const formValidations = props.formValidations;
    const [dependent, setDependent] = useState<CustomerDependent>({});
    const [formValidation, setFormValidation] = useState<any>({});
    const [initialDependent, setInitialDependent] = useState<CustomerDependent>(dp);

    const modalContext = useConfirmationModalContext();

    // TODO : Remove once modal handler implemented
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const removeDependent = () => {
        setShowDeleteConfirmation(false);
        props.onDelete(dependent);
    }

 useEffect(() => {
        setDependent(initialDependent);
    }, [props.isReset]);

    useEffect(() => {
        setDependent(dp);
    }, []);

    useEffect(() => {
        setFormValidation(formValidations ? formValidations : {});
    },[formValidations]);
    
    const maritalStatuses = props.maritalStatuses??[];
    const industries = props.industries??[];
    const occupancies = props.occupancies??[];
    const relationships = props.relationships??[];
    
   // const isEditable = true;
    

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        setDependent({
            ...dependent,
            [propName]: value,
          });
       props.onFieldChange('customerDependents', 'depdId', dependent.depdId,  propName, value);
    }

    const onDependentDelete = async() => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_DEPENDENT_DELETE)
        if(result){
            removeDependent();
        }
    }

    return (
        <div className="cd-main-container" ref={props.divToScroll}>
            <Row>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                            id='ddRelationShip'
                            label={cstScrLables.RELATIONSHIP}
                            options={relationships}
                            defaultSelected={relationships?.filter(({ value }) => value === dependent.relationshipId)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('relationshipId', values[0])
                                }
                              }
                            labelSize={4}
                            dropDownSize={8}
                            labelClass='inlineLable'
                            disabled ={!props.isEditable}
                            placeholder={' '}
                            multiple={false}
                            tabIndex={9}
                        />
                </Column>
                <Column lg={6} md={6}></Column>
                <Column lg={3} md={3}>
                    <div className="cst-dp-div-actions">
                        <Button
                            onClick={() => onDependentDelete()}
                            className=' btn-md btn-primary'
                            key="btnDepdDel"
                            icon="trash"
                            disabled={!props.isEditable}
                            id="btnDepdDel">
                        </Button>
                    </div>
                </Column>
            </Row>
            <Row>
                <Column lg={3} md={3}
                    className={formValidation['firstName']?.isValid == false ? 'feedback-row' : ''}>
                    <TextInputWithLabelInline
                        name='firstName'
                        label={cstScrLables.FIRST_NAME}
                        placeholder={' '}
                        value={dependent.firstName??""}
                        onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        isInvalid={formValidation['firstName']?.isValid == false}
                        invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='midName'
                        label={cstScrLables.MIDDLE_NAME??""}
                        placeholder={' '}
                        value={dependent.midName}
                        onChange={(event) => onEntityFieldChange('midName', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3}
                    className={formValidation['lastName']?.isValid == false ? 'feedback-row' : ''}>
                    <TextInputWithLabelInline
                        name='lastName'
                        label={cstScrLables.LAST_NAME}
                        placeholder={' '}
                        value={dependent.lastName??""}
                        onChange={(event) => onEntityFieldChange('lastName', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        isInvalid={formValidation['lastName']?.isValid == false}
                        invalidWarning={formValidation['lastName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3} className={formValidation['mobile']?.isValid == false ? 'feedback-row' : ''}>
                    <FormattedInputWithLableInline
                        name='mobile'
                        label={cstScrLables.CELL_PHONE}
                        placeholder={' '}
                        value={dependent.mobile??""}
                        onValueChange={(newVal) => onEntityFieldChange('mobile', newVal)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={15}
                        className='texbox-input'
                        inputFormatType={InputFormatType.Phone}
                        isInvalid={formValidation['mobile']?.isValid == false}
                        invalidWarning={formValidation['mobile']?.warningText}
                        >
                    </FormattedInputWithLableInline>
                </Column>
            </Row>  
            <Row>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                            id='ddDeptIndustryId'
                            label={cstScrLables.INDUSTRY}
                            options={industries}
                            defaultSelected={industries?.filter(({ value }) => value === dependent.industryId)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('industryId', values[0])
                                }
                              }
                            labelSize={4}
                            dropDownSize={8}
                            disabled ={!props.isEditable}
                            labelClass='inlineLable'
                            placeholder={' '}
                            multiple={false}
                            tabIndex={5}
                        />
                </Column>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                            id='ddOccupation'
                            label={cstScrLables.OCCUPATION}
                            options={occupancies}
                            defaultSelected={occupancies?.filter(({ value }) => value === dependent.occupationId)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('occupationId', values[0])
                                }
                              }
                            labelSize={4}
                            dropDownSize={8}
                            disabled ={!props.isEditable}
                            labelClass='inlineLable'
                            placeholder={' '}
                            multiple={false}
                            tabIndex={6}
                        />
                </Column>
                <Column lg={3} md={3}
                    className={formValidation['yearEmployed']?.isValid == false ? 'feedback-row' : ''}>
                    <TextInputWithLabelInline
                        name='yearEmployed'
                        label={cstScrLables.YEAR_EMPLOYED}
                        placeholder={' '}
                        value={dependent.yearEmployed??""}
                        onChange={(event) => onEntityFieldChange('yearEmployed', event.currentTarget.value)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={4}
                        isInvalid={formValidation['yearEmployed']?.isValid == false}
                        invalidWarning={formValidation['yearEmployed']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3} className={formValidation['homePhone']?.isValid == false ? 'feedback-row' : ''}>
                    <FormattedInputWithLableInline
                        name='homePhone'
                        label={cstScrLables.HOME_PHONE}
                        placeholder={' '}
                        value={dependent.homePhone??""}
                        onValueChange={(newVal) => onEntityFieldChange('homePhone', newVal)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={15}
                        className='texbox-input'
                        inputFormatType={InputFormatType.Phone}
                        isInvalid={formValidation['homePhone']?.isValid == false}
                        invalidWarning={formValidation['homePhone']?.warningText}>
                    </FormattedInputWithLableInline>
                </Column>
            </Row>  
            <Row>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                            id='ddDependentMaritalStatus'
                            label={cstScrLables.MARITAL_STATUS}
                            options={maritalStatuses}
                            defaultSelected={maritalStatuses?.filter(({ value }) => value === dependent.maritalStatusId)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('maritalStatusId', values[0])
                                }
                              }
                            labelSize={4}
                            dropDownSize={8}
                            labelClass='inlineLable'
                            disabled ={!props.isEditable}
                            placeholder={' '}
                            multiple={false}
                            tabIndex={9}
                        />
                </Column>
                <Column lg={3} md={3}>
                    {/* Empty Place Holder */}
                </Column>
                <Column lg={3} md={3}>
                    {/* Empty Place Holder */}
                </Column>
                <Column lg={3} md={3} className={formValidation['workPhone']?.isValid == false ? 'feedback-row' : ''}>
                    <FormattedInputWithLableInline
                        name='workPhone'
                        label={cstScrLables.WORK_PHONE}
                        placeholder={' '}
                        value={dependent.workPhone??""}
                        onValueChange={(newVal) => onEntityFieldChange('workPhone', newVal)}
                        isEditable={props.isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={15}
                        className='texbox-input'
                        inputFormatType={InputFormatType.Phone}
                        isInvalid={formValidation['workPhone']?.isValid == false}
                        invalidWarning={formValidation['workPhone']?.warningText}>
                    </FormattedInputWithLableInline>
                </Column>
            </Row>
        </div>
    );
}

export default CustomerDependentView;