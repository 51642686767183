import { Button, Column, Label, Row } from '../../../components'
import { FallbackComponent } from '../../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'
import TextInputWithLabelInline from '../../../shared/components/input/TextInputWithLabelInline';
import TextFieldWithLabelInline from '../../../shared/components/input/TextFieldWithLabelInline';
import * as label from '../../../shared/util/label'
import './ScreenPrivilege.scss';
import RoleDetailsModel from '../../model/RoleDetailsModel'
import ScreenPrivilegeGrid from './ScreenPrivielgeGrid';
import ScreenElementPrivlegeModel from '../../model/ScreenElementPrivlegeModel';
import { useContext, useEffect, useState } from 'react';
import { SelectOption } from '../../../shared/components/input/SelectOption';
import ScreenPrivilegeService from './ScreenPrivilegeService';
import AppContext from '../../../shared/components/AppContext';
import * as constant from '../../../shared/util/constant'
import { useConfirmationModalContext } from "../../../shared/components/ModalConfirmationContext";
import AppRolePrivielge from '../../model/AppRolePrivielge';
import { toast } from 'react-toastify';
import Logger from '../../../shared/services/Logger';

interface Props {
    roleId: string
    onCancel: () => void;
    onSave: (value: String) => void;
}


const ScreenPrivilege = (props: Props) => {

    const roleId = props.roleId

    const [screenPrivilegeGrids, setScreenPrivilegeGrids] = useState<ScreenElementPrivlegeModel[][]>([]);

    const screenPrivilegeService = new ScreenPrivilegeService();

    const [screenOptions, setScreenOptions] = useState<SelectOption[]>([]);

    const [selectedScreenOptions, setSelectedScreenOptions] = useState<{ [key: number]: string }>({});

    const initialScreenPrivilegeGridData: ScreenElementPrivlegeModel[] = [];

    const initialRoleDetails: RoleDetailsModel = {
        id: '',
        roleName: '',
        roleDescription: ''
    }

    const initialScreenPrivilege: AppRolePrivielge = {
        roleDetails: initialRoleDetails,
        featurePrivielges: []
    };

    const intialValidationState = {
        roleName: { isValid: true, message: '' },
        roleDescription: { isValid: true, message: '' },
    };

    const [roleDetails, setRoleDetails] = useState<RoleDetailsModel>(initialRoleDetails)

    const [roleScreenDetails, setRoleScreenDetails] = useState<AppRolePrivielge>(initialScreenPrivilege);

    const modalContext = useConfirmationModalContext();

    const roleDetailsContext = useContext(AppContext);

    const [validations, setValidations] = useState(intialValidationState);

    useEffect(() => {
        initializeFeatures();
    }, []);

    useEffect(() => {
        setScreenPrivilegeGrids([]);
        setRoleDetails(initialRoleDetails)
        setValidations(intialValidationState)
        setSelectedScreenOptions([]);

        if (roleId) {
            getRoleDetails();
        }
    }, [roleId]);

    useEffect(() => {
        setRoleScreenDetails({
            ...roleScreenDetails,
            roleDetails: roleDetails
        })
    }, [roleDetails]);

    useEffect(() => {
        setRoleScreenDetails({
            ...roleScreenDetails,
            featurePrivielges: screenPrivilegeGrids.map(grid => ({
                elementPrivilege: grid,
                featureId: grid[0]?.featureId
            }))
        })

    }, [screenPrivilegeGrids]);

    const onRoleScreenDetailsUpdate = async (roleScreenDetails : AppRolePrivielge) => {
        setRoleDetails(roleScreenDetails.roleDetails)
        const existingScreenOptionsSelected = roleScreenDetails.featurePrivielges.map(fp => fp.featureId);
        setSelectedScreenOptions(existingScreenOptionsSelected.reduce((acc, curr, index) => {
            acc[index] = curr;
            return acc;
        }, {}));
        setScreenPrivilegeGrids(roleScreenDetails.featurePrivielges.map(fp => fp.elementPrivilege))
    }

    const initializeFeatures = async () => {
        try {
            let response = await screenPrivilegeService.getFeatures();
            setScreenOptions(response)
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
            Logger.logError(error.toString());
        }
    }

    const getRoleDetails = async () => {
        try {
            let response = await screenPrivilegeService.getRoleDetails(roleId);
            setRoleScreenDetails(response)
            onRoleScreenDetailsUpdate(response)
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
            Logger.logError(error.toString());
        }
    }

    const handleAddScreenPrivilege = () => {
        setScreenPrivilegeGrids([...screenPrivilegeGrids, initialScreenPrivilegeGridData])
        roleDetailsContext.formSavedStatus = false;
    };

    const handleScreenOptionChange = (selectedValue: string, gridIndex: number) => {
        setSelectedScreenOptions((prevSelected) => ({
            ...prevSelected,
            [gridIndex]: selectedValue,
        }));
        //  roleDetailsContext.formSavedStatus = false;
    };

    const getFilteredScreenoptions = (gridIndex: number) => {
        return screenOptions.filter(
            (option) =>
                !Object.values(selectedScreenOptions).includes(option.value) ||
                selectedScreenOptions[gridIndex] === option.value
        )
    }

    const handleScreenPrivilegeChange = (index: number, updatedData: ScreenElementPrivlegeModel[]) => {
        roleDetailsContext.formSavedStatus = false;
        setScreenPrivilegeGrids((prevGrids) => {
            const newGrids = [...prevGrids];
            newGrids[index] = updatedData;
            return newGrids;
        })
    }

    const onCancel = async () => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
        if (result) {
            setRoleDetails(initialRoleDetails)
            setScreenPrivilegeGrids([])
            roleDetailsContext.formSavedStatus = true;
            props.onCancel();
        }
    }

    const validateLength = (value: string, min: number, max: number) => {
        if (value.length === 0)
            return 'required';
        else if (value.length < min)
            return 'tooShort';
        else if (value.length > max)
            return 'tooLong';
        else
            return null
    }

    const getValidationMessage = (fieldName: string, errorType: string | null) => {
        if (!errorType)
            return '';
        else if (errorType === 'required')
            return constant.FIELD_BLANK
        else if (errorType === 'tooShort' || errorType === 'tooLong') {
            if (fieldName === 'roleName')
                return constant.NAME_LEN_ERROR
            else if (fieldName === 'roleDescription')
                return constant.DESCRIPTION_LEN_ERROR
            else return '';
        }
        else return '';
    }

    const validateForSave = () => {
        const roleNameError = validateLength(roleDetails.roleName, 3, 150);
        const roleDescriptionError = validateLength(roleDetails.roleDescription, 3, 250);
        setValidations({
            roleName: {
                isValid: !roleNameError,
                message: getValidationMessage('roleName', roleNameError),
            },
            roleDescription: {
                isValid: !roleDescriptionError,
                message: getValidationMessage('roleDescription', roleDescriptionError),
            },
        });

        return !roleNameError && !roleDescriptionError
    };

    const onSave = async () => {
        if (validateForSave()) {
            if (!roleScreenDetails.roleDetails.id) {
                try {
                    const saveRoleDetailsResposne = await screenPrivilegeService.saveRoleDetails(roleScreenDetails)
                    if (saveRoleDetailsResposne) {
                        toast.success(constant.RolePrivilege.ROLE_SAVED_SUCCESSFULLY, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        setRoleScreenDetails(saveRoleDetailsResposne)
                        roleDetailsContext.formSavedStatus = true;
                        props.onSave(saveRoleDetailsResposne.roleDetails.id)
                    }
                    else {
                        toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        roleDetailsContext.formSavedStatus = false;
                    }
                } catch (error) {
                    toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                    Logger.logError(error.toString());// Catch any additional errors
                    roleDetailsContext.formSavedStatus = false;
                }
            }
            else {
                try {
                    const updateRoleDetailsResposne = await screenPrivilegeService.updateRoleDetails(roleScreenDetails)
                    if (updateRoleDetailsResposne) {
                        toast.success(constant.RolePrivilege.ROLE_SAVED_SUCCESSFULLY, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        setRoleScreenDetails(updateRoleDetailsResposne)
                        onRoleScreenDetailsUpdate(updateRoleDetailsResposne)
                        roleDetailsContext.formSavedStatus = true;
                         props.onSave(updateRoleDetailsResposne.roleDetails.id)

                    }
                    else {
                        toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        roleDetailsContext.formSavedStatus = false;
                    }
                } catch (error) {
                    toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                    Logger.logError(error.toString()); // Catch any additional errors
                    roleDetailsContext.formSavedStatus = false;
                }
            }
        }
        else {
            toast.warn(constant.RolePrivilege.ROLE_VALIDATION_ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onFieldChange = (name: string, value: string) => {
        setRoleDetails({
            ...roleDetails,
            [name]: value
        })
        roleDetailsContext.formSavedStatus = false;
    }

    return (
        <Row className="screenPrivilegeDetails">
            <Column lg={12} md={12}>
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                    <Column lg={12} md={12} className=''>
                        <Row>
                            <Column>
                                <br></br>
                                {roleId ?
                                    <Label text={`EDIT ${roleDetails.roleName.toLocaleUpperCase()}`} className='role-name-label'></Label>
                                    : null}
                            </Column>
                        </Row>
                        <Row className='cstPanel'>
                            <Column lg={4} className={validations.roleName.isValid ? 'feedback-row' : ''}>
                                <TextInputWithLabelInline
                                    label={label.RolePrivilegeSetupLabels.ROLE}
                                    placeholder={label.RolePrivilegeSetupLabels.ROLE_NAME}
                                    name="roleName"
                                    className=''
                                    value={roleDetails.roleName}
                                    onChange={(event) => onFieldChange('roleName', event.currentTarget.value)}
                                    isRequired
                                    isEditable={true}
                                    lglabel={2}
                                    mdlabel={2}
                                    lgText={10}
                                    mdText={10}
                                    isInvalid={!validations.roleName.isValid}
                                    invalidWarning={validations.roleName.message}
                                    labelClass='inlineLable'
                                    maxLength={150} />
                            </Column>
                            <Column lg={8} className={validations.roleDescription.isValid ? 'feedback-row' : ''}>
                                <TextFieldWithLabelInline
                                    label={label.DESCRIPTION}
                                    name='roleDescription'
                                    className='role-description'
                                    labelClass='inlineLable'
                                    value={roleDetails.roleDescription}
                                    onChange={(event) => onFieldChange('roleDescription', event.currentTarget.value)}
                                    isRequired
                                    isEditable={true}
                                    placeholder={label.DESCRIPTION}
                                    isInvalid={!validations.roleDescription.isValid}
                                    invalidWarning={validations.roleDescription.message}
                                    tabIndex={2}
                                    lglabel={2}
                                    mdlabel={2}
                                    lgText={10}
                                    mdText={10}
                                    maxLength={250}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <br></br>
                                <Button color='primary' outlined={true} onClick={handleAddScreenPrivilege}>{label.RolePrivilegeSetupLabels.ADD_SCREEN_PRIVILEGE}</Button>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <br></br>
                                {screenPrivilegeGrids.map((gridData, index) => (
                                    <ScreenPrivilegeGrid
                                        key={index}
                                        // gridIndex= {index}
                                        screenPrivilegeData={gridData}
                                        screenOptions={getFilteredScreenoptions(index)}
                                        onScreenDropDownChange={(selectedValue) => handleScreenOptionChange(selectedValue, index)}
                                        onGridDataChange={(updatedData) => handleScreenPrivilegeChange(index, updatedData)}
                                        zIndexHeader={10000 - index * 100}>
                                    </ScreenPrivilegeGrid>
                                ))}
                            </Column>
                        </Row>
                        <Row className='screen-privelege-footer'>
                            <Column lg={12} className='right-align'>
                                <Button color='primary' outlined={false} onClick={onSave} >{label.SAVE.toUpperCase()}</Button>
                                <Button color='primary' outlined={true} onClick={onCancel}>{label.CANCEL.toUpperCase()}</Button>
                            </Column>
                        </Row>
                    </Column>
                </ErrorBoundary>
            </Column>
        </Row>

    );
}

export default ScreenPrivilege;