import {AxiosRequestConfig, AxiosResponse} from 'axios'
import { client } from "../../shared/services/client"; 
import CampaignList from '../model/CampaignList'
import CampaignResult from '../model/CampaignResult';
import CampaignSendTest from '../model/CampaignSendTest';
import RequestCampaignRepsonse from '../model/RequestCampaignRepsonse';
import CampaignApprovalAPI from '../model/CampaignApprovalAPI';
import CampaignQueryFilterSetupAPI from '../model/CampaignQueryFilterSetupAPI';
import CampaignDetailsAPIRequest from '../model/CampaignDetailsAPIRequest';
import CampaignDetailsAPIResponse from '../model/CampaignDetailsAPIResponse';
import RequestCampaignApiRequest from '../model/RequestCampaignApiRequest';
import ApproveRejectCampaign from '../model/ApproveRejectCampaign';


export const getCampaignList = async (): Promise<CampaignList[]> => {
  client.setBaseURLForService("campaign")
    const res: AxiosResponse<CampaignList[]> =  await client.get<CampaignList[]>("/api/Campaign/GetDashboardCampaigns");
    return res.data;
  
  };

  export const getAllCampaignList = async (): Promise<CampaignList[]> => {
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<CampaignList[]> =  await client.get<CampaignList[]>("/api/Campaign/GetAllCampaigns");
  // console.log('API',res)
    return res.data;
  };

  export const getCustomerCampaignList = async (custId : string): Promise<CampaignList[]> => {
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<CampaignList[]> =  await client.get<CampaignList[]>(`/api/Campaign/GetCustomerCampaigns?customerId=${custId}`);
    return res.data;
  };

    export const fetchCampaignDetails = async (campaignId: string): Promise<any> => {
      client.setBaseURLForService("campaign");
      const response: AxiosResponse<any> = await client.get<CampaignDetailsAPIResponse>("api/Campaign/"+campaignId);
      return response
    };

    export const fetchCampaignApprovalDetails = async (campaignId: string): Promise<any> => {
      client.setBaseURLForService("campaign");
      const response: AxiosResponse<any> = await client.get<CampaignApprovalAPI>("api/CampaignApproval/GetCampaignDetailsForApproval?_campaignId="+campaignId);
      return response
    };
    
    export async function saveCampaignDetails(campaignDetails:CampaignDetailsAPIRequest) : Promise<any> {
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> = (await client.post("/api/Campaign/CreateCampaign",campaignDetails));
      return response     
    }

    export async function updateCampaignDetails(campaignDetails:CampaignDetailsAPIRequest) : Promise<any> {
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> = (await client.post("/api/Campaign/UpdateCampaign",campaignDetails));
      return response    
    }

    export async function deleteCampaign(campaignId:string) :Promise<any>{
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> =  await client.delete("/api/Campaign/"+campaignId);
      return response;
      
    }

     export const fetchCampaignQueryFilterSetup = async (queryId: string): Promise<CampaignQueryFilterSetupAPI> => {
     client.setBaseURLForService("campaign")
        const res: AxiosResponse<CampaignQueryFilterSetupAPI> = await client.get<CampaignQueryFilterSetupAPI>("api/Campaign/GetSelectedCampaignQuery?id="+queryId);
       const campaignQueryFilters = res.data;
        return campaignQueryFilters 
     };

     export const getBlackoutDates = async (): Promise<any> => {
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> = await client.get<Date[]>("api/Campaign/GetAllBlackoutDates");
      return response
     }

     export const fetchCampaignResults = async (campaignId:string): Promise<any> => {
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> = await client.get<CampaignResult[]>("api/Campaign/GetCampaignResultsById?id="+campaignId);
      return  response
    };

    
    export async function sendTestMailForCampaignResult(campaignSendTestDetails:CampaignSendTest){
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> = (await client.post("/api/Campaign/SendTestEmail",campaignSendTestDetails));
      return response     
    }
  
    // export const requestCampaignDetails = async (campaignDetail: CampaignDetails): Promise<RequestCampaignRepsonse> => {

    //   const response: AxiosResponse<RequestCampaignRepsonse> = (await client.post("http://localhost:5000/RequestCampaign",campaignDetail));
    //   return response.data
    // }

    export async function requestCampaignDetails(requestCampaignApiRequest: RequestCampaignApiRequest): Promise<any> {
      // console.log("Request Campaign request: " + requestCampaignApiRequest)
      client.setBaseURLForService("campaign")
      const response: AxiosResponse<any> = await client.post("/api/Campaign/GetRequestCampaignPopupDetails", requestCampaignApiRequest);
      return response;
    }

    export  async function getcsvResultFromCampaignId(campaignId: string): Promise<any> {
      client.setBaseURLForService("campaign");
      const response: AxiosResponse<any> = await client.get<any>("api/Campaign/getcsvResultFromId?id="+campaignId);
      return response
    };
    
    export async function approveRejectCampaign(approveRejectDetails:ApproveRejectCampaign) : Promise<any> {
      client.setBaseURLForService("campaign")
      const queryParams = new  URLSearchParams({
        campaignId: approveRejectDetails.campaignId,
        isApproved: approveRejectDetails.isApproved.toString(),
        rejectionReason: approveRejectDetails.rejectionReason      
      });
      const response: AxiosResponse<any> = (await client.post(`/api/CampaignApproval/ApproveRejectCampaign?${queryParams.toString()}`));
      return response     
    }