import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DocuMateScanPortal.scss';
import { Icon } from '../../components';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import * as docuMateLabel from '../shared/util/docuMateLabel';
import * as docuMateConstant from '../shared/util/docuMateConstant';
import { ConfirmModal } from '../../shared/components/confirmModal';
import DocViewer from './../shared/components/DocViewer';

const initialData: DocImage[] = [
    {
        "id": "1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6",
        "fileName": "Sample File for DocuMate DnD Test 1"
    },
    {
        "id": "2b3c4d5e-6f7g-8h9i-0j1k-l2m3n4o5p6q7",
        "fileName": "Sample File for DocuMate DnD Test 2"
    },
    {
        "id": "3c4d5e6f-7g8h-9i0j-1k2l-m3n4o5p6q7r8",
        "fileName": "Sample File for DocuMate DnD Test 3"
    },
    {
        "id": "4d5e6f7g-8h9i-0j1k-2l3m-n4o5p6q7r8s9",
        "fileName": "Sample File for DocuMate DnD Test 4"
    },
    {
        "id": "5e6f7g8h-9i0j-1k2l-3m4n-o5p6q7r8s9t0",
        "fileName": "Sample File for DocuMate DnD Test 5"
    },
    {
        "id": "6f7g8h9i-0j1k-2l3m-4n5o-p6q7r8s9t0u1",
        "fileName": "Sample File for DocuMate DnD Test 6"
    },
    {
        "id": "7g8h9i0j-1k2l-3m4n-5o6p-q7r8s9t0u1v2",
        "fileName": "Sample File for DocuMate DnD Test 7"
    },
    {
        "id": "8h9i0j1k-2l3m-4n5o-6p7q-r8s9t0u1v2w3",
        "fileName": "Sample File for DocuMate DnD Test 8"
    },
    {
        "id": "9i0j1k2l-3m4n-5o6p-7q8r-s9t0u1v2w3x4",
        "fileName": "Sample File for DocuMate DnD Test 9"
    },
    {
        "id": "0j1k2l3m-4n5o-6p7q-8r9s-t0u1v2w3x4y5",
        "fileName": "Sample File for DocuMate DnD Test 10"
    },
    {
        "id": "1k2l3m4n-5o6p-7q8r-9s0t-u1v2w3x4y5z6",
        "fileName": "Sample File for DocuMate DnD Test 11"
    },
    {
        "id": "2l3m4n5o-6p7q-8r9s-0t1u-v2w3x4y5z6a7",
        "fileName": "Sample File for DocuMate DnD Test 12"
    },
    {
        "id": "3m4n5o6p-7q8r-9s0t-1u2v-w3x4y5z6a7b8",
        "fileName": "Sample File for DocuMate DnD Test 13"
    },
    {
        "id": "4n5o6p7q-8r9s-0t1u-2v3w-x4y5z6a7b8c9",
        "fileName": "Sample File for DocuMate DnD Test 14"
    },
    {
        "id": "5o6p7q8r-9s0t-1u2v-3w4x-y5z6a7b8c9d0",
        "fileName": "Sample File for DocuMate DnD Test 15"
    },
    {
        "id": "6p7q8r9s-0t1u-2v3w-4x5y-z6a7b8c9d0e1",
        "fileName": "Sample File for DocuMate DnD Test 16"
    },
    {
        "id": "7q8r9s0t-1u2v-3w4x-5y6z-a7b8c9d0e1f2",
        "fileName": "Sample File for DocuMate DnD Test 17"
    },
    {
        "id": "8r9s0t1u-2v3w-4x5y-6z7a-b8c9d0e1f2g3",
        "fileName": "Sample File for DocuMate DnD Test 18"
    },
    {
        "id": "9s0t1u2v-3w4x-5y6z-7a8b-c9d0e1f2g3h4",
        "fileName": "Sample File for DocuMate DnD Test 19"
    },
    {
        "id": "0t1u2v3w-4x5y-6z7a-8b9c-d0e1f2g3h4i5",
        "fileName": "Sample File for DocuMate DnD Test 20"
    }];

const initialFolders = {
    "CentralProcessing": {
        name: "Central Processing",
        path: "CP",
        files: [],
        subfolders: {
            "AlarmCertificate": {
                "name": "Alarm Certificate",
                "path": "Central Processing/Alarm Certificate",
                "files": []
            },
            "CancellationRequest": {
                "name": "Cancellation Request",
                "path": "Central Processing/Cancellation Request",
                "files": []
            },
            "Check": {
                "name": "Check",
                "path": "Central Processing/Check",
                "files": []
            },
            "ClaimNotice": {
                "name": "Claim Notice",
                "path": "Central Processing/Claim Notice",
                "files": []
            },
            "ClaimSurvey": {
                "name": "Claim Survey",
                "path": "Central Processing/Claim Survey",
                "files": []
            },
            "CustomerContactInfo": {
                "name": "Customer Contact Info",
                "path": "Central Processing/Customer Contact Info",
                "files": []
            },
            "LenderChangeRequest": {
                "name": "Lender Change Request",
                "path": "Central Processing/Lender Change Request",
                "files": []
            },
            "Miscellaneous": {
                "name": "Miscellaneous",
                "path": "Central Processing/Miscellaneous",
                "files": []
            },
            "MoneyEndorsement": {
                "name": "Money Endorsement",
                "path": "Central Processing/Money Endorsement",
                "files": []
            },
            "NewBusiness": {
                "name": "New Business",
                "path": "Central Processing/New Business",
                "files": []
            },
            "NonMoneyEndorsement": {
                "name": "Non-Money Endorsement",
                "path": "Central Processing/NonMoney Endorsement",
                "files": []
            },
            "NonPayNOC": {
                "name": "Non-Pay NOC",
                "path": "Central Processing/NonPay NOC",
                "files": []
            },
            "NonRenewalNotice": {
                "name": "Non-Renewal Notice",
                "path": "Central Processing/NonRenewal Notice",
                "files": []
            },
            "Refi": {
                "name": "Refi",
                "path": "Central Processing/Refi",
                "files": []
            },
            "RefiDuplicate": {
                "name": "Refi Duplicate",
                "path": "Central Processing/Refi Duplicate",
                "files": []
            },
            "Reinstatement": {
                "name": "Reinstatement",
                "path": "Central Processing/Reinstatement",
                "files": []
            },
            "RenewalOffer": {
                "name": "Renewal Offer",
                "path": "Central Processing/Renewal Offer",
                "files": []
            },
            "Renewal": {
                "name": "Renewal",
                "path": "Central Processing/Renewal",
                "files": []
            },
            "ReturnMail": {
                "name": "Return Mail",
                "path": "Central Processing/Return Mail",
                "files": []
            },
            "UnderwritingNOC": {
                "name": "Underwriting NOC",
                "path": "Central Processing/Underwriting NOC",
                "files": []
            }
        }
    },
    "CustomerService": {
        name: "Customer Service",
        path: "CS",
        files: [],
        subfolders: {
            "AdvancedNonRenewal": {
                "name": "Advanced Non-Renewal",
                "path": "Customer Service/Advanced Non Renewal",
                "files": []
            },
            "ApplicationQuestionnaire": {
                "name": "Application/Questionnaire",
                "path": "Customer Service/Application Questionnaire",
                "files": []
            },
            "Appraisal": {
                "name": "Appraisal",
                "path": "Customer Service/Appraisal",
                "files": []
            },
            "Authorization": {
                "name": "Authorization",
                "path": "Customer Service/Authorization",
                "files": []
            },
            "CompanyMemo": {
                "name": "Company Memo",
                "path": "Customer Service/Company Memo",
                "files": []
            },
            "CSGAgentFollowUp": {
                "name": "CSG Agent Follow Up",
                "path": "Customer Service/CSG Agent Follow Up",
                "files": []
            },
            "DeathCertificate": {
                "name": "Death Certificate",
                "path": "Customer Service/Death Certificate",
                "files": []
            },
            "EndorsementAuthorization": {
                "name": "Endorsement Authorization",
                "path": "Customer Service/Endorsement Authorization",
                "files": []
            },
            "InspectionReport": {
                "name": "Inspection Report",
                "path": "Customer Service/Inspection Report",
                "files": []
            },
            "CSMiscellaneous": {
                "name": "Miscellaneous",
                "path": "Customer Service/Miscellaneous",
                "files": []
            },
            "OccupancyLetter": {
                "name": "Occupancy Letter",
                "path": "Customer Service/Occupancy Letter",
                "files": []
            },
            "Retention": {
                "name": "Retention",
                "path": "Customer Service/Retention",
                "files": []
            }
        }
    },
    "Sales": {
        name: "Sales",
        path: "Sales",
        files: [],
        subfolders: {
            "Auto Miscellaneous": {
                "name": "Auto Miscellaneous",
                "path": "Sales/Auto Miscellaneous",
                "files": []
            },
            "Home Miscellaneous": {
                "name": "Home Miscellaneous",
                "path": "Sales/Home Miscellaneous",
                "files": []
            },
            "SalesReturnMail": {
                "name": "Return Mail",
                "path": "Sales/Return  Mail",
                "files": []
            }
        }
    }
}

const reScanFolder = {
    "Rescan": {
        name: "Rescan",
        path: "Rescan",
        files: []
    }
}

const DocuMateScanPortal = () => {
    const [unassignedFiles, setUnassignedFiles] = useState(initialData);
    const [destinationFolders, setDestinationFolders] = useState(initialFolders);
    const [rescanFolder, setRescanFolder] = useState(reScanFolder);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [expandedFolders, setExpandedFolders] = useState({});
    const [showConfirmAssign, setShowConfirmAssign] = useState(false);
    const [showEmptyRescan, setShowEmptyRescan] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [pendingDragDop, setPendingDragDrop] = useState(null);
    const [confirmAssignBody, setConfirmAssignBody] = useState(null);
    const [docViewerOpen, setDocViewerOpen] = useState(false);
    const [docId, setDocId] = useState('');
    const [fileToDelete, setFileToDelete] = useState('');
    const [fileName, setFileName] = useState('');
    const getConfirmDeleteBody = (fileName) => (
        <>   
        Are you sure you want to delete <strong>{fileName}</strong> from the <strong>Rescan</strong> folder?
        </>
    );

    const confirmDeleteBody = getConfirmDeleteBody(fileName);

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || destination.droppableId === source.droppableId) {
            return;
        }
        let selectedFilesToMove = selectedFiles.includes(result.draggableId) ? selectedFiles : [result.draggableId];
        if (destination.droppableId === 'Rescan') {
            setConfirmAssignBody(<span>Are you sure you want to assign {selectedFilesToMove.length} scans to the <b>Rescan</b> folder?</span>);
          } else {
            const destFolder = getFolderById(destinationFolders, destination.droppableId);
            let [folderGrp, folderName] = destFolder.path.split('/');
            setConfirmAssignBody(<span>Are you sure you want to assign {selectedFilesToMove.length} scans to the <b>{folderGrp} Group "{folderName}"</b> folder?</span>);
          }
        if (selectedFilesToMove.length > 1) {
          // If more than one file is selected, open the modal
          setPendingDragDrop(result); // Store the result in state
          setShowConfirmAssign(true);
        } else {
          // If only one file is selected, move it immediately
          moveFiles(result, selectedFilesToMove);
        }
      }

    const moveFiles = (result, selectedFilesToMove) => {
        const { source, destination } = result;
        let updatedUnassignedFiles = [...unassignedFiles];
        let updatedFolders = { ...destinationFolders };
        let updatedRescanFolder = { ...rescanFolder };

        const filesToMove = source.droppableId === 'unassigned'
            ? unassignedFiles.filter(file => selectedFilesToMove.includes(file.id))
            : source.droppableId === 'Rescan'
                ? rescanFolder.Rescan.files.filter(file => selectedFilesToMove.includes(file.id))
                : getFolderById(destinationFolders, source.droppableId).files.filter(file => selectedFilesToMove.includes(file.id));

        if (source.droppableId === 'unassigned') {
            updatedUnassignedFiles = unassignedFiles.filter(file => !selectedFilesToMove.includes(file.id));
        } else if (source.droppableId === 'Rescan') {
            updatedRescanFolder.Rescan.files = rescanFolder.Rescan.files.filter(file => !selectedFilesToMove.includes(file.id));
        } else {
            const sourceFolder = getFolderById(updatedFolders, source.droppableId);
            sourceFolder.files = sourceFolder.files.filter(file => !selectedFilesToMove.includes(file.id));
        }

        if (destination.droppableId === 'Rescan') {
            updatedRescanFolder.Rescan.files.push(...filesToMove);
        } else {
            const destFolder = getFolderById(updatedFolders, destination.droppableId);
            destFolder.files.push(...filesToMove);
        }

        setUnassignedFiles(updatedUnassignedFiles);
        setDestinationFolders(updatedFolders);
        setRescanFolder(updatedRescanFolder);
        setSelectedFiles([]);
    }

    const getFolderById = (folders, id) => {
        for (const key in folders) {
            if (key === id) return folders[key];
            const subfolder = getFolderById(folders[key].subfolders, id);
            if (subfolder) return subfolder;
        }
        return null;
    }

    const handleCheckBoxChange = (fileId) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.includes(fileId) ? prevSelectedFiles.filter(selectedFile => selectedFile !== fileId) : [...prevSelectedFiles, fileId])
    }

    const handleSelectAll = () => {
        if (selectedFiles.length === unassignedFiles.length) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(unassignedFiles.map(file => file.id));
        }
    }

    const toggleFolder = (folderId) => {
        setExpandedFolders(prevState => ({
            ...prevState,
            [folderId]: !prevState[folderId]
        }));
    }

    const handleCancelAssign = () => {
        setPendingDragDrop(null);
        setShowConfirmAssign(false);
    }

    const handleConfirmAssign = () => {
        if (pendingDragDop) {
            let selectedFilesToMove = selectedFiles.includes(pendingDragDop.draggableId) ? selectedFiles : [pendingDragDop.draggableId];
            moveFiles(pendingDragDop, selectedFilesToMove);
            setPendingDragDrop(null);
            setConfirmAssignBody(null);
          }
          setShowConfirmAssign(false);
    }

    const deleteFileFromRescan = () => {
        setRescanFolder((prevRescanFolder) => {
            const updatedFiles = prevRescanFolder.Rescan.files.filter(file => file.id !== fileToDelete);
            return {
                ...prevRescanFolder,
                Rescan: {
                    ...prevRescanFolder.Rescan,
                    files: updatedFiles
                }
            };
        });
        if(docId === fileToDelete) {
            setDocId('');
            setDocViewerOpen(false);
        }
        setShowConfirmDelete(false);
    }

    const emptyRescanFolder = () => {
        if (rescanFolder.Rescan.files.some(file => file.id === docId)) {
            setDocId('');
            setDocViewerOpen(false);
        }
        setRescanFolder((prevRescanFolder) => ({
            ...prevRescanFolder,
            Rescan: {
                ...prevRescanFolder.Rescan,
                files: []
            }
        }));
    }

    const handleCancelEmptyRescan = () => {
        setShowEmptyRescan(false);
    }

    const handleCancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const handleEmptyRescan = () => {
        emptyRescanFolder();
        setShowEmptyRescan(false);
    }

    const RescanFolder = ({ folderId, folder }) => (
        <Droppable droppableId={folderId} key={folderId}>
            {(provided) => (
                <div
                    className="folder"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    <div style={{ fontWeight: 'bold' }}>
                        {folder.files.length > 0 ? (
                            <Icon icon="folderOpen" outline="false" className="folder-icon" />
                        ) : (
                            <Icon icon="folder" outline="false" className="folder-icon" />
                        )}
                        {folder.name}{folder.files.length > 0 && (<span onClick={() => setShowEmptyRescan(true)}><Icon icon="trash" className="trash-icon"/></span>)}
                        {folder.files.length > 0 && (
                            <span className="count">{folder.files.length}</span>
                        )}
                    </div>
                    {folder.files.map((file, index) => (
                        <Draggable key={file.id} draggableId={file.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`dest-file-item ${snapshot.isDragging ? 'dragging' : ''} ${docId === file.id ? 'clicked' : ''}`}
                                    onClick={() => handleFileClick(file.id)}
                                >
                                    {file.fileName}
                                    {!snapshot.isDragging && (
                                        <span 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowConfirmDelete(true);
                                            setFileToDelete(file.id);
                                            setFileName(file.fileName);}}>
                                            <Icon icon="trash" className="trash-icon"/>
                                        </span>
                                    )}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
      );

    const getFolderCount = (folder) => {
        let count = folder.files.length;
        for (const subfolderId in folder.subfolders) {
            count += getFolderCount(folder.subfolders[subfolderId]);
        }
        return count;
    }

    const handleFileClick = (docId) => {
        if (selectedFiles.length > 1) {
          // More than one file selected, do not open the doc viewer
          setDocId('');
          setDocViewerOpen(false);
        } else if (selectedFiles.length === 1 && selectedFiles[0] !== docId) {
          // Only one file selected and it's the same as the clicked file, open the doc viewer
          setDocId('');
          setDocViewerOpen(false);
        } else {
          setDocId(docId);
          setDocViewerOpen(true);
        }
      };

    const handleDocViewerClose = () => {
        setDocViewerOpen(false);
        setDocId('');
    }

    const renderFolders = (folders) => {
        return Object.keys(folders).map((folderId) => {
                return (
                    <div key={folderId} className="folder">
                        <div onClick={() => toggleFolder(folderId)} style={{fontWeight: 'bold'}}>
                            {expandedFolders[folderId] ? (
                                <FaChevronDown className="folder-icon" />
                            ) : (
                                <FaChevronRight className="folder-icon" />
                            )}
                            {folders[folderId].name}
                            {getFolderCount(folders[folderId]) > 0 && (
                                <span className="count">{getFolderCount(folders[folderId])}</span>
                            )}
                        </div>
                        {expandedFolders[folderId] && (
                            <>
                                {Object.keys(folders[folderId].subfolders).map((subfolderId) => (
                                    <Droppable droppableId={subfolderId} key={subfolderId}>
                                        {(provided) => (
                                            <div
                                                className="subfolder"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                <div>
                                                {folders[folderId].subfolders[subfolderId].files.length > 0 ? <Icon icon="folderOpen" outline='false' className='subfolder-icon'/> : <Icon icon="folder" outline='false' className='subfolder-icon'/>}
                                                    {folders[folderId].subfolders[subfolderId].name} 
                                                    {folders[folderId].subfolders[subfolderId].files.length > 0 && (
                                                        <span className="count">{folders[folderId].subfolders[subfolderId].files.length}</span>
                                                    )}
                                                </div>
                                                {folders[folderId].subfolders[subfolderId].files.map(
                                                    (file: { id: string, fileName: string }, index: number) => (
                                                        <Draggable key={file.id} draggableId={file.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={`dest-file-item ${snapshot.isDragging ? 'dragging' : ''} ${docId === file.id ? 'clicked' : ''}`}
                                                                    onClick={() => handleFileClick(file.id)}
                                                                >
                                                                    {file.fileName}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </>
                        )}
                    </div>
                );
        });
    };

    return (
        <>
        <div className='bulkActionHeader'>Selected {selectedFiles.length} of {unassignedFiles.length} for bulk actions</div>
        <div className='parent-container'>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="docs-container">
                {/* Unassigned Files Panel */}
                <div className="left-panel">
                    <div className="panel-header">
                        <label>
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedFiles.length === unassignedFiles.length && unassignedFiles.length > 0}
                            />
                            {docuMateLabel.DOCUMATE_UNASSIGNED.toUpperCase()} <span className="count">{unassignedFiles.length}</span>
                        </label>
                    </div>
                    <Droppable droppableId="unassigned">
                        {(provided) => (
                            <div
                                className="file-list"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {unassignedFiles.map((file, index) => (
                                    <Draggable key={file.id} draggableId={file.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`file-item ${snapshot.isDragging ? 'dragging' : ''} ${selectedFiles.includes(file.id) ? 'selected' : ''} ${docId === file.id ? 'clicked' : ''}`}
                                                onClick={() => handleFileClick(file.id)}
                                            >
                                            {!snapshot.isDragging && (
                                                <input
                                                    type="checkbox"
                                                    checked={selectedFiles.includes(file.id)}
                                                    onChange={() => handleCheckBoxChange(file.id)}
                                                    onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the div click
                                                />
                                            )}
                                                {snapshot.isDragging && selectedFiles.length > 1
                                                    ? (
                                                        <>
                                                            {file.fileName}{' '}<span style={{fontWeight:'bold'}}>+ {selectedFiles.length - 1} more</span>
                                                        </>
                                                    )
                                                    : file.fileName}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                {/* Destination Folders Panel */}
                <div className="right-panel">
                    <div className="panel-header">
                        <label>{docuMateLabel.DOCUMATE_DEST_FOLDER.toUpperCase()}</label>
                    </div>
                    {renderFolders(destinationFolders)}
                    {/* Rescan Folder */}
                    <RescanFolder folderId="Rescan" folder={rescanFolder.Rescan} />
                </div>
            </div>
            {docViewerOpen &&
            <div className='doc-view-container'>
                <DocViewer docId='' handleDocViewerClose={handleDocViewerClose}/>
            </div>
            }
        </DragDropContext>
        <ConfirmModal
        modaltitle={docuMateLabel.CONFIRM}
        showConfirmation={showConfirmAssign}
        setConfirmation={(isModalOpen) => setShowConfirmAssign(isModalOpen)}
        body={confirmAssignBody}
        closeButtonClick={() => handleCancelAssign()}
        successButtonClick={() => handleConfirmAssign()}
        closeButtonLabel={docuMateLabel.CONFIRM_NO.toUpperCase()}
        successButtonLabel={docuMateLabel.CONFIRM_YES.toUpperCase()}
        />
        <ConfirmModal
        modaltitle={docuMateLabel.CONFIRM}
        showConfirmation={showEmptyRescan}
        setConfirmation={(emptyRescanOpen) => setShowEmptyRescan(emptyRescanOpen)}
        body={docuMateConstant.CONFIRM_EMPTY_RESCAN}
        closeButtonClick={() => handleCancelEmptyRescan()}
        successButtonClick={() => handleEmptyRescan()}
        closeButtonLabel={docuMateLabel.CONFIRM_NO.toUpperCase()}
        successButtonLabel={docuMateLabel.CONFIRM_YES.toUpperCase()}
        />
      </div>
      <ConfirmModal
        modaltitle={docuMateLabel.CONFIRM}
        showConfirmation={showConfirmDelete}
        setConfirmation={(showConfirmDelete) => setShowConfirmDelete(showConfirmDelete)}
        body={confirmDeleteBody}
        closeButtonClick={() => handleCancelDelete()}
        successButtonClick={() => deleteFileFromRescan()}
        closeButtonLabel={docuMateLabel.CONFIRM_NO.toUpperCase()}
        successButtonLabel={docuMateLabel.CONFIRM_YES.toUpperCase()}
        />
    </>
    );
};

export default DocuMateScanPortal;