import { Select, Label, Button, Container, Row, Column,Panel,Spinner } from '../components'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextInputWithLabelFormGroup from '../shared/components/input/TextInputWithLabelFormGroup'
import { useButtonToolbarSetter } from '../page-header/button-toolbar/ButtonBarProvider'
import { useUpdateTitle } from '../page-header/title/TitleContext'
import { SelectOption } from '../shared/components/input/SelectOption'
import useTranslator from '../shared/hooks/useTranslator'
import useQuery from 'react-query' 
import useStateCodes from '../lookups/useStateCodes'

const CustomerDashboardView = () => {

    return (
        <div style={{display : 'flex', justifyContent : 'space-between'}}>
            Customer Dashboard
        </div>
    // <div className="row">

    //     <h4>Dashboard</h4>
    // </div>
    // <div>
    //     <h4>Dashboard</h4>
    // </div>
        )
    }
export default CustomerDashboardView