import React from "react";
import { Modal } from "../../../components";
import './EntityHistoryViewer.scss';
import EntityHistoryGrid from "./EntityHistoryGrid";

export interface IEntityHistoryViewer {
    title : string,
    show : boolean,
    onClose : () => void,
    data : any[],
    columns : any[],
    defaultPageSize? : number,
    paginationDisabled? : boolean,
    hiddenColumns? : string[],
    groupByColumns? : string[],
    columnsGroupOn? : string[],
    entityHistoryMainDivClassName? : string,
    entityHistoryGrdTableContainerClass? : string,
    modalClassName? : string,
}

const EntityHistoryViewer : React.FunctionComponent<IEntityHistoryViewer> = (props) => {
    return  <Modal
                title={props.title}
                show={props.show}
                backdrop={'static'}
                toggle={() => props.onClose()}
                showHeaderCloseButton={true}
                className={props.modalClassName ? props.modalClassName : 'entity-history-modal'}
                closeButton={{
                    size: "small",
                    iconStyle: { color: "gray" },
                    outlined: true,
                    color: 'link',
                    onClick : () => props.onClose(),
                    children: <button className={"btn btn-outline-tertiary"}>{'CLOSE'.toUpperCase()}</button>
                    }}
                
                buttonsAlignment={"right"}
                body={
                    <EntityHistoryGrid
                        data={props.data} 
                        columns={props.columns}
                        hiddenColumns={props.hiddenColumns}
                        groupByColumns={props.groupByColumns}
                        columnsGroupOn={props.columnsGroupOn}
                        showFilters={false}
                        defaultPageSize={props.defaultPageSize}
                        paginationDisabled={props.paginationDisabled}
                        entityHistoryMainDivClassName={props.entityHistoryMainDivClassName}
                        entityHistoryGrdTableContainerClass={props.entityHistoryGrdTableContainerClass}></EntityHistoryGrid>
                }
            ></Modal>;
};

export default EntityHistoryViewer