import { Select, Label, Button, Row, Column,Panel,Spinner, Checkbox, TextInput } from '../../components'
import React, { useContext, useEffect, useState } from 'react'
import { SelectOption } from '../../shared/components/input/SelectOption'
import '../customer.css'
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline'
import{getPolicyStatusList} from '../hooks/useLookUpOptions'
import CustomerFilterRequest from '../model/CustomerFilterRequest'
import {QuickSearchContext} from '../../customer/customer'
import {showGridContext} from '../filter/CustomerFilter'
import { ErrorBoundary } from 'react-error-boundary'
import _ from 'lodash'
import Logger from '../../shared/services/Logger'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../shared/util/constant'
import * as label from '../../shared/util/label'
import CustomerStandardSearchService from './CustomerStandardSearchService'
import FormattedInputWithLableInline, { InputFormatType } from '../../shared/components/input/formatted-input/FormattedInputWithLableInline'
import { AbilityContext } from '../../shared/abilities/AbilityContext'

// const updateTitle = useUpdateTitle()
//   useEffect(() => {
//     updateTitle('Customer Filter')
//   })
// interface Props {
//   onSearching: (filterRequest: CustomerFilterRequest) => void,
//   onAllowingSearching: (isSearchingAllowed: boolean) => void
// }
// interface Props{
//   onSearch:Function
// }


  const CustomerStandardFilterForm = (props) => {
    //const {onSearching, onAllowingSearching } = props

  //   const [state, setState] = useState({
  //   isPaneOpen: false,
  //   isPaneOpenLeft: false
  // });

  const custSearchService = new CustomerStandardSearchService();

  const [collapse, SetisCollapse]=useState(false)
  const [mailCollapse, SetMailCollapse]=useState(false)
  const [applicantSearch, SetApplicantSearch]=useState(false)
  const [coapplicantSearch, SetCoApplicantSearch]=useState(false)
  const [quickSearchStatus, SetquickSearchStatus]=useState(false)
  
  

  const initalFilterState: CustomerFilterRequest = {
    firstName: '',
    lastName: '',
    eMail : '',
    homePhone: '',
    address1: '',
    address2: '',
    city: '',
    county: '',
    zipCode: '',
    state : '',
    /* Uncommenting for now - enable back when advance serach is enabled.
    ApplicantOccupation: '',
    ApplicantSelfemployed: '',
    ApplicantDateofBirthFrom: null,
    ApplicantDateofBirthTo: null,
    CoApplicantFirstName: '',
    CoApplicantLastName: '',
    CoApplicantEmail : '',
    CoApplicantPhone: '',
    CoApplicantOccupation: '',
    CoApplicantSelfemployed: '',
    CoApplicantDateofBirthFrom: null,
    CoApplicantDateofBirthTo: null,
    LastDateSolicited:null,
    SolicitedCampaignsTimeframe:'',
    DaySinceLastSolicited:'',
    ByCampaigns:'',
    Unsubscribe:'',
    DoNotEmail:'',
    DoNotMail:'',
    EDD:'',
    SMSText:'',
    PropertyAppSuit:'',
    PropertyCity:'',
    PropertyCountry:'',
    PropertyState:'',
    PropertyZip:'',
    MailingStreetAddress:'',
    MailingAppSuit:'',
    MailingCity:'',
    MailingCountry:'',
    MailingState:'',
    MailingZip:'',
    RoofUpdateYearFrom:null,
    RoofUpdateYearTo:null,
    RoofType:'',
    RoofShape:'',
    RoofMaterial:'',
    ConstructionType:'',
    Pool:'',
    Trampoline:'',
    DogBreed:'',
    Carrier:'',
    WritingCompany:'',
    YearAsCustomer:'',
    Agency:'',
    PolicyType:'',
    PremiumFrom:'',
    PremiumTo:'',
    LoanNumber:'',
    NotationCode:'',
    CoverageARangeFrom:'',
    CoverangeARangeTo:'',
    YearBuiltRangeFrom:'',
    YearBuiltRangeTo:'',
    IntialPolicyEffectiveDateFrom:null,
    InitialPolicyEffectiveDateTo:null,
    CurrentPolicyEffectiveDateFrom:null,
    CurrentPolicyEffectiveDateTo:null,
    PolicyStatus:'',
    LastNoteEnteredBy:'',
    AllPerilDeductiblesFrom:null,
    AllPerilDeductibleTo:null,
    WindHailDeductiblesFrom:null,
    WindHailDeductiblesTo:null,
    HurricaneDeductiblesFrom:null,
    HurricaneDeductiblesTo:null,
    DwellingCoverageFrom:null,
    DwellingCoverageTo:null,
    PolicyNumber:'',
    AMSNumber:'',
    IsApplicantSearch:applicantSearch,
    IsCoApplicantSearch:coapplicantSearch,
    IsPropertySearch:collapse,
    IsMailingSearch:mailCollapse,
    IsQuickSearch:quickSearchStatus,
    QuickSearchString:''
    */
  }

   
  const [isSearchingAllowed, setIsSearchingAllowed] = useState(false);
  //const [GridContex,SetGridContext]=useContext(showGridContext);
  const {GridVisibility,InputFilter, searchResultData }=useContext(showGridContext);
  const [showGrid, setShowGrid]=GridVisibility
  const [searchFilter, setInputFilter]=InputFilter
  const {searchResult, setsearchResult} = searchResultData
  const [filter, setSearchFilter] = useState<CustomerFilterRequest>(initalFilterState);
  const {quickFilterData}=useContext(QuickSearchContext);
  //const[quickFilterContextData, setquickFilterContextData]=quickFilterData
  const[quickSearch, setQuickSearch]=useState(quickFilterData);

  const ability = useContext(AbilityContext);
  const canUserCreateCustomer = ability.can('create',"Customer",constant.ScreenName.CUSTOMER);
  

  //console.log("Standard Search"+ filter);
    useEffect(() => {
    if (custSearchService.IsFilterInputValid(filter)) {
      setIsSearchingAllowed(true);
      //setShowGrid(true)
    }
    else {
      //onAllowingSearching(false);
      setIsSearchingAllowed(false);
     // setShowGrid(false)

    }

  }, [filter]);
  


    const onSearch =  async () => {
     
      /*
      //console.log('Standard Search')
      if(quickSearch!= null && quickSearch!=undefined)
      {
      filter.IsQuickSearch=quickSearch[0].IsQuickSearch;
      filter.QuickSearchString=quickSearch[0].QuickSearchString
      setQuickSearch(quickFilterData)
      SetquickSearchStatus(filter.IsQuickSearch)
      }
      */
      //setSearchFilter(filter);
      //setInputFilter(filter)
      
      // GridContex.GridVisibility=showGrid
      // GridContex.InputFilter=filter
      // SetGridContext(GridContex)

      try
      {
        let customerSearchData = await custSearchService.FetchCustomers(filter);
        setsearchResult(customerSearchData);

        if (!customerSearchData || customerSearchData.length == 0) {
          toast.warn(constant.CUSTOMER_STANDARD_SEARCH_NO_RESULT_FOUND, {
            position: toast.POSITION.TOP_RIGHT
        });
        }
      }
      catch(ex)
      {
        toast.error(constant.CUSTOMER_STANDARD_SEARCH_ERROR_WHILE_SEARCHING, {
          position: toast.POSITION.TOP_RIGHT
      });
        Logger.logError(ex);
      }
      //setShowGrid(true);
    }

    useEffect(()=>{
      if(quickFilterData!=null &&quickFilterData[0].QuickSearchString!==undefined && quickFilterData[0].QuickSearchString.length>3 && quickFilterData[0].IsQuickSearch===true  )
      {
        onSearch();
      }
      },[quickFilterData]);

  const onFieldChange = (name: string, value: string | Date|boolean) => {
    setSearchFilter({
      ...filter,

      [name]: value,
    })
  }

 //const [PolicyOption, setPolicyOptions] = useState<SelectOption[]>([{ label: "", value: "" }]);
const manageCollapse=(event)=>{
   if(event.target.checked)
   {
     SetisCollapse(true)
    onFieldChange("IsPropertySearch",event.target.checked)
   }
   else{
    SetisCollapse(false)
    onFieldChange("IsPropertySearch",event.target.checked)
   }

 }
 useEffect(() => {
//
},[collapse])


const manageMailCollapse=(event)=>{
  if(event.target.checked)
  {
    SetMailCollapse(true)
    onFieldChange("IsMailingSearch",event.target.checked)
  }
  else{
    SetMailCollapse(false)
    onFieldChange("IsMailingSearch",event.target.checked)
  }

}
useEffect(() => {
//
},[mailCollapse]);

const manageApplicantSearch=(event)=>{
  if(event.target.checked)
  {
    SetApplicantSearch(true)
    onFieldChange("IsApplicantSearch",event.target.checked)
  }
  else{
    SetApplicantSearch(false)
    onFieldChange("IsApplicantSearch",event.target.checked)
    
  }

}


const manageCoApplicantSearch=(event)=>{
  if(event.target.checked)
  {
    SetCoApplicantSearch(true)
    onFieldChange("IsCoApplicantSearch",event.target.checked)
  }
  else{
    SetCoApplicantSearch(false)
    onFieldChange("IsCoApplicantSearch",event.target.checked)
  }

}

const onEnterClick =(event) => {
  if(isSearchingAllowed && event.target.id!=="btnStandardSearchClear" && event.key === 'Enter'){
    onSearch()
  }
}

useEffect(() => {
  window.addEventListener("keydown", onEnterClick);
    return () => {
        window.removeEventListener("keydown", onEnterClick);
    };

  },[onEnterClick]);

//#region Policy Status list
//const [filter, setFilter] = useState<CustomerFilterRequest>(initalFilterState);
const { data: policyStatus, isLoading: isLoadingPolicyStatus } = getPolicyStatusList();
      const [PolicyStatusOption, setPolicyStatusOptions] = useState<SelectOption[]>([{ label: "", value: "" }]);
/* enable when policy base search is enabled.
 useEffect(() => {
   if (policyStatus === undefined || isLoadingPolicyStatus) {

     <Spinner type="DotLoader" loading />
   }
   else {
     setPolicyStatusOptions(policyStatus.map((data) => {
       return {
         value: data.PolicyStatusCode,
         label: data.PolicyStatusName
       }
     }
     )
     )
   }

 }, [policyStatus])
 */
//#endregion Policy Status list
const FallbackComponent = ({ error, resetErrorBoundary }) => {
  console.log("An error occurred:");
  console.log(error.message);
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const onClearFilters = () => {
  setSearchFilter(initalFilterState);
  setsearchResult([]);
}

const onAddNewCustomer = () => {
  props.navigateTo("/customer-new");
}

  return (
    <ErrorBoundary  FallbackComponent={FallbackComponent}>   
        <Row className="bg-light">
        
          <Column lg={12}  md={12}>
         
        {/* <Row>
          <Column>
          <span className="labelStyle">
          <strong>Filter Discription</strong>: Homesales-AMS360 Accounts with Active Flod Policies with Pulte Aug2021.
            </span>

         
          </Column>
        </Row> */}
        <Row  className='paddingTop5'>
          <Column md={4} lg={4}  className='rightBorder'>
          <TextInputWithLabelInline
          name='Policy'
          label={label.StandardSearchLable.POLICY_NUMBER} 
          value={filter.policyNumber}
          maxLength={25}
          isEditable
         type='text'
         labelClass='inlineLable'
         onChange={(event) => onFieldChange('policyNumber', event.currentTarget.value)}
         tabIndex={4}
         lglabel={5}
                  mdlabel={5}
                  lgText={7}
                  mdText={7}
        />
          </Column>
          <Column md={4} lg={4}  className='paddingleft25'>
          <TextInputWithLabelInline
          name='FirstName'
          label={label.StandardSearchLable.FIRST_NAME}
          value={filter.firstName}
          isEditable
         type='text'
         labelClass='inlineLable'
         onChange={(event) => onFieldChange('firstName', event.currentTarget.value)}
         tabIndex={7}
         lglabel={5}
                  mdlabel={5}
                  lgText={7}
                  mdText={7}
        />
          </Column>
        
          <Column md={4} lg={4}  className='paddingTop1 cstCheckbox'>
          <div className='cust-filter-add-new-div'>
              <Checkbox
                id='SearchApplicant'
                label= {label.StandardSearchLable.SEARCH_INSURED}
                labelClassName='inlineLable'
                checked={applicantSearch}
                onChange={manageApplicantSearch}
                tabIndex='11'
                inline/>

              <Button onClick={onAddNewCustomer} disabled = {!canUserCreateCustomer}>Add New Customer</Button>
          </div>
        <Checkbox
        id='SearchCoApplicant'
        label={label.StandardSearchLable.SEARCH_CO_INSURED}
        labelClassName='inlineLable'
        checked={coapplicantSearch}
        onChange={manageCoApplicantSearch}
        tabIndex='12'
        inline
        />

       

          </Column>
        </Row>
        <Row className=''>
          <Column md={4} lg={4}  className='rightBorder'>
          <TextInputWithLabelInline
          name='custNumber'
          label={label.StandardSearchLable.ACCOUNT} 
          value={filter.custNumber}
          maxLength={25}
          isEditable
         type='text'
         labelClass='inlineLable'
         onChange={(event) => onFieldChange('custNumber', event.currentTarget.value)}
         tabIndex={5}
         lglabel={5}
                  mdlabel={5}
                  lgText={7}
                  mdText={7}
        />
          </Column>
          <Column md={4} lg={4}  className='paddingleft25'>
          <TextInputWithLabelInline
          name='LastName'
          label={label.StandardSearchLable.LAST_NAME}
          value={filter.lastName}
          isEditable
         type='text'
         labelClass='inlineLable'
         onChange={(event) => onFieldChange('lastName', event.currentTarget.value)}
         tabIndex={8}
         lglabel={5}
                  mdlabel={5}
                  lgText={7}
                  mdText={7}
        />
          </Column>
          <Column md={4} lg={4} ></Column>
         
        </Row>
        <Row >
          <Column md={4} lg={4}  className='rightBorder'>
            {/*<Row>
              <Column lg={5} md={5} className='nopadding'>
              <Label text='Policy Status' title="policyStatus" className='labelStyle inlineLable'/>
              </Column>
              <Column lg={7} md={7} className='paddingleft0'>
              <Select
                id='policyStatus'
                options={PolicyStatusOption}
                inputProps={{className:'inputStyle', tabIndex:'6'}}
                defaultSelected={PolicyStatusOption.filter(({ value }) => value === filter.PolicyStatus)}
              onChange={(values) => onFieldChange('PolicyStatus', values[0])}
              tabIndex='6'
              />
             </Column>
          </Row> */}
          
       
          </Column>
          <Column md={4} lg={4}  className='paddingleft25'>
          <TextInputWithLabelInline
          name='Email'
          label={label.StandardSearchLable.EMAIL} 
          value={filter.eMail}
          isEditable
         type='text'
         labelClass='inlineLable'
         onChange={(event) => onFieldChange('eMail', event.currentTarget.value)}
         tabIndex={9}
         lglabel={5}
                  mdlabel={5}
                  lgText={7}
                  mdText={7}
        />
          </Column>
          {/* <Column md={3} lg={3} >&nbsp;</Column> */}
          <Column md={4} lg={4} className='cstCheckbox'>
          <Checkbox
              id='SearchProperty'
              label= {label.StandardSearchLable.SEARCH_PROPERTY}
              onChange={manageCollapse}
              labelClassName='inlineLable'
              checked={collapse}
              tabIndex='13'
              inline/>
              <br/>
              <Checkbox
              id='SearchMailing'
              label= {label.StandardSearchLable.SEARCH_MAILING}
              onChange={manageMailCollapse}
              checked={mailCollapse}
              labelClassName='inlineLable'
              tabIndex='14'
              inline/>
          </Column>
        </Row>
        <Row className='bottomBorder'>
        <Column md={4} lg={4} className='rightBorder' ></Column>
        <Column md={4} lg={4} className='paddingleft25' >
          <FormattedInputWithLableInline
              name='Phone'
              label= {label.StandardSearchLable.PHONE} 
              value={filter.homePhone}
              isEditable
            type='tel'
            labelClass='inlineLable'
            onValueChange={(newVal) => onFieldChange('homePhone', newVal)}
            tabIndex={10}
            lglabel={5}
                      mdlabel={5}
                      lgText={7}
                      mdText={7}
                      inputFormatType={InputFormatType.Phone}>
          </FormattedInputWithLableInline>
        </Column>
        <Column md={4} lg={4} style={{textAlign:'end'}} >
          

          <Button
              className=' btn-md btn-primary'
              key="Search"
              icon="search"
              onClick={onSearch}
              disabled={!isSearchingAllowed}
              tabIndex={20}
              id="btnStandardSearch"
             // keyDown={(event) => event.key === 'Enter'? onSearch: event.preventDefault}
            >{label.StandardSearchLable.SEARCH}
          </Button>
         &nbsp;  &nbsp;  &nbsp; 
         <Button
              className=' btn-md btn-primary'
              key="Clear"
              icon="clear"
              onClick={onClearFilters}
              tabIndex={21}
              id="btnStandardSearchClear"
            >{label.StandardSearchLable.CLEAR}
          </Button>
          </Column>
        </Row>
        
        <Row>
       {(collapse ||mailCollapse) && <Panel 
        collapsible ={false}
        className='col-lg-12 bordernone'
        collapsed={collapse}
        >
          {/* <Row>&nbsp;</Row> */}
      <Row className='paddingTop25'>
          <Column md={5} lg={5}  className='nopadding'>
          <Label text={label.StandardSearchLable.STREET_ADDRESS} title="StreetAddress" className='labelStyle inlineLable'/>
          <TextInput value={filter.address1} 
          className='inputStyle'
          onChange={(event) => onFieldChange('address1', event.currentTarget.value)}
          tabIndex={15}
          placeholder={label.StandardSearchLable.STREET_ADDRESS}
          />
          </Column>
          <Column md={4} lg={4} >
          <Label text={label.StandardSearchLable.APRARTMENT} title="Appartment" className='labelStyle inlineLable'/>
          <TextInput value={filter.address2} 
          className='inputStyle'
          onChange={(event) => onFieldChange('address2', event.currentTarget.value)}
          tabIndex={16}
          placeholder= {label.StandardSearchLable.APRARTMENT}
          />
          </Column>
          
          <Column md={3} lg={3}  className='paddingtop25'>
               
          </Column>
        </Row>

        <Row>
          <Column md={3} lg={3}  className='nopadding'>
          <Label text= {label.StandardSearchLable.CITY} title="City" className='labelStyle inlineLable'/>
          <TextInput value={filter.city} 
          className='inputStyle'
          onChange={(event) => onFieldChange('city', event.currentTarget.value)}
          tabIndex={17}
          placeholder={label.StandardSearchLable.CITY}
          />
          </Column>
          <Column md={3} lg={3} >
          <Label text={label.StandardSearchLable.STATE} title="State" className='labelStyle inlineLable'/>
          <TextInput value={filter.state} 
          className='inputStyle' 
          onChange={(event) => onFieldChange('state', event.currentTarget.value)}
          tabIndex={18}
          placeholder={label.StandardSearchLable.STATE}
          />
          </Column>
          <Column md={3} lg={3} >
          <Label text={label.StandardSearchLable.ZIP} title="Zip" className='labelStyle inlineLable'/>
          <TextInput value={filter.zipCode} 
          className='inputStyle'
          onChange={(event) => onFieldChange('zipCode', event.currentTarget.value)}
          tabIndex={19}
          placeholder={label.StandardSearchLable.ZIP}
          />
          </Column>
          <Column md={3} lg={3} ></Column>
        </Row>
        </Panel>}
        </Row>

     
     
       </Column>

       
    </Row>
    </ErrorBoundary>
  )
  
  }

  export default CustomerStandardFilterForm