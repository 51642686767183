import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AppEvenType {
    None,
    NewCustomerActivityCreated,
    NewCustomerNoteCreated,
    NewTaskCreated
}


interface IAppEvent {
    eventType? : AppEvenType;
    eventData? : any
}

const initialState : IAppEvent = {
    eventType : AppEvenType.None,
    eventData : null
}

const eventSlice = createSlice({
    name : "appEvent",
    initialState,
    reducers : {
        notifyAppEvent(state, action: PayloadAction<IAppEvent>){
           const appEvent = action.payload;
            state.eventData = appEvent.eventData;
            state.eventType = appEvent.eventType;
        },
        clearAppEvent(state) {
            state.eventData = initialState.eventData;
            state.eventType = initialState.eventType;
        }
    }
});

export const { notifyAppEvent, clearAppEvent  } = eventSlice.actions;
export default eventSlice.reducer;