import { createSlice } from '@reduxjs/toolkit'
import { SelectOption } from "../../shared/components/input";
import * as constant from '../../shared/util/constant';

interface lookupData {
    maritalStatusList: SelectOption[];
    industryList: SelectOption[];
    occupancyList: SelectOption[];
    relationshipList: SelectOption[];
    agencyList: SelectOption[];
    stateList : SelectOption[];
    claimsStatusList : SelectOption[];
    taskPriorityList : SelectOption[];
    taskStatusList : SelectOption[];
    lossTypeList : SelectOption[];
    activityTypeList : SelectOption[];
    employeeList : SelectOption[];
    companyList : SelectOption[];
}

const intialLookupState: lookupData = {
    maritalStatusList: ([]),
    industryList: ([]),
    occupancyList: ([]),
    relationshipList: ([]),
    agencyList: ([]),
    stateList : ([]),
    claimsStatusList : ([]),
    lossTypeList : ([]),
    activityTypeList : ([]),
    taskStatusList : ([]),
    taskPriorityList : ([]),
    employeeList : ([]),
    companyList :([])
}



const LookupState = createSlice({
    name: "lookupState",
    initialState: intialLookupState,
    reducers: {
        updateLookupData(state, action) {
            switch (action.payload.type) {
                case constant.SlicerLookUpConstant.MARITAL_STATUS:
                    state.maritalStatusList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.INDUSTRY:
                    state.industryList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.OCCUPATION:
                    state.occupancyList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.RELATIONSHIP:
                    state.relationshipList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.AGENCY:
                    state.agencyList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.STATE:
                    state.stateList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.CLAIMS_STATUS:
                    state.claimsStatusList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.LOSS_TYPE:
                    state.lossTypeList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.ACTIVITY_TYPE:
                    state.activityTypeList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.TASK_PRIORITY:
                    state.taskPriorityList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.TASK_STATUS:
                    state.taskStatusList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.EMPLOYEE_LIST:
                    state.employeeList = [...action.payload.data]
                    break;
                case constant.SlicerLookUpConstant.COMPANY_LIST:
                    state.companyList = [...action.payload.data]
                    break;
                default:
                    break;
            }
        }
    }
})
export const { updateLookupData } = LookupState.actions
export default LookupState.reducer;

