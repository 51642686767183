import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {client} from "../../../shared/services/client"
import UserSetupModel from '../../model/userSetupModel'
import RoleDetailsModel from '../../model/RoleDetailsModel'
import EmployeeSetupModel from "../../model/EmployeeSetupModel";
import UserDetailModel from "../../model/UserDetailModel";

class UserSetupAPI{

    async getallUsersList()
    {
        client.setBaseURLForService("security")
        const res: AxiosResponse<UserSetupModel[]> = await client.get<UserSetupModel[]>("api/UserSetup/GetAllEmployeeList");
        return res;
    }

    async getAgencyGroups()
    {
        client.setBaseURLForService("admin")
        const res: AxiosResponse<UserSetupModel[]> = await client.get<UserSetupModel[]>("api/agencies/GetAllAgencyGroups");
        return res.data;
    }

    async getAgenciesByGroup(agencyGroupId)
    {
        client.setBaseURLForService("admin")
        const res: AxiosResponse<UserSetupModel[]> = await client.get<UserSetupModel[]>("api/agencies/GetAgencyByGroup?agencyGroupId="+ agencyGroupId);
        return res.data;
    }

     async fetchDropdownOptions (tableId: string): Promise<any> {
        try {
          client.setBaseURLForService("admin")
          const res: AxiosResponse<any> = await client.get<any>("/api/LookupDetail/GetAllLookupDetails?tableId=" + tableId);
          return await res.data;
        }
        catch (exception) {
          console.log("error: ", exception)
        }
      
      };

      async getEmployeeList()
      {
          client.setBaseURLForService("admin")
          const res: AxiosResponse<UserSetupModel[]> = await client.get<UserSetupModel[]>("api/CommonData/GetEmployeeList");
          return res.data;
      }

      getAppRoles = async (appId): Promise<RoleDetailsModel[]> => {
        client.setBaseURLForService("security")
          const res: AxiosResponse<RoleDetailsModel[]> =  await client.get<RoleDetailsModel[]>("api/RolePrivilege/GetRoles?appId=" + appId);
          return res.data;       
        };

        createEmployee = async (employeeSetup: EmployeeSetupModel) : Promise<string> => {
          client.setBaseURLForService("admin")
          const res: AxiosResponse<string>= await client.post<any>("/api/EmployeeSetup/CreateEmployee", employeeSetup);
          return res.data;
      };
      updateEmployee = async (employeeSetup: EmployeeSetupModel) : Promise<string> => {
        client.setBaseURLForService("admin")
        const res: AxiosResponse<string>= await client.post<any>("/api/EmployeeSetup/UpdateEmployee", employeeSetup);
        return res.data;
      };
      createUser = async (userDetail: UserDetailModel) : Promise<string> => {
        client.setBaseURLForService("security")
        const res: AxiosResponse<string>= await client.post<any>("/api/UserSetup/CreateUpdateUser", userDetail);
        debugger
        return res.data;
      };
      async getEmployeeDetails(empId)
      {
          client.setBaseURLForService("admin")
          const res: AxiosResponse<any> = await client.get<any>("api/EmployeeSetup/GetEmployee?employeeId="+ empId);
          return res.data;
      }
      async getUserDetails(userId)
      {
          client.setBaseURLForService("security")
          const res: AxiosResponse<any> = await client.get<any>("api/UserSetup/GetUserDetails?userId="+ userId);
          return res.data;
      }
      
}

export const userSetupAPI = new UserSetupAPI();