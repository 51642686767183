import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Column, DateTimePicker, Label, Row } from "../../../components";
import TextInputWithLabelInline from "../../../shared/components/input/TextInputWithLabelInline";
import * as lables from "../../../shared/util/label";
import * as appConstants from '../../../shared/util/constant';
import { CustomerLossReadDto } from "../../model/CustomerLossDto";
import './CustomerLossForm.scss';
import CustomerLossFormService from "./CustomerLossFormservice";
import { updateLookupData } from "../../customer-slice/Customer-lookup-slice";
import { CustomerLossHistoryConstant, SlicerLookUpConstant } from "../../../shared/util/constant";
import { RootState } from "../../../shared/store";
import DropDownWithLabelInline from "../../../shared/components/input/DropDownWithLabelInline";
import { SelectOption } from "../../../shared/components/input";
import Logger from "../../../shared/services/Logger";
import TextFieldWithLabelInline from "../../../shared/components/input/TextFieldWithLabelInline";
import { useConfirmationModalContext } from "../../../shared/components/ModalConfirmationContext";

export interface ICustomerLossForm {
    customerId? : string;
    customerLoss : CustomerLossReadDto; 
    onCancelClick : () => void;
    onLossHistoryCreated : (customerLossDto : CustomerLossReadDto) => void;
    onLossHistoryUpdated : (customerLossDto : CustomerLossReadDto) => void;
}

const CustomerLossForm : React.FunctionComponent<ICustomerLossForm> = (props) => {
    const dispatch = useDispatch()
    const modalContext = useConfirmationModalContext();
    const customerLossFormService = new CustomerLossFormService();
    const {customerId, customerLoss} = props;

    const lossTypeData = useSelector((state: RootState) => state?.LookupState.lossTypeList);
    const claimStatusData = useSelector((state: RootState) => state?.LookupState.claimsStatusList);
    const userDetails = useSelector(state => state.userContextSlice);
    const userName = `${userDetails?.userContext?.firstName} ${userDetails?.userContext?.lastName}`;

    const [formValidation, setFormValidation] = useState<any>({});
    const [customerLossData, setCustomerLossData] = useState<CustomerLossReadDto>({});    

    const [lossTypesList, setLossTypeList] = useState<SelectOption[]>([]);
    const [claimStatusList, setClaimStatusList] = useState<SelectOption[]>([]);

    useEffect(() => {
        initializeComponent();
    }, [customerLoss]);

    const initializeComponent = async () => {

        if (!lossTypeData || lossTypeData.length == 0) {
            let lossTypes = await customerLossFormService.fetchLossTypes();
            dispatch(updateLookupData({data : lossTypes, type : SlicerLookUpConstant.LOSS_TYPE}));
            setLossTypeList(lossTypes);
        }
        else {
            setLossTypeList([...lossTypeData]);
        }

        if (!claimStatusData || claimStatusData.length == 0) {
            let claimsStsList = await customerLossFormService.fetchClaimStatusList();
            dispatch(updateLookupData({data : claimsStsList, type : SlicerLookUpConstant.CLAIMS_STATUS}));
            setClaimStatusList(claimsStsList);
        }
        else {
            setClaimStatusList([...claimStatusData]);
        }

        let lossDataObj : CustomerLossReadDto = null;

        if (customerLoss.id) {
            lossDataObj = {...customerLoss};
        }
        else {
            lossDataObj = {...customerLoss, changedByValue : userName};
        }

        setCustomerLossData(lossDataObj);
    };

    const defaultColumnLg = 6
    const defaultColumnMd = 6

    const columnFullLg = 12
    const columnFullMd = 12

    const isEditable = true;

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        setCustomerLossData({
            ...customerLossData,
            [propName]: value,
          });
    }

    const onCancelClick = async () => {
        const result = await modalContext.showConfirmation(CustomerLossHistoryConstant.ARE_YOU_SURE_TO_EXIST)
        if(result){
            props.onCancelClick();
        }
    }

    const onSaveClick = async () => {
        let validationError = customerLossFormService.validateCustomerLossData(customerLossData);
        setFormValidation(validationError);
            if (Object.keys(validationError).length > 0) {
                toast.warn(CustomerLossHistoryConstant.FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

        try {

            let requestDto = customerLossFormService.getCustomerLossRequestDto(customerLossData, customerId);
            let data = await customerLossFormService.saveCustomerLossData(requestDto);
            if (data) {
                toast.success(CustomerLossHistoryConstant.SAVE_CUSTOMER_LOSS_SUCCESS, {
                    position: toast.POSITION.TOP_RIGHT
                });

                if (requestDto.id) {
                    props.onLossHistoryUpdated(data);
                }
                else {
                    props.onLossHistoryCreated(data);
                }
            }
            else {
                toast.error(CustomerLossHistoryConstant.SAVE_CUSTOMER_LOSS_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        catch(ex) {
            Logger.logError(CustomerLossHistoryConstant.SAVE_CUSTOMER_LOSS_ERROR_MESSAGE, ex);
            if (ex.status == 404) {
                toast.error(appConstants.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(CustomerLossHistoryConstant.SAVE_CUSTOMER_LOSS_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const mainLayout = <>
        <Row>
            <Column lg={columnFullLg} md={columnFullMd} className="col-section-header">
                <span className="new-entry-main-title">{lables.CustomerLossHistoryScreenLables.LOSS_HISOTRY}</span>
            </Column>
        </Row>
        <Row>
            <Column lg={columnFullLg} md={columnFullMd} className="col-section-header">
                <span className="cst-lss-section-header">{lables.CustomerLossHistoryScreenLables.general}</span>
            </Column>
        </Row>

        <Row>
            <Column lg={7} md={7}>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <TextInputWithLabelInline
                            name='company'
                            label={lables.CustomerLossHistoryScreenLables.company}
                            placeholder={' '}
                            value={customerLossData.company}
                            isEditable={isEditable}
                            onChange={(event) => onEntityFieldChange('company', event.currentTarget.value)}
                            type='text'
                            labelClass='inlineLable entry-form-inlineLable'
                            tabIndex={1}
                            lglabel={4}
                            mdlabel={4}
                            lgText={8}
                            mdText={8}
                            maxLength={30}
                            className='texbox-input'>
                        </TextInputWithLabelInline>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <TextInputWithLabelInline
                            name='employeeName'
                            label={lables.CustomerLossHistoryScreenLables.employeeName}
                            placeholder={' '}
                            value={customerLossData.changedByValue}
                            isEditable={false}
                            type='text'
                            labelClass='inlineLable entry-form-inlineLable'
                            tabIndex={2}
                            lglabel={4}
                            mdlabel={4}
                            lgText={8}
                            mdText={8}
                            className='texbox-input'>
                        </TextInputWithLabelInline>
                    </Column>
                </Row>
                <Row>
                    <Column lg={columnFullLg} md={columnFullMd} className="col-section-header">
                        <span className="cst-lss-section-header">{lables.CustomerLossHistoryScreenLables.lossDetail}</span>
                    </Column>
                </Row>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <TextInputWithLabelInline
                            className='texbox-input'
                            name='policyNumber'
                            label={lables.CustomerLossHistoryScreenLables.policyNumber}
                            placeholder={' '}
                            value={customerLossData.policyNumber}
                            isEditable={isEditable}
                            onChange={(event) => onEntityFieldChange('policyNumber', event.currentTarget.value)}
                            type='text'
                            labelClass='inlineLable entry-form-inlineLable shrink-astrik '
                            tabIndex={4}
                            isRequired={true}
                            lglabel={4}
                            mdlabel={4}
                            lgText={8}
                            mdText={8}
                            maxLength={25}
                            isInvalid={formValidation['policyNumber']?.isValid == false}
                            invalidWarning={formValidation['policyNumber']?.warningText}
                            invalidLableClass="lable-error">
                        </TextInputWithLabelInline>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <TextInputWithLabelInline
                            name='claimNumber'
                            label={lables.CustomerLossHistoryScreenLables.claimNumber}
                            placeholder={' '}
                            value={customerLossData.claimNo}
                            isEditable={isEditable}
                            onChange={(event) => onEntityFieldChange('claimNo', event.currentTarget.value)}
                            type='text'
                            labelClass='inlineLable entry-form-inlineLable'
                            tabIndex={5}
                            lglabel={4}
                            mdlabel={4}
                            lgText={8}
                            mdText={8}
                            maxLength={36}
                            className='texbox-input'>
                        </TextInputWithLabelInline>
                    </Column>
                </Row>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <DropDownWithLabelInline
                            id='ddKindOfLoss'
                            label={lables.CustomerLossHistoryScreenLables.kindOfLoss}
                            options={lossTypesList}
                            defaultSelected={lossTypesList?.filter(({ value }) => value === customerLossData.lossType)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('lossType', values[0])
                                }
                            }
                            labelSize={4}
                            dropDownSize={8}
                            labelClass='inlineLable entry-form-inlineLable'
                            placeholder={CustomerLossHistoryConstant.PLEASE_SELECT}
                            multiple={false}
                            tabIndex={6}/>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <Row>
                            <Column lg={4} md={4} className="col-individual-lable">
                                <Label text={lables.CustomerLossHistoryScreenLables.dateOfLoss} className="inlineLable  entry-form-inlineLable"/>
                            </Column>
                            <Column lg={8} md={8} className="col-individual-input">
                                <DateTimePicker
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={customerLossData.dateOfLoss}
                                    tabIndex={7}
                                    onChange={(date) => onEntityFieldChange('dateOfLoss', date)}/>
                            </Column>
                        </Row>
                    </Column>
                </Row>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <Row>
                            <Column lg={4} md={4} className="col-individual-lable">
                                <Label text={lables.CustomerLossHistoryScreenLables.effectiveDate} className="inlineLable  entry-form-inlineLable"/>
                            </Column>
                            <Column lg={8} md={8} className="col-individual-input">
                                <DateTimePicker
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={customerLossData.policyEffDate}
                                    tabIndex={8}
                                    onChange={(date) => onEntityFieldChange('policyEffDate', date)}/>
                            </Column>
                        </Row>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <Row>
                            <Column lg={4} md={4} className="col-individual-lable">
                                <Label text={lables.CustomerLossHistoryScreenLables.dateReported} className="inlineLable  entry-form-inlineLable"/>
                            </Column>
                            <Column lg={8} md={8} className="col-individual-input">
                                <DateTimePicker
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={customerLossData.reportDate}
                                    tabIndex={9}
                                    onChange={(date) => onEntityFieldChange('reportDate', date)}/>
                            </Column>
                        </Row>
                    </Column>
                </Row>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <Row>
                            <Column lg={4} md={4} className="col-individual-lable">
                                <Label text={lables.CustomerLossHistoryScreenLables.expirationDate} className="inlineLable  entry-form-inlineLable"/>
                            </Column>
                            <Column lg={8} md={8} className="col-individual-input">
                                <DateTimePicker
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={customerLossData.policyExpDate}
                                    tabIndex={10}
                                    onChange={(date) => onEntityFieldChange('policyExpDate', date)}/>
                            </Column>
                        </Row>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <TextInputWithLabelInline
                            name='amountToBePaid'
                            label={lables.CustomerLossHistoryScreenLables.amountToPay}
                            placeholder={' '}
                            value={customerLossData.amountPaid?.toString()}
                            isEditable={isEditable}
                            onChange={(event) => onEntityFieldChange('amountPaid', event.currentTarget.value)}
                            type='number'
                            labelClass='inlineLable entry-form-inlineLable'
                            tabIndex={11}
                            lglabel={4}
                            mdlabel={4}
                            lgText={8}
                            mdText={8}
                            className='texbox-input'>
                        </TextInputWithLabelInline>
                    </Column>
                </Row>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <DropDownWithLabelInline
                            id='claimStatus'
                            label={lables.CustomerLossHistoryScreenLables.claimStatus}
                            options={claimStatusList}
                            defaultSelected={claimStatusList?.filter(({ value }) => value === customerLossData.claimStatusId)}
                            onChange={
                                (values) => {
                                    onEntityFieldChange('claimStatusId', values[0])
                                }
                            }
                            dropup={true}
                            labelSize={4}
                            dropDownSize={8}
                            labelClass='inlineLable entry-form-inlineLable'
                            placeholder={CustomerLossHistoryConstant.PLEASE_SELECT}
                            multiple={false}
                            tabIndex={12}/>
                    </Column>
                </Row>
                <Row>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                    </Column>
                    <Column lg={defaultColumnLg} md={defaultColumnMd}>
                        <Row>
                            <Column lg={4} md={4} className="col-individual-lable">
                                <Label text={lables.CustomerLossHistoryScreenLables.closeDate} className="inlineLable  entry-form-inlineLable"/>
                            </Column>
                            <Column lg={8} md={8} className="col-individual-input">
                                <DateTimePicker
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={customerLossData.closedDate}
                                    tabIndex={13}
                                    onChange={(date) => onEntityFieldChange('closedDate', date)}
                                    />
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Column>
            <Column lg={5} md={5}>
                <Row>
                    <Column lg={12} md={12}>
                        <TextFieldWithLabelInline
                            name='lossDescription'
                            label={lables.CustomerLossHistoryScreenLables.description}
                            placeholder={' '}
                            value={customerLossData.lossDescription}
                            isEditable={isEditable}
                            onChange={(event) => onEntityFieldChange('lossDescription', event.currentTarget.value)}
                            type='text'
                            labelClass='inlineLable entry-form-inlineLable'
                            tabIndex={3}
                            lglabel={3}
                            mdlabel={3}
                            lgText={9}
                            mdText={9}
                            maxLength={765}
                            className='texbox-input cst-lss-descr-input'>
                        </TextFieldWithLabelInline>
                </Column>
                </Row>
            </Column>
        </Row>

        {/* Action Row */}
        <Row className="mb-2">
            <Column lg={10} md={10}>
                <Button
                    className='btn btn-outline-tertiary  action-btn-base'
                    key="btnLossHistoryCancel"
                    onClick={() => onCancelClick()}
                    id="btnLossHistoryCancel"
                    color='primary'
                    outlined={true}
                >{lables.CANCEL.toLocaleUpperCase()}
                </Button>

            </Column>
            <Column lg={2} md={2} className="text-right">
                <Button
                    className='btn btn-outline-primary action-btn-base'
                    key="btnLossHistorySave"
                    onClick={() => onSaveClick()}
                    tabIndex={21}
                    id="btnLossHistorySave"
                    color='primary'
                    outlined={true}
                >{lables.SAVE.toLocaleUpperCase()}
                </Button>
            </Column>
        </Row>
    </>;

    return <div className="main-cst-lss-div">
        {mainLayout}
    </div>;
}

export default CustomerLossForm;