import { Navbar,Row } from  '../../components' 
import './CutomerMainPortalHeader.scss';
import { Button, Column, Label } from '../../components';
import { CustomerScreenTabs } from '../../shared/util/constant';
import { NavLink, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import CutomerMainPortalHeaderService from './CutomerMainPortalHeaderService';
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline';
import { CustomerFilterViewMoel } from '../model/CustomerFilterViewModel';
import useSlider from '../../shared/components/Slider/useSlider';
import * as constant from '../../shared/util/constant';
import AppContext from "../../shared/components/AppContext";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import CustomerSliderContainerService from '../customer-slider-content/CustomerSliderContainerService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store';
import { cleareTabsInputs } from '../customer-slider-content/CustomerSliderContext';
import { toast } from 'react-toastify';


export interface ICutomerMainPortalHeader {
    customerId? : string,
    viewName? : string,
    isCreatingNewCustomer?: boolean,
    navigateTo: (path: string) => void
}

const CutomerMainPortalHeader : React.FunctionComponent<ICutomerMainPortalHeader> = (props) => {
    const customerSliderContainerService = new CustomerSliderContainerService();
    const customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
    const originalCustomerObj = useSelector((state: RootState) => state?.customerSlice).customer;
    const dispatch = useDispatch();

    const customerId = props.customerId;
    const viewName = props.viewName;
    const isCreatingNewCustomer = props.isCreatingNewCustomer;
    const history = useHistory()
    const modalContext = useConfirmationModalContext();
    const myContext = useContext(AppContext);

    const slider =  useSlider();

    let [customer, setCustomer] = useState<CustomerFilterViewMoel>({});

    const cutomerMainPortalHeaderService = new CutomerMainPortalHeaderService();
    const sliderContext = useSlider();

    useEffect(() => {
        initComponent();
    }, [isCreatingNewCustomer]);

    const initComponent = async () => {
        try {
            if (!isCreatingNewCustomer) {
                let cst = await cutomerMainPortalHeaderService.getCustomerSummary(customerId);
                setCustomer(cst ? cst : {});
            }
        }
        catch (ex) {
            if (ex.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
                props.navigateTo('/customer/customerfilter');
            }
        }
    }

    const redirectTo = async(path) =>{
        event.preventDefault();

        const changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
        if (Object.keys(changes).length > 0) {
            const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if(!result){
                return;
            }

            dispatch(cleareTabsInputs());
            sliderContext.hideSlider();
        }


        if(!myContext.formSavedStatus){
        const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
        if(result){
            history.push({
                pathname: path,
            });
            }
        }
        else{
            history.push({
                pathname: path,
            });
        }
    }
    const customerAddress = `${customer?.address1 ? customer?.address1 + ', ':''}${customer?.address2 ? customer?.address2 + ', ' : ''}${customer?.city ? customer?.city + ', ' : ''}${customer?.state ? customer?.state + ' ' : ''}${customer?.zipCode ? customer?.zipCode : ''}`
    let secondColumnLg = 3;
    let secondColumnMd = 3;

    let lastColumnLg = 4;
    let lastColumnMd = 4;
     let propMarginLeft =false;
    if (slider.isSliderVisible && slider.isSliderPinned) {
        secondColumnLg = 4;
        secondColumnMd = 4;

        lastColumnLg = 3;
        lastColumnMd = 3;

        propMarginLeft =true
    }

    let customerSummary = <></>;
    if (!props.isCreatingNewCustomer) {
        customerSummary = <>
         <Row>
                <Column lg={1} md={1}>
                    <span className='cmph-highlighted-lbl'>#{customer?.custNumber}</span> 
                </Column> 
                <Column lg={secondColumnLg} md={secondColumnMd}>
                    <span className='cmph-highlighted-lbl'>{customer?.displayName}</span> 
                </Column> 
                <Column lg={4} md={4} className='lbl-data-div'>
                    <span >{customer.agencyDisplay}</span> 
                </Column> 
            </Row>
            <Row>
                <Column lg={1} md={1}>
                    <span >Address</span> 
                </Column> 
                <Column lg={secondColumnLg} md={secondColumnMd}>
                    <span >{customerAddress}</span> 
                </Column> 
            </Row>
        </>;
    }

    const onSliderMenuClick = () => {
        if (!sliderContext.isSliderVisible) {
            sliderContext.showSlider();
        }
    }

    return (
        <div className='cmph-main-container'>
            <Row>
            <Column lg={1} md={1} className='pl-3 mt-1' style={{whiteSpace:"nowrap"}}>
                    <span className='cmph-header'>{CustomerScreenTabs[viewName]?.text}</span>
                </Column>
<Column lg={3} md={3} className={`${propMarginLeft?"ml-3":"" } mt-n3`}>
<Navbar
bg='light'
variant='light'
className=''
title={''} id={''}
navItems={

    [
        {
            type: 'link',
            // alignRight: true,
            // children: null,
             className: 'cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width',
            // iconClassName: 'align-bottom',
            label: 'Dashboard',
            name: 'Dashboard',
            size: 'sm',
            onClick:(event)=>{redirectTo('/customer/customerdashboard') }
          },
          {
            type: 'link',
            // alignRight: true,
            // children: null,
             className: 'cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width',
            // iconClassName: 'align-bottom',
            label: 'Filters',
            name: 'Filters',
            size: 'sm',
            onClick:(event)=>{redirectTo('/customer/customerfilter') }
          }
    ]}
/>
</Column>

                {/* <Column lg={2} md={2}>
                    <span className='cmph-header'>{CustomerScreenTabs[viewName]?.text}</span>
                </Column>
                <Column lg={2} md={2}>
                    <NavLink onClick={redirectTo('/customer/customerdashboard')} to={'/customer/customerdashboard'} className="cmph-link" >Dashboard</NavLink>
                    <NavLink onClick={redirectTo('/customer/customerfilter')} to={'/customer/customerfilter'} className="cmph-link">Filters</NavLink>
                </Column> */}
                {/* {marginLeft && <Column lg={1} md={1} className='nopadding'></Column>} */}
                <Column lg={3} md={3} className={`${propMarginLeft?"margin-search-box":"" }`} >
                    <TextInputWithLabelInline
                        name='txtFilterCustomer'
                        placeholder={'Search Phone, Name or Email'}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        tabIndex={1}
                        lglabel={3}
                        mdlabel={3}
                        lgText={9}
                        mdText={9}
                        className={`${!propMarginLeft?"nopaddingLeft":"" } texbox-input`}>
                    </TextInputWithLabelInline>
                </Column>
                {!propMarginLeft && <Column lg={1} md={1}></Column>}
                <Column lg={lastColumnLg} md={lastColumnMd} className={`${propMarginLeft?"margin-search-box":"" }`}>
                    <div className='slider-menu-container'>
                        <Button
                            className=' btn-md btn-primary'
                            key="menu"
                            icon="menu"
                            id="btnSliderMenu"
                            disabled={props.isCreatingNewCustomer}
                            onClick={onSliderMenuClick}
                            >
                        </Button>
                    </div>
                    
                </Column>
            </Row>
            {customerSummary}
        </div>
    );
}

export default CutomerMainPortalHeader;
