import _ from "lodash";
import validator from 'validator';
import CustomerFilterRequest from "../model/CustomerFilterRequest";
import CS2AggregatorService from "../../Services/CS2AggregatorService";
import { CustomerFilterViewMoel } from "../model/CustomerFilterViewModel";
import { PolicySearchDto } from "../model/PolicySearchDto";
import { CustomerFilterResponseDto } from "../model/CustomerFilterResponseDto";
import * as CustomerApi from "../services/CustomerAPI";

class CustomerStandardSearchService
{
    IsFilterInputValid(filterRequest : CustomerFilterRequest) : boolean
    {
        if (_.isEmpty(filterRequest.firstName?.trim())
            && _.isEmpty(filterRequest.lastName?.trim())
            && _.isEmpty(filterRequest.homePhone?.trim())
            && _.isEmpty(filterRequest.eMail?.trim())
            && _.isEmpty(filterRequest.address1?.trim())
            && _.isEmpty(filterRequest.address2?.trim())
            && _.isEmpty(filterRequest.city?.trim())
            && _.isEmpty(filterRequest.state?.trim())
            && _.isEmpty(filterRequest.county?.trim())
            && _.isEmpty(filterRequest.zipCode?.trim())
            && _.isEmpty(filterRequest.custNumber?.toString()?.trim())
            // Policy fields
            && _.isEmpty(filterRequest.policyNumber?.trim()))
        {
            return false;
        }

        if (!_.isEmpty(filterRequest.eMail?.trim()) && !validator.isEmail(filterRequest.eMail?.trim()))
        {
            return false;
        }

        if (!_.isEmpty(filterRequest.firstName?.trim()) && filterRequest.firstName?.trim()?.length < 2)
        {
            return false;
        }

        if (!_.isEmpty(filterRequest.lastName?.trim()) && filterRequest.lastName?.trim()?.length < 2)
        {
            return false;
        }

        if (!_.isEmpty(filterRequest.custNumber?.toString()?.trim()) && (isNaN(filterRequest.custNumber) || (filterRequest.custNumber?.toString()?.trim().length < 3)))
        {
            return false;
        }

        if (!_.isEmpty(filterRequest.policyNumber?.trim()) && filterRequest.policyNumber?.trim()?.length < 3)
        {
            return false;
        }

        if (!_.isEmpty(filterRequest.homePhone?.trim()) && filterRequest.homePhone?.trim().length < 10 )
        {
            return false;
        }

        return true;
    }

    async FetchCustomers(filterRequest : CustomerFilterRequest) : Promise<CustomerFilterViewMoel[]>
    {
        let customerData : CustomerFilterViewMoel[] = []
        let custFilterRequest = {...filterRequest }; 
        if (custFilterRequest.custNumber) {
            custFilterRequest.custNumber = parseFloat(custFilterRequest.custNumber.toString());
        }
        else {
            custFilterRequest.custNumber = null;
        }

        // Trim down all the search inputs.
        custFilterRequest.firstName = custFilterRequest.firstName?.trim();
        custFilterRequest.lastName = custFilterRequest.lastName?.trim();
        custFilterRequest.eMail = custFilterRequest.eMail?.trim();
        custFilterRequest.homePhone = custFilterRequest.homePhone?.trim();
        custFilterRequest.address1 = custFilterRequest.address1?.trim();
        custFilterRequest.address2 = custFilterRequest.address2?.trim();
        custFilterRequest.city = custFilterRequest.city?.trim();
        custFilterRequest.state = custFilterRequest.state?.trim();
        custFilterRequest.zipCode = custFilterRequest.zipCode?.trim();
        custFilterRequest.policyNumber = custFilterRequest.policyNumber?.trim();

        try
        {
            customerData = await CustomerApi.SearchCustomers(custFilterRequest);
        }
        catch(ex)
        {
            if(ex.status != 404)
                throw ex;
        }

        return customerData;
    }
}

export default CustomerStandardSearchService;