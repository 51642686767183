import { Row, Column, Button, Spinner, Panel, Label, TextField, Icon, Modal } from '../../../../components';
import { ErrorBoundary } from 'react-error-boundary'
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import HistoryDetail from '../model/HistoryDetail';
import { notesUtils } from '../hooks/ManageNotesOperations'
import { toast } from 'react-toastify';
import * as constant from '../../../util/constant'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormSaved } from '../../../components/formSaved-slice';


const HistoryCard = () => {
    const historydetails: HistoryDetail = {
        HistoryID: 0,
        EntityID: 0,
        EntityType: '',
        ChangedField: '',
        OldValue: '',
        NewValue: '',
        CreatedOn: new Date(),
        CreatedBy: '',
        UpdatedOn: new Date(),
        UpdatedBy: ''
    }

    const location = useLocation();
    const state = location.state as { entityId: string, type: string };
    const urlParam = new URLSearchParams(location.search)
    const queryId = state?.entityId ?? urlParam.get('id');
    const type = location.state as { type: string };
    const noteType = state?.type;
    const [queryHistory, getHistory] = useState([]);
    const dispatch = useDispatch();
    const formSaved = useSelector(state => state.formSavedSlice.formSaved);

    function cleanupProperties(data) {
        const resData = { ...data };
        if(resData.fieldName.startsWith('_')) {
            resData.fieldName = resData.fieldName.charAt(1).toUpperCase() + resData.fieldName.slice(2);
        }
        return resData;
    }
    
    function GetHistoryByQueryId() {
        if (queryId !== undefined && queryId !== '' && queryId !== null) {
            notesUtils.GetHistoryByIdType(queryId).then((res) => {

                if (res.data !== undefined && res.data.length > 0) {
                    const cleanedProperties = res.data.map(cleanupProperties);
                    getHistory(cleanedProperties);
                }

                if (res.status >= 200 && res.status < 300) {
                    //Some Code here if required
                }
                else {
                    toast.error(constant.ERROR_MESSAGE_LOAD, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                    });
                }
            }).catch(exception => {
                toast.error(constant.ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 10000
                });
            })
        }
    }


    useEffect(() => {
        GetHistoryByQueryId();
        if(formSaved) {
          dispatch(toggleFormSaved())
        }
    }, [formSaved])

    return (
        <>
            {queryHistory.map((data, index) => {

                return (
                    <Row key={data.HistoryID}>
                        <Column lg={12} md={12}>
                            <Row>
                                <Column lg={2} md={2} className="nopadding-right historyPanel-boldText">
                                    <Label text={"FIELD:"} />
                                </Column>
                                <Column lg={10} md={10} className="nopadding historyPanel-normalText">
                                    <Label className="normalText" text={data.fieldName} />
                                </Column>
                            </Row>
                        </Column>
                        <Column lg={12} md={12}>
                            <Row>
                                <Column lg={6} md={6} className="auto-height">
                                    <Label text={"OLD VALUE:"} className="nomargin-bottom  historyPanel-boldText" /><br/>
                                    <Label text={data.oldValue} className="normalText nomargin-top historyPanel-normalText text-break" />
                                </Column>
                                <Column lg={6} md={6} className="auto-height">
                                    <Label text={"NEW VALUE:"} className="nomargin-bottom  historyPanel-boldText" /><br/>
                                    <Label text={data.newValue} className="normalText nomargin-top historyPanel-normalText text-break" />
                                </Column>

                            </Row>
                        </Column>
                        <Column lg={12} md={12} className='cst-font-col'>
                            <Row className='padding-top-10'>
                                <Column lg={12} md={12} className='padding-left10 cst-font-div margin-bottom-20'><label style={{fontWeight: 'bold'}}>{'Time/User: '}</label>{moment(data.changedDate.toString()).format("MMMM Do YYYY, h:mm:ss a")} ET by <u>{data.changedBy}</u></Column>
                            </Row>
                            <br />
                        </Column>
                    </Row>
                )
            })}

        </>
    )


}
export default HistoryCard