import { useEffect, useState } from "react";
import { Button, Column, Label, Panel, Row } from "../../components";
import { approveDocument, getCarrierDropdownOptions, getRefiDocument, getRequestRCEDropdownOptions, getRequestTypeDropdownOptions, getVestingDropdownOptions } from "./DocuMateRefiService";
import { useHistory, useLocation } from "react-router-dom";
import { RefiDocument } from "../model/RefiDocument";
import DocViewer from "../shared/components/DocViewer";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import { SelectOption } from "../../shared/components/input";

export const sampleDocument: RefiDocument = {
    "id": "9b1deb4d-3b7d-4bad-9bdd-2bbbbbbbbbd",
    "scanId": "CS_RM_10152024_1000",
    "docDesc": "Document 100 Description",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "isLocked": false,
    requestType: "R",
    requestRCE: "Y",
    vesting: "MA",
    policyNumber: "12556",
    carrier: "ASI"
}

const RefiProcessing = () => {

    const location = useLocation();
    const state = location.state as {id: string, scanId: string};
    const urlParam = new URLSearchParams(location.search)
    const [scanId,setScanId]=useState(state?.scanId);
    const [id,setId]=useState(state?.id || urlParam.get('id'));
    const [currentDocument, setCurrentDocument] = useState<RefiDocument>(sampleDocument);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [requestTypeOptions] = useState<SelectOption[]>(getRequestTypeDropdownOptions());
    const [requestRCEOptions] = useState<SelectOption[]>(getRequestRCEDropdownOptions());
    const [vestingOptions] = useState<SelectOption[]>(getVestingDropdownOptions());
    const [carrierOptions] = useState<SelectOption[]>(getCarrierDropdownOptions());

    useEffect(() => {
        getRefiDocument(id).then((res) => {
            setCurrentDocument(res);
        });
    }   , []);

    const navigateToTodoList = () => {
        history.push('/documate-refi');
    }

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        setCurrentDocument({
          ...currentDocument,
          [propName]: value,
        });
    }

    const onApproveDocument = (id: string) => {
        // save state to the db
        console.log('Approve Document Clicked');
        setIsLoading(true);
        approveDocument(id).then((data) => {
                console.log(data);
                // setCurrentDocument(data);
                // console.log(currentDocument.scanId);
                if(data) {
                    history.push(`/documate-refi/processing/${data.id}`, { scanId: data.scanId, id: data.id });
                    setIsLoading(false);
                }
                else {
                    navigateToTodoList();
                    setIsLoading(false);
                }
            });
    }

    return (
        <>
        <Label title="DocuMateRefiProcessing" text={scanId} className='documateHeader'></Label>
        {!isLoading && <div className="parent-container">
            <div className="indexingPanel">
                    <Panel title={'Request of Evidence of Insurance'}>
                    <Row>
                    <Column lg={6} md={6}>
                        <DropDownWithLabelInline
                        id='requestType'
                        label={'Request Type'}
                        options={requestTypeOptions}
                        defaultSelected={requestTypeOptions.filter(({ value }) => value === currentDocument.requestType)}
                       onChange={
                           (values) => {
                               onEntityFieldChange('requestType', values[0])
                           }
                       }
                        labelSize={3}
                        dropDownSize={9}
                        labelClass='inlineLable'
                        placeholder={' '}
                        multiple={false}
                        tabIndex={6}
                        // disabled={}
                        ></DropDownWithLabelInline>
                    </Column>
                    <Column lg={6} md={6}>
                        <DropDownWithLabelInline
                        id='requestRCE'
                        label={'Request RCE'}
                        options={requestRCEOptions}
                        defaultSelected={requestRCEOptions.filter(({ value }) => value === currentDocument.requestRCE)}
                       onChange={
                           (values) => {
                               onEntityFieldChange('requestRCE', values[0])
                           }
                       }
                        labelSize={3}
                        dropDownSize={9}
                        labelClass='inlineLable'
                        placeholder={' '}
                        multiple={false}
                        tabIndex={6}
                        // disabled={}
                        ></DropDownWithLabelInline>
                    </Column>
                    </Row>
                    <Row>
                    <Column lg={6} md={6}>
                        <DropDownWithLabelInline
                        id='vesting'
                        label={'Vesting'}
                        options={vestingOptions}
                        defaultSelected={vestingOptions.filter(({ value }) => value === currentDocument.vesting)}
                       onChange={
                           (values) => {
                               onEntityFieldChange('vesting', values[0])
                           }
                       }
                        labelSize={3}
                        dropDownSize={9}
                        labelClass='inlineLable'
                        placeholder={' '}
                        multiple={false}
                        tabIndex={6}
                        // disabled={}
                        ></DropDownWithLabelInline>
                    </Column>
                    <Column lg={6} md={6}>
                        <TextInputWithLabelInline
                        name='policyNumber'
                        label={'Policy #'}
                        placeholder={' '}
                        value={currentDocument.policyNumber??""}
                        isEditable={true}
                        onChange={(event) => onEntityFieldChange('policyNumber', event.currentTarget.value)}
                        type='text'
                        labelClass='inlineLable'
                        // isRequired
                        tabIndex={1}
                        lglabel={3}
                        mdlabel={3}
                        lgText={9}
                        mdText={9}
                        // maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                    </Column>
                    </Row>
                    <Row>
                        <Column lg={6} md={6}>
                        <DropDownWithLabelInline
                        id='carrier'
                        label={'Carrier'}
                        options={carrierOptions}
                        defaultSelected={carrierOptions.filter(({ value }) => value === currentDocument.carrier)}
                    //    onChange={
                    //        (values) => {
                    //            onEntityFieldChange('occupationId', values[0])
                    //        }
                    //    }
                        labelSize={3}
                        dropDownSize={9}
                        labelClass='inlineLable'
                        placeholder={' '}
                        multiple={false}
                        tabIndex={6}
                        // disabled={}
                        ></DropDownWithLabelInline>
                    </Column>
                    </Row>
                    </Panel>
            </div>
            {/* <div className='doc-view-container'>
                <Button
                    className='btn btn-primary bt-main-action'
                    key="filter"
                    onClick={() => onApproveDocument(id)}
                    tabIndex={21}
                    id="approveRefi"
                    disabled={false}
                    outlined={true}>
                    {"APPROVE"}
                </Button>
                <Button
                    className='btn btn-primary bt-main-action'
                    key="filter"
                    onClick={() => onApproveDocument(id)}
                    tabIndex={21}
                    id="rejectRefi"
                    disabled={false}
                    outlined={true}>
                    {"REJECT"}
                </Button>
                <DocViewer docId='' handleDocViewerClose={}/>
            </div> */}
        </div>
    }
    </>
    );
}

export default RefiProcessing;