import CampaignNavbar from './campaignNavbar'
import {Row, Column} from '../components'
import { Redirect, Route, Switch } from 'react-router-dom'
import CampaignDashboard from './filter/CampaignDashboard'
import Campaigns from './filter/Campaign'
import Calendar from './filter/Calendar'
import Queries from './filter/Queries'
import ExternalTemplate from './filter/3rdPartyTemplate'
import QueryDetail from './filter/QueryDetail'
import ExternalTemplateDetail from './filter/3rdPartyTemplateDetail'
import { useEffect} from 'react'
import CampaignDetails from './filter/CampaignDetails'
import CampaignApprovalDetails from './filter/CampaignApprovalDetails'
import { AbilityContext } from '../../src/shared/abilities/AbilityContext';
import { useContext } from 'react';
import * as constant from '../shared/util/constant'
import MainDashboard from '../dashboard/main-dashboard'

interface Props {
  isSideBarCollapsed: boolean
}


const Campaign = (props: Props) => {
  const ability = useContext(AbilityContext);
  const canUserViewCampaign = ability.can('view', "Campaign", constant.ScreenName.CAMPAIGN)
  const canUserReadCampaign = ability.can('read', "Campaign", constant.ScreenName.CAMPAIGN)
  const canUserViewQuery = ability.can('view', "Campaign Query", constant.ScreenName.Campaign_Query)
  const canUserReadQuery = ability.can('read', 'Campaign Query', constant.ScreenName.Campaign_Query);
  const canUserViewCalendar = ability.can('view', 'Campaign Calendar', constant.ScreenName.Campaign_Calendar);
  const canUserReadCalendar = ability.can('read', 'Campaign Calendar', constant.ScreenName.Campaign_Calendar);
  const canUserViewTemplate = ability.can('view', "Campaign Template", constant.ScreenName.CAMAPIGN_TEMAPLATE)
  const canUserReadTemplate = ability.can('read', 'Campaign Template', constant.ScreenName.CAMAPIGN_TEMAPLATE);
  const canUserViewApproval = ability.can('view', "Campaign Approval", constant.ScreenName.CAMPAIGN_APPROVAL)
  const canUserReadApproval = ability.can('read', 'Campaign Approval', constant.ScreenName.CAMPAIGN_APPROVAL);


  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--marginLeftCustomerRow', props.isSideBarCollapsed ? '5px' : '60px')
  }, [props.isSideBarCollapsed]);


  return (
    <div>
      <Row className="bg-light sidebarToggle">
        <Column lg={12}>
          <CampaignNavbar />
        </Column>
      </Row>
      <Row className='bg-light sidebarToggle'>
        <Column lg={12}>
          {/* <CustomerFilterForm/> */}

          <Switch>
            <Route
              exact
              path="/Campaign/CampaignDashboard"
              component={CampaignDashboard}
            />
            {canUserViewCampaign && canUserReadCampaign && <Route
              exact
              path="/campaign/Campaign"
              component={Campaigns}
            />}
            {canUserViewCalendar && canUserReadCalendar &&
              <Route
                exact
                path="/campaign/Calendar"
                component={Calendar}
              />}

            {canUserViewApproval && canUserReadApproval &&<Route
              exact
              path="/campaign/ApprovalDetails"
              component={CampaignApprovalDetails}
            />}
            
            {canUserViewQuery && canUserReadQuery &&
              <Route
                exact
                path="/campaign/Queries"
                component={Queries}
              />}
            {(canUserViewTemplate && canUserReadTemplate) && <Route
              exact
              path="/campaign/3rdPartyTemplate"
              render={(props) => <ExternalTemplate {...props} subject="CampaignTemplate" />}
            />}
            {(canUserViewTemplate && canUserReadTemplate) && <Route
              exact
              path="/campaign/3rdPartyTemplateDetail"
              component={ExternalTemplateDetail}
            />}
            {canUserViewCampaign && canUserReadCampaign && <Route
              exact
              path="/campaign/CampaignDetails"
              render={(props) => <CampaignDetails {...props} subject="Campaign" />}
            //component={CampaignDetails}
            />}
            {canUserViewQuery && canUserReadQuery && <Route
              exact
              path="/campaign/QueryDetail"
              component={QueryDetail}
            />}
            <Redirect to={"/"} >{MainDashboard}</Redirect>
          </Switch>
        </Column>
      </Row>
      {/* <div className="row">
        <div className="col-lg-12">
        <CustomerDashboardView/>
        </div>
    </div> */}
    </div>

    //  </QuickSearchContext.Provider>

  )

}

export default Campaign