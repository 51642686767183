import { SelectOption } from "../../shared/components/input";
import { RefiDocument } from "../model/RefiDocument";
import { RefiTodoListDto } from "../model/RefiiTodoListDto";

export let todoList: RefiTodoListDto[] = [
    {
        "id": "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
        "scanId": "CP_Refi_12221",
        "docDesc": "Document 1 Description",
        "dateInitiated": "10-12-2024 12:00:00 PM",
        "dateAvailable": "10-12-2024 12:05:00 PM",
        "isLocked": true,
        "status": "Pending"
    },
    {
        "id": "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12222",
        "docDesc": "Document 2 Description",
        "dateInitiated": "10-12-2024 12:10:00 PM",
        "dateAvailable": "10-12-2024 12:15:00 PM",
        "isLocked": true,
        "status": "Pending"
    },
    {
        "id": "2c9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12223",
        "docDesc": "Document 3 Description",
        "dateInitiated": "10-12-2024 12:20:00 PM",
        "dateAvailable": "10-12-2024 12:25:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "3d9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12224",
        "docDesc": "Document 4 Description",
        "dateInitiated": "10-12-2024 12:30:00 PM",
        "dateAvailable": "10-12-2024 12:35:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "4e9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12225",
        "docDesc": "Document 5 Description",
        "dateInitiated": "10-12-2024 12:40:00 PM",
        "dateAvailable": "10-12-2024 12:45:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "5f9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12226",
        "docDesc": "Document 6 Description",
        "dateInitiated": "10-12-2024 12:50:00 PM",
        "dateAvailable": "10-12-2024 12:55:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "6g9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12227",
        "docDesc": "Document 7 Description",
        "dateInitiated": "10-12-2024 01:00:00 PM",
        "dateAvailable": "10-12-2024 01:05:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "7h9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12228",
        "docDesc": "Document 8 Description",
        "dateInitiated": "10-12-2024 01:10:00 PM",
        "dateAvailable": "10-12-2024 01:15:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "8i9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12229",
        "docDesc": "Document 9 Description",
        "dateInitiated": "10-12-2024 01:20:00 PM",
        "dateAvailable": "10-12-2024 01:25:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "9j9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12230",
        "docDesc": "Document 10 Description",
        "dateInitiated": "10-12-2024 01:30:00 PM",
        "dateAvailable": "10-12-2024 01:35:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "ak9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12231",
        "docDesc": "Document 11 Description",
        "dateInitiated": "10-12-2024 01:40:00 PM",
        "dateAvailable": "10-12-2024 01:45:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "bl9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12232",
        "docDesc": "Document 12 Description",
        "dateInitiated": "10-12-2024 01:50:00 PM",
        "dateAvailable": "10-12-2024 01:55:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "cm9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12233",
        "docDesc": "Document 13 Description",
        "dateInitiated": "10-12-2024 02:00:00 PM",
        "dateAvailable": "10-12-2024 02:05:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "dn9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12234",
        "docDesc": "Document 14 Description",
        "dateInitiated": "10-12-2024 02:10:00 PM",
        "dateAvailable": "10-12-2024 02:15:00 PM",
        "isLocked": false,
        "status": "Pending"
    },
    {
        "id": "eo9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
        "scanId": "CP_Refi_12235",
        "docDesc": "Document 15 Description",
        "dateInitiated": "10-12-2024 02:20:00 PM",
        "dateAvailable": "10-12-2024 02:25:00 PM",
        "isLocked": false,
        "status": "Pending"
    }
];

let nextDocument: RefiDocument = {
    "id": "9b1deb4d-3b7d-4bad-9bdd-2bbbbbbbbbd",
    "scanId": "CS_RM_10152024_1000",
    "docDesc": "Document 100 Description",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "isLocked": false,
    requestType: "R",
    requestRCE: "Y",
    vesting: "MA",
    policyNumber: "12556",
    carrier: "ASI"
}

export const requestTypeList: SelectOption[] = [{
    label : 'Refinance',
    value : 'R'
},
{
    label : 'Lender Change Request',
    value : 'L'
},
{
    label : 'Evidence of Insurance',
    value : 'E'
}];

export const requestRCEList: SelectOption[] = [{
    label : 'Yes',
    value : 'Y'
},
{
    label : 'No',
    value : 'N'
}];

export const vestingList: SelectOption[] = [{
    label : 'A married man',
    value : 'MA'
},
{
    label : 'A married woman',
    value : 'MW'
},
{
    label : 'A single man',
    value : 'SM'
},
{
    label : 'A single woman',
    value : 'SW'
}
];

export const carrierList: SelectOption[] = [{
    label : 'ASI',
    value : 'ASI'
}];

export const getRefiTodoList = async() => {
    // make api call to get todo list
    let refiTodoList = todoList;
    return refiTodoList;
}

export const getRefiDocument = async(id: string) => {
    // make api call to get current document
    const refiDocument = nextDocument;
    return refiDocument;
}

export const handleHardLockClick = async(id: string) => {
    // make api call to hard lock the document
    const refiDocument = todoList.find((doc) => doc.id === id);
    if (refiDocument) {
        refiDocument.isLocked = true;
    }
    return todoList;
}

export const approveDocument = async(id: string) => {
    // make api call to approve the document
    const refiDocument = todoList.find((doc) => doc.id === id);
    if (refiDocument) {
        refiDocument.status = 'Approved';
    }
    return nextDocument;
}

export const getRequestTypeDropdownOptions = () => {
    return requestTypeList;
}

export const getRequestRCEDropdownOptions = () => {
    return requestRCEList;
}

export const getVestingDropdownOptions = () => {
    return vestingList;
}

export const getCarrierDropdownOptions = () => {
    return carrierList;
}