import { useState } from 'react'
import { Column, Label, Modal }from '../../../components'
import  campaignResultsUtils  from '../../utils/campaignResultsUtils'
import TextInputWithLabelFormGroup from '../../../shared/components/input/TextInputWithLabelFormGroup'
import CampaignSendTest from '../../model/CampaignSendTest'
import { toast } from 'react-toastify'
import * as constant from '../../../shared/util/constant'
import * as label from '../../../shared/util/label'


interface Props {
    showSendTestPopup: boolean,
    customerId:string,
    handleClose: Function
    campaignId:string
  }

const SendTest = (props: Props) => {

    const [sendTestEmails, setSendTestEmails] = useState<string>("");

    const [isTestEmailsInvalid, setIsTestEmailsInvalid] = useState<boolean>(false);

    const initialCampaignSendTest: CampaignSendTest = {
      toEmail:'',
      customerId:null,
      campaignId:''
      }

    const [campaignSendTest, setCampaignSendTest] = useState<CampaignSendTest>(initialCampaignSendTest);

    
  const updateTestEmailAndCustomerNumber = (sendTestEmails:string) => {
    setCampaignSendTest(() => {
      return {
        toEmail: sendTestEmails.split(';').filter(x=>x!='').join(';'),
        customerId:props.customerId,         
        campaignId: props.campaignId
      };
    });
  };

  const validateInputChange = () => {
    const emails =sendTestEmails.split(';').filter(str=>str!='')
    var isValid = true
    if (emails.length > 0) {
      isValid = emails.every(email => email ? /^[\wW.-]+@[\wW.-]+\.com+$/i.test(email) : false);
    }

    setIsTestEmailsInvalid(!isValid);
    return isValid;
  };

  const sendTestMail = async () => {
    try {
  const isMailSentSuccessfully=await campaignResultsUtils.sendTestMailForCampaignResultUtils(campaignSendTest)
  if(isMailSentSuccessfully)
  {
    toast.success(constant.CAMPAIGN_SEND_TEST_SUCCESS_MESSAGE, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: constant.AUTO_CLOSE
    });
  }
  else
  {
    toast.error(constant.CAMPAIGN_SEND_TEST_ERROR_MESSAGE, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: constant.AUTO_CLOSE
    });
  }

  }

  catch (error) {
    toast.error(constant.CAMPAIGN_SEND_TEST_ERROR_MESSAGE, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: constant.AUTO_CLOSE
    });
    console.log("error", error) // Catch any additional errors
  }
  }

  
  function  handleClose() {
    props.handleClose()
    setCampaignSendTest(initialCampaignSendTest)
}


return (
    
    <Modal
        verticallyCentered={true}
        title={label.Send_Test}
        show={props.showSendTestPopup}
        toggle={() => {
            handleClose()
        } }
        body={
          <> 
            <TextInputWithLabelFormGroup
            label={label.SEND_TEST_EMAIL_LABEL}
             labelClass='font-bold'
            placeholder={label.SEND_TEST_EMAIL_PLACEHOLDER}
            name="sendTestEmail"
            value={sendTestEmails}
            isEditable={true}
            isInvalid={isTestEmailsInvalid}
            onChange={(event) => {
                 setSendTestEmails(event.currentTarget.value)
                updateTestEmailAndCustomerNumber(event.currentTarget.value)
            } }
            />
             {
              isTestEmailsInvalid && 
            <Label 
            text={constant.SEND_TEST_INVALID_EMAIL_FORMAT_WARNING}
            style={{color:"red"}}
            /> 
             }
             </>
          }
        closeButton={{
            size: "small",
            icon: 'cancel',
            iconStyle: { color: "gray" },
            outlined: true,
            color: 'link',
            onClick() {
                handleClose()
            },
            children: <span style={{ color: "gray" }}>{label.CANCEL.toUpperCase()}</span>
        }}
        successButton={{
            size: "small",
            icon: "check",
            iconStyle: { color: "177db8" },
            outlined: true,
            color: 'link',
            disabled: sendTestEmails === '',
            onClick() {
                if (validateInputChange()) {
                    sendTestMail()
                    handleClose()
                }
            },
            children: <span style={{ color: "#177db8" }}>{label.SEND.toUpperCase()}</span>
        }}
        buttonsAlignment={"right"} />
       
    )

}
export default SendTest