// import * as locales from 'date-fns/locale'
import React, { ReactNode, CSSProperties } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import './DateTimePicker.scss';
import 'react-datepicker/dist/react-datepicker.css'
import { Icon } from '../Icon'
import { Label } from '../Label';
import * as constant from '../../../shared/util/constant'
// // Generic way to register all available locales
// Object.entries(locales).forEach(([key, locale]) => {
//   registerLocale(key, locale)
// })

// // Additional locales required for the frontend
// registerLocale('ar', locales.arDZ)
// registerLocale('zr', locales.zhCN)

setDefaultLocale('enUS')

interface Props {
  /** Yield element as children. */
  children?: ReactNode
  /** The format for parsed and displayed dates. */
  dateFormat?: string
  /** The format for parsed and displayed dates in internal yeardropdown. */
  dateFormatCalendar?: string
  /** Disable datepicker. */
  disabled?: boolean
  /** Change selection for internal year/month dropdowns. */
  dropdownMode: 'scroll' | 'select'
  /** The end date of the initially selected date range. */
  endDate?: Date
  /** Exclude dates within the specific date range. */
  excludeDates?: Date[]
  /** Include dates within the specific date range. */
  includeDates?: Date[]
  /** Visualize calendar as inline element. */
  inline?: boolean
  /** Set datepicker locale. */
  locale?: string
  /** The maximum selectable date. */
  maxDate?: Date
  /** The maximum selectable time. */
  maxTime?: Date
  /** The minimum selectable date. */
  minDate?: Date
  /** The minimum selectable time. */
  minTime?: Date
  /** Show more months. */
  monthsShown?: number
  /** Input.Group class */
  className?: string
  /** DatePicker calendar class */
  calendarClassName?: string
  /** Callback when date is changed. */
  onChange?: (date: Date, event: React.ChangeEvent<HTMLInputElement>) => void
  /** Selected date value. */
  selected?: Date
  /** Selected date end. */
  selectsEnd?: boolean
  /** Selected date start. */
  selectsStart?: boolean
  /** Show dropdown list with all months. */
  showMonthDropdown?: boolean
  /** Enable time selection. */
  showTimeSelect?: boolean
  /** Show dropdown list with years. */
  showYearDropdown?: boolean
  /** Enable only time selection. */
  showTimeSelectOnly?: boolean
  /** The beginning date of the initially selected date range. */
  startDate?: Date
  /** Inline Style props */
  style?: CSSProperties
  /** The format for parsed and displayed time. */
  timeFormat?: string
  /** Configure timer intervals. */
  timeIntervals?: number
  /** Header over the time selector. */
  timeCaption?: string
  /** Enable and set text for today button. */
  todayButton?: string
  /** Visualize calendar as portal. */
  withPortal?: boolean
  /** Determines whether the Select should be rendered as valid or not. Default is false. */
  isValid?: boolean
  /** Determines whether the Select should be rendered as invalid or not. Default is false. */
  isInvalid?: boolean
  /** Defines the custom feedback of the input. */
  feedback?: string
  tabIndex?:number
  highlightedDates?:Date[]
  invalidWarning?:string,
  invalidLableClass?:string,
  name?:string
  value?:string
  placeholderText?:string,
  errorLableDivClassName?: string,
  popperClassName? : string,
  title?: string
}

const DateTimePicker = (props: Props) => {
  const {
    children,
    className,
    calendarClassName,
    dateFormat,
    dateFormatCalendar,
    disabled,
    dropdownMode,
    endDate,
    excludeDates,
    includeDates,
    inline,
    locale,
    maxDate,
    maxTime,
    minDate,
    minTime,
    monthsShown,
    onChange,
    selected,
    selectsEnd,
    selectsStart,
    showMonthDropdown,
    showTimeSelect,
    showYearDropdown,
    showTimeSelectOnly,
    style,
    timeFormat,
    timeIntervals,
    timeCaption,
    todayButton,
    startDate,
    withPortal,
    isValid,
    isInvalid,
    feedback,
    tabIndex,
    highlightedDates,
    invalidWarning,
    invalidLableClass,
    errorLableDivClassName,
    popperClassName,
    name,
    value,
    placeholderText,
    title
  } = props

  const highlightCustomClass = (date) => {
    if (date!=undefined && date!=null && highlightedDates!=undefined && highlightedDates!=null && highlightedDates.some((highlightedDate) => date.toDateString() === highlightedDate.toDateString())) {
      return 'highlighted';
    }
    return '';
  };

  let invalidLable = `cst-Feedback-Linebreak${invalidLableClass ? ' ' + invalidLableClass : ''}`;

  return (
    <>
      <InputGroup className={className} style={style}>
        {/* <InputGroup.Prepend>
          <InputGroup.Text>
            <Icon icon="calendar" />
          </InputGroup.Text>
        </InputGroup.Prepend> */}
        <DatePicker
          className={`form-control ${isValid ? 'is-valid' : isInvalid ? 'is-invalid' : ''}`}
          calendarClassName={calendarClassName}
          dateFormat={dateFormat}
          dateFormatCalendar={dateFormatCalendar}
          disabled={disabled}
          dropdownMode={dropdownMode}
          endDate={endDate}
          excludeDates={excludeDates}
          includeDates={includeDates}
          popperClassName={popperClassName ? popperClassName : ''}
          inline={inline}
          locale={locale}
          maxDate={maxDate?maxDate:constant.MAX_DATE}
          maxTime={maxTime}
          minDate={minDate}
          minTime={minTime}
          monthsShown={monthsShown}
          onChange={onChange}
          placeholderText ={placeholderText??"mm/dd/yyyy"}
          selected={selected}
          selectsEnd={selectsEnd}
          selectsStart={selectsStart}
          showMonthDropdown={showMonthDropdown}
          showTimeSelect={showTimeSelect}
          showYearDropdown={showYearDropdown}
          showTimeSelectOnly={showTimeSelectOnly}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          timeCaption={timeCaption}
          todayButton={todayButton}
          startDate={startDate}
          withPortal={withPortal}
          tabIndex={tabIndex}
          highlightDates={highlightedDates}
          dayClassName={highlightCustomClass}
          name={name}
          value={value}
          title={title}
        >
          {children}
        </DatePicker>
                {/* <InputGroup.Append>
          <InputGroup.Text id="inputGroup-sizing-sm">
            <Icon icon="calendar" size="xs"/>
          </InputGroup.Text>
        </InputGroup.Append> */}
      </InputGroup>
      {feedback !== undefined && (
        <div
          className={`text-left ml-5 mt-1 text-small ${
            isValid ? 'text-success' : isInvalid ? 'text-danger' : undefined
          }`}
        >
          {feedback}
        </div>          
      )}
      {isInvalid &&
            <div className={`${errorLableDivClassName ? errorLableDivClassName : ''}`}>
                 <Label
                  className={invalidLable}
                  text={invalidWarning}
                  style={{ color: "red" }} />
              </div>}
    </>
  )
}
DateTimePicker.defaultProps = {
  dateFormat: 'MM/dd/yyyy',
  locale: 'enUS',
  dateFormatCalendar: 'LLLL yyyy',
  dropdownMode: 'scroll',
  timeIntervals: 30,
  withPortal: false,
}

export { DateTimePicker }
