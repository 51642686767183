import React from "react";
import './material-label.scss';
import { Column, Row } from "../../../components";

export enum MaterialLableDisplayType {
    Vertical = "vertical",
    Horizontal = "horizontal",
    HorizontalFlex = "horizontalFlex",
}

export interface IMaterialLable {
    lableCaption? : string;
    lableValue? : string;
    lableCaptionClass? : string;
    lableValueClass? : string;
    valueSectionTemplate? : any;
    displayType? :MaterialLableDisplayType,
    captionLgCol? : number,
    captionMdCol? : number,
    valueLgCol? : number,
    valueMdCol? : number,
}

const MaterialLable : React.FunctionComponent<IMaterialLable>   = (props) => {
    const { lableCaption, lableValue, lableCaptionClass, lableValueClass, valueSectionTemplate, displayType, captionLgCol, captionMdCol, valueLgCol, valueMdCol } = props;

    const captionClass = `mat-lable-caption ${lableCaptionClass ? lableCaptionClass : ''}`;

    let valuePlaceHolder = valueSectionTemplate;
    if (!valuePlaceHolder) {
        const valueClass = `mat-lable-value ${lableValueClass ? lableValueClass : ''}`;
        valuePlaceHolder = <span className={valueClass} dangerouslySetInnerHTML={{ __html: lableValue }} ></span>
    }

    let lableOutput = <></>;
    
    if (!displayType || displayType == MaterialLableDisplayType.Vertical) {
        lableOutput = <div className="mat-lable-container">
                        <span className={captionClass}>{lableCaption}</span>
                        {valuePlaceHolder}
                    </div>;
    }
    else if (displayType == MaterialLableDisplayType.Horizontal) {
        const capLg = captionLgCol ? captionLgCol : 6;
        const capMd = captionMdCol ? captionMdCol : 6;
        const valLg = valueLgCol ? valueLgCol : 6;
        const valMd = valueMdCol ? valueMdCol : 6;
        lableOutput =   <Row>
                            <Column lg={capLg} md={capMd}>
                                <span className={captionClass}>{lableCaption}</span>
                            </Column>
                            <Column lg={valLg} md={valMd}>
                                {valuePlaceHolder}
                            </Column>
                        </Row>;
    }
    else if (displayType == MaterialLableDisplayType.HorizontalFlex) {
        lableOutput = <div className="mat-lable-container-hr-flex">
                        <span className={captionClass}>{lableCaption}</span>
                        {valuePlaceHolder}
                    </div>;
    }

    return (
        <>
        {lableOutput}
        </>
    );
}

export default MaterialLable;