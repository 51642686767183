import validator from 'validator';
import { APP_IDs, CustomerTaskConstants, FIELD_BLANK } from "../../../shared/util/constant";
import { CustomerTaskDto } from "../../model/CustomerTaskDto";
import { CustomerTaskDtoRead } from '../../model/CustomerTaskDtoRead';
import TaskApi from '../../services/TaskApi';
import { LookupDetailConstants } from '../../../shared/util/LookupDetailConstants';
import { CommonUtility } from '../../../Services/CommonUtility';

class SliderTaskService {

    taskApi : TaskApi;
    constructor(){
        this.taskApi = new TaskApi();
    }

    public validateTask = (taskDto : CustomerTaskDto) => {
        let validationErrors = {};
        
        if (!taskDto.description || taskDto.description?.trim().length == 0) {
            validationErrors['description'] = {isValid : false, warningText : FIELD_BLANK };
        }

        if(!taskDto.dueDate){
            validationErrors['dueDate'] = {isValid : false, warningText : FIELD_BLANK };
        }
        else if(!validator.isDate(taskDto.dueDate)) {
            validationErrors['dueDate'] = {isValid : false, warningText : CustomerTaskConstants.INVALID_TASK_DATE };
        }

        if (!taskDto.priorityId) {
            validationErrors['priorityId'] = {isValid : false, warningText : FIELD_BLANK };
        }

        if (!taskDto.statusId) {
            validationErrors['statusId'] = {isValid : false, warningText : FIELD_BLANK };
        }

        if (!taskDto.toUserId) {
            validationErrors['toUserId'] = {isValid : false, warningText : FIELD_BLANK };
        }

        if (!taskDto.taskTypeId) {
            validationErrors['taskTypeId'] = {isValid : false, warningText : FIELD_BLANK };
        }

        return validationErrors;
    }

    public getTaskRequestDto = (taskDto : CustomerTaskDtoRead, polEffDate : Date) => {

        let requestDto : CustomerTaskDto = {};

        if (taskDto.id) {
            requestDto.id  = taskDto.id;
        }
        
        requestDto.description = taskDto.description;
        requestDto.dueDate = CommonUtility.datetimeToDateOnly(taskDto.dueDate);
        requestDto.priorityId = taskDto.priorityId;
        requestDto.statusId = taskDto.statusId;

        // If task is marked as completed. pass on current date as completed date.
        if (requestDto.statusId 
            && requestDto.statusId?.toLocaleLowerCase() == LookupDetailConstants.CUSTOMER_TASK_STATUS.COMPLETED?.toLocaleLowerCase()) {
            requestDto.completedDate = new Date();
        }

        requestDto.taskTypeId = taskDto.taskTypeId;
        requestDto.toUserId = taskDto.toUserId;
        requestDto.ccUserId = taskDto.ccUserId;
        requestDto.custId = taskDto.custId;
        requestDto.polId = taskDto.polId;
        requestDto.effDate =  polEffDate;
        requestDto.companyId = taskDto.companyId;
        requestDto.claimId = null;  /// TODO : Dont's send claims as of now, as database side yet to be done. 

        requestDto.appId = taskDto.appId ? taskDto.appId : APP_IDs.CS2_APP_ID

        return requestDto;
    }

    public createTask = async (customerTaskDto : CustomerTaskDto) => {
        return await this.taskApi.createTask(customerTaskDto);
    }

    public updateTask = async (customerTaskDto : CustomerTaskDto) => {
        return await this.taskApi.udpateTask(customerTaskDto);
    }
}

export default SliderTaskService;