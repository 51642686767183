import { useSelector } from 'react-redux';
import './taskdashboard.scss';
import React, { useEffect, useState } from 'react';
import TaskDashboardService from './taskdashboardservice';
import { CustomerTaskDtoReadExtended } from '../../customer/model/CustomerTaskDtoRead';
import Logger from '../../shared/services/Logger';
import TaskSummaryItem from './TaskSummaryItem';
import ReactPaginator, { IReactPaginatorState } from '../../components/components/Pagination/ReactPaginator';
import { toast } from 'react-toastify';

const defaultPaginatorState : IReactPaginatorState = {
    currentPageNumber : 0,
    itemsPerPageCount : 6,
    totalItemsCount : 0,
    numberOfPageButtonsToShow : 2,
    pageButtonIndexes : []
};

export interface ITaskDashboard {
    navigateTo : (path : string) => void
}

// TODO : will move it to constants file. 
export const TaskDashboardConstants = {
    ERROR_WHILE_FETCHING_USERS_TASKS_DETAILS : "We encountered an issue while fetching user's task details. Please try again in a moment. If the problem persists, contact support team for assistance."
}

const TaskDashboard : React.FunctionComponent<ITaskDashboard> = (props) => {

    const userDetails = useSelector((state : any) => state.userContextSlice);
    const [tasks, setTasks] = useState<CustomerTaskDtoReadExtended[]>([]);

    const [tasksToShow, setTasksToShow] = useState<CustomerTaskDtoReadExtended[]>([]);
    const [paginatorState, setPaginatorState] = useState<IReactPaginatorState>(defaultPaginatorState);

    useEffect(() => {
        initComponent();
    }, [userDetails.userContext]);

    const initComponent = async () => {
        try {
            if (!userDetails.userContext) {
                return;
            }

            let userId = userDetails.userContext.id;

            let taskDashboardService = new TaskDashboardService();

            let usersTasks = await taskDashboardService.getTasks(userId);
            setTasks(usersTasks);
        }
        catch(ex) {
            Logger.logError(TaskDashboardConstants.ERROR_WHILE_FETCHING_USERS_TASKS_DETAILS, ex);
            toast.error(TaskDashboardConstants.ERROR_WHILE_FETCHING_USERS_TASKS_DETAILS, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    useEffect(() => {
        setPaginatorState((pstate) => {return {...pstate, currentPageNumber : 0, totalItemsCount : tasks.length}});
    }, [tasks]);

    useEffect(() => {
        showTasks();
    },[paginatorState]);

    const showTasks = () => {
        let pageItems = [];
        if (tasks.length > 0) {
            let startIndex = paginatorState.currentPageNumber * paginatorState.itemsPerPageCount;
            let endIndex = startIndex + paginatorState.itemsPerPageCount;
            for(let indx = startIndex; indx < endIndex && indx < tasks.length;indx++) {
                pageItems.push(tasks[indx]);
            }
        }

        setTasksToShow(pageItems);
    }

    const taskItems = tasksToShow.map(item => {
        return <TaskSummaryItem taskItem={item} navigateTo={props.navigateTo}></TaskSummaryItem>;
    });

    const onPageChange = (pagerState : IReactPaginatorState) => {
        setPaginatorState(pagerState);
    }

    return (
        <div className='task-dashboard-card' >
            <div className='task-dashboard-card--header'>
                Incomplete Tasks
                <div className="default-badge-div"><span className={`badge default-badge`}>{tasks.length}</span></div>
            </div>
            <div className='task-dashboard-card--content'>
                <div className='task-dashboard-card--items'>
                    {taskItems}
                </div>
                <div className='task-dashboard-card--pagination'>
                    <ReactPaginator paginatorState={paginatorState} onPageChange={onPageChange}></ReactPaginator>
                </div>
            </div>
        </div>
    );
}

export default TaskDashboard;