import { useQuery } from 'react-query'
import { userSetupAPI } from '../Services/AdminAPI/UserSetupAPI'
import { SelectOption } from '../.../../../shared/components/input';
import RolePrivilegeSetupAPI from '../Services/RolePrivilegeSetupAPI'


class UserSetupUtils {
    async getallUsersList() {
        var response = await userSetupAPI.getallUsersList();
        return response.data;
    }

    async getAgencyGroups() {
        const agencyGroups = await userSetupAPI.getAgencyGroups();
        return  (agencyGroups).map((agGroup) => {
                return {
                    label: agGroup.description,
                    value: agGroup.id
                }
            })
    }

    async getAgenciesByGroup(agencyGroupId) {
        const agencyGroups = await userSetupAPI.getAgenciesByGroup(agencyGroupId);
        return  (agencyGroups).map((agGroup) => {
                return {
                    label: agGroup.description,
                    value: agGroup.id
                }
            })
    }

    async getAppRoles(appId) {
        const appRoles = await userSetupAPI.getAppRoles(appId);
        return  (appRoles).map((role) => {
                return {
                    label: role.roleName,
                    value: role.id
                }
            })
    }
    

    async getEmployeeList() {
        const employeeList = await userSetupAPI.getEmployeeList();
        return  (employeeList).map((employee) => {
                return {
                    label: employee.description,
                    value: employee.id
                }
            })
    }

    async getDropdownOptions(tableid): Promise<SelectOption[]> {
        const externalTemplateTypes = await userSetupAPI.fetchDropdownOptions(tableid);
        return externalTemplateTypes.sort((a, b) =>
            a.lookupValue > b.lookupValue ? 1 : -1).map((externalTemplateType) => {
                return {
                    label: externalTemplateType.lookupValue,
                    value: externalTemplateType.id,
                    isInactive: externalTemplateType.isInactive
                }
            }
            )
    };

    async getEmployeeDetails(empId) {
        const employeeDetails = await userSetupAPI.getEmployeeDetails(empId);
        return  employeeDetails;
    }
    async getUserDetails(empId) {
        const userDetails = await userSetupAPI.getUserDetails(empId);
        return  userDetails;
    }
}



export const userSetupUtils = new UserSetupUtils();