import { createSlice } from '@reduxjs/toolkit'

interface FormSavedState {
  formSaved: boolean
}

const initialState: FormSavedState = {
    formSaved: false,
}

const formSavedSlice = createSlice({
  name: 'renderHistory',
  initialState,
  reducers: {
    toggleFormSaved(state) {
      state.formSaved = !state.formSaved
    },
  },
})

export const { toggleFormSaved } = formSavedSlice.actions


export default formSavedSlice.reducer