export const TODOLIST_GRID_LABELS = 
{
    scanId: 'Scan #',
    onHold: 'On Hold',
    fileName: 'File Name',
    CS2Id: 'CS2 #',
    AdvId: 'A+ Reference Number',
    policyNumber: 'Policy #',
    docDescription: 'Document Description',
    fromUser: 'From User',
    fromFolder: 'From Folder',
    redirectReason: 'Redirect Reason',
    dateRedirected: 'Redirect Date',
    dateInitiated: 'Date Initiated',
    dateAvailable: 'Available Date'
}
export const INDEXING_GRID_LABELS = {
    cs2RefNum: 'CS2 #',
    advRefNum: 'A+ Ref #',
    status: 'STATUS',
    fullName: 'FULL NAME'
}
export const SEARCH_RADIO_LABELS = {
    advRefNum: 'A+ Reference #',
    cs2RefNum: 'CS2 #',
    polRefNum: 'CS2 Policy #'
}
export const DOCUMATE_LABEL='DocuMate+'
export const DOCUMATE_SCAN='Scan'
export const DOCUMATE_SEARCH='Search'
export const DOCUMATE_REFI='Refi Process'
export const DOCUMATE_SCAN_TITLE='DocuMate+ Images'
export const DOCUMATE_INDEXING_TITLE='DocuMate+ Indexing'
export const DOCUMATE_REFI_TITLE='DocuMate+ Refinance Automated Process'
export const DOCUMATE_INDEXING='Indexing & Processing'
export const DOCUMATE_UNASSIGNED='Unassigned'
export const DOCUMATE_DEST_FOLDER='Destination Folders'
export const CONFIRM='Confirm'
export const CONFIRM_YES='Yes'
export const CONFIRM_NO='No'
export const TODO_LIST_GROUPS='Group'
export const TODO_LIST_FOLDERS='ToDo Folder'
export const TODO_LIST_USERS='User(s)'