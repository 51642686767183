
import { Link } from 'react-router-dom'
import { Button, Row, Column, Spinner, Label, DateTimePicker } from '../../components'
import Grid from '../../components/components/Grid/Grid'
//import { GetAllExternalTemplateList } from '../hooks/Manage3rdPartyTemplateOperations'
import React, { useState, useEffect, Suspense, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import * as label from '../../shared/util/label'
import * as constant from '../../shared/util/constant'
import { ErrorBoundary } from 'react-error-boundary'
import { userSetupUtils } from '../utils/UserSetupUtils'
import Loading from '../../shared/components/Loading'
import ScrollTop from '../../shared/components/scrollTopButton'
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { AbilityContext } from '../../shared/abilities/AbilityContext';
import UserSetupMiniTabs from './UserSetupMiniTabs'
import { Accordion, Card } from 'react-bootstrap'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import './UserSetupGrid.scss';

//import { constant } from 'lodash'
//import * as constant from '../../shared/util/constant'

interface Props {
  isSideBarCollapsed: boolean
  // subject: any
}

const UserSetupGrid = (props: Props) => {
  // const subject = props.subject;
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch()
  const history = useHistory()
  const [highlightedMenu, setHighlightedMenu] = useState('3rdpartytemplate');
  const [open, setOpen] = useState("0");
  
  //const { data, isLoading } = GetAllExternalTemplateList();
  const [data, setData] = useState([]);
//var data=[];
  const [showFilters, setShowFilters] = useState(true);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedColumnValue, setSelectedColumnValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [includeInactiveRecords, setIncludeInactiveStatus] = useState(false);
  const [activeRecord, setActiveRecord] = useState([])
  const[ErrorText,setErrorText]=useState('')
  const[isScrollTop, setScrollTop]=useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleLoading = () => {
    setLoading(false);
  }
  useEffect(() => {
    window.addEventListener("load", handleLoading)
    return () => window.removeEventListener("load", handleLoading);
  }, [])

  async function fetchData() {
    //setLoading(true)
      await userSetupUtils.getallUsersList()
      .then(response =>setData(response))
      .catch(error => console.error(error))
      //.finally(()=> setLoading(false));
  }

  useEffect(() => {
    //alert('called')
    fetchData();
   // highlightMenu();
  },[]);

   //#region Call when there is error occur on the page
//  const FallbackComponent = ({ error, resetErrorBoundary }) => {
//   console.log("An error occurred:");
//   console.log(error.message);
//   return (
//       <div>
//           <h1>An error occurred: {error.message}</h1>
//           <button onClick={resetErrorBoundary}>Try again</button>
//       </div>
//   );
// };

 
  useEffect(() => {
    if (isRowSelected) {
      setSelectedColumnValue((prevValue) => {
        if (prevValue !== selectedColumnValue) {
          return selectedColumnValue;
        }
        return prevValue;
      });
    }
  }, [isRowSelected]);

  useEffect(() => {

  }, [isRowSelected]);

  const handleSelectedRow = (selected, columnValue) => {
    setIsRowSelected(selected);
    setSelectedColumnValue(columnValue);
  };
  //#region Scroll to Top function 
  const getScrollPostionStatus=()=>{
    var scrollPostion=window.pageYOffset;
    
    if(scrollPostion>= constant.SHOW_SCROLL_BUTTON_POSITION){
     // setScrollTop(true)
    }
    else{
      //setScrollTop(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', getScrollPostionStatus, { passive: true });

    return () => {
        window.removeEventListener('scroll', getScrollPostionStatus)
    };
}, []);
//#endregion Scroll to Top function
  

  const routeChange = () => {
    //highlightMenu('3rdpartytemplate')
    // let path = '/Campaign/3rdPartyTemplateDetail';
    // history.push(path);
  }

  const setEmployee = (empId) => {
    setSelectedEmployee(empId);
  }

  const checkInactiveStatus = (event) => {
    if (event.target.checked) {
      setIncludeInactiveStatus(true)

    }
    else {
      setIncludeInactiveStatus(false)
    }
  }

  const handleNewEmp = (tab: string) => {
    setSelectedEmployee(null)
    setOpen(tab)
};
  

  return (
    <>
    <Column lg={12} md={12}>
      <span>{ErrorText}</span>
    </Column>
   
    <ErrorBoundary FallbackComponent={FallbackComponent}>
    
    <div style={{ 
    marginLeft: props.isSideBarCollapsed ? '5px' : '60px',
    }}>
      <div className='outerPanel'>
        <Row>
          <Column lg={8} md={8} className=""><b>{label.USER_SETUP.toUpperCase()}</b>
          </Column>
          <Column lg={1} md={1} ></Column>
          <Column lg={2} md={2} className='padding-bottom10'>
            <Button onClick={() => handleNewEmp("1")}>{label.NEW_EMPLOYEE.toLocaleUpperCase()}</Button>
          </Column>
        </Row>
      </div>
      <Accordion activeKey={open}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setOpen(open !== "0" ? "0" : null)} className="customToggle">
            {open === "0" ? <FaChevronDown /> : <FaChevronRight />} {label.USER_SEARCH_FILTER}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
                {/* <Row className='gridrow'> */}
            {/* <Suspense fallback={<Loading />}> */}
                <Grid

                  data={data !== undefined && data.length>0  ? data :
                  data !== undefined  ? data.filter(item => item.status===null?'':item.status.toUpperCase() !== constant.INACTIVE.toUpperCase()) : []}
                  showFilters={showFilters}
                columns={[
                  {
                    Header: label.EmployeeId,
                    accessor: 'id',
                    Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>

                  },
                  {

                    Header: label.EMAIL,
                    accessor: 'eMail',
                    dataType: 'string',
                    sortType:'string',
                    Cell: ({ row }) =>
                      <div><Link
                        // to={{
                        //   pathname: '/Campaign/3rdPartyTemplateDetail',
                        //   state: { entityId: row.original.id, type: "3" },// Pass the row data as state
                        //   search :"?id="+row.original.id
                        // }}
                        href='#'
                        onClick={(event) => { 
                          event.preventDefault();
                          setOpen("1");
                          setEmployee(row.original.id);
                          // set the data to be passed to the mini tabs here.
                         }}
                        title={row.original.eMail}>{data !== undefined ? row.original.eMail : ''}</Link></div>,
                        width:250,
                        minWidth:150
                      },
                  {
                    Header: label.FIRST_NAME,
                    accessor: 'firstName',
                    // useFilters:()=>{getInactiveRecords},
                    Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                    width:250,
                    minWidth:150,
                    dataType: 'string',
                  },
                  {
                    Header: label.LAST_NAME,
                    accessor: 'lastName',
                    Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                    width:250,
                    minWidth:150,
                    dataType: 'string',
                  },
                  {
                    Header: label.AGENCY,
                    accessor: 'agencyName',
                    Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                    width:250,
                    minWidth:150,
                    dataType: 'string',
                  },
                  {
                    Header: label.STATUS,
                    accessor: 'employeeStatus',
                    Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                    width:250,
                    minWidth:150,
                    dataType: 'string',
                  },
                  {
                    Header: label.USER_SETUP,
                    accessor: 'userSetupCompleted',
                    Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                    width:250,
                    minWidth:150,
                    dataType: 'string',
                  },
                  {
                    Header: label.LastLogin,
                    accessor: 'lastLogin',
                    Cell: row => <div><span title={row.value}>{data !== undefined && row.value !== null ? row.value + ' ET' : ''}</span></div>,
                    width:250,
                    minWidth:150,
                    dataType: 'string',
                  }
                ]}
                hiddenColumns={['id','selection']}
                sortDefault={[{ id:'LastLogin', desc: false },{id:'employeeStatus', desc: false}]}
                id={'tblExternalTemplate'}

              />
              {/* </Suspense> */}
              {/* {loading && <Spinner color="blue" loading size={[10, 25]} type="FadeLoader" />} */}

              <ScrollTop isDisplay={isScrollTop}/>
              {/* </Row> */}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Accordion activeKey={open} style={{marginBottom: '20px'}}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setOpen(open !== "1" ? "1" : null)} className="customToggle">
            {open === "1" ? <FaChevronDown /> : <FaChevronRight />} {label.USER_SEARCH_DETAILS}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {<UserSetupMiniTabs selectedEmployee={selectedEmployee}/>}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      </div>
        
      </ErrorBoundary>
    </>
  )
}
export  {UserSetupGrid} 