// import { icon } from "@fortawesome/fontawesome-svg-core";
import React, { useEffect, useState } from "react";
import { Row, Column, Label, Spinner, Select, Button, Icon, TextField } from "../../components";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
//import { SelectOption } from "../../shared/components/input/SelectOption";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
//import { getQueryFieldTypeList, getQueryLogicList } from '../hooks/GetQueryLookups'
import {getDropdownOptionsList } from '../utils/dropdownUtils';
import _campaignFilterList from '../model/QueryFilters'
import * as table from '../../shared/util/tableConstant'
//import { constant } from "lodash";
import * as constant from '../../shared/util/constant'

function QueryFilterRow({ rowsData, onDetailFieldChange, onDetailFieldLogicChange, handleChange, deleteTableRows, count, multiSelectedOptions }) {
    const [QueryLogicOption, setQueryLogicOptions] = useState([]);
    const [FieldTypeOption, setFieldTypeOptions] = useState([]);
    const [multiSelectionsOptions, setMultiSelectionsLogic] = useState(null);
    const [dataTypeLable, setDataTypeLable]=useState('')
    var elementCounter=4;

 //--------------------#region Populate Dropdown------------------------------------------ 
 useEffect(() => {
    getDropdownOptionsList(table.FIELD_TYPE).then(res => {
        setFieldTypeOptions(res);
    })
}, [])
useEffect(() => {
    getDropdownOptionsList(table.FILTER_CONDITION).then(res => {
        setQueryLogicOptions(res);
    })
}, [])



    return (
        // Load filters row by row
        
        rowsData.map((data, index) => {
            //console.log('rowsData:',data)
            var ddlLogicId = 'ddlCondition' + index.toString();
            var fieldTypeId = 'ddlFieldType' + index.toString()
            var txtDataTypeId = 'txtDataType' + index.toString()
            data.dataTypeLogicVisible=data.dataType===undefined?false:data.dataType===null?false:data.dataType.toUpperCase()===constant.DROPDOWN_SQL_ID.toUpperCase()?true:data.dataType.toUpperCase()===constant.DROPDOWN_LIST_ID.toUpperCase()?true:false;
            //console.log('datatypeloop',data.dataType)
            data.dataTypeText=data.dataType===constant.DROPDOWN_SQL_ID?constant.FIELD_VALUE_SQL:data.dataType===constant.DROPDOWN_LIST_ID?constant.FIELD_VALUE_LIST:'';
            //console.log('Logic Display',data.dataTypeLogicVisible, data.dataType, constant.DROPDOWN_SQL_ID,constant.DROPDOWN_LIST_ID)
            var logicids = data.campaignFilterIds===undefined?'':data.campaignFilterIds.toString().split(",");
            if(data.id===null||data.id===undefined||data.id===''){
                data.id=null;
            }
            return (
                <Row key={index} className='filter-row-height filter-criteria-section'>
                    
                    <Column lg={1} className='hidden'>
                        <Label text={data.id} className='hidden' />
                    </Column>
                    <Column lg={2} className={data.isDbFieldEmpty?'feedback-row-filter' : ''}>
                        <TextInputWithLabelInline
                            name={'DatabaseField' + index}
                            isEditable
                            type='text'
                            maxLength={15}
                            value={data.fieldName}
                            onChange={(evnt) => (handleChange(index, evnt, 'fieldName'))}
                            tabIndex={elementCounter++}
                            lgText={12}
                            mdText={12}
                            isRequired
                            className="cstFilterInput texbox-input cstCollection"
                            isInvalid={data.isDbFieldEmpty}
                            isEmpty={data.isDbFieldEmpty}
                            errorText={data.dbFieldError}
                        />
                    </Column>
                    {/* <Column lg={1} className=''>
                        <Label text={JSON.stringify(data.campaignFilterLogic)} className='' />
                    </Column> */}
                    <Column lg={5} className={data.isLogicEmpty?'feedback-row-filter' : ''}>
                        <Select
                            id={ddlLogicId}
                            tabIndex={elementCounter++}
                            options={QueryLogicOption}
                            placeholder={constant.PLEASE_SELECT}
                            inputProps={{ className: `form-control texbox-input cstCollection `+ddlLogicId.toString(), multiple: true, tabIndex:elementCounter++,
                                            title:(QueryLogicOption.filter(({ value }) => data.campaignFilterIds === undefined ? data.campaignFilterIds : data.campaignFilterIds=== null ? data.campaignFilterIds : data.campaignFilterIds.includes(value)).map((option)=>option.label).toString().replaceAll(',','\r\n')
                                            
                                            )
                                        }}
                            onChange={(values) => {
                                const selectedOption = QueryLogicOption.filter((option) => values.includes(option.value));
                                //console.log('onChange:' + selectedOption.toString())
                                setMultiSelectionsLogic(selectedOption);
                                onDetailFieldLogicChange('campaignFilterIds', values, index,data.campaignFilterLogic);
                            }
                            }
                            defaultSelected={QueryLogicOption.filter(({ value }) => data.campaignFilterIds === undefined ? data.campaignFilterIds : data.campaignFilterIds=== null ? data.campaignFilterIds : data.campaignFilterIds.includes(value))}
                            multiple={true}
                            isInvalid={data.isLogicEmpty}
                            invalidWarning={data.logicFieldError}
                           // isEmpty={data.isLogicEmpty}
                            //errorText={data.logicFieldError}
                        />
                    </Column>
                    <Column lg={2} className={data.isFriendlyNameEmpty?'feedback-row-filter' : ''}>
                        <TextInputWithLabelInline
                            name={'FriendlyName' + index}
                            isEditable
                            type='text'
                            maxLength={15}
                            value={data.filterDisplay}
                            className="cstFilterInput texbox-input cstCollection"
                            onChange={(evnt) => (handleChange(index, evnt, 'filterDisplay'))}
                            tabIndex={elementCounter++}
                            lgText={12}
                            mdText={12}
                            isRequired
                            isInvalid={data.isFriendlyNameEmpty}
                            isEmpty={data.isFriendlyNameEmpty}
                            errorText={data.friendlyNameError}
                        />
                    </Column>
                    <Column lg={2} className={data.isFieldTypeEmpty?'feedback-row-filter' : ''}>
                        <DropDownWithLabelInline
                            id={fieldTypeId}
                            options={FieldTypeOption}
                            inputProps={{ className: 'inputStyle cstFilterInput texbox-input cstCollection', id: fieldTypeId,tabIndex:elementCounter++}}
                            defaultSelected={FieldTypeOption.filter(({ value }) => value === (data===undefined?'':data.dataType===undefined?'':data.dataType===null?'':data.dataType.toUpperCase()))}
                            onChange={
                                (values) => {
                                    const selectedOption = FieldTypeOption.find((option) => option.value === (values[0]));
                                    const selectedLable=selectedOption===undefined?'':selectedOption===null?'':selectedOption.label
                                    setDataTypeLable(selectedLable)
                                //   if(selectedLable===constant.FIELD_VALUE_SQL ||selectedLable===constant.FIELD_VALUE_LIST){
                                //       data.dataTypeText=selectedLable
                                //       data.dataTypeLogicVisible=true
                                //   }
                                    // console.log('filter',selectedLable)
                                    onDetailFieldChange('dataType', values[0], index,selectedLable)
                                    onDetailFieldChange('dataTypeText', selectedLable, index,selectedLable)
                                    onDetailFieldChange('expression', '', index,selectedLable)
                                    if(selectedLable===constant.FIELD_VALUE_SQL ||selectedLable===constant.FIELD_VALUE_LIST){
                                    onDetailFieldChange('dataTypeLogicVisible', true, index,selectedLable)
                                    }
                                    else{
                                        onDetailFieldChange('dataTypeLogicVisible', false, index,selectedLable)
                                        
                                    }
                                }
                            }
                            dropDownSize={12}
                            placeholder={constant.PLEASE_SELECT}
                            tabIndex={elementCounter++}
                            isRequired
                            isInvalid={data.isFieldTypeEmpty}
                            invalidWarning={data.fieldTypeError}
                            //isEmpty={data.isFieldTypeEmpty}
                           // errorText={data.fieldTypeError}
                        />

                    </Column>
                    <Column lg={11} md={11} sm={11} className={`cstDataType ${!data.dataTypeLogicVisible?'hidden' : ''} ${data.isDataTypeLogicEmpty?'feedback-row-filter' : ''}`} >
                    <TextField id={txtDataTypeId}
                     className="cstCollection data-type-value"
                     value={data.expression}
                     placeholder={dataTypeLable === constant.FIELD_VALUE_SQL?constant.FIELD_VALUE_SQL_INSERT_TEXT:constant.FIELD_VALUE_LIST_INSERT_TEXT}
                     onChange={(evnt) => (handleChange(index, evnt, 'expression'))}
                     isInvalid={data.isDataTypeLogicEmpty}
                            isEmpty={data.isDataTypeLogicEmpty}
                            errorText={data.dataTypeLogicError}
                     />
                    </Column>
                   
                    <Column lg={1}><Button className="btn btn-outline-tertiary cstDeleteIconBtn cstCollection"
                        onClick={() => (deleteTableRows(index))}
                        tabIndex={elementCounter++}>
                        <Icon icon="trash" size="xs" />
                    </Button>
                    </Column>
                </Row>
            )
        })
    )
}
//#endregion
export default QueryFilterRow