export const LookupDetailConstants = {
    CUSTOMER_TASK_STATUS: {
        INCOMPLETE: 'e1198239-1ac6-4f13-b4f0-9a1afb89a10c',
        COMPLETED : '939350e4-74b4-475c-a97f-a8b02dd021b8',
    },
    CUSTOMER_TASK_PRIORITY : {
        NORMAL : '59b3a4f4-27d4-4bba-9b8b-1231c446b103',
        CRITICAL : 'b96ffdd4-30a5-4a43-a1dc-48caf75523d1',
        LOW : 'd8b20966-37d8-4fa9-955e-5a6104dfb1c4'
    },
    POLICY_STATUS : {
        ACTIVE : 'fc889e0f-0a0b-47e2-a686-67ef95116794',
        CANCELLED : '4199f133-a4f2-418a-8834-f77ca2b78a0a',
        EXPIRED : '9855aecf-3694-481a-8b51-ae5e13d19196',
        INCLUDE : '60dbdb01-1915-4de9-88ee-33072d06c743',
        NON_RENEWED : '3b7972c4-9b41-4644-9427-14cda6147210',
        NOT_TAKEN : 'e13f49db-9213-4793-8950-809cd3393f77',
        QUOTE : 'fc896037-baef-419c-a5e1-dfddab54395a',
        RENEWED : '0e1f4954-65d6-4079-aa33-6cbb4120277c',
        REWRITTEN : '4b6ae0be-12d8-4a9f-88fc-c481a3feaf92',
    },
    LOB_CODE : {
        HOMEOWNERS : 'be2b23b2-1bae-4c7e-8933-87627dfc89a1',
        PRIVATE_PASSENGER_AUTO : "b8ebc545-75ef-45ac-8ab8-625029e26b68",
        UMBRELLA_P : "001b57d6-f745-4f90-af19-a586721d9679"
    }
}