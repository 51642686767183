import Customer from "../model/Customer";
import { ICustomerSlider } from "./CustomerSliderContext";


class CustomerSliderContainerService {

    public getUnSavedChanges = (customerSlider : ICustomerSlider, existingCustomer : Customer) : any => {
        let changes = {};

        if (this.isAnyUnsavedChangeInActivityTab(customerSlider)) {
            changes['activityPage'] = true;
        }

        if (this.isAnyUnsavedChangeInContacts(customerSlider, existingCustomer)) {
            changes['contactPage'] = true;
        }

        if (this.isAnyUnsavedChagneInNotesTab(customerSlider)) {
            changes['notePage'] = true;
        }

        if (this.isAnyUnsavedChagneInTaskPage(customerSlider)) {
            changes['taskPage'] = true;
        }

        return changes;
    }

    public isAnyUnsavedChangeInActivityTab = (customerSlider : ICustomerSlider) : boolean => {
        
        let activity = customerSlider.activityPage.activity;

        if (activity.activityTypeId
            || activity.description?.trim()
            || activity.policyId
            || activity.claimId?.trim()) {
                return true;
        }

        return false;
    }

    public isAnyUnsavedChagneInNotesTab = (customerSlider : ICustomerSlider) : boolean => {
        let customerNote = customerSlider.notePage.customerNote;
        if (customerNote.note) {
                return true;
        }

        return false;
    }

    public isAnyUnsavedChangeInContacts = (customerSlider : ICustomerSlider, existingCustomer : Customer) : boolean => {

        let customerObjOnContactScreen = customerSlider.contactPage.customer;

        if (Object.keys(customerObjOnContactScreen).length == 0) {
            return false;
        }

        if (customerObjOnContactScreen.smsOptOut != existingCustomer.smsOptOut
            || customerObjOnContactScreen.emailOptOut != existingCustomer.emailOptOut
            || customerObjOnContactScreen.mailOptOut != existingCustomer.mailOptOut
            || customerObjOnContactScreen.eddOptIn != existingCustomer.eddOptIn
            || customerObjOnContactScreen.callOptOut != existingCustomer.callOptOut
            || customerObjOnContactScreen.mobile != existingCustomer.mobile
            || customerObjOnContactScreen.workPhone != existingCustomer.workPhone
            || customerObjOnContactScreen.homePhone != existingCustomer.homePhone
            || customerObjOnContactScreen.eMail?.trim() != existingCustomer.eMail?.trim()
            || customerObjOnContactScreen.eMail2?.trim() != existingCustomer.eMail2?.trim()) {
                return true;
        }

        return false;
    }

    public isAnyUnsavedChagneInTaskPage = (customerSlider : ICustomerSlider) : boolean => {

        let taskDto = customerSlider.taskPage.customerTask;

        if (taskDto.description?.trim().length > 0
            || taskDto.priorityId
            || taskDto.statusId
            || taskDto.toUserId
            || taskDto.ccUserId
            || taskDto.polId
            || taskDto.claimId) {
                return true;
        }

        return false;
    }
}

export default CustomerSliderContainerService;