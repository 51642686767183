import RolePrivilegeSetupAPI from "../../Services/RolePrivilegeSetupAPI";
import RoleDetailsModel from '../../model/RoleDetailsModel'

class RolesSideBarService
{
     rolePrivilegeSetupAPI :RolePrivilegeSetupAPI= new RolePrivilegeSetupAPI ();
    getRoles = async () : Promise<RoleDetailsModel[]> => {
       return await this.rolePrivilegeSetupAPI.getRoles()
    }
}
export default RolesSideBarService;