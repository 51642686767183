import { useContext, useState } from 'react';
import MenuBar, { IMenuIconItem, IMenuItem } from '../../shared/components/menubar/MenuBar';
import './CustomerSliderContainer.scss';
import { CustomerScreenTabs, SliderActivityConstant } from '../../shared/util/constant';
import useSlider from '../../shared/components/Slider/useSlider';
import SliderActivity from './slider-activity/SliderActivity';
import SliderNote from './slider-note/SliderNote';
import SliderContact from './slider-contact/SliderContact';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store';
import { cleareTabsInputs, updateSliderCurrentTab } from './CustomerSliderContext';
import { useConfirmationModalContext } from '../../shared/components/ModalConfirmationContext';
import CustomerSliderContainerService from './CustomerSliderContainerService';
import SlidterTask from './slider-task/SliderTask';
import { AbilityContext } from '../../shared/abilities/AbilityContext';
import * as constant from '../../shared/util/constant';

export interface ICustomerSliderContainer {
    customerId? : string;
}

const CustomerSliderContainer : React.FunctionComponent<any> = (props) => {
    const customerSliderContainerService = new CustomerSliderContainerService();
    const sliderContext = useSlider();
    const modalContext = useConfirmationModalContext();
    
    
    const dispatch = useDispatch();
    let customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
    const originalCustomerObj = useSelector((state: RootState) => state?.customerSlice).customer;

    const [currentTab, setCurrentTab] = useState(customerSliderState.currentTab);

    const ability = useContext(AbilityContext);
    const canUserViewCustomerContact = ability.can('view',"Customer",constant.ScreenName.CUSTOMER);
    const canUserReadCustomerContact = ability.can('read',"Customer",constant.ScreenName.CUSTOMER); 
    const canUserCreateCustomerTask = ability.can('create',"Customer Task",constant.ScreenName.CUSTOMER_TASK);
    const isUserPermitToViewCustomerNotes = ability.can('view', 'Customer Notes' ,constant.CustomerScreenName.CUSTOMER_NOTES)
    const isCustomerNoteMenuEnableForUser = ability.can('read', 'Customer Notes',constant.CustomerScreenName.CUSTOMER_NOTES)
    const isNoteEnabled = (isUserPermitToViewCustomerNotes ===true && isCustomerNoteMenuEnableForUser===true)?true:false
console.log(isUserPermitToViewCustomerNotes,isCustomerNoteMenuEnableForUser)

    const menuItems : IMenuItem[] = [];

    let primaryActionIconMenuItems : IMenuIconItem[] = [
        {
            menuKey : CustomerScreenTabs.activity.key,
            iconName : 'activity',
            toolTip : "Activity",
            iconClass : "sld-menu-icons"
        },
        {
            menuKey : CustomerScreenTabs.contacts.key,
            iconName : 'contact',
            toolTip : "Contact",
            iconClass : "sld-menu-icons",
            isVisible: canUserViewCustomerContact,
            isDisabled: !canUserReadCustomerContact
        },
        {
            menuKey : CustomerScreenTabs.notes.key,
            iconName : 'note',
            toolTip : "Note",
            iconClass : "sld-menu-icons",
            isDisabled : !isNoteEnabled
        },
        {
            menuKey : CustomerScreenTabs.tasks.key,
            iconName : 'tasks',
            toolTip : "Task",
            iconClass : "sld-menu-icons",
            isDisabled: !canUserCreateCustomerTask
        }
    ];
    
    let secondaryActionIconMenuItems : IMenuIconItem[] = [
        {
            menuKey : 'close',
            iconName : 'close',
            toolTip : "Close",
            iconClass : "sld-menu-icons"
        }
    ];

    if (sliderContext.isSliderPinned) {
        secondaryActionIconMenuItems = [
            {
                menuKey : 'pin',
                iconName : 'pin',
                iconClass : 'sld-menu-icons pin-icon-pinned',
                toolTip : "Unpin"
            }, ...secondaryActionIconMenuItems];
    }
    else {
       secondaryActionIconMenuItems = [
        {
            menuKey : 'pin',
            iconName : 'pin',
            toolTip : "Pin",
            iconClass : "sld-menu-icons"
        }, ...secondaryActionIconMenuItems];
    }
    
    const closeSlider = async () => {
        let changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
        
        if (Object.keys(changes).length > 0) {
            const result = await modalContext.showConfirmation(SliderActivityConstant.ARE_YOU_SURE_TO_EXIT);
            if(!result){
                return;
            }
        }
        
        dispatch(cleareTabsInputs());
        sliderContext.hideSlider();
    };

    const onMenuClick = (menuKey) => {
        setCurrentTab(menuKey);
        dispatch(updateSliderCurrentTab(menuKey));
    };

    
    const onIconMenuItemClick = (clickedMenuItemKey : IMenuIconItem) => {
        switch(clickedMenuItemKey.menuKey) {
            case "close": 
                    closeSlider();
                break;
            case "pin": 
                if (sliderContext.isSliderPinned) {
                    sliderContext.unpinSlider();
                }
                else {
                    sliderContext.pinSlider();
                }
                break;
            case CustomerScreenTabs.activity.key:
            case CustomerScreenTabs.notes.key:
            case CustomerScreenTabs.contacts.key:
            case CustomerScreenTabs.tasks.key:
                activateTab(clickedMenuItemKey.menuKey);
                break;
            default:
                break;
        }
    };

    const activateTab = async (tabToShow : string ) => {

        let changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
        if (Object.keys(changes).length > 0) {
            const result = await modalContext.showConfirmation(SliderActivityConstant.ARE_YOU_SURE_TO_EXIT);
            if(!result) {
                setCurrentTab(currentTab);
                return;
            }
        }

        dispatch(cleareTabsInputs());
        setCurrentTab(tabToShow);
        dispatch(updateSliderCurrentTab(tabToShow));
    }

    const menuBarContent = <>
            <MenuBar 
                menuItems={menuItems} 
                onMenuClick={onMenuClick} 
                primaryActionIconMenuItems={primaryActionIconMenuItems}
                secondaryActionIconMenuItems={secondaryActionIconMenuItems} 
                onIconMenuClick={onIconMenuItemClick}
                selectedMenuKey={currentTab}
                navbarClass='slider-menu-header-nav-bar'>
            </MenuBar>
    </>;

    return <div className='customer-slider-main-div'>
        {menuBarContent}
        {currentTab == CustomerScreenTabs.activity.key && <SliderActivity customerId={props.customerId}></SliderActivity>}
        {currentTab == CustomerScreenTabs.notes.key && <SliderNote customerId={props.customerId}></SliderNote>}
        {currentTab == CustomerScreenTabs.contacts.key && <SliderContact customerId={props.customerId}></SliderContact>}
        {currentTab == CustomerScreenTabs.tasks.key && <SlidterTask customerId={props.customerId}></SlidterTask>}
    </div>;
};

export default CustomerSliderContainer