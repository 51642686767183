import {Row,Column} from '../../components'

import {campaignDashboardUtils} from '../utils/CampaignDashboardUtils'

import {useEffect, useState} from 'react'

import { FallbackComponent } from '../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'

import SentonDateGraph from '../filter/SentonDateGraph'


const CampaignDashboard = () => {
  const [sendonDateData,setSenonDateDate]=useState([])
  const [rerender, setRerender] = useState(false);
  
      //Fetch data from API for campaign sent on date
      //This data contains count of sent campaign from current date-1 till last month
      //and Approved data count from today, till next month
      async function fetchSendonDateData() {
         let chartData= await campaignDashboardUtils.GetCampaignDataonSendonDate();
         setSenonDateDate(chartData)
        setRerender(true)
      }

      useEffect(() => {
        fetchSendonDateData();
            },[rerender]);

    return(
      <div className='outerPanel'>
      <Row>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Column lg={12} md={12} sm={12}>
          <Column lg={6} md={6} className={'section-border'}>
            <SentonDateGraph sendonDateData={sendonDateData}/>
             </Column>
          <Column lg={6} md={6}>&nbsp;</Column>
        </Column>
        </ErrorBoundary>
      </Row>
      </div>
   )
    }
export default CampaignDashboard