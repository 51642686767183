import {AxiosResponse} from 'axios'
import { client } from "../../../shared/services/client"; 
import Query from '../../model/QueryList';
import { Promise} from 'cypress/types/bluebird';
import CampaignQueryInfo from '../../model/CampaignQueryInfo'
import TestQuery from '../../model/TestQuery';

class CampaignQueryAPI {
   
   getAllQueryList = async (): Promise<Query[]> => {
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<Query[]> =  await client.get<Query[]>("/api/CampaignQuery/GetAllCampaignQueries");
    return res.data;
  
  };

   async saveQueryToDatabase(queryDetails:CampaignQueryInfo){
    client.setBaseURLForService("campaign")
    const response: AxiosResponse<any> = (await client.post("/api/CampaignQuery/AddCampaignQuery",queryDetails));
    //console.log(response);
    return response
    
  }
   async updateQueryToDatabase(queryDetails:CampaignQueryInfo){
    client.setBaseURLForService("campaign")
    const response: AxiosResponse<any> = (await client.post("/api/CampaignQuery/UpdateCampaignQuery",queryDetails));
    //console.log(response);
    return response
    
  }

    getQueryByID = async (id:string): Promise<CampaignQueryInfo> => {
 // export async function getQueryByID(id:string){
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<CampaignQueryInfo> =  await client.get<CampaignQueryInfo>("/api/CampaignQuery/GetCampaignQueryById?id="+id);
    //console.log('API response:',res.data)
    return res;
    
  }

   async deleteQueryByID(id:string){
    console.log('API '+id);
    const param={"id":id}
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<any> =  await client.delete("/api/CampaignQuery/"+id);
    //console.log(res.status)
    return res;
    
  }
    fetchDynamicDropdownOptions = async (query: string): Promise<any> => {
    //console.log('API:',query)
    try {
      client.setBaseURLForService("campaign")
      //console.log('client',client)
      const res: AxiosResponse<any> = await client.get<any>("/api/CampaignQuery/GetSQLQueryValues?query=" + query);
      //console.log('API data:',res.data)
      return await res.data;
    }
    catch (exception) {
      console.log("error: ", exception)
    }
  
  };

    GetTestQueryResult = async (filterData:TestQuery): Promise<any> => {
    //console.log('API:',query)
    try {
      client.setBaseURLForService("campaign")
      const res: AxiosResponse<any> = await client.post<any>("/api/Campaign/GetCampaignResultbyData", filterData);
      return await res.data;
    }
    catch (exception) {
      console.log("error: ", exception)
    }
  
  };
}
export const campaignQueryAPI = new CampaignQueryAPI();