import { PureAbility, AbilityBuilder } from '@casl/ability';

export type Actions =  'view' |'read' | 'create' | 'update' | 'delete';
export type Subjects = 'User' | 'Post' | 'Comment' | 'Campaign' | 'Notes' | 'History' | 'CS2 Main Menu' | 'Campaign Query' | 'Campaign Template' | 'Campaign Calendar' | 'Campaign Approval' | 'Customer Notes'
                     | 'Customer' | 'Customer Task' | 'Customer History' ;

export type AppAbility = PureAbility<[Actions, Subjects], string>;
// export const ability = new PureAbility<Actions, Subjects>([], {
//   subjectName: item => typeof item === 'string' ? item : item.__type
// });
function fieldMatcher(fields) {
   return (field) => fields.includes(field);
}

export const ability = new PureAbility<AppAbility>([], {
    fieldMatcher: fieldMatcher
  });