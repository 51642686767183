import { Button, Column, Container, Icon, Row } from '../../components'
import { useCallback, useEffect, useState } from 'react'
import CampaignSettings from './CampaignSettings'
import CampaignResults from './CampaignResults'
import { useHistory, useLocation } from 'react-router-dom'
import CampaignDetailUI from '../model/CampaignDetailUI'
import NotesPanel from '../../shared/components/Notes&History/filters/NotesPanel';
import { useContext } from 'react'
import AppContext from '../../shared/components/AppContext'
import * as constant from '../../shared/util/constant'
import { FallbackComponent } from '../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'

const CampaignDetails = (props) => {

  const [showCampaignResultsGrid, setShowCampaignResultsGrid] = useState(false);
  const [campaignSettingsfilter, setCampaignSettingsfilter] = useState<CampaignDetailUI>();
  const [requestButtonClickCounter, setRequestButtonClickCounter] = useState<number>(0);
  const [isDetailsChangedAfterResults, setIsDetailsChangedAfterResults] = useState(false)
  const [sendOnDateEmpty,setSendOnDateEmpty] = useState(false);
  const [isRequestValidated,setIsRequestValidated] = useState(false)
  const [updatedCampaignID,setUpdatedCampaignID] = useState(constant.DEFAULT_GUID)
  const [isCompleteSave,setCompleteSave] = useState(false)
  const [isSaveAsTriggered,setSaveAsTriggered]=useState(false)
  const ab_subject = props.subject;

  const location = useLocation();
  const state = location.state as { entityId: string };
  //const campaignId = state?.entityId;
  const urlParam = new URLSearchParams(location.search)
  const [campaignId,setCampaignId]=useState(state?.entityId ?? urlParam.get('id'))

//Save note with detail pane constant and context
const campaignDetailsContext = useContext(AppContext);
campaignDetailsContext.isDetailWithNoteSave=isCompleteSave;
campaignDetailsContext.isSaveAsCliked=isSaveAsTriggered;
// Save note with detail pane constant and context end 
//console.log('campaignDetailsContext',campaignDetailsContext)
  const history = useHistory();

  const onShowingResults = useCallback((campaignDetailsInput: CampaignDetailUI) => {
    setCampaignSettingsfilter(campaignDetailsInput)
  }, [])

  const showResults = useCallback((showResultsGrid: boolean) => {
    setShowCampaignResultsGrid(showResultsGrid);
  }, [])

  const handleRequestButtonClick = useCallback(() => {
    setRequestButtonClickCounter(prevCount => prevCount + 1);
  },[]);

  const handleSendOnDateEmpty = useCallback((sendOnDateEmpty:boolean) => {
    setSendOnDateEmpty(sendOnDateEmpty)
  },[])

  const onDetailsChangedAfterResults = useCallback((isDetailsChangedAfterResults:boolean) => {
setIsDetailsChangedAfterResults(isDetailsChangedAfterResults)
}, [])

const onRequestValidated = useCallback((isRequestValidated:boolean,campaignDetails:CampaignDetailUI) => {
  setIsRequestValidated(isRequestValidated)
  setCampaignSettingsfilter(campaignDetails)
  }, [])

  const handleFieldChange = (name, value) => {
    setCampaignSettingsfilter(prevInput => ({
      ...prevInput,
      [name]: value,
    }));
  };

  useEffect(()=>{
    setCampaignId(updatedCampaignID===""?constant.DEFAULT_GUID:updatedCampaignID)
  },[updatedCampaignID])

  return (
    <div>

      <Row className="bg-light">
        <Column lg={12} md={12}>

          <Row>
        <Column lg={9}>
          <Row>
            <Column lg={12}>
            <ErrorBoundary FallbackComponent={FallbackComponent}>
            <CampaignSettings campaignId={campaignId} history={history} requestButtonClickCounter={requestButtonClickCounter} onFieldChange={handleFieldChange}
            onShowingResults={onShowingResults} showResults={showResults} isDetailsChangedAfterResults={onDetailsChangedAfterResults} 
            isRequestValidated={onRequestValidated} makeSendOnDateEmpty ={sendOnDateEmpty} updatedCampaignId={setUpdatedCampaignID} setCompleteSave={setCompleteSave} setSaveAsTriggered={setSaveAsTriggered}/>
            </ErrorBoundary>
            </Column>
          </Row>
          <br></br>
          <Row>
            <Column lg={12}>
            <ErrorBoundary FallbackComponent={FallbackComponent}>
              {showCampaignResultsGrid &&
                <CampaignResults campaignFilters={campaignSettingsfilter}
                 onRequestButtonClick={handleRequestButtonClick}
                 isDetailsChangedAfterResults={isDetailsChangedAfterResults} 
                  isRequestValidated={isRequestValidated} 
                 makeSendOnDateEmpty={handleSendOnDateEmpty} 
                 requestButtonClickCounter={requestButtonClickCounter}
                 />
              }
             </ErrorBoundary>
            </Column>
          </Row>
          </Column>
         
          <Column lg={3} style={{paddingTop:"35px"}}>
                <NotesPanel collapsible selectId={updatedCampaignID} subject={ab_subject}>
                </NotesPanel>
            </Column>
            </Row>
        </Column>
      </Row>
    </div>
  )
}

export default CampaignDetails
