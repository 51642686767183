import { useContext, useEffect, useState } from "react";
import Grid from "../../components/components/Grid/Grid";
import  './CustomerTasks.scss'
import { Button, Column, Icon, Row } from "../../components";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { SelectOption } from "../../shared/components/input";
import Logger from "../../shared/services/Logger";
import { toast } from "react-toastify";
import { CustomerTaskDtoReadExtended } from "../model/CustomerTaskDtoRead";
import CustomerTaskViewService  from "./CustomerTasksViewService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../shared/store";
import { updateLookupData } from "../customer-slice/Customer-lookup-slice";
import { CustomerTaskConstants, SlicerLookUpConstant } from "../../shared/util/constant";
import { AppEvenType, clearAppEvent } from "../../shared/store/component-notification/ComponentNotification";
import { TaskScreenLables } from "../../shared/util/label";
import { CustomerFilterViewMoel } from "../model/CustomerFilterViewModel";
import CutomerMainPortalHeaderService from "../customer-main-portal/CutomerMainPortalHeaderService";
import SliderTaskService from "../customer-slider-content/slider-task/SliderTaskService";
import { CommonUtility } from "../../Services/CommonUtility";
import CustomerActivityService from "../customer-activity/CustomerActivityService";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import AppContext from "../../shared/components/AppContext";
import EntityHistoryViewer from "../../shared/components/entity-history/EntityHistoryViewer";
import EntityHistoryModel from "../model/EntityHistoryModel";
import { AbilityContext } from "../../shared/abilities/AbilityContext";
import * as constant from '../../shared/util/constant';

export interface ICustomerTask {
    customerId? : string,
    onRouteChange? : (tabKey : string) => void
};

/// Move it to TaskScreenLables, once changes are merged
export const TaskScreenLables_Additional = {
    addTasks : "ADD TASKS"
  }

interface ITaskGridComponentState {
    customerTasks : CustomerTaskDtoReadExtended[],
    gridColumns : any[],
    taskStatusList : SelectOption[],
    taskTypeList : SelectOption[],
    taskPriorityList : SelectOption[],
    policiesForSelect : SelectOption[],
    taskStatusId : string,
    taskTypeId : string,
    taskPriorityId : string,
    isRowDataAddedOrEdited : boolean,
    customerSummary : CustomerFilterViewMoel,
    showTaskHistory : boolean,
    taskHistoryData : EntityHistoryModel[],
    historyDialogTitle : string
}

const taskGridComponentInitData : ITaskGridComponentState = {
    customerTasks : [],
    gridColumns : [],
    taskStatusList : [],
    taskTypeList : [],
    taskPriorityList : [],
    policiesForSelect: [],
    taskStatusId : '',
    taskTypeId : '',
    taskPriorityId : '',
    isRowDataAddedOrEdited : false,
    customerSummary : {},
    showTaskHistory : false,
    taskHistoryData : [],
    historyDialogTitle : ''
}

const CustomerTasksView : React.FunctionComponent<ICustomerTask> = (props) => {
    const dispatch = useDispatch();
    const appContext = useContext(AppContext);
    const customerTaskService = new CustomerTaskViewService();
    const sliderTaskService = new SliderTaskService();
    const cutomerMainPortalHeaderService = new CutomerMainPortalHeaderService();
    const customerActivityService = new CustomerActivityService();

    const taskTypes = useSelector((state: RootState) => state?.LookupState.activityTypeList);
    const taskPriorities = useSelector((state: RootState) => state?.LookupState.taskPriorityList);
    const taskStatuses = useSelector((state: RootState) => state?.LookupState.taskStatusList);
    const empList = useSelector((state: RootState) => state?.LookupState.employeeList);
    const companyList = useSelector((state: RootState) => state?.LookupState.companyList);
    const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([]);

    const eventState = useSelector((state: RootState) => state?.eventState);
    const userDetails = useSelector((state : any) => state.userContextSlice);

    const ab_subject = "Customer Task";
    const ability = useContext(AbilityContext);
    const canUserCreateCustomerTask = ability.can('create',ab_subject,constant.ScreenName.CUSTOMER_TASK);
    const canUserEditCustomerTask = ability.can('update',"Customer Task",constant.ScreenName.CUSTOMER_TASK);
    
    const modalContext = useConfirmationModalContext();

    const [taskGridComponentState, setTaskGridComponentState] = useState<ITaskGridComponentState>(taskGridComponentInitData);

    const [historyGridColumns, setHistoryGridColumns] = useState<any[]>([]);

    const [navigateToFirstPage, setNavigateToFirstPage] = useState(false);

    const { customerTasks
        , gridColumns
        , taskStatusList
        , taskTypeList
        , taskPriorityList
        , policiesForSelect
        , taskStatusId
        , taskTypeId
        , taskPriorityId} = taskGridComponentState;

    useEffect(() => {
        initializeComponent();
    }, []);

    useEffect(() => {
        if (eventState.eventType == AppEvenType.NewTaskCreated) {
            fetchTasks();
            dispatch(clearAppEvent());
        }
    }, [eventState.eventType]);

    useEffect(() => {
        appContext.formSavedStatus = !taskGridComponentState.isRowDataAddedOrEdited
    }, [taskGridComponentState.isRowDataAddedOrEdited]);
    

    const initializeComponent = async () => {
        try {

            setHistoryGridColumns(customerTaskService.getHistoryGridColumns());

            let tskStatusList = [];
            let tskPriorityList = [];
            let tskTaskTypeList = [];
            let emplSelectList = [];
            let compSelectList = [];
            let custOptions = [];

            let taskGridColumns = customerTaskService.getTaskGridColumns(onTaskGridOriginalRowDataChange, onEditingCellDataChange, props.onRouteChange, taskGridCellClickHandler, tskStatusList, tskPriorityList, tskTaskTypeList, emplSelectList, policiesForSelect, compSelectList,custOptions,canUserEditCustomerTask);
            setTaskGridComponentState(compState => { return {...compState, gridColumns : taskGridColumns};});

            if (!taskTypes || taskTypes.length == 0) {
                tskTaskTypeList = await customerTaskService.getTaskTypeList();
                dispatch(updateLookupData({data : tskTaskTypeList, type : SlicerLookUpConstant.ACTIVITY_TYPE}));
            }
            else {
                tskTaskTypeList = [...taskTypes];
            }

            if (!taskPriorities || taskPriorities.length == 0) {
                tskPriorityList = await customerTaskService.getTaskPriorityList();
                dispatch(updateLookupData({data : tskPriorityList, type : SlicerLookUpConstant.TASK_PRIORITY}));
            }
            else {
                tskPriorityList = [...taskPriorities];
            }

            if (!taskStatuses || taskStatuses.length == 0) {
                tskStatusList = await customerTaskService.getTaskStatusList();
                dispatch(updateLookupData({data : tskStatusList, type : SlicerLookUpConstant.TASK_STATUS}));
            }
            else {
                tskStatusList = [...taskStatuses];
            }

            if (!empList || empList.length == 0) {
                emplSelectList = await customerTaskService.getEmployeeList();
                dispatch(updateLookupData({data : emplSelectList, type : SlicerLookUpConstant.EMPLOYEE_LIST}));
            }
            else {
                emplSelectList = [...empList];
            }
            
            if (!companyList || companyList.length == 0) {
                compSelectList = await customerTaskService.getCompanyList();
                dispatch(updateLookupData({data : compSelectList, type : SlicerLookUpConstant.COMPANY_LIST}));
            }
            else {
                compSelectList = [...companyList];
            }

            setTaskGridComponentState(compState => { return {...compState, taskStatusList : tskStatusList};});
            setTaskGridComponentState(compState => { return {...compState, taskTypeList : tskTaskTypeList};});
            setTaskGridComponentState(compState => { return {...compState, taskPriorityList : tskPriorityList};});

            let cst = await cutomerMainPortalHeaderService.getCustomerSummary(props.customerId);
            setTaskGridComponentState(compState => { return {...compState, customerSummary : cst ? cst : {}};});

            let custPolicyData = await customerActivityService.getPoliciesForCustomer(props.customerId);
            setTaskGridComponentState(compState => { return {...compState, policiesForSelect : custPolicyData};});

            
            if (cst) {
                custOptions =  [
                    {
                        label : ((cst.firstName ? cst.firstName : '') + ' ' + (cst.lastName ? cst.lastName : '')).trim(),
                        value : cst.id
                    }
                ];
            }

            setCustomerOptions(custOptions);

            taskGridColumns = customerTaskService.getTaskGridColumns(onTaskGridOriginalRowDataChange, onEditingCellDataChange, props.onRouteChange, taskGridCellClickHandler, tskStatusList, tskPriorityList, tskTaskTypeList, emplSelectList, custPolicyData, compSelectList, custOptions, canUserEditCustomerTask);
            setTaskGridComponentState(compState => { return {...compState, gridColumns : taskGridColumns};});
        }
        catch (ex) {
            Logger.logError(CustomerTaskConstants.ERROR_WHILE_LOADING_TASK_GRID_PAGE, ex);
            toast.error(CustomerTaskConstants.ERROR_WHILE_LOADING_TASK_GRID_PAGE, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    useEffect(() => {
        fetchTasks();
    }, [taskStatusId, taskTypeId, taskPriorityId]);

    const fetchTasks = async () => {
        try {
            let taskData = await customerTaskService.getTasks(props.customerId, taskTypeId, taskStatusId, taskPriorityId);
            setTaskGridComponentState(compState => { return {...compState, customerTasks : taskData, isRowDataAddedOrEdited : false};});
        }
        catch(ex) {
            Logger.logError(CustomerTaskConstants.ERROR_WHILE_LOADING_TASK_LIST, ex);
            toast.error(CustomerTaskConstants.ERROR_WHILE_LOADING_TASK_LIST, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const hideTaskHistoryModal = () => {
        setTaskGridComponentState(compState => { return {...compState, showTaskHistory : false, taskHistoryData : [], historyDialogTitle : ''};});
    }

    const onComponentStateChange = (propName: string, value: string | Date|boolean) => {
        setTaskGridComponentState(compState => { return {...compState, [propName] : value};});
    }

    const onTaskGridOriginalRowDataChange = (originalRow : any, column : any, value : any, additionalInfo : any) => {
        if (additionalInfo == TaskScreenLables.rowexpander) {
            originalRow.isRowExpanded = value;
        }
        else {
            originalRow[column.fieldName] = value;
        }

        setTaskGridComponentState(compState => { return {...compState, customerTasks : [...compState.customerTasks]};});
    }

    const onEditingCellDataChange = (editingRow : any, column : any, value : any, additionalInfo : any) => {
        editingRow[column.fieldName] = value;

        setTaskGridComponentState(compState => { return {...compState, customerTasks : [...compState.customerTasks]};});
    }

    const onAddTaskButtonClick = () => {
        setTaskGridComponentState(compState => { 
            compState.customerTasks.forEach(tsk => {
                tsk.isGridInEditMode = true;
            });
            let newTaskRow = customerTaskService.getNewTaskRow(compState.customerSummary, userDetails);
            let newTaskList = [ newTaskRow,...compState.customerTasks];
            return {...compState, customerTasks : newTaskList, isRowDataAddedOrEdited : true};
        });

        setNavigateToFirstPage(true);
    }

    const taskGridCellClickHandler = (originalRow : CustomerTaskDtoReadExtended, column : any, additionalData : any) => {
        switch(additionalData) {
            case 'save_row':
                saveRowClickHandler(originalRow)
                break;
            case 'cancel_row_editing':
                cancelRowEditingClickHandler(originalRow, column);
                break;
            case 'edit_row':
                editRowClickHandler(originalRow, column);
                break;
            case 'show_task_history':
                showHistory(originalRow);
                break;
            default:
                break;
        }
    }

    const showHistory = async (originalRow) => {
        try {

            let originalRowData : CustomerTaskDtoReadExtended = originalRow;

            let data = await customerTaskService.getTaskHistory(originalRowData.id);

            if (!data || data.length == 0) {
                toast.warn(CustomerTaskConstants.NO_HISTORY_AVAILABLE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            let dialogTitle = `${CustomerTaskConstants.TASK_DIALOG_TITLE}${originalRowData.policyNumber ? '  |  #' + originalRowData.policyNumber : ''}  |  ${originalRowData.custDescr}`;

            // Show task history modal.
            setTaskGridComponentState(compState => { return {...compState, showTaskHistory : true, taskHistoryData : data, historyDialogTitle : dialogTitle};});
        }
        catch(ex) {
            Logger.logError(CustomerTaskConstants.ERROR_WHILE_FETCHGIN_TASK_HISTORY, ex);
            toast.error(CustomerTaskConstants.ERROR_WHILE_FETCHGIN_TASK_HISTORY, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const saveRowClickHandler = async (originalRow : CustomerTaskDtoReadExtended) => {
        try {
            let editingRow = originalRow.editingRow;
            if (!editingRow) {
                return;
            }

            let validationError = sliderTaskService.validateTask(editingRow);
            editingRow.errors = validationError;

            if (Object.keys(validationError).length > 0) {
                toast.warn(CustomerTaskConstants.FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            let taskPolicy = policiesForSelect?.find(item => item.value == editingRow.polId);
            let taskRequestDto = sliderTaskService.getTaskRequestDto(editingRow, taskPolicy?.originalData?.polEffDate);

            if (!taskRequestDto.id) {
                await sliderTaskService.createTask(taskRequestDto);
            }
            else {
                await sliderTaskService.updateTask(taskRequestDto);
            }

            toast.success(CustomerTaskConstants.TASK_SAVED_SUCCESSFULLY);

            fetchTasks();
        }
        catch(ex) {
            toast.error(CustomerTaskConstants.ERROR_WHILE_INITIALIZING_TASK_SLIDER_COMPONENT);
            Logger.logError(CustomerTaskConstants.ERROR_WHILE_INITIALIZING_TASK_SLIDER_COMPONENT, ex);
        }
    }

    const cancelRowEditingClickHandler = async (originalRow : CustomerTaskDtoReadExtended, column : any) => {
        const result = await modalContext.showConfirmation(CustomerTaskConstants.ARE_YOU_SURE_TO_EXIT);
        if(!result){
            return;
        }

        setTaskGridComponentState(compState => { 

            compState.customerTasks.forEach(tsk => {
                tsk.isGridInEditMode = false;
            });

            let custTasks = [];
            if (originalRow.id) {

                // mark current row as readonly again.
                delete originalRow.editingRow;
                originalRow.enableGridRowEditing = false;

                custTasks = [...compState.customerTasks]
            }
            else {
                // Eliminate first rows without id. as user cancelled adding new customer task.
                custTasks = compState.customerTasks.filter((item) => item.id);
            }

            return {...compState, customerTasks : custTasks, isRowDataAddedOrEdited : false };});
    }

    const editRowClickHandler = async (originalRow : CustomerTaskDtoReadExtended, column : any) => {
        let rowToEdit = CommonUtility.cloneObject(originalRow);

        // mark current row as editable.
        originalRow.editingRow = rowToEdit;
        originalRow.enableGridRowEditing = true;        

        setTaskGridComponentState(compState => { 
            compState.customerTasks.forEach(tsk => {
                tsk.isGridInEditMode = true;
            });
            return {...compState, customerTasks : [...compState.customerTasks], isRowDataAddedOrEdited : true};
        });
    }

    return (
        <div className="cst-task-main-div">
            <Row>
                <Column lg={3} md={3}>
                        <DropDownWithLabelInline
                            id='ddTaskStatus'
                            label={TaskScreenLables.status}
                            options={taskStatusList}
                            defaultSelected={taskStatusList?.filter(({ value }) => value === taskStatusId)}
                            onChange={
                                (values) => {
                                    onComponentStateChange('taskStatusId', values[0])
                                }
                            }
                            labelSize={3}
                            dropDownSize={9}
                            labelClass='inlineLable entry-form-inlineLable tsk-grd-first-filter-lable'
                            placeholder={TaskScreenLables.pleaseSelect}
                            multiple={false}
                            disabled={taskGridComponentState.isRowDataAddedOrEdited}
                            tabIndex={1}/>
                </Column>
                <Column lg={3} md={3}>
                        <DropDownWithLabelInline
                            id='ddTaskType'
                            label={TaskScreenLables.taskType}
                            options={taskTypeList}
                            defaultSelected={taskTypeList?.filter(({ value }) => value === taskTypeId)}
                            onChange={
                                (values) => {
                                    onComponentStateChange('taskTypeId', values[0])
                                }
                            }
                            labelSize={3}
                            dropDownSize={9}
                            labelClass='inlineLable entry-form-inlineLable'
                            placeholder={TaskScreenLables.pleaseSelect}
                            multiple={false}
                            disabled={taskGridComponentState.isRowDataAddedOrEdited}
                            tabIndex={2}/>
                </Column>
                <Column lg={3} md={3} >
                        <DropDownWithLabelInline
                            id='ddTaskPriority'
                            label={TaskScreenLables.priority}
                            options={taskPriorityList}
                            defaultSelected={taskPriorityList?.filter(({ value }) => value === taskPriorityId)}
                            onChange={
                                (values) => {
                                    onComponentStateChange('taskPriorityId', values[0])
                                }
                            }
                            labelSize={3}
                            dropDownSize={9}
                            labelClass='inlineLable entry-form-inlineLable'
                            placeholder={TaskScreenLables.pleaseSelect}
                            multiple={false}
                            disabled={taskGridComponentState.isRowDataAddedOrEdited}
                            tabIndex={3}/>
                </Column>
                <Column lg={3} md={3} >
                    <div className="tsk-grd-filter-last-col">
                        <Button
                            className='btn btn-primary bt-main-action'
                            key="filter"
                            onClick={(e) => onAddTaskButtonClick()}
                            tabIndex={21}
                            id="btnAddCustomerTask"
                            disabled={taskGridComponentState.isRowDataAddedOrEdited || !canUserCreateCustomerTask}
                            outlined={true}>
                                <b>
                                    <span>
                                        <Icon icon={"add"} className="fa fa-add icon-color-blue"></Icon>&nbsp;
                                    </span>
                                    {TaskScreenLables_Additional.addTasks}</b>
                        </Button>
                    </div>
                </Column>
            </Row>
            <div className="tsk-grd-div">
                <Grid
                    data={customerTasks}
                    showFilters={false}
                    id={'customerTasksGrid'}
                    retainGridState={true}
                    columns={gridColumns}
                    hiddenColumns={['selection']}
                    sortDefault={[]}
                    grdTableContainerClass="grd-table-container"
                    grdTabelClass="grd-table"
                    zindexHeader={10000}
                    navigateToFirstPage={navigateToFirstPage}
                    onNavigateToFirstPage={() => setNavigateToFirstPage(false)}/>
            </div>
            <EntityHistoryViewer 
                title={taskGridComponentState.historyDialogTitle}
                data={taskGridComponentState.taskHistoryData} 
                columns={historyGridColumns}
                onClose={() => hideTaskHistoryModal()} 
                show={taskGridComponentState.showTaskHistory}
                paginationDisabled={true}
                hiddenColumns={['selection']}
                entityHistoryMainDivClassName="task-history-grd-div"
                entityHistoryGrdTableContainerClass="task-history-table-container"
                modalClassName="task-history-modal">
            </EntityHistoryViewer>
        </div>
    );
}

export default CustomerTasksView;