import moment from "moment";
import { LookupData } from "../Models/LoookupData";
import { SelectOption } from "../shared/components/input";
import Logger from "../shared/services/Logger";
import { CommonDataDto } from "../customer/model/CommonDataDto";

export class CommonUtility {
    public static CS2DefaultDateFormat = 'MM/DD/YYYY';
    private static timeZoneOffSet : string = null;

    public static isEmailValid = (email : string) : boolean => {
         return email ? /^[\wW.-]+@[\wW.-]+\.com+$/i.test(email) : false;
    }

    public static cloneObject = (objToClone : any) => {
        if (!objToClone) {
            return objToClone;
        }
        
        return {...objToClone};
    }

    public static datetimeToDateOnly = (date : Date) => {
        let dateOnly : any = moment(date).format('YYYY-MM-DD');
        return dateOnly;
    }

    public static mapToSelectOptions = (lookupData : LookupData[]) : SelectOption[] => {
        return lookupData?.map(item => {
            return {
                label: item.lookupValue,
                value: item.id,
                isInactive: item.inactive
            };
        });
    }

    public static mapCommonDataDtoToSelectOptions = (lookupData : CommonDataDto[]) : SelectOption[] => {
        return lookupData?.map(item => {
            return {
                label: item.description,
                value: item.id,
                isInactive: false
            };
        });
    }

    public static truncateString = (inputString : string, length : number) : string => {
        if (inputString.length > length) {
          return inputString.substring(0, length) + '...';
        } else {
          return inputString;
        }
    }

    public static getOffSet = () => {

        if (CommonUtility.timeZoneOffSet) {
            return CommonUtility.timeZoneOffSet;
        }

        const currentDate = new Date();
        const offsetInMinutes = currentDate.getTimezoneOffset();

        const sign = offsetInMinutes <= 0 ? "+" : "-";
        const absOffsetInMinutes = Math.abs(offsetInMinutes);
        const hours = Math.floor(absOffsetInMinutes / 60);
        const minutes = absOffsetInMinutes % 60;
        
        CommonUtility.timeZoneOffSet = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        return CommonUtility.timeZoneOffSet;
    }

    public static getLocalDateFromDateOnly = (date : any) : Date => {

        if (typeof date === 'string') {
            let dateOnly = date;
            if (dateOnly.includes("T")) {
                dateOnly = dateOnly.substring(0, dateOnly.indexOf("T"));
            }

            return new Date(`${dateOnly}T00:00:00${CommonUtility.getOffSet()}`);
        }

        return date;
    }

    public static getDisplayDateString = (date : any) : string => {

        if (typeof date === 'string') {
            let dateOnly = date;
            if (dateOnly.includes("T")) {
                dateOnly = dateOnly.substring(0, dateOnly.indexOf("T"));
            }
            else if (dateOnly.includes(" ")) {
                dateOnly = dateOnly.substring(0, dateOnly.indexOf(" "));
            }

            return dateOnly;
        }

        return date;
    }

    public static formateDate = (date : Date, dateFormat? : string) : string => {
        if (!date) {
            return '';
        }

        let mnt = moment(date);
        let format = dateFormat ? dateFormat : CommonUtility.CS2DefaultDateFormat;
        return mnt.format(format);
    }

    public static getClipboardText = async () : Promise<string> => {
        try {
            return await navigator.clipboard.readText();
        }
        catch(ex) {
            Logger.logWarning("Error while reading clipboard data - ", ex);
        }

        return null;
    }

    public static isValidNumber = (str : string) : boolean => {
        if (!str || str.trim().length == 0) {
            return false;
        }
        
        const num = Number(str);
        return !isNaN(num);
    }
}