import { Column, Row } from '../../components'
import { useHistory, useLocation } from 'react-router-dom'
import CampaignApproval from './CampaignApproval'
import { FallbackComponent } from '../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'

const CampaignApprovalDetails = () => {

  const location = useLocation();
  const state = location.state as { entityId: string };
  const urlParam = new URLSearchParams(location.search)
  const campaignId = state?.entityId?? urlParam.get('id');

  const history = useHistory();


  return (
    <div>

      <Row className="bg-light">
        <Column lg={12} md={12}>
          <Row>
            <Column lg={9}>
            <ErrorBoundary FallbackComponent={FallbackComponent}>
            <CampaignApproval campaignId={campaignId} history={history}  />
            </ErrorBoundary>
            </Column>
            <Column lg={3} style={{paddingTop:"35px"}}>
                {/* <NotesPanel collapsible >
                </NotesPanel> */}
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  )
}

export default CampaignApprovalDetails