import useSlider from "./useSlider";
import './Slider.scss';

export interface ISlider {
    sliderClassName? : string
}


const Slider : React.FunctionComponent<ISlider> = (props) => {

    const sliderContext = useSlider();

    let sliderClass : any = {};

    if (sliderContext.isSliderPinned) {
        sliderClass = `slider-base slider-pinned${props.sliderClassName ? ' ' + props.sliderClassName : ''}`;
    }
    else {
        sliderClass = `slider-base sliding-component ${sliderContext.detachSlider ? ' slider-detached' : ''}${props.sliderClassName ? ' ' + props.sliderClassName : ''}`;
    }

    let sliderContent = <div className={sliderClass}></div>;

    if (sliderContext.isSliderVisible) {
        sliderContent = <div className={`${sliderClass} show`}>{props.children}</div>;
    }

    return sliderContent;
};

export default Slider;