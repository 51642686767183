import React, { useContext, useEffect, useState } from "react";
import { CustomerNote } from "../model/CustomerNoteModel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../../src/shared/store";
import { Button, Column, Icon, Label, Panel, Row } from "../../components";
import * as label  from '../../shared/util/label';
import moment from "moment";
import './CustomerNote.scss';
import NoteEdit from "./CustomerNoteEdit";
import CustomerNoteService from "./CustomerNoteService";
import { toast } from "react-toastify";
import * as constant from '../../shared/util/constant';
import AppContext from "../../shared/components/AppContext";
//import { ConfirmModal } from "../../shared/components/confirmModal";
import { useParams } from "react-router-dom";
import { CustomerNoteRead } from "../model/CustomerNoteReadDto";
import { updateNoteList } from "../customer-slice/Customer-note-slice";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import { AppEvenType, clearAppEvent } from "../../shared/store/component-notification/ComponentNotification";
import { AbilityContext } from "../../shared/abilities/AbilityContext";

interface Props {
    accessDenied: (boolean) => void,
}

const CustomerNotes: React.FunctionComponent<any> = (props: Props) => {
    const {accessDenied} = props
    const myContext = useContext(AppContext);
    const ability = useContext(AbilityContext);
    const customerNoteService = new CustomerNoteService();
    const notes = useSelector((state: RootState) => state.noteSlice);
    const [noteList, setNoteList] = useState<CustomerNoteRead[]>(notes.notes);
    const [isEditClicked, setEditClicked] = useState(false)
    const [editNote, setNoteEditable] = useState<CustomerNote>({})
    const [noteListToRender, setNoteListToRender]=useState<CustomerNoteRead[]>(noteList)
    const [isFormSaved, setFormSaved] = useState(true);
   
    
    const [customerId, setcustomerId] = useState('');
    const modalContext = useConfirmationModalContext();

    
    const ab_subject ="Customer Notes" ;
    const isUserPermitToViewCustomerNotes = ability.can('view', ab_subject ,constant.CustomerScreenName.CUSTOMER_NOTES)
    const isCustomerNoteMenuEnableForUser = ability.can('read', ab_subject,constant.CustomerScreenName.CUSTOMER_NOTES)
    const isUserPermitToCreateNotes = ability.can('create', ab_subject,constant.CustomerScreenName.CUSTOMER_NOTES)
    const isUserPermitToUpdateNotes = ability.can('update',ab_subject,constant.CustomerScreenName.CUSTOMER_NOTES)
    
    const eventState = useSelector((state: RootState) => state?.eventState);

    //Redirect user to homepage, if no access on page.
    useEffect(() => {
       if(isUserPermitToViewCustomerNotes ===false || isCustomerNoteMenuEnableForUser === false){
        accessDenied(true)
       }
    },[])

    const dispatch = useDispatch();
    let {id} = useParams();

    ///Get customer object from redux slicer.
    let customerNotesList = useSelector((state: RootState) => state?.noteSlice);
    const NotesEditView = (note : CustomerNote)=>{
    return <NoteEdit 
                Note={editNote} 
                SetEditClicked={setEditClicked} 
                UpdateEditedNote={UpdateEditedNote} 
                CancelNote = {CancelNote}
                setFormSaved={setFormSaved}
                AddNote ={AddNote}  >
                </NoteEdit>
    }

    /// Set from status  in context.
    useEffect(() => {
        myContext.formSavedStatus = isFormSaved
    }, [isFormSaved])

    /// Load data on page refresh.
    useEffect(() => {
            setcustomerId(id)
            let previousNoteId = customerNotesList.notes?.filter(e=> e.custId)[0]?.custId
            if(previousNoteId !== id ||customerNotesList.notes.length === 0){
            fetchNotes(id)
            }
      }, [window.onload]);

      useEffect(() => {
        if (eventState.eventType == AppEvenType.NewCustomerNoteCreated) {
            fetchNotes(id);
            dispatch(clearAppEvent());
        }
      }, [eventState.eventType])

      /// Fetch customer from service.

      const fetchNotes = async(customerId) =>{
            let customerNoteData = await customerNoteService.getNotes(customerId);
            dispatch(updateNoteList(customerNoteData))
            setNoteList(customerNoteData)
            setNoteListToRender(customerNoteData)
      }

   
      /// Passing data for edit to CustomerNoteEdit(child component). 

    const PassNoteToEdit = (editableNote: CustomerNote) => {
        if(!isEditClicked){
        setNoteEditable(editableNote);
        var scrollDiv = document.getElementById('customerNotePanel');
        scrollDiv.scrollTop = 0;
        setNoteListToRender(noteList.filter(note => note.id !== editableNote.id))
        // var noteCard = document.getElementById(editableNote.id) as unknown as HTMLInputElement | null;
        // noteCard?.classList.add('hidden');
        }
    }

    const PassNoteToAdd = () => {
        const newNote = () : CustomerNote => ({custId:id, note:'',noteDate: new Date,noteType:constant.DEFAULT_GUID,changedDate:new Date, enteredDate:new Date})
       
        if(!isEditClicked){
         
        setNoteEditable(newNote);
        var scrollDiv = document.getElementById('customerNotePanel');
        scrollDiv.scrollTop = 0;
        }
    }


    
    /// Called when user hit cancel button on CustomerNoteEdit(child component).

    const CancelNote =async() => {
        // if(!isFormSaved){
            const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if(result){
                setEditClicked(false)
                setNoteListToRender(noteList)
                setFormSaved(true);
            }
    }


    /// Called when user hit save button on CustomerNoteEdit(child component).
    const UpdateEditedNote = async (updatedNote : CustomerNote) => {
        try{
            var status = await customerNoteService.updateNote(updatedNote);
            if(status === 200){
                fetchNotes(id);
                setEditClicked(false);
                setFormSaved(true);
                toast.success(constant.CustomerNotesConstant.NOTE_SUCCESS_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        catch (ex) {
            if (ex.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(constant.CustomerNotesConstant.NOTE_UPDATE_ERROR, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const AddNote = async (newNote : CustomerNote) => {
        try{
        var customer = await customerNoteService.createNote(newNote);
        if(customer.id !== "" ){
            fetchNotes(id);
            setEditClicked(false);
            setFormSaved(true);
            toast.success(constant.CustomerNotesConstant.NOTE_SUCCESS_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    catch(ex){
            if (ex.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(constant.CustomerNotesConstant.NOTE_UPDATE_ERROR, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const AddNewNoteButton = <>
                                     <Button
                                    className='btn btn-primary bt-main-action mb-2 ml-n5'
                                    key="filter"
                                    onClick={() => {setEditClicked(true);  setFormSaved(false); PassNoteToAdd();}}
                                    tabIndex={21}
                                    disabled ={!isUserPermitToCreateNotes}
                                    id="btnNotesAdd"
                                    color='primary'
                                    outlined={true}
                                ><b><span><Icon icon={"add"} className="fa fa-add icon-color-blue"
                                ></Icon>&nbsp;</span>{label.CustomerNotesLabel.ADD_NEW_NOTE.toUpperCase()}</b>
                                </Button>
    </>

    return (
        <>
            <Column lg={12} className="pt-3">
            <Column lg={8} md={8}>
            <Panel
                        title={label.CustomerNotesLabel.CUSTOMER_NOTE.toUpperCase()}
                        className={'customerNotePanel'}
                        panelHeaderItems={AddNewNoteButton}>
               
                <Row>
                    <Column lg={12} md={12} className="note-main-box" id={'customerNotePanel'} >
                    {isEditClicked &&
                    <>
                        <Row>
                        <Column lg={12} md={12} className="mb-4">
                        {NotesEditView(editNote) }
                        </Column>
                        </Row>
                     </>
                    }
                        {noteListToRender?.map((data, index) => {
                            return (
                                <>
                                    <div className='cst-font-col notebox mb-2' id={data.id.toString()}>
                                        <Row className='cst-font-row'>
                                            <Column lg={10} md={10} className="padding-left10 nopadding-right">
                                                {/* <div><b>{data.changedBy}</b></div> */}
                                                <div><b>{data.noteDate!==null?moment(data.noteDate?.toString()).format("MMM D, YYYY"):""}</b></div>
                                            </Column>
                                            <Column lg={2} md={2} className="nopadding text-right  ml-n3">
                                                <span><Icon icon={"edit"} className={`${ isUserPermitToUpdateNotes===true? 'icon-color ' : 'disabled-icon '}fa fa-edit`}
                                                    onClick={(e) => {isUserPermitToUpdateNotes=== true?(setEditClicked(true), setFormSaved(false), PassNoteToEdit(data)):e.preventDefault; }
                                                    }></Icon></span>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column lg={12} md={12} className="padding-left10 pt-2">
                                                <div className="css-fix"><p className="uniform-font-size">{data.note}</p></div>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column lg={6} md={6} className='padding-left10 cst-font-div'><label><b>{label.UPDATED_BY + label.COLON}</b></label> {data.updatedBy} </Column>
                                            <Column lg={6} md={6} className='padding-left10 cst-font-div'><label><b>{label.UPDATED_ON + label.COLON}</b></label> {moment.parseZone(data.changedDate).format("MMMM Do YYYY, h:mm:ss a")} ET </Column>
                                        </Row>

                                    </div>
                                </>
                            )
                        })
                        }
                    </Column>
                </Row>
                </Panel>
                </Column>
            </Column>
        </>
    )
}

export default CustomerNotes;