import React, { useContext, useState } from "react";
import './SliderNote.scss';
import useSlider from "../../../shared/components/Slider/useSlider";
import { Button, Column, DateTimePicker, Label, Row, TextField } from "../../../components";
import * as  lables from "../../../shared/util/label";
import Logger from "../../../shared/services/Logger";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/store";
import { useConfirmationModalContext } from "../../../shared/components/ModalConfirmationContext";
import { cleareTabsInputs, SliderDataAction, updateSliderData } from "../CustomerSliderContext";
import { CustomerNotesConstant } from "../../../shared/util/constant";
import CustomerNoteService from "../../customer-notes/CustomerNoteService";
import CustomerSliderContainerService from "../CustomerSliderContainerService";
import SliderNoteService from "./SliderNoteService";
import { AppEvenType, notifyAppEvent } from "../../../shared/store/component-notification/ComponentNotification";
import { AbilityContext } from "../../../shared/abilities/AbilityContext";
import * as constant from '../../../shared/util/constant';

export interface ISliderNote {
    customerId? : string
}

const SliderNote : React.FunctionComponent<ISliderNote> = (props) => {
    const customerNoteService = new CustomerNoteService();
    const customerSliderContainerService = new CustomerSliderContainerService();
    const sliderNoteServcice = new SliderNoteService();
    const dispatch = useDispatch();
    const slider = useSlider();
    const modalContext = useConfirmationModalContext();
    const ability = useContext(AbilityContext);
    const isUserPermitToCreateNotes = ability.can('create', "Customer Notes",constant.CustomerScreenName.CUSTOMER_NOTES)
    
    let customerInfo = useSelector((state: RootState) => state?.customerSlice);
    let customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);

    const customerNote = customerSliderState.notePage.customerNote;
    const formValidation = customerSliderState.notePage.formValidation;

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        let updatedNoteData = {
            ...customerNote,
            [propName]: value,
          };
        dispatch(updateSliderData({ type : SliderDataAction.NoteUpdate, data : updatedNoteData}));
    }

    const onCancelClick = async () => {
        let isAnyChange = customerSliderContainerService.isAnyUnsavedChagneInNotesTab(customerSliderState);

        if (isAnyChange) {
            const result = await modalContext.showConfirmation(CustomerNotesConstant.ARE_YOU_SURE_TO_EXIT);
            if(!result){
                return;
            }
        }
        
        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }

    const onSaveClick = async () => {
        try {
            let validationError = customerNoteService.validateNote(customerNote);
            dispatch(updateSliderData({ type : SliderDataAction.NoteFormValidationUpdate, data : validationError}));
            if (Object.keys(validationError).length > 0) {
                toast.warn(CustomerNotesConstant.FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            let noteRequest = sliderNoteServcice.getCustomerNoteRequest(customerNote, customerInfo.customer.id);
            let newNote = await customerNoteService.createNote(noteRequest);
            if (newNote && newNote.id) {
                toast.success(CustomerNotesConstant.NOTE_SUCCESS_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
        
                dispatch(notifyAppEvent({ eventType : AppEvenType.NewCustomerNoteCreated, eventData : newNote}));
                dispatch(cleareTabsInputs());
                slider.hideSlider();
            }
            else {
                toast.error(CustomerNotesConstant.NOTE_UPDATE_ERROR);
            }
        }
        catch (ex) {
            toast.error(CustomerNotesConstant.NOTE_UPDATE_ERROR);
            Logger.logError(CustomerNotesConstant.NOTE_UPDATE_ERROR, ex);
        }
    }

    const mainContent = <>
        <Row>
            <Column lg={12} md={12} className="sld-col-section-header">
                <span className="sld-note-section-header">{lables.CustomerNotesLabel.NEW_NOTE}</span>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12} className="sld-note-col">
                <Label className="cs2-lables" isRequired={true} htmlFor="sldCustomerNote" title={lables.CustomerNotesLabel.DESCRIPTION} text={lables.CustomerNotesLabel.DESCRIPTION}></Label>
                <TextField
                    name='sldCustomerNote'
                    placeholder={' '}
                    value={customerNote.note}
                    isRequired={true}
                    onChange={(event) => onEntityFieldChange('note', event.currentTarget.value)}
                    tabIndex={1}
                    maxLength={2000}
                    isInvalid={formValidation['note']?.isValid == false}
                    invalidWarning={formValidation['note']?.warningText}
                    invalidLableClass="lable-error sld-note-input-error"
                    className='texbox-input sld-note-descr-input'>
                </TextField>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12} className="sld-act-col-individual-input sld-note-col sld-note-date-col">
                <Label text={lables.CustomerNotesLabel.NOTE_DATE} isRequired={true} className="inlineLable sld-inlineLable sld-note-lable"/>
                <div>
                    <DateTimePicker
                        className="sld-note-date"
                        dateFormatCalendar="LLLL yyyy"
                        dropdownMode="scroll"
                        selected={customerNote.noteDate}
                        minDate={new Date()}
                        onChange={(date) => onEntityFieldChange('noteDate', date)}
                        isInvalid={formValidation['noteDate']?.isValid == false}
                        invalidWarning={formValidation['noteDate']?.warningText}
                        invalidLableClass="lable-error sld-note-input-error">
                    </DateTimePicker>
                </div>
            </Column>
        </Row>

          {/* Action Row */}
          <Row className="mb-2 sld-note-action-row">
            <Column lg={6} md={6} className="sld-note-col">
                <Button
                    className='btn btn-outline-tertiary sld-action-btn-base'
                    key="btnActivityCancel"
                    onClick={() => onCancelClick()}
                    id="btnActivityCancel"
                    color='primary'
                    outlined={true}
                >{lables.CANCEL.toLocaleUpperCase()}
                </Button>

            </Column>
            <Column lg={6} md={6} className="sld-note-col text-right">
                <Button
                    className='sld-action-btn-base'
                    key="btnActivitySave"
                    onClick={() => onSaveClick()}
                    tabIndex={21}
                    id="btnActivitySave"
                    disabled={!isUserPermitToCreateNotes}
                    color='primary'
                    outlined={true}
                >{lables.SAVE.toLocaleUpperCase()}
                </Button>
            </Column>
        </Row>
    </>

    return <div className="slider-tab-content-main-div">
       {mainContent}
    </div>;
}

export default SliderNote;