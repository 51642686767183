import { Collapse } from "react-bootstrap";
import { DataTableState, ITableColumn } from "./DataTable";

class DataTableService {

    public getTableHeader = (columns : ITableColumn[], defaultColumnWidth : string) : JSX.Element[] => {
        return columns?.map(col => {
            let thWidth = col.width ? col.width : (defaultColumnWidth ? defaultColumnWidth : null)
            let thStyle : any = {};
        
            if (thWidth) {
              thStyle['width'] = thWidth;
            }

            if (col.maxWidth) {
                thStyle['maxWidth'] = col.maxWidth;
            }

            const headerClassName = `${this.getClassName(col.headerClassName,'')}`;
        
            return <th style={thStyle} className={headerClassName}>
                      {col.header}
                  </th>;
          });
    }

    public getTableBody = (columns : ITableColumn[], data : any[], dataTableState : DataTableState, cellTemplateActionHandler : (data : any) => void) : JSX.Element[] => {

        let bodyContent = data?.map((row, rowIndex) => {

            let rowContent = columns?.map(col => {
                let cellContent = null;
                if (col.cellTemplate) {

                    cellContent = col.cellTemplate(row, col, rowIndex, dataTableState, cellTemplateActionHandler);
                }
                else {
                    let cellData = row[col.field];
                    if (cellData) {
                        cellContent = cellData;
                        if (col.cellDataFormatter) {
                            cellContent = col.cellDataFormatter(cellData);
                        }
                    }
                }

                let tdClassName = `cell-td ${col.cellTdClassName ? col.cellTdClassName : ''}`;
                let tdContentClassName = `cell-td-div ${col.cellTdContentClassName ? col.cellTdContentClassName : ''}`;

                let tdWidth = col.width ? col.width : null;
                let tdStyle : any = {};
            
                if (tdWidth) {
                    tdStyle['width'] = tdWidth;
                }

                if (col.maxWidth) {
                    tdStyle['maxWidth'] = col.maxWidth;
                }

                return <td className={tdClassName} style={tdStyle}>
                            <div className={tdContentClassName}>
                                {cellContent}
                            </div>
                        </td>;
                ;
            });

            const trClassName = `${rowIndex % 2 == 0 ? 'data-row-1' : 'data-row-2'}`;
            
            let showDetailTemplate = false;
            let rowDetailTemplateContent = <span></span>;
            let rowDetailTrStyle : any = { 'display' : 'none'}; // By defualt keep hidden.
            const rowsState  = dataTableState.rowsState;
            if (rowsState) {
                let currentRowState = rowsState[row[dataTableState.dataTable.keyColumnName]];
                if (currentRowState && currentRowState.expanded && dataTableState.dataTable.rowDetailTemplate) {
                    showDetailTemplate = true;
                    rowDetailTemplateContent = dataTableState.dataTable.rowDetailTemplate(row, dataTableState, rowIndex);
                    rowDetailTrStyle = {}
                }
            }

            return <>
                    <tr className={trClassName}>{rowContent}</tr>
                    <tr style={rowDetailTrStyle}>
                        <td colSpan={dataTableState.dataTable.columns.length}>
                            <Collapse in={showDetailTemplate}>
                                {rowDetailTemplateContent}
                            </Collapse>
                        </td>
                    </tr>
                </>;
        });

        return bodyContent;
    }

    public getClassName = (className : string, defaultClassName : string) : string => {
        return `${defaultClassName ? defaultClassName: ''}${className ? ' ' + className : ''}`;
    }
}

export default DataTableService;