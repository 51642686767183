//import { Link } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import { GetBlackoutDateList, GetCampaignEventList } from '../hooks/GetCalendarInputs'
import {Button,Row,Column,Spinner,Icon} from '../../../src/components'
import CalendarList from '../model/Calendar'
import BlackoutDates from '../model/BlackoutDates'
import CalendarSearch from '../filter/CalendarSearch'
import React, { useState, useEffect, useContext } from 'react'
import { Calendar } from '../../../src/components/components/Calendar'
import Event from '../../../src/components/components/Calendar/interfaces'
import * as label from '../../shared/util/label'
import { getBlackoutDates } from '../services/CampaignAPI'
import moment from 'moment'
import CalendarEvent from '../model/Calendar'
import '../../cs2common.css';
import { AbilityContext } from '../../shared/abilities/AbilityContext'
import * as constant from '../../shared/util/constant'

const CalendarInfo = () => {

    const history = useHistory()
    const location = useLocation();
    //var blackoutList:BlackoutDates[]
    const [highlightedMenu, setHighlightedMenu] = useState('Calendar');
    var tabLink = document.querySelectorAll(".nav-link");
    // const { data: blackoutList, isLoading: loadingDates } = GetBlackoutDateList();
    // const { data: calendarEvent, isLoading: loadingevent } = GetCampaignEventList()
    const [_blackoutList,setBlackoutList] = useState([]);
    const [isCalenderSearch,setCalendarSearch] = useState(false);
    const[searchString, setSearchString]=useState('')
    const [searchResults, setSearchResults] = useState([]);
    const [calendarApi, setCalendarApi] = useState(null);
    const [highlightedEventId, setHighlightedEventId] = useState(null);
    const [eventLimitFromFilter, setEventLimitFromFilter] = useState(3);
    const ability = useContext(AbilityContext);
    const ab_subject = "Campaign Calendar";
    const isUserPermitToViewCampaign= ability.can('view', ab_subject,constant.CampaignCalendarPrivileges.CAMPAIGN_INTERACTION)
    const isUserPermitToRedirectToCampaign = ability.can('read', ab_subject,constant.CampaignCalendarPrivileges.CAMPAIGN_INTERACTION)
    var eventlist: Event[];
    eventlist = [];

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const { data: calendarEvent, isLoading: loadingevent } = GetCampaignEventList(fromDate, toDate);

    let events: CalendarEvent[] = [];

    if (calendarEvent && calendarEvent.campaignDetails) {
      events = calendarEvent.campaignDetails.map(detail => ({
        CampaignId: detail.campaignId,
        allDay: true,
        start: new Date(detail.sendOnDate),
        end: new Date(detail.sendOnDate),
        title: detail.campaignName,
        url: '/Campaign/CampaignDetails',
        id: detail.status, 
        status: detail.status,
        statusValue: detail.statusValue,
        displayColor: detail.displayColor,
        queryName: detail.queryName,
        requestedBy: detail.requestedBy,
      }));
    }
    

    // function highlightMenu(menuItem?) {
    //     tabLink.forEach(function (item) {
    //         item.classList.remove("active");
    //     });
    //     var activeMenu = '';
    //     if (menuItem === undefined || menuItem === '' || menuItem === null) {
    //         activeMenu = location.pathname.slice(location.pathname.lastIndexOf('/') + 1)
    //     }
    //     else {
    //         activeMenu = menuItem
    //     }

    //     if (activeMenu === 'CampaignDashboard') {
    //         activeMenu = 'Dashboard'
    //     }
    //     history.push(location)
    //     setHighlightedMenu(activeMenu)
    //     //document.getElementById(activeMenu).classList.add('active');
    // }
    // window.onload = () => {
    //     highlightMenu('Calendar');
        
    //  }
    const redirecttoCamapignSearch = () => {
        let path = '/Campaign/CampaignDetails';
        history.location.state = '0003'
        history.push({
            pathname: path,
            state: { entityId: '0003', type: '2' }
        });
    }
    const routeChange = (id?:string) => {
        event.preventDefault()
        let path = '/Campaign/CampaignDetails';
        history.location.state = id
        history.push({
            pathname: path,
            state: { entityId:id, type: '2' },
            search: "?id="+id
        });
       
    }

    loadCalenderEvent()
    function loadCalenderEvent() {
        const backgroundColors = {
            'Approved': '#1e88e5',
            'Waiting Approval': '#e53935',
            'Sent': '#43a047'
        }

        if (events !== undefined && events.length > 0) {
            events.forEach(event => {

                const eventCom: Event = {
                    id: event.CampaignId,
                    allDay: event.allDay,
                    start: new Date(event.start),
                    end: new Date(event.end),
                    url: "#",
                    title: event.title,
                    extendedProps: {statusValue: event.statusValue, displayColor: event.displayColor},
                    backgroundColor: backgroundColors[event.statusValue]
                }
                eventlist.push(eventCom)
            }); 
        }
    }
    useEffect(() => {
        getBlackoutDates()
        .then(response => {
            setBlackoutList(response.data)
        })
        .catch(error => console.log("Error fetching dates", error))
    }, [])

    function redirectToCalendarList(){
        let searchInput=document.getElementById('txtSearchBox') as HTMLInputElement|null;
        let searchStr=searchInput!=null?searchInput.value:null
        //alert(searchStr)
        if(searchStr!==undefined && searchStr.trim()!==null && searchStr.trim()!==''){
        setCalendarSearch(true);
        setSearchString(searchStr)
        }
    }

    const handleDatesOnRender = (el?: HTMLElement, view?: any) => {
        if(searchString === '') {
            setHighlightedEventId(null);
            setEventLimitFromFilter(3);
        }
        const fromDate = moment(view.activeStart).format('MM-DD-YYYY');
        const toDate = moment(view.activeEnd).format('MM-DD-YYYY');
        setFromDate(fromDate);
        setToDate(toDate);
    }

    const handleEventSearch = (e) => {
        setHighlightedEventId(null);
        setSearchString(e.target.value)
        const searchText = e.target.value.toLowerCase();
        if(searchText == '') {
            setSearchResults([]);
        }
        else {
            const filteredEvents = events.filter((event) => 
            event.title.toLowerCase().includes(searchText));
            setSearchResults(filteredEvents)
        }
    }

    const handleSearchEventClick = (date, eventId) => {
        const searchDate = new Date(date);
        const eventsOnSearchDate = events.filter(event => {
            const eventStartDate = new Date(event.start);
            return areDatesEqual(eventStartDate, searchDate);
        })
        if(eventsOnSearchDate.length > eventLimitFromFilter) {
            setEventLimitFromFilter(eventsOnSearchDate.length);
        }
        setHighlightedEventId(eventId);
        const formattedDate = date.toISOString().slice(0, 10);
        if(calendarApi) {
            calendarApi.gotoDate(formattedDate);
            clearSearch();
        }
    }

    function areDatesEqual(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate();
      }

    const clearSearch = () => {
        setSearchString("");
        setSearchResults([]);
      };

    return (
        <>

       {!isCalenderSearch && <Row>
        <Column lg={9} md={9} className=""><b>{label.CAMPAIGN_CALENDAR.toUpperCase()}</b> 
        
        </Column>
        <Column lg={3} md={3} >
        <input type="input" onChange={handleEventSearch} id='txtSearchBox' className='texbox-input search-input' placeholder={label.Search_CALENDAR} value={searchString}/>
                    <span><Icon icon={"search"} className="fa fa-search search-icon"></Icon></span>
                    {/* {searchString && <span><Icon icon="cancel" onClick={clearSearch} className="fa fa-close close-icon"></Icon></span>} */}
                    {searchResults.length > 0 && (
                    <ul style={{ 
                        position: 'absolute', 
                        zIndex: 1000, 
                        backgroundColor: '#fff',
                        cursor: 'pointer', 
                        listStyle: 'none', 
                        padding: '5px',
                        borderRadius: '5px',
                        width: '350px',
                        maxHeight: '400px',
                        overflowY: 'auto',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)'
                    }}>
                        {searchResults.map((event, index) => (
                            <li className="calendarsearch-li" key={index} onClick={() => handleSearchEventClick(event.start, event.CampaignId)}>
                                {event.title}
                            </li>
                        ))}
                    </ul>
                    )}
        </Column>
        {/* <Column lg={2} md={2} className='padding-bottom10'>
            <Button onClick={redirecttoCamapignSearch}>{label.NEW_CAMPAIGN}</Button>
        </Column> */}
       </Row>}
       {!isCalenderSearch && <Calendar
                view={'month'}
                onEventClick={function redirectToCampaignSetting(arg) {
                    location.state = { entityId: arg.id }
                    
                    routeChange(arg.id);
                }}
                // onDayRender={
                //     function (view, date, allDay, el) {
                //         let blackoutDates = _blackoutList;
                //         if (blackoutDates !== undefined) {
                //             if (blackoutDates.find(f => {
                //                 const [year, month, day] = f.split('-');
                //                 return new Date(year, month - 1, day).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0);
                //               })){
                //                 el.style.backgroundColor = "#ccc"
                //             }
                //         }
                //     }
                // }
                events= {isUserPermitToViewCampaign && eventlist}
                blackoutDates={_blackoutList}
                onDateRender={handleDatesOnRender}
                setCalendarApi={setCalendarApi}
                highlightedEventId={highlightedEventId}
                eventLimitFromFilter={eventLimitFromFilter}
                disabled={!isUserPermitToRedirectToCampaign}
            />}
            {/* {isCalenderSearch && <CalendarSearch filterRequest={searchString}></CalendarSearch>} */}

        </>
    )
}
export default CalendarInfo