import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import { client } from "../../../shared/services/client"; 
import ExternalTemplate from '../../model/3rdPartyTemplate';

class CampaignTemplateAPI {
   async  getAllExternalTemplateList () {
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<ExternalTemplate[]> =  await client.get<ExternalTemplate[]>("api/CampaignTemplate/GetAllCampaignTemplates");
    //console.log('API',res)
    return res;
  };

   async saveExternalTemplate(externalTemplateDetail:ExternalTemplate){
    client.setBaseURLForService("campaign")
    const response: AxiosResponse<any> = (await client.post("/api/CampaignTemplate/AddCampaignTemplate",externalTemplateDetail));
    //console.log(response);
    return response
    
  }

   async updateExternalTemplate(externalTemplateDetail:ExternalTemplate){
    client.setBaseURLForService("campaign")
    const response: AxiosResponse<any> = (await client.post("/api/CampaignTemplate/UpdateCampaignTemplate/",externalTemplateDetail));
    //console.log(response);
    return response
  }

   async  getExternalTemplateById(id:string){
    client.setBaseURLForService("campaign")
    const res: AxiosResponse<any> =  await client.get("/api/CampaignTemplate/GetCampaignTemplateDetail?_id="+id);
    return res;
  }

   async  deleteExternalTemplateById(id:string){
    client.setBaseURLForService("campaign")
    //console.log('API '+id);
    const param={"id":id}
    const res: AxiosResponse<any> =  await client.delete("/api/CampaignTemplate/DeleteCampaignTemplate",{data:param});
    //console.log(res.status)
    return res;
    
  }
}
export const campaignTemplateAPI = new CampaignTemplateAPI();

  
  
  