import { useEffect, useState, useRef } from 'react'
import ScreenElementPrivlegeModel from '../../model/ScreenElementPrivlegeModel'
import { Checkbox, Icon, Select } from '../../../components'
import Grid from '../../../components/components/Grid/Grid'
import { toast } from 'react-toastify'
import * as constant from '../../../shared/util/constant'
import * as label from '../../../shared/util/label'
import { RolePrivilegeSetupLabels as privelgeLabels } from '../../../shared/util/label'
import { ErrorBoundary } from 'react-error-boundary'
import { FallbackComponent } from '../../../shared/components/FallbackComponent'
import { SelectOption } from '../../../shared/components/input'
import './ScreenPrivilegeGrid.scss';
import ScreenPrivilegeService from './ScreenPrivilegeService'
import Logger from '../../../shared/services/Logger'
import { useConfirmationModalContext } from '../../../shared/components/ModalConfirmationContext'


interface Props {
  screenPrivilegeData: ScreenElementPrivlegeModel[];
  screenOptions: SelectOption[];
  zIndexHeader: number;
  onScreenDropDownChange: (value: any) => void;
  onGridDataChange: (updatedData: ScreenElementPrivlegeModel[]) => void;
}


const ScreenPrivilegeGrid = (props: Props) => {
  toast.configure();

  const { screenPrivilegeData, screenOptions, zIndexHeader, onScreenDropDownChange, onGridDataChange } = props;

  const [screenPrivilegeGridData, setScreenPrivilegeGridData] = useState<ScreenElementPrivlegeModel[]>(screenPrivilegeData);

  const [selectedScreenElementOptions, setSelectedScreenElementOptions] = useState<string[]>([]);

  const [selectedScreenElements, setSelectedScreenElements] = useState<{ [key: number]: string }>({});

  const [screenElementOptions, setScreenElementOptions] = useState<SelectOption[]>([]);

  const [screenId, setScreenId] = useState(screenPrivilegeData[0]?.featureId);

  const screenPrivilegeService = new ScreenPrivilegeService();

  const modalContext = useConfirmationModalContext();

  const addNewRow = () => {
    const newRow: ScreenElementPrivlegeModel = {
      id: constant.DEFAULT_GUID,
      elementId: null,
      isView: false,
      isCreate: false,
      isUpdate: false,
      isRead: false,
      isDelete: false,
      featureId: screenId
    };

    setScreenPrivilegeGridData([...screenPrivilegeGridData, newRow])
  };

  const deleteRow = async (rowIndex: number) => {
    if (rowIndex == 0) {
      // Confiramtion before deleting whole grid if deleting first row
      const confirmation = await modalContext.showConfirmation(constant.RolePrivilege.DELETE_PRIVIELGE_GRID)
      if (confirmation) {
        // Delete the entire grid 
        setScreenId(null)
        setScreenElementOptions([]);
        setScreenPrivilegeGridData([]);
        onGridDataChange([]);
        onScreenDropDownChange(null)
        toast.success(constant.RolePrivilege.GRID_DELETED_SUCCESS_MESSAGE)
      }
    }
    else {
      // Confirm before deleting a single row (excluding the first row)
      const confirmation = await modalContext.showConfirmation(constant.RolePrivilege.DELETE_ELEMENT_ROW)
      if (confirmation) {
        // Update selected elements and options if needed
        const updatedSelectedElements = { ...selectedScreenElements };
        delete updatedSelectedElements[rowIndex];
        setSelectedScreenElements(updatedSelectedElements);

        //UpdAate the grid by removing row to be deleted
        const updatedGridData = [...screenPrivilegeGridData];
        updatedGridData.splice(rowIndex, 1);
        setScreenPrivilegeGridData(updatedGridData);
        onGridDataChange(updatedGridData)
      }
    }
  };


  const columns = [
    {
      Header: "",
      accessor: 'delete',
      Cell: row => {
        return (
          <div className='delete-container'>
            <Icon
              icon={"trash"}
              className='grid-icon'
              onClick={() => deleteRow(row.row.id)}
            ></Icon>
          </div>
        )
      },
      width: 50
    },
    {
      Header: privelgeLabels.PRIVILEGE,
      accessor: 'screen',
      Cell: row => {
        const isFeature = row.row.id == 0;
        const options = isFeature ? screenOptions : selectedScreenElements[row.row.id] ?
          screenElementOptions.filter(screenElementOption => screenElementOption.value === selectedScreenElements[row.row.id] || !selectedScreenElementOptions.includes(screenElementOption.value)) :
          screenElementOptions.filter(screenElementOption => !selectedScreenElementOptions.includes(screenElementOption.value));
        return (
          <div className='select-container'>
            <Select
              id={`screen-${row.index}`}
              placeholder={label.PLEASE_SELECT}
              options={options as any}
              defaultSelected={options.filter(({ value }) => value?.toLowerCase() === (isFeature ? screenPrivilegeGridData[row.row.id]?.featureId?.toLowerCase() : screenPrivilegeGridData[row.row.id]?.elementId?.toLowerCase())) as any}
              onChange={(values) => handleDropDownChange(row.row.id, values[0], isFeature)}
            />
          </div>
        )

      },
      width: 240
    },
    {
      Header: privelgeLabels.VIEW,
      accessor: 'isView',
      Cell: row => {
        return (
          <div className='checkbox-cell'>
          <Checkbox
            id={`viewCheckbox-${row.index}`}
            label={""}
            checked={screenPrivilegeGridData[row.row.id]?.isView ? true : false}
            onChange={(event) => handleCheckboxChange(row.row.id, "isView", event)}            
          />
          </div>
        )
      },
      width: 200
    },
    {
      Header: privelgeLabels.READ,
      accessor: 'isRead',
      Cell: row => {
        return (
          <div className='checkbox-cell'>
          <Checkbox
            id={`readCheckbox-${row.index}`}
            label={""}
            checked={screenPrivilegeGridData[row.row.id]?.isRead ? true : false}
            onChange={(event) => handleCheckboxChange(row.row.id, "isRead", event)}            
          />
          </div>
        )
      },
      width: 200
    },
    {
      Header: privelgeLabels.CREATE,
      accessor: 'isCreate',
      Cell: row => {
        return (
          <div className='checkbox-cell'>
          <Checkbox
            id={`createCheckbox-${row.index}`}
            label={""}
            checked={screenPrivilegeGridData[row.row.id]?.isCreate ? true : false}
            onChange={(event) => handleCheckboxChange(row.row.id, "isCreate", event)}            
          />
          </div>
        )
      },
      width: 200
    },
    {
      Header: privelgeLabels.UPDATE,
      accessor: 'isUpdate',
      Cell: row => {
        return (
          <div className='checkbox-cell'>          
          <Checkbox
            id={`updateCheckbox-${row.index}`}
            label={""}
            checked={screenPrivilegeGridData[row.row.id]?.isUpdate ? true : false}
            onChange={(event) => handleCheckboxChange(row.row.id, "isUpdate", event)}            
          />
          </div>
        )
      },
      width: 200
    },
    {
      Header: privelgeLabels.DELETE,
      accessor: 'isDelete',
      Cell: row => {
        return (
          <div className='checkbox-cell'>

          <Checkbox
            id={`deleteCheckbox-${row.index}`}
            label={""}
            checked={screenPrivilegeGridData[row.row.id]?.isDelete ? true : false}
            onChange={(event) => handleCheckboxChange(row.row.id, "isDelete", event)}            
          />
          </div>
        )
      },
      width: 200
    }
  ]

  const handleDropDownChange = (rowIndex, value, isFeature) => {
    let updatedData = [...screenPrivilegeGridData];
    let updatedScreenElementSelectedOptions = [...selectedScreenElementOptions]
    let updatedSelectedScreenElements = { ...selectedScreenElements }


    if (isFeature) {
      setScreenId(value);
      updatedData = updatedData.filter((_, index) => index === 0);
      updatedData[0].featureId = value
      // updatedData.forEach(data => {
      //   data.featureId = value;
      //   data.elementId = null
      // }
      // );
      setSelectedScreenElementOptions([]);
      setSelectedScreenElements({});
      onScreenDropDownChange(value);
    } else {
      updatedData[rowIndex].featureId = screenId;
      updatedData[rowIndex].elementId = value;
      let previousValue = selectedScreenElements[rowIndex];
      updatedScreenElementSelectedOptions = updatedScreenElementSelectedOptions.filter(option => option !== previousValue)

      if (value) {
        updatedScreenElementSelectedOptions.push(value)
        updatedSelectedScreenElements[rowIndex] = value;
      } else {
        delete updatedSelectedScreenElements[rowIndex]
      }
      setSelectedScreenElements(updatedSelectedScreenElements)
      setSelectedScreenElementOptions(updatedScreenElementSelectedOptions)
    }
    updatedData[rowIndex].isCreate = false;
    updatedData[rowIndex].isDelete = false;
    updatedData[rowIndex].isRead = false;
    updatedData[rowIndex].isUpdate = false;
    updatedData[rowIndex].isView = false;
    setScreenPrivilegeGridData(updatedData)
    onGridDataChange(updatedData)
  }

  const handleCheckboxChange = (rowIndex, field, event) => {
    const updatedData = [...screenPrivilegeGridData];
    updatedData[rowIndex][field] = event.target.checked ? true : false
    setScreenPrivilegeGridData(updatedData)
    onGridDataChange(updatedData)
  }

  const initializeFeatureElements = async () => {
    try {
      let response = await screenPrivilegeService.getFeatureElements(screenId);
      setScreenElementOptions(response);
    }
    catch (error) {
      toast.error(constant.ERROR_MESSAGE_LOAD, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: constant.AUTO_CLOSE
      });
      Logger.logError(error.toString());
    }
  }

  useEffect(() => {
    if (screenId) {
      initializeFeatureElements();
    }
    else {
      setScreenElementOptions([]);
    }

  }, [screenId]);

  useEffect(() => {
    setScreenId(screenPrivilegeData[0]?.featureId);
    setScreenPrivilegeGridData(screenPrivilegeData)
  }, [screenPrivilegeData]);

  useEffect(() => {
    if (screenPrivilegeGridData.length > 0) {
      const initialSelectedElements = {};
      const initialSelectedOptions = [];

      screenPrivilegeGridData.forEach((row, index) => {
        if (row.elementId) {
          initialSelectedElements[index] = row.elementId;
          initialSelectedOptions.push(row.elementId);

        }
      });

      setSelectedScreenElements(initialSelectedElements);
      setSelectedScreenElementOptions(initialSelectedOptions);
    }
  }, [screenPrivilegeGridData]);

  return (
    <><br></br>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <div className='grid-container'>
          <Grid
            data={screenPrivilegeGridData}
            showFilters={false}
            id={'screenPrivilegeGrid'}
            columns={columns}
            sortDefault={[{ id: 'screen', asc: true }]}
            hiddenColumns={['selection']}
            disableSorting={true}
            paginationDisabled={true}
            zindexHeader={zIndexHeader}
          />
          <div className='add-container'>
            <Icon
              icon={"add"}
              className='grid-icon'
              onClick={addNewRow}
            ></Icon>
          </div>
        </div>
      </ErrorBoundary></>
  );
}
export default ScreenPrivilegeGrid