import { CustomerNote } from "../model/CustomerNoteModel";
import { updateNote, getAllNotesList, createNote } from "../services/CustomerAPI";
import ValidateCustomerNote from "./CustomerNoteValidate";

class CustomerNoteService
{
    updateNote = async (note : CustomerNote) => {
        var status= await updateNote(note)
        //customerData.customerNotes = customerData.customerNotes.sort((a,b) =>  b.changedDate.localeCompare(a.changedDate));
        return status;
    }

    getNotes = async (customerId : string) => {
        var noteData =  await getAllNotesList(customerId)
        return noteData;
    }

    createNote =  async (note : CustomerNote) => {
        var noteData =  await createNote(note)
        return noteData;
    }

    validateNote = (customerNote : CustomerNote) : any => {
        let validator =  new ValidateCustomerNote();
        let validationErrors = {};
        
        let descrError = validator.validateDescription(customerNote.note);
        if (descrError.isValid == false) {
            validationErrors['note'] = descrError;
        }

        let noteDateError =  validator.validateNoteDate(customerNote.noteDate);
        if (noteDateError.isValid == false) {
            validationErrors['noteDate'] = noteDateError;
        }

        return validationErrors;
    }
}
export default CustomerNoteService;