import { Column, Label, Row } from '../components';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'
import './DocuMateHeader.scss';
import { useEffect } from 'react';
import * as documateLabel from './shared/util/docuMateLabel';
import DocuMateScanPortal from './DocuMateScan/DocuMateScanPortal';
import DocuMateTodoList from './DocuMateIndexing/DocuMateTodoList';
import DocuMateIndexing from './DocuMateIndexing/DocuMateIndexing';
import RefiTodoList from './DocuMateRefi/RefiTodoList';
import RefiProcessing from './DocuMateRefi/RefiProcessing';

interface Props {
    isSideBarCollapsed: boolean
  }

const DocuMateHeader = (props: Props) => {


    useEffect (() => {
        const root =document.documentElement;
        root.style.setProperty('--marginLeftCustomerRow',props.isSideBarCollapsed?'5px':'60px')
      },[props.isSideBarCollapsed]);

      
    return (
        <div className='bg-light sidebarToggle'>
            <Switch>
            <Route path="/documate-scan">
                <Label title="DocuMateScan" text={documateLabel.DOCUMATE_SCAN_TITLE} className='documateHeader'></Label>
                <DocuMateScanPortal />
            </Route>
            <Route exact path="/documate-indexing">
                <Label title="DocuMateTodoList" text={documateLabel.DOCUMATE_SCAN_TITLE} className='documateHeader'></Label>
                <DocuMateTodoList />
            </Route>
            <Route exact path="/documate-indexing/processing/:id" render={() => (
                <>
                    <Label title="DocuMateIndexing" text={documateLabel.DOCUMATE_INDEXING_TITLE} className='documateHeader'></Label>
                    <DocuMateIndexing />
                </>
            )} />
            <Route exact path="/documate-refi">
                <Label title="DocuMateRefi" text={documateLabel.DOCUMATE_REFI_TITLE} className='documateHeader'></Label>
                <RefiTodoList />
            </Route>
            <Route exact path="/documate-refi/processing" render={() => (
                <>
                    <RefiProcessing />
                </>
            )} />
            </Switch>
        </div>
    );
};

export default DocuMateHeader;