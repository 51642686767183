import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './MenuBar.scss'
import { Button } from "../../../components";

export interface IMenuItem {
    menuKey : string;
    menuText : string
    isVisible?: boolean; // New property for show/hide
    isDisabled?: boolean; // New property for enable/disable
}

export interface IMenuIconItem {
    menuKey : string;
    iconName : string;
    iconClass? : string;
    toolTip? : string;
    isVisible?: boolean; // New property for show/hide
    isDisabled?: boolean; // New property for enable/disable
}

export interface IMenuBar
{
    menuItems : IMenuItem[],
    selectedMenuKey? : string,
    onMenuClick? : (menuClicked : string) => void
    primaryActionIconMenuItems? : IMenuIconItem[];
    secondaryActionIconMenuItems? : IMenuIconItem[];
    onIconMenuClick? : (clickedIconMenuItem : IMenuIconItem ) => void;
    navbarClass? : string;
}

const MenuBar : React.FunctionComponent<IMenuBar> = (props) => {

    const {primaryActionIconMenuItems, secondaryActionIconMenuItems, menuItems, selectedMenuKey } = props;

    const links = menuItems?.filter((item) => item.isVisible !== false) // Filter based on visibility
    .map(item => {
        const activeMenuClass = item.menuKey == selectedMenuKey ? 'active' : '';
        const disabledClass = item.isDisabled ? 'disabled' : ''; // Add disabled class

        return <Nav.Link 
                    key={item.menuKey}
                    href={''} 
                    disabled ={item.isDisabled??false}
                    className={`nav-link-custom ${activeMenuClass} ${disabledClass}`}
                    onClick={() => (item.isDisabled ? null : props.onMenuClick(item.menuKey))} // Prevent click for disabled items
                    >
                      {item.menuText}
                    </Nav.Link>
                    // className={'nav-link-custom ' + activeMenuClass}
                    // onClick={() => props.onMenuClick(item.menuKey)}>{item.menuText} </Nav.Link>;
    });

    const primaryIconMenus = primaryActionIconMenuItems?.filter((item) => item.isVisible !== false) // Filter based on visibility
    .map((item) => {
        let iconMenuClass = `btn-md btn-primary ${item.menuKey == selectedMenuKey ? ' active ' : ''} ${item.iconClass ? ' ' + item.iconClass : ''}`;
        return <Button
                    className={iconMenuClass}
                    key={`iconMenu${item.iconName}`}
                    icon={item.iconName as any}
                    id={`iconMenu${item.iconName}`}
                    title={item.toolTip}
                    disabled={item.isDisabled} // Prevent click for disabled items
                    onClick={() => props.onIconMenuClick(item)}>
                </Button>;
    });

    const secondaryIconMenus = secondaryActionIconMenuItems?.filter((item) => item.isVisible !== false) // Filter based on visibility
    .map((item) => {
        let iconMenuClass = `btn-md btn-primary ${item.iconClass ? ' ' + item.iconClass : ''}`;
        return <Button
                    className={iconMenuClass}
                    key={`iconMenu${item.iconName}`}
                    icon={item.iconName as any}
                    id={`iconMenu${item.iconName}`}
                    title={item.toolTip}
                    disabled={item.isDisabled} // Prevent click for disabled items                    
                    onClick={() => props.onIconMenuClick(item)}>
                </Button>;
    });

    const navbarClassName = props.navbarClass ? props.navbarClass : '';
    
    return (
        <Navbar bg="primary" data-bs-theme="dark" className={navbarClassName}>
            <Nav className="me-auto">
                {links}
                {primaryIconMenus && primaryIconMenus.length > 0 && <div className="primary-icon-menu-container">
                        {primaryIconMenus}
                    </div>}
            </Nav>
            {secondaryIconMenus && secondaryIconMenus.length > 0 
                && <div className="secondary-icon-menu-container">{secondaryIconMenus}
                    </div>
            }
        </Navbar>
    );
}

export default MenuBar;