
import React, { useContext, useEffect, useState } from 'react'
import { SelectOption } from '../../../shared/components/input/SelectOption'
import TextInputWithLabelInline from '../../../shared/components/input/TextInputWithLabelInline'
import { ErrorBoundary } from 'react-error-boundary'
import _, { update } from 'lodash'
import Logger from '../../../shared/services/Logger'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../../shared/util/constant'
import { Nav, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as label from '../../../shared/util/label'
import { Card, Collapse } from 'react-bootstrap'
import { Row } from "react-bootstrap";
import { Button, Column, Checkbox } from "../../../components";
import DropDownWithLabelInline from "../../../shared/components/input/DropDownWithLabelInline";
import { CustomerScreentLables as cstScrLables } from "../../../shared/util/label";
import { userSetupUtils } from '../../utils/UserSetupUtils'
import EmployeeSetupService   from '../service/EmployeeSetupService'
import './../UserSetupMiniTabs.scss';

import * as tableId from '../../../shared/util/tableConstant'

import { useTable } from 'react-table';
import { table } from 'console'
import EmployeeSetupModel from '../../model/EmployeeSetupModel'

import e from 'express'

export interface IEmployeeView {
    relationships: SelectOption[],
    onFieldChange: (collectionPropName: string, collectionEntityIdentifierPropName: string, collectionEntityIdentifierValue: any, propName: string, value: string | Date | boolean) => void,
    // onDelete : (dependent : CustomerDependent) => void,
    formValidations: any,
    isEditable: boolean,
    isReset: boolean
}

const EmployeeDetails: React.FunctionComponent<IEmployeeView> = ({ employeeDetail, setEmployeeDetail }) => {

    const employeeSetup: EmployeeSetupModel = {
        id: constant.DEFAULT_GUID,
        agencyGroupId: '',
        firstName: '',
        lastName: '',
        middleName: '',
        title: '',
        email: '',
        employeeStatusId: '',
        agencyId: '',
        legacyEmpCode: '',
        workPhone: '',
        workPhoneExt: '',
        countryCode: '',
        fullPartTimeIndId: '',
        empSupervisorCode: '',
        changedDate:'',
        changedByName:''
        // roleId: ''
    }

    const employeeSetupService = new EmployeeSetupService()

    const [activeTab, setActiveTab] = useState('tab1');
    const history = useHistory();

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };



    const [employeeStatusOptions, setEmployeeStatus] = useState<SelectOption[]>([]);
    const [agencyGroups, setAgencyGroups] = useState<SelectOption[]>([]);
    const [selectedAgencyGroup, setSelectedAgencyGroup] = useState([]);
    const [agencies, setAgencyList] = useState<SelectOption[]>([]);
    // const [employeeDetail, setEmployeeDetail] = useState(employeeSetup);
    const [isEditable, setIsEditable] = useState(true);
    const [employeeList, setEmployeeList] = useState<SelectOption[]>([]);
    const [indicatorList, setIndicators] = useState<SelectOption[]>([]);
    const [roleList, setRoleList] = useState<SelectOption[]>([]);
    const [formValidation, setFormValidation] = useState<any>({});
    useEffect(() => {
        
        if (employeeDetail) {
                handleAgencyGroupChange(employeeDetail.agencyGroupId);
            // Perform side effects with employeeDetail
        }
    }, [employeeDetail]);
    useEffect(() => {
        //alert('called')
        userSetupUtils.getDropdownOptions(tableId.EMPLOYEE_STATUS)?.then(res => {
            let filteredRes = res.filter(item => item.label.toLowerCase() !== "deleted");
            setEmployeeStatus(filteredRes)
        })
        userSetupUtils.getDropdownOptions(tableId.EMPLOYEE_FULLTIMEIND)?.then(res => {
            setIndicators(res)
        })
        // userSetupUtils.getAppRoles(tableId.ROLE_APP_ID)?.then(res => {
        //     setRoleList(res)
        // })
        userSetupUtils.getAgencyGroups()?.then(res => {
            setAgencyGroups(res)
        })
        userSetupUtils.getEmployeeList()?.then(res => {
            setEmployeeList(res);
        })
        userSetupUtils.getEmployeeList()?.then(res => {
            setEmployeeList(res);
        })
        
       
        // highlightMenu();
    }, []);
    // useEffect(() => {
    // if(employeeDetail.id != constant.DEFAULT_GUID)
    //     {
           
    //     }
    // }, [employeeDetail, setEmployeeDetail]);

    useEffect(() => {
        if (selectedAgencyGroup) {
            userSetupUtils.getAgenciesByGroup(selectedAgencyGroup)?.then(res => {
                setAgencyList(res);
                // setEmployeeStatus(res)
            })
        }
    }, [selectedAgencyGroup]);

    const handleAgencyGroupChange = async (newValue) => {
        setSelectedAgencyGroup(newValue);
    };
    const onEntityFieldChange = (propName: string, value: string | Date | boolean) => {
        setEmployeeDetail({
            ...employeeDetail,
            [propName]: value,
        });

        // if (propName == 'firstName' || propName == 'lastName') {
        //     setEmployeeDetail(cst => {
        //         return {
        //         ...cst,
        //         displayName: (cst.firstName ? cst.firstName : '') + ' ' + (cst.lastName ? cst.lastName : ''),
        //         };
        //       });
        // }
    }

    const SaveEmployee = async () => {
        try {
            let validationError = employeeSetupService.validateEmployee(employeeDetail);
            setFormValidation(validationError);
            if (Object.keys(validationError).length > 0) {
                toast.warn(constant.CUSTOMER_FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            if(employeeDetail.id != constant.DEFAULT_GUID && employeeDetail.id != null)
            {
                let updatedEmployer = await employeeSetupService.saveEmployee(employeeDetail, true);
                toast.success("Saved Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: constant.AUTO_CLOSE
                });
            }
            else
            {
                let updatedEmployer = await employeeSetupService.saveEmployee(employeeDetail, false);
                if (updatedEmployer) {
                    
                    employeeDetail = updatedEmployer;
                    
                    setEmployeeDetail({
                        ...employeeDetail
                    });
                    toast.success("Saved Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                }
                else {
                    toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
           
        }
        catch (e) {
            debugger
            if(e.status == 400 || e.data != null)
            {
                Logger.logError(e.data, e);
                toast.error(e.data, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else
            {
                Logger.logError(constant.CUSTOMER_ERROR_WHILE_SAVING, e);
                toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }


    return (



        <div className="employee-details">
            <Row>
                <Column lg={6} md={6}>&nbsp;</Column>
                <Column lg={6} md={6}>&nbsp;</Column>
            </Row>


            <Row>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='firstName'
                        label={cstScrLables.FIRST_NAME}
                        placeholder={'First Name'}
                        value={employeeDetail.firstName}
                        onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                        isEditable={isEditable}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        isInvalid={formValidation['firstName']?.isValid == false}
                        invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>

                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='legacyEmpCode'
                        label={'Legacy Employee Code'}
                        placeholder={'Legacy Employee Code'}
                        value={employeeDetail.legacyEmpCode ?? ""}
                        onChange={(event) => onEntityFieldChange('legacyEmpCode', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={3}
                        isInvalid={formValidation['legacyEmpCode']?.isValid == false}
                        invalidWarning={formValidation['legacyEmpCode']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='agencyGroupId'
                        label='Agency Group'
                        options={agencyGroups}
                        onChange={
                            (values) => {
                                onEntityFieldChange('agencyGroupId', values[0])
                                handleAgencyGroupChange(values[0])
                            }
                        }
                        defaultSelected={agencyGroups?.filter(({ value }) => value === employeeDetail.agencyGroupId)}
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        isRequired
                        isInvalid={formValidation['agencyId']?.isValid == false}
                        invalidWarning={formValidation['agencyId']?.warningText}
                        placeholder={'Select Agency Group'}
                        multiple={false}
                        tabIndex={9}
                    />
                </Column>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='countryCode'
                        label={'Country Code'}
                        placeholder={'Country Code'}
                        value={employeeDetail.countryCode??""}
                        onChange={(event) => onEntityFieldChange('countryCode', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={2}
                        isInvalid={formValidation['countryCode']?.isValid == false}
                        invalidWarning={formValidation['countryCode']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>

                
            </Row>
            <Row>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='middleName'
                        label={'Middle Name'}
                        placeholder={'Middle Name'}
                        value={employeeDetail.middleName ?? ""}
                        onChange={(event) => onEntityFieldChange('middleName', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        tabIndex={11}
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={100}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='employeeStatusId'
                        label='Employee Status'
                        options={employeeStatusOptions !== undefined ? employeeStatusOptions : []}
                        defaultSelected={employeeStatusOptions.filter(({ value }) => value?.toLowerCase() === employeeDetail.employeeStatusId)}
                        onChange={
                            (values) => {
                                onEntityFieldChange('employeeStatusId', values[0])
                            }
                        }
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        isRequired
                        // disabled ={!props.isEditable}
                        placeholder={'Status'}
                        multiple={false}
                        tabIndex={9}
                        isInvalid={formValidation['employeeStatusId']?.isValid == false}
                        invalidWarning={formValidation['employeeStatusId']?.warningText}
                    />
                </Column>

                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='agencyId'
                        label='Agency'
                        isRequired
                        options={agencies}
                        defaultSelected={agencies?.filter(({ value }) => value === employeeDetail.agencyId)}
                        onChange={
                            (values) => {
                                onEntityFieldChange('agencyId', values[0])
                            }
                        }
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        isInvalid={formValidation['agencyId']?.isValid == false}
                        invalidWarning={formValidation['agencyId']?.warningText}
                        // disabled ={!props.isEditable}
                        placeholder={'Agency'}
                        multiple={false}
                        tabIndex={9}
                    />
                </Column>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='enteredDate'
                        label={'Entered Date'}
                        placeholder={'Entered Date'}
                        value={employeeDetail.enteredDate??""}
                        isEditable={false}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                

            </Row>



            <Row><Column lg={3} md={3}>
                <TextInputWithLabelInline
                    name='lastName'
                    label={cstScrLables.LAST_NAME}
                    placeholder={'Last Name'}
                    value={employeeDetail.lastName??""}
                    onChange={(event) => onEntityFieldChange('lastName', event.currentTarget.value)}
                    isEditable={true}
                    type='text'
                    labelClass='inlineLable'
                    lglabel={4}
                    mdlabel={4}
                    isRequired
                    lgText={8}
                    mdText={8}
                    maxLength={60}
                    isInvalid={formValidation['lastName']?.isValid == false}
                    invalidWarning={formValidation['lastName']?.warningText}
                    className='texbox-input'>
                </TextInputWithLabelInline>
            </Column>

                <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='employeeSupervisor'
                        label='Employee Supervisor'
                        options={employeeList}
                        onChange={
                            (values) => {
                                onEntityFieldChange('empSupervisorCode', values[0])
                            }
                          }
                        defaultSelected={employeeList?.filter(({ value }) => value === employeeDetail.empSupervisorCode)}
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        // disabled ={!props.isEditable}
                        placeholder={'Supervisor'}
                        multiple={false}
                        tabIndex={9}
                    />
                </Column>

                {/* <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='roleId'
                        label='Role'
                        options={roleList}
                        defaultSelected={roleList?.filter(({ value }) => value === employeeDetail.roleId)}
                        onChange={
                            (values) => {
                                onEntityFieldChange('roleId', values[0])
                            }
                          }
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        isRequired
                        // disabled ={!props.isEditable}
                        placeholder={' '}
                        multiple={false}
                        tabIndex={9}
                    />
                </Column> */}
                 <Column lg={3} md={3}>
                    <DropDownWithLabelInline
                        id='fullPartTimeIndId'
                        label='FullTime Indicator'
                        options={indicatorList}
                        defaultSelected={indicatorList?.filter(({ value }) => value === employeeDetail.fullPartTimeIndId)}
                        onChange={
                            (values) => {
                                onEntityFieldChange('fullPartTimeIndId', values[0])
                            }
                          }
                        labelSize={4}
                        dropDownSize={8}
                        labelClass='inlineLable'
                        // disabled ={!props.isEditable}
                        placeholder={'FullTime Indicator'}
                        multiple={false}
                        tabIndex={9}
                    />
                </Column>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='changedByName'
                        label={'Updated By'}
                        placeholder={'Updated By'}
                        value={employeeDetail.changedByName??""}
                        isEditable={false}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                
            </Row>

            <Row>
            <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='email'
                        label={'Email'}
                        placeholder={'Email'}
                        value={employeeDetail.email ?? ""}
                        onChange={(event) => onEntityFieldChange('email', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        isRequired
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        isInvalid={formValidation['eMail']?.isValid == false}
                        invalidWarning={formValidation['eMail']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='title'
                        label={'Title'}
                        placeholder={'Title'}
                        value={employeeDetail.title ?? ""}
                        onChange={(event) => onEntityFieldChange('title', event.currentTarget.value)}
                        isEditable={true}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
                <Column lg={3} md={3}>
                    <Row>
                        <Column lg={8} md={8}>
                            <TextInputWithLabelInline
                                name='WorkPhone'
                                label={'WorkPhone'}
                                placeholder={'Phone'}
                                value={employeeDetail.workPhone}
                                onChange={(event) => onEntityFieldChange('workPhone', event.currentTarget.value)}
                                isEditable={true}
                                type='text'
                                labelClass='inlineLable'
                                lglabel={6}
                                mdlabel={6}
                                lgText={6}
                                mdText={6}
                                maxLength={60}
                                className='texbox-input'>
                            </TextInputWithLabelInline>
                        </Column>
                        <Column lg={4} md={4}>
                            <TextInputWithLabelInline
                                name='workPhoneExt'
                                placeholder={'Ext.'}
                                value={employeeDetail.workPhoneExt}
                                onChange={(event) => onEntityFieldChange('workPhoneExt', event.currentTarget.value)}
                                isEditable={true}
                                type='text'
                                lgText={6}
                                mdText={6}
                                maxLength={60}
                                className='texbox-input'>
                            </TextInputWithLabelInline>
                        </Column>
                    </Row>
                </Column>
                <Column lg={3} md={3}>
                    <TextInputWithLabelInline
                        name='changedDate'
                        label={'Updated Date'}
                        placeholder={'Updated Date'}
                        value={employeeDetail.changedDate}
                        // onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                        isEditable={false}
                        type='text'
                        labelClass='inlineLable'
                        lglabel={4}
                        mdlabel={4}
                        lgText={8}
                        mdText={8}
                        maxLength={60}
                        // isInvalid={formValidation['firstName']?.isValid == false}
                        // invalidWarning={formValidation['firstName']?.warningText}
                        className='texbox-input'>
                    </TextInputWithLabelInline>
                </Column>
            </Row>

            <Row className="bottom-stick-actions  button-row">
                <Column lg={3} md={3}>
                <Button
                            className='btn btn-outline-primary col-3 ml-2'
                            key="filter"
                            onClick={() =>CancelEdit()}
                            tabIndex={20}
                            id="btnCancel"
                            color='tertiary'
                            outlined={true}
                            disabled={false}
                            style={{minWidth:"90px"}}
                        >{label.CANCEL.toLocaleUpperCase()}
                        </Button> 
                </Column>
                <Column lg={5} md={5}></Column>
                
                <Column lg={3} md={3} className="text-right">
                <Button
                            className='btn btn-outline-primary col-3 mr-3'
                            key="filter"
                            onClick={() =>EditCustomer()}
                            tabIndex={21}
                            id="btnEdit"
                            color='second'
                            disabled={false}
                            outlined={true}
                        >{'DELETE'}
                        </Button>
                <Button
                            className='btn btn-outline-primary col-3 mr-3'
                            key="filter"
                            onClick={() =>SaveEmployee()}
                            tabIndex={21}
                            id="btnAddCustomer"
                            disabled={false}
                            color='primary'
                            outlined={true}
                        >{label.SAVE.toLocaleUpperCase()}
                        </Button>
                
                </Column>
            </Row>

        </div>
    );
};
export default EmployeeDetails;