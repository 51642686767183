import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './UserSetupMiniTabs.scss';
import * as label from '../../shared/util/label'
import { Card, Collapse } from 'react-bootstrap'
import EmployeeDetails from './EmployeeSetup/EmployeeDetails'
import { Row } from "react-bootstrap";
import { Button, Column, Checkbox } from "../../components";
import UserDetails from './UserSetup/UserDetails';
import { EmployeeContext } from './EmployeeContext'
import EmployeeSetupModel from '../model/EmployeeSetupModel'
import { useEffect } from 'react'
import * as constant from '../../shared/util/constant'
import { userSetupUtils } from '../utils/UserSetupUtils'
import UserDetailModel from '../model/UserDetailModel'
import { toast } from 'react-toastify';

// import CustomerStandardFilterForm from '../../customer/filter/CustomerStandardSearch'

const UserSetupMiniTabs = (props) => {
    const [activeTab, setActiveTab] = useState('tab1');
    const employeeSetup: EmployeeSetupModel = {
        id: constant.DEFAULT_GUID,
        agencyGroupId: '',
        firstName: '',
        lastName: '',
        middleName: '',
        title: '',
        email: '',
        employeeStatusId: '',
        agencyId: '',
        legacyEmpCode: '',
        workPhone: '',
        workPhoneExt: '',
        countryCode: '',
        fullPartTimeIndId: constant.DEFAULT_GUID,
        empSupervisorCode: constant.DEFAULT_GUID,
        changedDate:'',
        enteredDate:'',
        changedByName:''
        // roleId: ''
    };
    const [employeeDetail, setEmployeeDetail] = useState(employeeSetup);
    const userSetup: UserDetailModel = {
        id: employeeDetail.id,
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
        objectId:'',
        isActive:false
    }
    const [userdetail, setUserDetail] = useState(userSetup);
    useEffect(() => {
        setEmployeeDetail(employeeSetup);  
        setUserDetail(userSetup)
        if (props.selectedEmployee != null && props.selectedEmployee != constant.DEFAULT_GUID) {
            userSetupUtils.getEmployeeDetails(props.selectedEmployee)?.then(res => {      
                employeeDetail.id = props.selectedEmployee
                setEmployeeDetail(res);  
            })
            userSetupUtils.getUserDetails(props.selectedEmployee)?.then(res => {      
                userdetail.id = props.selectedEmployee
                setUserDetail(res);  
            })
           }
    }, [props]);
    const history = useHistory();

    const handleTabChange = (tab: string) => {
        if(tab == 'tab2' && employeeDetail.id == constant.DEFAULT_GUID)
        {
            toast.warn("Please create an employee before accessing the User Setup tab.", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else
        {
            setActiveTab(tab);
        }
    };

    return (
        <EmployeeContext.Provider value={employeeDetail}>
            <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                <Nav variant="tabs" className="miniTabs">
                    <Nav.Item>
                        <Nav.Link eventKey="tab1">{label.EMPLOYEE_SETUP}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="tab2">{label.USER_SETUP}</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                    <Nav.Link eventKey="tab3">{label.HISTORY_TAB}</Nav.Link>
                </Nav.Item> */}
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="tab1" className="tabContent">
                        {<EmployeeDetails
                            employeeDetail={employeeDetail}
                            setEmployeeDetail={setEmployeeDetail}
                        />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab2" className="tabContent">
                        {<UserDetails userdetail={userdetail}
                            setUserDetail={setUserDetail}/>}
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>

        </EmployeeContext.Provider>

    );
};

export default UserSetupMiniTabs;