import * as constants from "../../../shared/util/constant";
import { CustomerActivityAddRequest } from "../../model/CustomerActivityAddRequest";


class SliderActivityService {

    public getActivityRequest = (requestData : CustomerActivityAddRequest , customerId : string) :  CustomerActivityAddRequest => {
        let dtoRequest : CustomerActivityAddRequest = {};
        
        dtoRequest.activityEffDate = requestData.activityEffDate;
        dtoRequest.activityTypeId = requestData.activityTypeId;
        dtoRequest.custId = customerId;
        dtoRequest.description = requestData.description;
        dtoRequest.policyId = requestData.policyId;

        return dtoRequest;
    };

    public validateActivity = (activityObj : CustomerActivityAddRequest) => {
        let validationErrors = {};

        if (!activityObj) {
            validationErrors['rootObject'] = {isValid : false, warningText : "Activity details missing." }
        }

        if (!activityObj.activityTypeId) {
            validationErrors['activityTypeId'] = {isValid : false, warningText : constants.FIELD_BLANK };
        }

        if (!activityObj.description || activityObj.description?.trim().length == 0) {
            validationErrors['description'] = {isValid : false, warningText : constants.FIELD_BLANK };
        }

        if (!activityObj.activityEffDate) {
            validationErrors['activityEffDate'] = {isValid : false, warningText : constants.FIELD_BLANK };
        }

        if (activityObj.activityEffDate > new Date()) {
            validationErrors['activityEffDate'] = {isValid : false, warningText : constants.SliderActivityConstant.DATE_CAN_NOT_BE_A_FUTURE_DATE };
        }

        return validationErrors;
    }
};

export default SliderActivityService;