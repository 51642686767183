import { CustomerFilterViewMoel } from "../model/CustomerFilterViewModel";
import * as CustomerAPI from "../services/CustomerAPI";

class CutomerMainPortalHeaderService {
    getCustomerSummary = async (customerId) : Promise<CustomerFilterViewMoel> => {
        let customerData = await CustomerAPI.getCustomerSummary(customerId);
        return customerData;
    }
};

export default  CutomerMainPortalHeaderService;