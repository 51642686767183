import { List, ListItem, Icon, Button } from '../../../components'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import Permissions from '../../model/Permissions'
import { RootState } from '../../store'
// import { updateSidebar } from '../component-slice'
import './Sidebar.css';
import * as label from '../../../shared/util/label'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Dropdown } from 'react-bootstrap'
import AppContext from "../AppContext";
import { useConfirmationModalContext } from "../ModalConfirmationContext";
import * as constant from '../../../shared/util/constant';
import CustomerSliderContainerService from '../../../customer/customer-slider-content/CustomerSliderContainerService'
import { cleareTabsInputs } from '../../../customer/customer-slider-content/CustomerSliderContext'
import useSlider from '../Slider/useSlider'
import * as docuMateLabel from '../../../DocuMate/shared/util/docuMateLabel';
import { AbilityContext } from '../../abilities/AbilityContext'

interface Props {
  handleCallBack:Function,
  userName: string,
  onLogout: Function
}

const Sidebar = (props:Props) => {
  const customerSliderContainerService = new CustomerSliderContainerService();
  const customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
  const originalCustomerObj = useSelector((state: RootState) => state?.customerSlice).customer;

  // const dispatch = useDispatch()
  // const { sidebarCollapsed } = useSelector((state: RootState) => state.components)
  const [sidebarCollapsed,setSidebarCollpased] = useState(true);
  const [pinsideBar,setPinSidebar] =useState(false)
  // const permissions = useSelector((state: RootState) => state.user.permissions)
  const [classNameForListItems, setClassNameForListItems] = useState("sideBarListItemsExpanded");
  const [classNameForLogInUser, setClassNameForLogInUser] = useState("sideBarLogInUserExpanded");
  const path = useLocation()
  const history = useHistory()
  const dispatch = useDispatch();
  const slider = useSlider();
  const { pathname } = path
  const splittedPath = pathname.split('/')
  const modalContext = useConfirmationModalContext();
  const myContext = useContext(AppContext);
  const ability = useContext(AbilityContext);
  const ab_subject = "CS2 Main Menu"
  const isUserPermitToViewAdmin = ability.can('view', ab_subject ,constant.SidebarPrivileges.Admin_Management)
  const isAdminMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Admin_Management)
  const isUserPermitToViewCampaign = ability.can('view', ab_subject ,constant.SidebarPrivileges.Campaign_Management)
  const isCampaignMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Campaign_Management)
  const isUserPermitToViewCustomer = ability.can('view', ab_subject ,constant.SidebarPrivileges.Customer_Management)
  const isCustomerMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Customer_Management)
  const cursorStyleForListItem  = !isAdminMenuEnableForUser ? { cursor: 'auto' } : {};
  const navigateTo = async(location: string) => {
    const changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
    if (Object.keys(changes).length > 0) {
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(!result){
            return;
          }

        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }


    if(!myContext.formSavedStatus){
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(result){
            history.push(location)
          }
      }
      else{
        history.push(location)
      }

    
  }
  const { userName } = props
   const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (sidebarRef.current &&  !sidebarRef.current.contains(event.target) && !pinsideBar){
      setSidebarCollpased(true);
      }
    };
    if (sidebarCollapsed) {

      setClassNameForListItems("sideBarListItemsCollapsed");
      setClassNameForLogInUser("sideBarLogInUserCollapsed");
    }
    else {
      setClassNameForListItems("sideBarListItemsExpanded");
      setClassNameForLogInUser("sideBarLogInUserExpanded");
        }

    {pinsideBar?setSidebarCollpased(false):null}

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };


  }, [pinsideBar,sidebarCollapsed,sidebarRef])

const handlePinClick =() =>{
  setPinSidebar(!pinsideBar);

}

const handleBurgerMenuClick = () => {
  if(!pinsideBar){
    setSidebarCollpased(!sidebarCollapsed);
  }
}

  // const listItemStyle: CSSProperties = {
  //   cursor: 'pointer',
  //   fontSize: 'small', 
  //   textAlign:sidebarCollapsed?'center':'left' ,
  //    paddingLeft: sidebarCollapsed ? '10px' : '10px' ,
  //    paddingRight:sidebarCollapsed? '1rem': '2rem'
   
    
  // }


  const [expandedItem, setExpandedItem] = useState(
    splittedPath[1].includes('customers')
      ? 'customer'
      : splittedPath[1].includes('campaigns')
      ? 'campaign'
      : splittedPath[1].includes('maintenance')

      ? 'maintenance'
      : splittedPath[1].includes('imaging')
      ? 'imaging'
      : splittedPath[1].includes('admin')
      ? 'admin'
      : splittedPath[1].includes('scan')
      ? 'scan'
      : splittedPath[1].includes('indexing')
      ? 'indexing'
      : splittedPath[1].includes('search')
      ? 'search'
      : splittedPath[1].includes('refi')
      ? 'refi'
      : 'none'
  )

  const setExpansion = (item: string) => {
    if (expandedItem === item) {
      setExpandedItem('none')
      return
    }

    setExpandedItem(item.toString())
  }

 
  const getDashboardLink = () => (
    <>
      <ListItem
        active={pathname === '/'}
        onClick={() => {
          navigateTo('/')
          setExpansion('none')
        }}
        className={classNameForListItems}
      >
        <Icon icon="dashboard" className='fa-light' /> {!sidebarCollapsed && 'Dashboard'}
      </ListItem>
    </>
  )

  const getMainDashboardLinks = () => (
    <>
      <ListItem
        active={splittedPath[1].includes('/') || pathname == '/'}
        onClick={() => {
          navigateTo('/')
          setExpansion('none')
        }}
        className={classNameForListItems}

      >
        <Icon icon="home" /> 
        {sidebarCollapsed?<br></br>:" "}
        {label.HOME}
      </ListItem>
    </>
  )

  const getCustomerLinks = () => (
    <>
      <ListItem
        active={splittedPath[1].includes('customer')}
        onClick={() => {
          isCustomerMenuEnableForUser && navigateTo('/customer/customerdashboard')
          setExpansion('none')
        }}
        className={classNameForListItems}
        style={!isCustomerMenuEnableForUser?{cursor:'auto'}:{}}
      >
        <Icon icon="customer" /> 
        {sidebarCollapsed?<br></br>:" "}
        {label.CUSTOMER}
      </ListItem>
    </>
  )

  const getCampaignLinks = () => (
    <>
    
      <ListItem
        active={splittedPath[1].includes('Campaign')}
        onClick={() => {
          isCampaignMenuEnableForUser && navigateTo('/Campaign/CampaignDashboard')
          setExpansion('none')
        }}
        className={classNameForListItems}
        style={!isCampaignMenuEnableForUser?{cursor:'auto'}:{}}
      > 
        <Icon icon="campaign" /> 
        {sidebarCollapsed?<br></br>:" "}
        {label.CAMPAIGN}
      </ListItem>
      
    </>
  )
 
  const getMaintenanceLinks = () => (
    <>
      <ListItem
        active={splittedPath[1].includes('maintenance')}
        onClick={() => {
          navigateTo('/maintenance')
          setExpansion('none')
        }}
        className={classNameForListItems}

      >       
        <Icon icon="setting" /> {!sidebarCollapsed && 'Maintenance'}
      </ListItem>
       
    </>
  )

  const getAdminLinks = () => (
    <>
      <ListItem
        active={splittedPath[1].includes('admin')}
        onClick={() => {
           isAdminMenuEnableForUser && navigateTo('/admin')
          setExpansion('none')
        }}
        className={classNameForListItems}
        style={cursorStyleForListItem}
      >      
        <Icon icon="admin" /> 
        {sidebarCollapsed?<br></br>:" "}
        {label.ADMIN}
      </ListItem>
      
    </>
  )
  const getUserProfile = () => (
    <>
     
      
    </>
  )

  const getImagingLinks = () => (
    <>
      <ListItem
      active={splittedPath[1].includes('imaging')}
      onClick={() => {
        navigateTo('/imaging')
        setExpansion('none')
      }}
      className={classNameForListItems}
      >
        <Icon icon="imaging" />
        {sidebarCollapsed?<br></br>:" "}
        {label.DOCUMATE}
      </ListItem>
    </>
  )

  const getInitials = name => {
    var initials = name.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
    return initials;
  };

  const getDocuMateScanLinks = () => (
    <>
      <ListItem
      active={splittedPath[1].includes('scan')}
      onClick={() => {
        navigateTo('/documate-scan')
        setExpansion('none')
      }}
      className={classNameForListItems}
      >
        <Icon icon="documateScan" />
        {sidebarCollapsed?<br></br>:" "}
        {docuMateLabel.DOCUMATE_SCAN}
      </ListItem>
    </>
  )

  const getDocuMateIndexingLinks = () => (
    <>
      <ListItem
      active={splittedPath[1].includes('indexing')}
      onClick={() => {
        navigateTo('/documate-indexing')
        setExpansion('none')
      }}
      className={`${classNameForListItems} ${sidebarCollapsed ? '' : 'sidebarExpandedLabel'}`}
      >
        <Icon icon="documateIndexing" />
        {sidebarCollapsed?<br></br>:" "}
        {docuMateLabel.DOCUMATE_INDEXING}
      </ListItem>
    </>
  )

  const getDocuMateSearchLinks = () => (
    <>
      <ListItem
      active={splittedPath[1].includes('search')}
      onClick={() => {
        navigateTo('/documate-search')
        setExpansion('none')
      }}
      className={classNameForListItems}
      >
        <Icon icon="documateSearch" />
        {sidebarCollapsed?<br></br>:" "}
        {docuMateLabel.DOCUMATE_SEARCH}
      </ListItem>
    </>
  )

  const getDocuMateRefiLinks = () => (
    <>
      <ListItem
      active={splittedPath[1].includes('refi')}
      onClick={() => {
        navigateTo('/documate-refi')
        setExpansion('none')
      }}
      className={classNameForListItems}
      >
        <Icon icon="documateRefi" />
        {sidebarCollapsed?<br></br>:" "}
        {docuMateLabel.DOCUMATE_REFI}
      </ListItem>
    </>
  )
 

  return (
    <><div ref={sidebarRef}>
      <nav
        className={"d-none d-md-block bg-light sidebar"}
        style={{ width: sidebarCollapsed ? '80px' : '140px' }}
      >
        <div className="sidebar-sticky">
          <List layout="flush" className="nav flex-column">
            <ListItem
              onClick={handleBurgerMenuClick}
              // {() => 
              //   setSidebarCollpased(!sidebarCollapsed)
              //   // dispatch(updateSidebar())
              // }
              className={classNameForListItems}
            >
              <Icon
                icon={sidebarCollapsed ? 'burger-menu' : 'burger-menu'} />
              &nbsp; CS²    &nbsp; &nbsp;
              {/* {!sidebarCollapsed && (

                <Icon
                  icon="pinned"
                 rotation={pinsideBar?90:null}
                  style={{ color: "white" }}
                  onClick={handlePinClick}>
                </Icon>


              )} */}
            </ListItem>
            {/* {getDashboardLink()} */}
            {getMainDashboardLinks()}
            {isUserPermitToViewCustomer && getCustomerLinks()}
            {/* {getImagingLinks()} */}
            {isUserPermitToViewCampaign && getCampaignLinks()}
            {/* {getMaintenanceLinks()}  */}
            
            { isUserPermitToViewAdmin && getAdminLinks()}
            {/* { getAdminLinks()} */}
            {getDocuMateScanLinks()}
            {getDocuMateIndexingLinks()}
            {getDocuMateRefiLinks()}
            {getDocuMateSearchLinks()}
            {getUserProfile()}
          </List>
          {/* start */}
        <div className={classNameForLogInUser}>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" as="div">
            <div className="userDetails">
              <div className="logo">
                  <span className="fa-layers fa-fw">
                      <Icon icon='circle' size={'2x'} style={{ color: "white"}} />
                      <span className="fa-layers-text fa-inverse logInUserNameInitials" data-fa-transform="shrink-11.5 rotate--30">
                          {getInitials(userName)}
                      </span>
                  </span>
              </div>
              <div className={`logInUserName ${sidebarCollapsed ? 'hidden' : ''}`}>
                  <span>{userName}</span>
              </div>
            </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="sidebar-dropdown-menu">
              <Dropdown.Item className="sidebar-dropdown-item" onClick={() => props.onLogout()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
          {/* end */}
        </div>
      </nav>
    </div><div> {props.handleCallBack(sidebarCollapsed)}</div></>
  )
}

export default Sidebar
