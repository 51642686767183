import { createSlice } from "@reduxjs/toolkit";
import { CustomerScreenTabs } from "../../shared/util/constant";
import { CustomerActivityAddRequest } from "../model/CustomerActivityAddRequest";
import { SelectOption } from "../../shared/components/input";
import Customer from "../model/Customer";
import { CustomerNote } from "../model/CustomerNoteModel";
import { CustomerTaskDto } from "../model/CustomerTaskDto";

export enum SliderDataAction {
    ActivityUpdate = "ActivityUpdate",
    ActivityPolicyListUpdate = "ActivityPolicyListUpdate",
    ActivityStateClear = "ActivityStateClear",
    ActivityFormValidationUpdate = "ActivityFormValidationUpdate",
    NoteUpdate = "NoteUpdate",
    NoteFormValidationUpdate = "NoteFormValidationUpdate",
    ContactUpdate = "ContactUpdate",
    CustomerFormValidationUpdate = "CustomerFormValidationUpdate",
    TaskUpdate = "TaskUpdate",
    TaskFormValidationUpdate = "TaskFormValidationUpdate",
    TaskPolicyListUpdate = "TaskPolicyListUpdate",
}

export interface ISliderPayload {
    type : string;
    data : any;
}

export interface ISliderAction {
    type : string;
    payload : ISliderPayload;
}

export interface ISliderActivityPage {
    activity : CustomerActivityAddRequest;
    formValidation : any;
    policiesForSelect : SelectOption[]
}

export interface ISliderContactPage {
    customer : Customer;
    formValidation : any;
}

export interface ISliderNotePage {
    customerNote : CustomerNote;
    formValidation : any;
}

export interface ISliderTaskPage {
    customerTask : CustomerTaskDto;
    formValidation : any;
    policiesForSelect : SelectOption[]
}

export interface ICustomerSlider {
    currentTab : string;
    activityPage : ISliderActivityPage;
    notePage : ISliderNotePage;
    contactPage : ISliderContactPage;
    taskPage : ISliderTaskPage
}

const initData : ICustomerSlider = {
    currentTab : CustomerScreenTabs.activity.key,
    activityPage : { activity : { activityEffDate : new Date() }, policiesForSelect : [], formValidation : {}},
    notePage : { customerNote : { noteDate : new Date()}, formValidation : {}},
    contactPage : { customer : {}, formValidation : {}},
    taskPage : { customerTask : {}, formValidation : {}, policiesForSelect : []}
};

const CustomerSliderState = createSlice({
    name : "customerSliderState",
    initialState : initData,
    reducers : {
        updateSliderCurrentTab(state, action) {
            state.currentTab = action.payload;
        },
        updateSliderData(state, action : ISliderAction) {
            switch(action.payload.type) {
                // Activity page ops
                case SliderDataAction.ActivityUpdate:
                    state.activityPage.activity = action.payload.data;
                    break;
                case SliderDataAction.ActivityPolicyListUpdate:
                    state.activityPage.policiesForSelect = action.payload.data;
                    break;
                case SliderDataAction.ActivityFormValidationUpdate:
                    state.activityPage.formValidation = action.payload.data;
                    break;

                // Contact pages ops
                case SliderDataAction.ContactUpdate:
                    state.contactPage.customer = action.payload.data;
                    break;
                case SliderDataAction.CustomerFormValidationUpdate:
                    state.contactPage.formValidation = action.payload.data;
                    break;

                // Note page ops
                case SliderDataAction.NoteUpdate:
                    state.notePage.customerNote = action.payload.data;
                    break;
                case SliderDataAction.NoteFormValidationUpdate:
                    state.notePage.formValidation = action.payload.data;
                    break;

                // Task page ops
                case SliderDataAction.TaskUpdate:
                    state.taskPage.customerTask = action.payload.data;
                    break;
                case SliderDataAction.TaskPolicyListUpdate:
                    state.taskPage.policiesForSelect = action.payload.data;
                    break;
                case SliderDataAction.TaskFormValidationUpdate:
                    state.taskPage.formValidation = action.payload.data;
                    break;
            }
        },
        cleareTabsInputs(state) {
            // Clear user inputs on activity page
            state.activityPage.activity = initData.activityPage.activity;
            state.activityPage.formValidation = initData.activityPage.formValidation;

            // Clear user inputs on contact page
            state.contactPage.customer = initData.contactPage.customer;
            state.contactPage.formValidation = initData.contactPage.formValidation;

            // Clear note inputs on notes page
            state.notePage.customerNote = initData.notePage.customerNote;
            state.notePage.formValidation = initData.notePage.formValidation;

            // Clear task inputs on task page
            state.taskPage.customerTask = initData.taskPage.customerTask
            state.taskPage.formValidation = initData.taskPage.formValidation;

            // reset currnt tab.
            state.currentTab = initData.currentTab;
        },
        resetCustomerSliderState(state) {
            state.activityPage = initData.activityPage;
            state.notePage = initData.notePage;
            state.contactPage = initData.contactPage;
            state.notePage = initData.notePage;
            state.taskPage = initData.taskPage;

            // reset currnt tab.
            state.currentTab = initData.currentTab;
        }
    }
});

export const { updateSliderCurrentTab, updateSliderData, cleareTabsInputs, resetCustomerSliderState } = CustomerSliderState.actions
export default CustomerSliderState.reducer;

