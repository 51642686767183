import { AxiosResponse } from "axios";
import { client } from "../../shared/services/client";
import { CustomerTaskDtoReadExtended } from "../model/CustomerTaskDtoRead";
import { CustomerTaskDto } from "../model/CustomerTaskDto";
import EntityHistoryModel from "../model/EntityHistoryModel";

class TaskApi {

    public fetchTasks = async (customerId : string, taskTypeId : string, taskStatusId : string, priorityId : string, userId: string) : Promise<CustomerTaskDtoReadExtended[]> => {
        try {
            client .setBaseURLForService("task")
            const config ={}
            const requestPayload = {
                customerId: customerId,
                statusId: taskStatusId,
                taskTypeId: taskTypeId,
                priorityId: priorityId,
                userId : userId
            };
            const res: AxiosResponse<CustomerTaskDtoReadExtended[]> =  await client.post(`/api/Tasks/SeachTasks`, requestPayload, config);
            return res.data;
        }
        catch(ex) {
            if (ex.status == 404) {
                return [];
            }

            throw ex;
        }
    }

    public createTask = async (customerTaskDto : CustomerTaskDto) => {
        client.setBaseURLForService("task")
        const res:AxiosResponse<any> = await client.post(`/api/Tasks`, customerTaskDto);
        return res.data;
    }

    public udpateTask = async (customerTaskDto : CustomerTaskDto) => {
        client.setBaseURLForService("task")
        const res:AxiosResponse<any> = await client.put(`/api/Tasks/${customerTaskDto.id}`, customerTaskDto);
        return res.data;
    }

    public getTaskHistory = async (taskId : string) : Promise<EntityHistoryModel[]> => {
        client.setBaseURLForService("task")
        const res:AxiosResponse<any> = await client.get<EntityHistoryModel[]>(`/api/Tasks/GetTaskHistory?id=${taskId}`);
        return res.data
    }
}

export default TaskApi;