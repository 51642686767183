import React, { CSSProperties } from 'react'
import BootstrapButton from 'react-bootstrap/Button'

import { ButtonVariant } from '../../interfaces'
import { Icon } from '../Icon'
import { IconType } from '../Icon/interfaces'
import { ButtonType } from './interfaces'

export interface ButtonProps {
  /**
   * Determines if the button should be outlined and not filled. By defaut is false
   * @default false
   * */
  outlined?: boolean

  /**
   *  Defines the button variant. By default is primary
   * @default "primary"
   */
  color?: ButtonVariant

  /**
   * Determines whether or not the button should be a block button or not. By default false
   * @default false
   * */
  block?: boolean

  /**
   * Determines whether or not the button should be disabled or not. By default is false.
   * @default false
   * */
  disabled?: boolean

  /** Determines whether or not the button should be a small or large button. By default is undefined. */
  size?: 'small' | 'large'

  /** The icon to display */
  icon?: IconType

  /**
   * Determines whether or not the icon should display on the left side or right side of the button. By default is left
   * @default left
   * */
  iconLocation?: 'left' | 'right'

  /** Handles the on click event for a button */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  /** The children to render */
  children?: React.ReactNode
  /**
   * Defines the class of the button
   */
  className?: string

  additionalClassName? : string
  /**
   * Defines the style of the button
   */
  style?: CSSProperties
  /**
   * Defines the class of the button.
   */
  iconClassName?: string
  /**
   * Defines the style of the button.
   */
  iconStyle?: CSSProperties
  tabIndex?:number
  id?:string

  title? : string

  /** Handles the on click event for a button */
  keyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
}

function getButtonSize(size: string | undefined): 'sm' | 'lg' | undefined {
  if (size === 'small') {
    return 'sm'
  }
  if (size === 'large') {
    return 'lg'
  }
  return undefined
}

/**
 * Buttons are used to initiate an action.
 */
const Button = (props: ButtonProps) => {
  const {
    size,
    iconLocation,
    outlined,
    color,
    icon,
    block,
    disabled,
    onClick,
    children,
    className,
    additionalClassName,
    style,
    iconClassName,
    iconStyle,
    tabIndex,
    id,
    title,
    keyDown
  } = props

  const displayIconLeft = icon && iconLocation === 'left'
  const displayIconRight = icon && iconLocation === 'right'

  let variant = color as string
  if (outlined) {
    variant = `outline-${variant}`
  }

  return (
    <BootstrapButton
      variant={variant as ButtonType}
      block={block}
      disabled={disabled}
      size={getButtonSize(size)}
      onClick={onClick}
      className={`${className ? className : ''} ${additionalClassName ? additionalClassName : ''}`}
      style={style}
      tabIndex={tabIndex}
      id={id}
      name={id}
      onKeyDown={keyDown}
    >
      {displayIconLeft && (
        <Icon icon={icon as IconType} className={iconClassName} style={iconStyle} title={title}/>
      )}{' '}
      {children}{' '}
      {displayIconRight && (
        <Icon icon={icon as IconType} className={iconClassName} style={iconStyle} />
      )}
    </BootstrapButton>
  )
}

Button.defaultProps = {
  color: 'primary',
  iconLocation: 'left',
}

export { Button }
