import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom'
import CustomerDetailService from "./CustomerDetailService";
import Customer from "../model/Customer";
import {Row } from "react-bootstrap";
import { Button, Checkbox, Column, Panel } from "../../components";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { SelectOption } from "../../shared/components/input";
import CustomerDependentView from "./CustomerDependentView";
import { CustomerScreentLables as cstScrLables } from "../../shared/util/label";
import { toast } from "react-toastify";
import * as constant from '../../shared/util/constant';
import './CustomerDetail.scss';
import { useDispatch, useSelector } from "react-redux";
import { updateCustomers } from "../customer-slice/Customer-slice";
import { updateLookupData } from "../customer-slice/Customer-lookup-slice"
import { RootState } from "../../shared/store";
import { uuid } from "../../shared/util/uuid";
import { CustomerDependent } from "../model/CustomerDependentModel";
import FormattedInputWithLableInline, { InputFormatType } from "../../shared/components/input/formatted-input/FormattedInputWithLableInline";
import Logger from "../../shared/services/Logger";
import CustomerCompanyContactView from "./CustomerCompanyContactView";
import { CustomerCompanyContact } from "../model/CustomerCompanyContactModel";
import * as label from '../../shared/util/label';
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import AppContext from "../../shared/components/AppContext";
import { cleareTabsInputs } from "../customer-slider-content/CustomerSliderContext";
import { AbilityContext } from "../../shared/abilities/AbilityContext";

export interface ICustomerDetailComponent
{
    customerId : string,
    isCreatingNewCustomer : boolean
}

const CustomerDetail : React.FunctionComponent<ICustomerDetailComponent> = (props) => {
    const history = useHistory();
    const customerDetailService = new CustomerDetailService();
    const id   = props.customerId;
    const isCreatingNewCustomer = props.isCreatingNewCustomer;

    // Customer details to show.
    const [customerDetail, setCustomerDetail] = useState<Customer>({});
    const [intialCustomerDetail, setInitialCustomerDetail] = useState<Customer>({});

    // Lookup data states.
    const [maritalStatuses, setMaritalStatuses] = useState<SelectOption[]>([]);
    const [industries, setIndustries] = useState<SelectOption[]>([]);
    const [occupancies, setOccupancies] = useState<SelectOption[]>([]);
    const [relationships, setRelationShips] = useState<SelectOption[]>([]);
    const [agencies, setAgencies] = useState<SelectOption[]>([]);
    const [genders] = useState<SelectOption[]>(customerDetailService.getGenderList());
    const [states, setStates] = useState<SelectOption[]>([]);

    const [formValidation, setFormValidation] = useState<any>({});
    const [isExpended,setIsExpended] =useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [reset, isReset] = useState(false);
    const [isFormSaved, setFormSaved] = useState(true);
    const [isBillingInfoVisible,setIsBillingInfoVisible] =useState(customerDetail?.isBillAddressSame);
    const modalContext = useConfirmationModalContext();
    const myContext = useContext(AppContext);
    const divToScroll = useRef(null)

    const ab_subject = "Customer";
    const ability = useContext(AbilityContext);
    const canUserEditCustomer = ability.can('update',ab_subject,constant.ScreenName.CUSTOMER);
    const canUserDeleteCustomer = ability.can('delete',ab_subject,constant.ScreenName.CUSTOMER);

    /// Get customer and lookup from redux slicer.
    const dispatch = useDispatch()
    let customerInfo = useSelector((state: RootState) => state?.customerSlice);
    let maritalStatusLookUp = useSelector((state: RootState) => state?.LookupState.maritalStatusList);
    let industryLookup = useSelector((state: RootState) => state?.LookupState.industryList);
    let occupationLookup = useSelector((state: RootState) => state?.LookupState.occupancyList);
    let relatioshipLookup = useSelector((state: RootState) => state?.LookupState.relationshipList);
    let agencyLookup = useSelector((state: RootState) => state?.LookupState.agencyList);
    let stateLookup = useSelector((state: RootState) => state?.LookupState.stateList);

    useEffect(() => {
        if(!isCreatingNewCustomer && customerInfo.customer.id === id && (customerInfo.customer.id !== null && customerInfo.customer.id !== undefined)){
            setCustomerDetail(customerInfo.customer)
        }
      }, [customerInfo]);

    useEffect(() => {
        initializeComponent(id);
      }, [id]);
      useEffect(() => {
        myContext.formSavedStatus = isFormSaved
    }, [isFormSaved])

    /// Method to intialize component.
    let initializeComponent = async (customerId : string) => {

        try 
        {
            /// Check if customer object available in redux slicer, if not fetch it from service.
            let customerData= getNewCustomer();
            if (!isCreatingNewCustomer) {
                customerData=customerInfo.customer;
                if(customerInfo.customer.id !== id || customerInfo.customer.id === null || customerInfo.customer.id === undefined){ 
                    customerData = await customerDetailService.fetchCustomerById(customerId);
                    dispatch(updateCustomers(customerData))
                }
            }
            else {
                EditCustomer();
            }

             let initData ={maritalStatusList:[], industryList :[], occupancyList :[], relationshipList : [], agencyList : [], stateList : []};
             if(maritalStatusLookUp.length === 0 || 
                industryLookup.length === 0 || occupationLookup.length === 0 || 
                relatioshipLookup.length === 0 || agencyLookup.length == 0 ||stateLookup.length === 0 ) {
              initData = await customerDetailService.fetchComponentInitData();
              dispatchAllLookup(initData)
             }
             else{
                initData = {maritalStatusList: [...maritalStatusLookUp], industryList : [...industryLookup], occupancyList : [...occupationLookup], relationshipList : [...relatioshipLookup], agencyList : [...agencyLookup], stateList : [...stateLookup] }
             }
           
            setMaritalStatuses(initData.maritalStatusList);
            setIndustries(initData.industryList);
            setOccupancies(initData.occupancyList);
            setRelationShips(initData.relationshipList);
            setAgencies(initData.agencyList);
            setStates(initData.stateList)
            setCustomerDetail(customerData);
            setInitialCustomerDetail(customerData)
           
        }
        catch(ex)
        {
            toast.error(constant.CUSTOMER_DETAIL_ERROR_WHILE_LOADING_INITIAL_DATA, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const getNewCustomer = () : Customer => {
        return {
            customerDependents : [],
            customerContacts : [],
        };
    }

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        setCustomerDetail({
          ...customerDetail,
          [propName]: value,
        });

        if (propName == 'firstName' || propName == 'lastName') {
            setCustomerDetail(cst => {
                return {
                ...cst,
                displayName: (cst.firstName ? cst.firstName : '') + ' ' + (cst.lastName ? cst.lastName : ''),
                };
              });
        }
    }

    const toggleEntityField = (propName: string) => {
        
        setCustomerDetail({
          ...customerDetail,
          [propName]: !customerDetail[propName],
        });
    }
    const populateBillingAddress=(event)=>{
        if(event.currentTarget.checked){
            setCustomerDetail({
                ...customerDetail,
                isBillAddressSame : !customerDetail.isBillAddressSame,
                billAddress1: customerDetail.address1,
                billAddress2 : customerDetail.address2,
                billCity : customerDetail.city,
                billCounty :customerDetail.county,
                billState : customerDetail.state,
                billZip : customerDetail.zipCode,
                
            }) 
        }
        else{
        setCustomerDetail({
            ...customerDetail,
            isBillAddressSame : !customerDetail.isBillAddressSame,
            billAddress1: "",
            billAddress2 : "",
            billCity : "",
            billCounty :"",
            billState : "",
            billZip : "",
    })
}
}

    // Update a collection property value. For example any of the dependent information changed in child component. 
    // param : collectionPropName                   -- Name of the navigation collection property. Example for customer.customerDependents, it's 'customerDependents'
    // param : collectionEntityIdentifierPropName   -- Name of the navigation collection property identifier. Example for customer.customerDependents, it's 'deptId'
    // param : collectionEntityIdentifierValue      -- Identifier for naviation collection prop. here it depdId value.
    // param : propName                             -- Name of navigation entity prop, which is changed. like 'firstName', 'lastName', 'relationshipId' etc.
    // param : value                                -- value changed.
    const onCollectionEntityFieldChange = (collectionPropName : string, collectionEntityIdentifierPropName : string, collectionEntityIdentifierValue : any, propName: string, value: string | Date|boolean) => {
        let subCollectionEntityIndex = customerDetail[collectionPropName].findIndex(item => item[collectionEntityIdentifierPropName] == collectionEntityIdentifierValue);
        let updatedSubEntity = {
            ...customerDetail[collectionPropName][subCollectionEntityIndex],
            [propName]: value,
            isModified : true
        };

        let collectionCopy = [...customerDetail[collectionPropName]];
        collectionCopy.splice(subCollectionEntityIndex,1,updatedSubEntity);
        
        setCustomerDetail(cst => {
            var updatedObj = {
                ...cst,
                [collectionPropName] : collectionCopy
            };
            return updatedObj;
          });
    }
        const scrollToNewSection=()=>{
            divToScroll.current && divToScroll.current.scrollIntoView({ behavior: 'smooth' })
        }
    const onAddDependentClick = (e) => {
        let panelHtml = e.currentTarget.closest('div') as HTMLDivElement
        panelHtml.click();
        setCustomerDetail(cust => {
            let custData = { ...cust};
            custData.customerDependents = [...custData.customerDependents, { depdId : uuid()}];
            return custData;
        });
        
        setTimeout(() => {
            scrollToNewSection();
        }, 500)
       
       e.stopPropagation()
    }

    const onAddCompanyContactClick =(e) => {
        let panelHtml = e.currentTarget.closest('div') as HTMLDivElement
        panelHtml.click();
        setCustomerDetail(cust => {
            let custData = { ...cust};
            custData.customerContacts = [...custData.customerContacts, { id : uuid()}];
            return custData;
        });
        setTimeout(() => {
            scrollToNewSection();
        }, 500)
        e.stopPropagation()
    }

    const onDependentDelete = (dependentToDelete : CustomerDependent) => {
        setCustomerDetail(cust => {
            let custData = { ...cust};
            custData.customerDependents = custData.customerDependents.filter(item => item.depdId != dependentToDelete.depdId);
            return custData;
        });
    }

    const onDeleteContact = (contactToDelete : CustomerCompanyContact) => {
        setCustomerDetail(cust => {
            let custData = { ...cust};
            custData.customerContacts = custData.customerContacts.filter(item => item.id != contactToDelete.id);
            return custData;
        });
    }

    const customerDependentsPanelHeaderItems = <><Button id={"btnAddDependent"} disabled={!isEditable} onClick={(e)=>{onAddDependentClick(e);}}>{cstScrLables.ADD_ADDITIONAL_CUSTOMER_BUTTON}</Button></>;
    const customerCompanyContsctsPanelHeaderItems = <><Button id={"btnAddCntact"} disabled={!isEditable} onClick={(e)=>{onAddCompanyContactClick(e);}}>{cstScrLables.ADD_COMPANY_CONTACT}</Button></>;
    
    const customerDependentView = customerDetail.customerDependents?.map(cd => {
        return <CustomerDependentView 
                        key={cd.depdId} 
                        dependent={cd} 
                        maritalStatuses={maritalStatuses}
                        industries={industries}
                        occupancies={occupancies}
                        onFieldChange={onCollectionEntityFieldChange}
                        onDelete={onDependentDelete}
                        formValidations={formValidation['customerDependents'] ? formValidation['customerDependents'][cd.depdId] : {}}
                        relationships={relationships}
                        isEditable = {isEditable}
                        isReset ={reset}
                        divToScroll={divToScroll}>

                        </CustomerDependentView>;
    });

    const customerContactView = customerDetail.customerContacts?.map(ct => {
        return <CustomerCompanyContactView 
                        key={ct.id} 
                        contact={ct} 
                        state={states}
                        onFieldChange={onCollectionEntityFieldChange}
                        onDelete={onDeleteContact}
                        formValidations={formValidation['customerContacts'] ? formValidation['customerContacts'][ct.id] : {}}
                        isEditable = {isEditable}
                        isReset ={reset}
                        divToScroll={divToScroll}
                      ></CustomerCompanyContactView>;
    });

    
    const contactInoLg = 4;
    const contactInoMd = 4;

    const dispatchAllLookup = (initData) =>{
        dispatch(updateLookupData({data : initData.maritalStatusList, type : constant.SlicerLookUpConstant.MARITAL_STATUS}))
        dispatch(updateLookupData({data : initData.industryList, type : constant.SlicerLookUpConstant.INDUSTRY} ))
        dispatch(updateLookupData({data : initData.occupancyList, type : constant.SlicerLookUpConstant.OCCUPATION}))
        dispatch(updateLookupData({data : initData.agencyList , type : constant.SlicerLookUpConstant.AGENCY}))
        dispatch(updateLookupData({data : initData.relationshipList, type : constant.SlicerLookUpConstant.RELATIONSHIP}))
        dispatch(updateLookupData({data : initData.stateList, type : constant.SlicerLookUpConstant.STATE}))
    }
const EditCustomer =() =>{
    setIsEditable(true)
    setFormSaved(false);
    isReset(false)
}

const deleteCustomer = async () => {
    try {

        let validationResp = await customerDetailService.canDeleteCustomer(id);

        if (validationResp != "CUSTOMER_CAN_BE_DELETED") {
            let errorMessage = validationResp;
            switch (validationResp) {
                case constant.CUSTOMER_DELETE_INCOMPLETE_TASKS_RESPONSE:
                    errorMessage = constant.CUSTOMER_DELETE_ACTIVE_TASK;
                    break;
                case constant.CUSTOMER_DELETE_ACTIVE_POLICIES_RESPONSE:
                    errorMessage = constant.CUSTOMER_DELETE_ACTIVE_POLICIES;
                    break;
                case constant.CUSTOMER_DELETE_INACTIVE_POLICIES_IN_LAST_ONE_YEAR_RESPONSE:
                    errorMessage = constant.CUSTOMER_DELETE_INACIVE_POLICY_IN_LAST_ONE_YEAR;
                    break;
                default:
                    errorMessage = constant.CUSTOMER_DELETE_ERROR;
                    break;
                }

            toast.warn(errorMessage, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let resp: any = await modalContext.showConfirmation(constant.CUSTOMER_DELETE_CONFIRM, constant.CUSTOMER_DELETE_CONFIRM_TITLE, constant.CUSTOMER_DELETE_CONFIRM_TEXT, constant.CUSTOMER_DELETE_CONFIRMATION_TEXT);

        if (!resp.result || resp.data?.toString() != constant.CUSTOMER_DELETE_CONFIRMATION_TEXT) {
            return;
        }

        await customerDetailService.deleteCustomer(id);

        toast.success(constant.CUSTOMER_DELETE_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT
        });

        history.push('/customer/customerfilter');
    }
    catch(ex) {
        if (ex.status == 404) {
            toast.error(constant.CUSTOMER_NOT_FOUND, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(constant.CUSTOMER_DELETE_ERROR, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
}

const CancelEdit =async() =>{
    const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
    if(result){
        setFormValidation(0)
    setCustomerDetail(intialCustomerDetail)
    isReset(true)
    setIsEditable(false)
    setFormSaved(true);

    if (isCreatingNewCustomer) {
        history.push('/customer/customerfilter');
    }

     }
}


    const SaveCustomer = async () => {
        try {
            let validationError = customerDetailService.validateCustomer(customerDetail);
            setFormValidation(validationError);
            if (Object.keys(validationError).length > 0) {
                toast.warn(constant.CUSTOMER_FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            if(customerDetail.isBillAddressSame){
                customerDetail.billAddress1 = customerDetail.address1,
                customerDetail.billAddress2 = customerDetail.address2,
                customerDetail.billCity = customerDetail.city,
                customerDetail.billCounty = customerDetail.county,
                customerDetail.billState = customerDetail.state,
                customerDetail.billZip = customerDetail.zipCode
            }
            let updatedCustomer = await customerDetailService.saveCustomer(customerDetail);
            if (updatedCustomer) {
                dispatch(cleareTabsInputs());
                toast.success(constant.CUSTOMER_SAVED_SUCCESSFULLY, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setIsEditable(false)
                setFormSaved(true);
                setCustomerDetail(updatedCustomer)
                setInitialCustomerDetail(updatedCustomer)
                dispatch(updateCustomers(updatedCustomer));
                history.push(`/customer-view/customer/${updatedCustomer.id}`);
            }
            else {
                toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        catch (e) {
            Logger.logError(constant.CUSTOMER_ERROR_WHILE_SAVING, e);
            if (e.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    /// JSX....
    return (
        <>
        <div className="col-lg-12 col-md-12 col-sm-12" style={{height:"710px", overflowX:"auto", scrollbarWidth:"thin" }}>
            <Row>
                <Column lg={12} md={12}>
                    <Panel
                        title={cstScrLables.CUSTOMER_INFORMATION}
                        className={'customerPanel cstPanel customerOverview'}
                        collapsible = {true}>
                            <Row>
                                <Column lg={9} md={9} className="section-height">
                                    <Row>
                                        <Column lg={contactInoLg} md={contactInoMd}
                                                className={formValidation['firstName']?.isValid == false ? 'feedback-row' : ''}>
                                                <TextInputWithLabelInline
                                                    name='firstName'
                                                    label={cstScrLables.FIRST_NAME}
                                                    placeholder={' '}
                                                    value={customerDetail.firstName??""}
                                                    isEditable={isEditable}
                                                    onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    isRequired
                                                    tabIndex={1}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={60}
                                                    isInvalid={formValidation['firstName']?.isValid == false}
                                                    invalidWarning={formValidation['firstName']?.warningText}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <TextInputWithLabelInline
                                                    name='midName'
                                                    label={cstScrLables.MIDDLE_NAME}
                                                    placeholder={' '}
                                                    value={customerDetail.midName??""}
                                                    onChange={(event) => onEntityFieldChange('midName', event.currentTarget.value)}
                                                    isEditable={isEditable}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    tabIndex={2}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={60}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}
                                                className={formValidation['lastName']?.isValid == false ? 'feedback-row' : ''}>
                                                <TextInputWithLabelInline
                                                    name='lastName'
                                                    label={cstScrLables.LAST_NAME}
                                                    placeholder={' '}
                                                    value={customerDetail.lastName??""}
                                                    onChange={(event) => onEntityFieldChange('lastName', event.currentTarget.value)}
                                                    isEditable={isEditable}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    tabIndex={3}
                                                    lglabel={4}
                                                    isRequired
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={60}
                                                    isInvalid={formValidation['lastName']?.isValid == false}
                                                    invalidWarning={formValidation['lastName']?.warningText}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={8} md={8}className={formValidation['displayName']?.isValid == false ? 'feedback-row' : ''}>
                                                <TextInputWithLabelInline
                                                    name='displayName'
                                                    label={cstScrLables.DISPLAY_NAME}
                                                    placeholder={' '}
                                                    value={customerDetail.displayName??""}
                                                    onChange={(event) => onEntityFieldChange('displayName', event.currentTarget.value)}
                                                    isEditable={isEditable}
                                                    type='text'
                                                    isRequired
                                                    labelClass='inlineLable'
                                                    tabIndex={4}
                                                    lglabel={2}
                                                    mdlabel={2}
                                                    lgText={10}
                                                    mdText={10}
                                                    maxLength={100}
                                                    className='texbox-input'
                                                    textInputContainerClass='texbox-input-container'
                                                    isInvalid={formValidation['displayName']?.isValid == false}
                                                    invalidWarning={formValidation['displayName']?.warningText}
                                                    >
                                                </TextInputWithLabelInline>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <DropDownWithLabelInline
                                                    id='ddGenderList'
                                                    label={cstScrLables.GENDER}
                                                    options={genders}
                                                    defaultSelected={genders.filter(({ value }) => value === customerDetail.sex)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('sex', values[0])
                                                        }
                                                    }
                                                    labelSize={4}
                                                    disabled={!isEditable}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={9}
                                                />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <DropDownWithLabelInline
                                                    id='ddIndustryId'
                                                    label={cstScrLables.INDUSTRY}
                                                    options={industries}
                                                    defaultSelected={industries.filter(({ value }) => value === customerDetail.industryId)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('industryId', values[0])
                                                        }
                                                    }
                                                    disabled={!isEditable}
                                                    labelSize={4}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={5}
                                                />
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <DropDownWithLabelInline
                                                    id='ddOccupation'
                                                    label={cstScrLables.OCCUPATION}
                                                    options={occupancies}
                                                    defaultSelected={occupancies.filter(({ value }) => value === customerDetail.occupationId)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('occupationId', values[0])
                                                        }
                                                    }
                                                    labelSize={4}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={6}
                                                    disabled={!isEditable}
                                                />
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}
                                                className={formValidation['yearEmployed']?.isValid == false ? 'feedback-row' : ''}>
                                                <TextInputWithLabelInline
                                                    name='yearEmployed'
                                                    label={cstScrLables.YEAR_EMPLOYED}
                                                    placeholder={' '}
                                                    value={customerDetail.yearEmployed??""}
                                                    onChange={(event) => onEntityFieldChange('yearEmployed', event.currentTarget.value)}
                                                    isEditable={isEditable}
                                                    type='number'
                                                    labelClass='inlineLable'
                                                    tabIndex={7}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={4}
                                                    isInvalid={formValidation['yearEmployed']?.isValid == false}
                                                    invalidWarning={formValidation['yearEmployed']?.warningText}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                    </Row>
                                    <Row className="cstErrorTopMargin">
                                        <Column lg={contactInoLg} md={contactInoMd}
                                                className={formValidation['agencyId']?.isValid == false ? 'feedback-row' : ''}>
                                                <DropDownWithLabelInline
                                                    id='ddAgencyId'
                                                    label={cstScrLables.AGENCY}
                                                    options={agencies}
                                                    defaultSelected={agencies.filter(({ value }) => value === customerDetail.agencyId)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('agencyId', values[0])
                                                        }
                                                    }
                                                    labelSize={4}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={9}
                                                    isRequired
                                                    disabled={!isEditable}
                                                    isInvalid={formValidation['agencyId']?.isValid == false}
                                                    invalidWarning={formValidation['agencyId']?.warningText}
                                                />
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <DropDownWithLabelInline
                                                    id='ddMaritalStatus'
                                                    label={cstScrLables.MARITAL_STATUS}
                                                    options={maritalStatuses}
                                                    defaultSelected={maritalStatuses.filter(({ value }) => value === customerDetail.maritalStatusId)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('maritalStatusId', values[0])
                                                        }
                                                    }
                                                    labelSize={4}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={9}
                                                    disabled={!isEditable}
                                                />
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                            <Checkbox
                                                        id='chkVip'
                                                        label={cstScrLables.VIP}
                                                        checked={customerDetail.vip}
                                                        onChange={(event) => toggleEntityField('vip')}
                                                        disabled={!isEditable}
                                                        checkboxStyle={{ position: "relative", marginRight: "5px" }}/>
                                        </Column>
                                    </Row>
                            
                                <br/>
                                <Panel
                                title={'Mailing Information'}
                                className={'customerPanel cstPanel nested-panel-header'}
                                >
                                <Row className="cstErrorTopMargin">
                                    <Column lg={contactInoLg} md={contactInoMd} className={formValidation['address1']?.isValid == false ? 'feedback-row' : ''}>
                                            <TextInputWithLabelInline
                                                name='address1'
                                                label={cstScrLables.STREET}
                                                placeholder={' '}
                                                value={customerDetail.address1??""}
                                                onChange={(event) => onEntityFieldChange('address1', event.currentTarget.value)}
                                                isEditable={isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                tabIndex={10}
                                                lglabel={4}
                                                isRequired
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={100}
                                                className='texbox-input'
                                                isInvalid={formValidation['address1']?.isValid == false}
                                                invalidWarning={formValidation['address1']?.warningText}>
                                            </TextInputWithLabelInline>
                                    </Column>
                                    <Column lg={contactInoLg} md={contactInoMd}>
                                            <TextInputWithLabelInline
                                                name='address2'
                                                label={cstScrLables.APARTMENT_OR_UNIT}
                                                placeholder={' '}
                                                value={customerDetail.address2??""}
                                                onChange={(event) => onEntityFieldChange('address2', event.currentTarget.value)}
                                                isEditable={isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                tabIndex={11}
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={100}
                                                className='texbox-input'>
                                            </TextInputWithLabelInline>
                                    </Column>
                                    <Column lg={contactInoLg} md={contactInoMd} className={formValidation['city']?.isValid == false ? 'feedback-row' : ''}>
                                            <TextInputWithLabelInline
                                                name='city'
                                                label={cstScrLables.CITY}
                                                placeholder={' '}
                                                value={customerDetail.city??""}
                                                onChange={(event) => onEntityFieldChange('city', event.currentTarget.value)}
                                                isEditable={isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                isRequired
                                                tabIndex={12}
                                                lglabel={4}
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={100}
                                                className='texbox-input'
                                                isInvalid={formValidation['city']?.isValid == false}
                                                invalidWarning={formValidation['city']?.warningText}>
                                            </TextInputWithLabelInline>
                                    </Column>
                                </Row>
                                <Row className="cstErrorTopMargin">
                                    <Column lg={contactInoLg} md={contactInoMd}>
                                                <DropDownWithLabelInline
                                                    id='ddState'
                                                    label={cstScrLables.STATE}
                                                    options={states}
                                                    defaultSelected={states?.filter(({ value }) => value === customerDetail.state)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('state', values[0])
                                                        }
                                                    }
                                                    labelSize={4}
                                                    disabled={!isEditable}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={13}
                                                />
                                    </Column>
                                    <Column lg={contactInoLg} md={contactInoMd} className={formValidation['zipCode']?.isValid == false ? 'feedback-row' : ''}>
                                            <TextInputWithLabelInline
                                                name='zipCode'
                                                label={cstScrLables.ZIP}
                                                placeholder={' '}
                                                value={customerDetail.zipCode??""}
                                                onChange={(event) => onEntityFieldChange('zipCode', event.currentTarget.value)}
                                                isEditable={isEditable}
                                                type='text'
                                                labelClass='inlineLable'
                                                tabIndex={14}
                                                lglabel={4}
                                                isRequired
                                                mdlabel={4}
                                                lgText={8}
                                                mdText={8}
                                                maxLength={9}
                                                className='texbox-input'
                                                isInvalid={formValidation['zipCode']?.isValid == false}
                                                invalidWarning={formValidation['zipCode']?.warningText}>
                                            </TextInputWithLabelInline>
                                    </Column>
                                    <Column lg={contactInoLg} md={contactInoMd}>
                                    <Checkbox
                                                    id='chkIsBillAddressSame'
                                                    label={cstScrLables.SAME_AS_MAILING}
                                                    checked={customerDetail.isBillAddressSame}
                                                    onChange={(event) => populateBillingAddress(event) }
                                                    disabled={!isEditable}
                                                    checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                                />
                                    </Column>
                                </Row>
                                </Panel>
                                <Panel
                                    title={'Billing Information'}
                                    className={`${customerDetail.isBillAddressSame===true ? 'hidden' : '' } customerPanel cstPanel nested-panel-header`}
                                    //className={'customerPanel cstPanel nested-panel-header'}
                                    >
                                       
                                    <Row>
                                            <Column lg={contactInoLg} md={contactInoMd}>
                                                <TextInputWithLabelInline
                                                    name='billAddress1'
                                                    label={cstScrLables.STREET}
                                                    placeholder={' '}
                                                    value={customerDetail.billAddress1??""}
                                                    onChange={(event) => onEntityFieldChange('billAddress1', event.currentTarget.value)}
                                                    isEditable={customerDetail.isBillAddressSame===true?false:isEditable}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    tabIndex={15}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={100}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <TextInputWithLabelInline
                                                    name='billAddress2'
                                                    label={cstScrLables.APARTMENT_OR_UNIT}
                                                    placeholder={' '}
                                                    value={customerDetail.billAddress2??""}
                                                    onChange={(event) => onEntityFieldChange('billAddress2', event.currentTarget.value)}
                                                    isEditable={customerDetail.isBillAddressSame===true?false:isEditable}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    tabIndex={16}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={100}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <TextInputWithLabelInline
                                                    name='billCity'
                                                    label={cstScrLables.CITY}
                                                    placeholder={' '}
                                                    value={customerDetail.billCity??""}
                                                    onChange={(event) => onEntityFieldChange('billCity', event.currentTarget.value)}
                                                    isEditable={customerDetail.isBillAddressSame===true?false:isEditable}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    tabIndex={17}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={100}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <DropDownWithLabelInline
                                                    id='billState'
                                                    label={cstScrLables.STATE}
                                                    options={states}
                                                    defaultSelected={states?.filter(({ value }) => value === customerDetail.billState)}
                                                    onChange={
                                                        (values) => {
                                                            onEntityFieldChange('billState', values[0])
                                                        }
                                                    }
                                                    labelSize={4}
                                                    disabled={!(customerDetail.isBillAddressSame === true ? false : isEditable)}
                                                    dropDownSize={8}
                                                    labelClass='inlineLable'
                                                    placeholder={' '}
                                                    multiple={false}
                                                    tabIndex={18}
                                                />                     
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                <TextInputWithLabelInline
                                                    name='billZip'
                                                    label={cstScrLables.ZIP}
                                                    placeholder={' '}
                                                    value={customerDetail.billZip??""}
                                                    onChange={(event) => onEntityFieldChange('billZip', event.currentTarget.value)}
                                                    isEditable={customerDetail.isBillAddressSame===true?false:isEditable}
                                                    type='text'
                                                    labelClass='inlineLable'
                                                    tabIndex={19}
                                                    lglabel={4}
                                                    mdlabel={4}
                                                    lgText={8}
                                                    mdText={8}
                                                    maxLength={9}
                                                    className='texbox-input'>
                                                </TextInputWithLabelInline>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                                {/* <Checkbox
                                                    id='chkIsBillAddressSame'
                                                    label={cstScrLables.SAME_AS_MAILING}
                                                    checked={customerDetail.isBillAddressSame}
                                                    onChange={(event) => toggleEntityField('isBillAddressSame')}
                                                    disabled={!isEditable}
                                                    checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                                /> */}
                                        </Column>
                                    </Row> 
                                

                                </Panel>
                                </Column>
                                <Column lg={3} md={3} className="section-height">
                            <Panel
                                title={'Contact Information'}
                                className={'customerPanel cstPanel'}>
                                    <Row>
                                        <Column lg={6} md={6}>
                                            <Checkbox
                                                id='chkmailOptOut'
                                                label={cstScrLables.DO_NOT_MAIL}
                                                checked={customerDetail.mailOptOut}
                                                onChange={(event) => toggleEntityField('mailOptOut')}
                                                disabled={!isEditable}
                                                checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                            />
                                        </Column>
                                        <Column lg={6} md={6}>
                                            <Checkbox
                                                id='chkcallOptOut'
                                                label={cstScrLables.DO_NOT_CALL}
                                                checked={customerDetail.callOptOut}
                                                onChange={(event) => toggleEntityField('callOptOut')}
                                                disabled={!isEditable}
                                                checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={6} md={6}>
                                            <Checkbox
                                                id='chkemailOptOut'
                                                label={cstScrLables.DO_NOT_EMAIL}
                                                checked={customerDetail.emailOptOut}
                                                onChange={(event) => toggleEntityField('emailOptOut')}
                                                disabled={!isEditable}
                                                checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                            />
                                        </Column>
                                        <Column lg={6} md={6}>
                                            <Checkbox
                                                id='chkeddOptIn'
                                                label={cstScrLables.EDD_OPT_IN}
                                                checked={customerDetail.eddOptIn}
                                                onChange={(event) => toggleEntityField('eddOptIn')}
                                                disabled={!isEditable}
                                                checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={6} md={6}>
                                            <Checkbox
                                                id='chksmsOptOut'
                                                label={cstScrLables.SMS_OPT_OUT}
                                                checked={customerDetail.smsOptOut}
                                                onChange={(event) => toggleEntityField('smsOptOut')}
                                                disabled={!isEditable}
                                                checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                            />
                                        </Column>
                                    </Row>
                            </Panel>  
                            <Panel 
                                className={'customerPanel cstPanel'}>
                                <Row>
                                    <Column lg={12} md={12} className={formValidation['Phone']?.isValid == false ? 'feedback-row' : ''}>
                                        <FormattedInputWithLableInline
                                            name='Phone'
                                            label= {cstScrLables.CELL_PHONE} 
                                            value={customerDetail.mobile??""}
                                            placeholder={' '}
                                            isEditable ={isEditable}
                                            type='tel'
                                            labelClass='inlineLable'
                                            onValueChange={(newVal) => onEntityFieldChange('mobile', newVal)}
                                            tabIndex={20}
                                            lglabel={4}
                                            mdlabel={4}
                                            lgText={8}
                                            mdText={8}
                                            maxLength={15}
                                            className='texbox-input'
                                            inputFormatType={InputFormatType.Phone}
                                            isInvalid={formValidation['Phone']?.isValid == false}
                                            invalidWarning={formValidation['Phone']?.warningText}>
                                        </FormattedInputWithLableInline>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={12} md={12} className={formValidation['homePhone']?.isValid == false ? 'feedback-row' : ''}>
                                        <FormattedInputWithLableInline
                                            name='homePhone'
                                            label={cstScrLables.HOME_PHONE}
                                            placeholder={' '}
                                            value={customerDetail.homePhone??""}
                                            onValueChange={(newVal) => onEntityFieldChange('homePhone', newVal)}
                                            isEditable={isEditable}
                                            type='text'
                                            labelClass='inlineLable'
                                            tabIndex={21}
                                            lglabel={4}
                                            mdlabel={4}
                                            lgText={8}
                                            mdText={8}
                                            maxLength={15}
                                            className='texbox-input'
                                            inputFormatType={InputFormatType.Phone}
                                            isInvalid={formValidation['homePhone']?.isValid == false}
                                            invalidWarning={formValidation['homePhone']?.warningText}>
                                        </FormattedInputWithLableInline>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={12} md={12} className={formValidation['workPhone']?.isValid == false ? 'feedback-row' : ''}>
                                        <FormattedInputWithLableInline
                                        name='workPhone'
                                        label={cstScrLables.WORK_PHONE}
                                        placeholder={' '}
                                        value={customerDetail.workPhone??""}
                                        onValueChange={(newVal) => onEntityFieldChange('workPhone', newVal)}
                                        isEditable={isEditable}
                                        type='text'
                                        labelClass='inlineLable'
                                        tabIndex={22}
                                        lglabel={4}
                                        mdlabel={4}
                                        lgText={8}
                                        mdText={8}
                                        maxLength={15}
                                        className='texbox-input'
                                        inputFormatType={InputFormatType.Phone}
                                        isInvalid={formValidation['workPhone']?.isValid == false}
                                        invalidWarning={formValidation['workPhone']?.warningText}>
                                        </FormattedInputWithLableInline>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['eMail']?.isValid == false ? 'feedback-row' : ''}>
                                        <TextInputWithLabelInline
                                            name='eMail'
                                            label={cstScrLables.EMAIL_1}
                                            placeholder={' '}
                                            value={customerDetail.eMail??""}
                                            onChange={(event) => onEntityFieldChange('eMail', event.currentTarget.value)}
                                            isEditable={isEditable}
                                            type='email'
                                            labelClass='inlineLable'
                                            tabIndex={23}
                                            lglabel={4}
                                            mdlabel={4}
                                            lgText={8}
                                            mdText={8}
                                            maxLength={150}
                                            isInvalid={formValidation['eMail']?.isValid == false}
                                            invalidWarning={formValidation['eMail']?.warningText}
                                            className='texbox-input'>
                                        </TextInputWithLabelInline>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['eMail2']?.isValid == false ? 'feedback-row' : ''}>
                                        <TextInputWithLabelInline
                                            name='eMail2'
                                            label={cstScrLables.EMAIL_2}
                                            placeholder={' '}
                                            value={customerDetail.eMail2??""}
                                            onChange={(event) => onEntityFieldChange('eMail2', event.currentTarget.value)}
                                            isEditable={isEditable}
                                            type='email'
                                            labelClass='inlineLable'
                                            tabIndex={23}
                                            lglabel={4}
                                            mdlabel={4}
                                            lgText={8}
                                            mdText={8}
                                            maxLength={150}
                                            isInvalid={formValidation['eMail2']?.isValid == false}
                                            invalidWarning={formValidation['eMail2']?.warningText}
                                            className='texbox-input'>
                                        </TextInputWithLabelInline>
                                    </Column>
                                </Row>
                            </Panel>
                                </Column>
                        </Row>
                    </Panel>
                   
                </Column>
               
            </Row>
            <Row>
                <Column  lg={12} md={12}>
                    <Panel  title={cstScrLables.ADDITIONAL_CUSTOMER_INFORMATION}
                        className={'customerPanel cstPanel cstCustAlign'}
                        panelHeaderItems={customerDependentsPanelHeaderItems}
                        cardBodyClassName='dpd-crd-body'
                         collapsible = {true}>
                        {customerDependentView}
                    </Panel>
                </Column>
            </Row>
            <Row>
                <Column  lg={12} md={12}>
                    <Panel  title={constant.CompanyContact.COMPANY_CONATCT}
                        className={'customerPanel cstPanel cstCustAlign'}
                        panelHeaderItems={customerCompanyContsctsPanelHeaderItems}
                        cardBodyClassName='dpd-crd-body'
                         collapsible = {true}>
                        {customerContactView}
                    </Panel>
                </Column>
            </Row>
        </div>
            <Row className="dv-cst-actions">
                <Column lg={3} md={3}>
                <Button
                            className='btn btn-outline-primary col-3 ml-2'
                            key="filter"
                            onClick={() =>CancelEdit()}
                            tabIndex={20}
                            id="btnCancel"
                            color='tertiary'
                            outlined={true}
                            disabled={!isEditable}
                            style={{minWidth:"90px"}}
                        >{label.CANCEL.toLocaleUpperCase()}
                </Button>
                {!isCreatingNewCustomer && 
                <Button
                            className='btn btn-outline-danger col-3 ml-2'
                            key="filter"
                            onClick={() => deleteCustomer()}
                            disabled={!canUserDeleteCustomer}
                            tabIndex={20}
                            id="btnCancel"
                            color='danger'
                            outlined={true}
                            style={{minWidth:"90px"}}
                        >{label.DELETE.toLocaleUpperCase()}
                        </Button> 
                }
                </Column>
                <Column lg={6} md={6}></Column>
                
                <Column lg={3} md={3} className="text-right">
                <Button
                            className='btn btn-outline-primary col-3 mr-3'
                            key="filter"
                            onClick={() =>EditCustomer()}
                            tabIndex={21}
                            id="btnEdit"
                            color='primary'
                            disabled={isEditable || !canUserEditCustomer}
                            outlined={true}
                        >{label.EDIT.toLocaleUpperCase()}
                        </Button>
                <Button
                            className='btn btn-outline-primary col-3 mr-3'
                            key="filter"
                            onClick={() =>SaveCustomer()}
                            tabIndex={21}
                            id="btnAddCustomer"
                            disabled={!isEditable}
                            color='primary'
                            outlined={true}
                        >{label.SAVE.toLocaleUpperCase()}
                        </Button>
                
                </Column>
            </Row>
            
        </>
    );
};

export default CustomerDetail;