import { Row, Column, Panel, Label, TextField, Icon, Modal, Button } from '../../../src/components';
import { ErrorBoundary } from 'react-error-boundary'
import React, { createContext,useContext, useEffect, useRef, useState } from 'react';
import {useHistory, useLocation } from "react-router-dom";
import QueryFilterRow from './QueryFilters';
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline';
import DropDownWithLabelInline from '../../shared/components/input/DropDownWithLabelInline';
import campaignFilter from './../model/QueryFilters';
import CampaignQueryInfo from './../model/CampaignQueryInfo'
import { campaignQueryUtils } from '../utils/campaignQueryUtils'
import * as constant from '../../shared/util/constant'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import NotesPanel from '../../shared/components/Notes&History/filters/NotesPanel';
import NoteCard,{exportNoteFunction} from '../../shared/components/Notes&History/filters/NoteCard'
import * as label from '../../shared/util/label'
import CampaignFilterLogic from '../model/CampaignFilterLogic';
import moment from 'moment';
import { getDropdownOptionsList } from '../utils/dropdownUtils';
import * as table from '../../shared/util/tableConstant'
import { ConfirmModal } from '../../shared/components/confirmModal';
import AppContext from '../../shared/components/AppContext'
import DynamicFilter from './DynamicFilter';
import { useDispatch } from 'react-redux';
import { toggleFormSaved } from '../../shared/components/formSaved-slice';
import TestQuery from '../model/TestQuery';
import {mkConfig, generateCsv, download} from "export-to-csv";
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { AbilityContext } from '../../shared/abilities/AbilityContext';
// import { writeFile } from "node:fs";
// import { Buffer } from "node:buffer";
// mkConfig merges your options with the defaults
// and returns WithDefaults<ConfigOptions>

//export const NoteContext = React.createContext();


const CampaignQueryDetail = () => {
  const [rowsData, setRowsData] = useState([]);
  const [count, setCount] = useState(0);
  const myContext = useContext(AppContext);
  var filterLogic: CampaignFilterLogic = {
    id: constant.DEFAULT_GUID,
    logic: '',
    filterId: constant.DEFAULT_GUID
  }
  var filterArr: any;//CampaignFilterLogic[];
  filterArr = [];
  const filter: campaignFilter = {
    id: constant.DEFAULT_GUID,
    fieldName: '',
    campaignLogicOptions: filterArr,
    filterDisplay: '',
    dataType: constant.DEFAULT_GUID,
    dataTypeText: '',
    dataTypeLogic: '',
    campaignFilterIds: [],
    expression: '',
    dynamicDropDowns: [],
    dataTypeLogicVisible: false,
    logicDropdown:[],
    isTestLogicEmpty:false,
    isTestFieldEmpty:false,
    isTestDataItemEmpty:[false,false]
  }
  const campaignQueryInfo: CampaignQueryInfo = {
    id: constant.DEFAULT_GUID,
    queryName: '',
    description: '',
    queryStatement: '',
    requestedBy: '',
    updatedOn: null,
    status: '',
    _campaignFilterList: filterArr
  }
  const testQueryModel: TestQuery = {
    selectQuery: campaignQueryInfo.queryStatement ?? '',
    filterList: [],
    download:false
}
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { entityId: string, type: string };
  const urlParam = new URLSearchParams(location.search)
  const queryId = state?.entityId ??urlParam.get('id')?? constant.DEFAULT_GUID;
  const [queryID, setQueryID] = useState(queryId);
  const type = location.state as { type: string };
  const noteType = state?.type;
  const [noteTp, setNoteType] = useState('1')
  const [queryInputs, setQueryDetail] = useState<CampaignQueryInfo>(campaignQueryInfo);
  const [queryFilter, setQueryFilter] = useState<campaignFilter>(filter);
  const [isRowAdded, setRowAdded] = useState(false);
  const [isQuerysaved, setQuerySaved] = useState();
  const [QuerySubmitedStatus, setQuerySubmitted] = useState(null)
  //const [isResponseAvailable, setResponseStatus] = useState(false)
  const [isFormValid, setFormValid] = useState(false);
  const [isQueryNameinvalid, validateQueryName] = useState(false);
  const [isQueryValid, validateQuery] = useState(false);
  const [isStatusValid, validateStatus] = useState(false);
  const [isFormValidated, checkValidationRun] = useState(false);
  const [previousQueryName, setPrevQueryName] = useState(campaignQueryInfo.queryName);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [isQueryNameSame, validateQueryNameSame] = useState(false)
  const [isQueryNameTooLong, setIsQueryNameTooLong] = useState(false);
  const [isQueryNameTooShort, setIsQueryNameTooShort] = useState(false);
  const [isQueryEmpty, setIsQueryEmpty] = useState(false);
  const [isQueryTooShort, setIsQueryTooShort] = useState(false);
  const [isDescEmpty, setIsDescEmpty] = useState(false);
  const [queryStatusTxt, setQueryStatusTxt] = useState('');
  const [isRequired, setIsRequired] = useState(queryStatusTxt === constant.SAVED ? false : true);
  const [highlightedMenu, setHighlightedMenu] = useState('Queries');
  const [isStatusSelected, setStatusSelectedEmpty] = useState(false);
  const [isDescTooShort, setIsDescTooShort] = useState(false);
  const [multiSelectedOptions, setMultiSelectionsLogic] = useState([]);
  const [strQueryError, setErrorText] = useState('');
  const [strDescError, setDescErrorText] = useState('');
  const [stQueryError, setQueryErrorText] = useState('');
  const [stStatusError, setStatusErrorText] = useState('');
  const [QueryStatusOption, setQueryStatusOptions] = useState([]);
  const [isFormSaved, setFormSaved] = useState(true);
  const [isnoteSaved,setNoteSaved]=useState(myContext.noteSavedStatus)
  const [showTestQueryModal,setShowQueryTestModal]=useState(false)
  const [isCompleteSave,setIsCompleteSave]=useState(false)
  const [isSaveAsTriggered,setSaveAsTriggered]=useState(false)
  const [querySelectedCondition, setquerySelectedCondition] = useState([]);
  const [testRunQuery,setTestQueryModal]=useState(testQueryModel)
  const [testQueryRecordCount,setTestQueryRecordCount]=useState("")
  const [testQueryRecords,setTestQueryRecords]=useState([])
  const[consolidateErrorMessage,setConsolidateErrorMessage]=useState("")

  const ability = useContext(AbilityContext);
  const ab_subject = "Campaign Query";
  const isUserPermitToCreateQuery = ability.can('create', ab_subject,constant.ScreenName.Campaign_Query)
  const isUserPermitToUpdateQuery = ability.can('update',ab_subject,constant.ScreenName.Campaign_Query)
  const isUserPermitToDeleteQuery = ability.can('delete', ab_subject,constant.ScreenName.Campaign_Query)
  const userPermitToSave = (isUserPermitToCreateQuery && queryId == constant.DEFAULT_GUID?true:
    isUserPermitToUpdateQuery && queryID  != constant.DEFAULT_GUID?true:false);
  const isUserPermitToViewSaveAs = ability.can('view', ab_subject,constant.QueryPrivileges.SAVE_AS)
  const isSaveAsDisabled = !ability.can('read', ab_subject,constant.QueryPrivileges.SAVE_AS)
  const isUserPermitToViewAddRule = ability.can('view', ab_subject,constant.QueryPrivileges.ADD_RULE)
  const isAddRuleDisabled = !ability.can('read', ab_subject,constant.QueryPrivileges.ADD_RULE)
  const isUserPermitToViewTestQuery = ability.can('view', ab_subject,constant.QueryPrivileges.TEST_QUERY)
  const isTestQueryDisabled = !ability.can('read', ab_subject,constant.QueryPrivileges.TEST_QUERY)
  
  myContext.isDetailWithNoteSave=isCompleteSave;
  myContext.isSaveAsCliked=isSaveAsTriggered;
  const dispatch = useDispatch();
  // const notesSessionFlag={
  //   isDetailWithNoteSave:isCompleteSave,
  //   isSaveAsCliked:isSaveAsTriggered
  // }

  //const NoteRef = React.useRef<exportNoteFunction>(null);

  // Toast to show success and failure message
  toast.configure();

  //#region Populate dropdown on page load
  useEffect(() => {
    getDropdownOptionsList(table.QUERY_STATUS).then(res => {
      setQueryStatusOptions(res);
    })
  }, [])
  //#endregion


  //#region Code to set flag
  // This flag help to identify, is formed saved or not
  // This flag help to show popup on screen, before user leave screen without saving
  useEffect(() => {
    myContext.formSavedStatus = isFormSaved
    //myContext.showExitConfirmation = !isFormSaved
  }, [isFormSaved])
  //#endregion

  useEffect(() => {
    getDropdownOptionsList(table.FILTER_CONDITION).then(res => {
        setquerySelectedCondition(res);
        //console.log('FILTER_CONDITION', res)
    })
}, [])
  //#region Setting the default status of query

  const setQueryStatusById = (statusId) => {
    return statusId.toUpperCase() === constant.QUERY_STATUS_SAVED ? constant.SAVED : statusId.toUpperCase() === constant.QUERY_STATUS_ACTIVE ? constant.ACTIVE : constant.INACTIVE
  }
  //#endregion

  //#region Get query info by ID
  //This code will populate query detail screen data, which is fetched by API.
  //If it is new template, then there wiil be no API call and screen load with empty controls.
  async function getQueryInfo() {
    if (queryID !== undefined && queryID.trim() !== '' && queryID.trim() !== constant.DEFAULT_GUID) {
      await campaignQueryUtils.GetQueryById(queryID)
        .then((res) => {
          if (res !== undefined && res !== null) {
            var campaignQueryData = campaignQueryUtils.setQueryDataModel(res);
            //console.log('campaignQueryData', campaignQueryData)
            setQueryDetail(campaignQueryData)
            if (res !== null && res.data !== undefined) {
              setRowAdded(true);
              setPrevQueryName(campaignQueryData.queryName)
              setIsRequired(campaignQueryData.status.toUpperCase() === constant.QUERY_STATUS_SAVED ? false : true)
              setQueryStatusTxt(setQueryStatusById(campaignQueryData.status))
              campaignQueryData._campaignFilterList.forEach(element => {
                rowsData.push(element)
              });
              setFormSaved(true)
            }
          }
          else {
            setFormSaved(false)
            toast.error(constant.ERROR_MESSAGE_LOAD, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
          }

        })

    }
    else {
      setQueryStatusTxt(constant.SAVED)
      setIsRequired(false)
      highlightMenu();
    }
  }
  //#endregion

  //#region to validate dynamic filters are empty or not
  //if empty return true else false
  const checkEmpty = (val) => {
    //console.log('val', val,(val === undefined || val === null || val === '' || val===constant.DEFAULT_GUID || val.length === 0 ||val===NaN) ? true : false)
    return (val === undefined || val === null || val === '' || val===constant.DEFAULT_GUID || val.length === 0) ? true : false;
  }
  const checkNaN=(val)=>{
    return isNaN(val)
  }
  //#endregion

  //#region  Check validation before submit
  //This function will validate form before create or update API is called.
  //If the status is saved, then only name and status is mandatory.
  //If the status is other then saved, then all fields are mandatory
  const validateForm = (event) => {
    //console.log('queryStatusTxt', queryStatusTxt)
    var formStatus = false;
    setFormValid(false)
    validateQueryName(false);
    var checkQueryNameValidity = true;
    var checkDescriptionValidity = true;
    var checkSelectedFilterOptionValidity = true
    const isQueryNameEmpty = !queryInputs.queryName || queryInputs.queryName.length === 0;
    const isSameQueryName = event === constant.SAVE ? false : previousQueryName === "" ? false : previousQueryName === undefined ? false : queryInputs.queryName.toLocaleLowerCase() === previousQueryName.toLocaleLowerCase();
    const isQueryNameLong = queryInputs.queryName.length > 150;
    const isQueryNameShort = !isQueryNameEmpty && queryInputs.queryName.length < 3;
    const isDescEmpty = !queryInputs.description || queryInputs.description.length === 0;
    const isDescShort = !isDescEmpty && queryInputs.description.length < 3;
    const isDescLong = !isDescEmpty && queryInputs.description.length > 250;
    const isQueryEmpty = !queryInputs.queryStatement || queryInputs.queryStatement.length === 0;
    const isQueryShort = !isQueryEmpty && queryInputs.queryStatement.length < 10;
    const isStatusEmpty = !queryInputs.status || queryInputs.status.length === 0;
    var isFilterValid = false;
    var validFilterStatus=[]
    var idx = 0;
    if (queryInputs._campaignFilterList.length === 0) {
      isFilterValid = true
    }
    queryInputs._campaignFilterList.forEach(ele => {
      
      const isDBFieldEmpty = queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && checkEmpty(ele.fieldName);
      const isConditionEmpty = queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && (ele.campaignFilterIds === undefined || ele.campaignFilterIds === null || ele.campaignFilterIds.length === 0);;
      const isFriendlyNameEmpty = queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && checkEmpty(ele.filterDisplay);
      const isFieldTypeEmpty = queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && checkEmpty(ele.dataType);
      const isDataTypeLogicEmp = queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && ele.dataTypeLogicVisible && checkEmpty(ele.expression);
      var fieldTypeControl = document.getElementById('ddlFieldType' + idx) as HTMLInputElement;
      var logicControl = document.getElementsByClassName('ddlCondition' + idx) as HTMLSelectElement;
     // console.log('dataType',ele.dataType,fieldTypeControl.value,ele.campaignFilterIds,logicControl[0].value)
      const isFieldTypeCorrect = (checkEmpty(ele.dataType) === true && checkEmpty(fieldTypeControl.value) === false) ? false : true;
      const isConditionCorrect = (checkEmpty(ele.campaignFilterIds) === true && checkEmpty(logicControl[0].value) === false) ? false : (checkEmpty(ele.campaignFilterIds) === false && checkEmpty(logicControl[0].value) === false) ? false : true;
     // console.log('check',isDBFieldEmpty,isConditionEmpty,isFriendlyNameEmpty,isFieldTypeEmpty,isFieldTypeCorrect,isConditionCorrect)
      validateFilterRows(idx, "dbFieldError", isDBFieldEmpty ? constant.FIELD_BLANK : "")
      validateFilterRows(idx, "isDbFieldEmpty", isDBFieldEmpty ? true : false)

      validateFilterRows(idx, "logicFieldError", isConditionCorrect !== true ? constant.FIELD_INCORRECT : isConditionEmpty ? constant.FIELD_BLANK : "")
      validateFilterRows(idx, "isLogicEmpty", isConditionEmpty ? true : isConditionCorrect === false ? true : false)

      validateFilterRows(idx, "friendlyNameError", isFriendlyNameEmpty ? constant.FIELD_BLANK : "")
      validateFilterRows(idx, "isFriendlyNameEmpty", isFriendlyNameEmpty ? true : false)

      validateFilterRows(idx, "fieldTypeError", isFieldTypeCorrect !== true ? constant.FIELD_INCORRECT : isFieldTypeEmpty ? constant.FIELD_BLANK : "")
      validateFilterRows(idx, "isFieldTypeEmpty", isFieldTypeCorrect === false ? true : isFieldTypeEmpty ? true : false)

      validateFilterRows(idx, "dataTypeLogicError", isDataTypeLogicEmp ? constant.FIELD_BLANK : "")
      validateFilterRows(idx, "isDataTypeLogicEmpty", isDataTypeLogicEmp ? true : false)
      if (queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && !isDBFieldEmpty && !isConditionEmpty && !isFriendlyNameEmpty && !isFieldTypeEmpty && !isDataTypeLogicEmp && isFieldTypeCorrect && isConditionCorrect) {
       // console.log('1')
        isFilterValid = true
      }
      else if (queryStatusTxt === constant.SAVED.toLowerCase() && isDBFieldEmpty && isConditionEmpty && isFriendlyNameEmpty && isFieldTypeEmpty && isDataTypeLogicEmp && !isFieldTypeCorrect && !isConditionCorrect) {
        //console.log('2')
        isFilterValid = true
      }
      else if (isFieldTypeCorrect === false || isConditionCorrect === false) {
        //console.log('3')
        isFilterValid = false
        checkSelectedFilterOptionValidity = false
      }

      else {
        //console.log('4')
        isFilterValid = false
      }
      validFilterStatus.push(isFilterValid)
      //console.log('isFilterValid',ele.fieldName,isFilterValid,validFilterStatus)
      idx++;
    });
    var filterValidity=validFilterStatus.find(e=>e===false)
    isFilterValid=filterValidity===false?false:true;
   // console.log('validate',isFilterValid)
    validateQueryName(isQueryNameEmpty);
    setIsQueryNameTooLong(isQueryNameLong);
    setIsQueryNameTooShort(isQueryNameShort);
    setIsDescTooShort(isDescShort);
    setIsDescEmpty(queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && isDescEmpty);
    setIsQueryEmpty(queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && isQueryEmpty);
    setStatusSelectedEmpty(isStatusEmpty)
    setIsQueryTooShort(queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && isQueryShort)
    validateQueryNameSame(isSameQueryName)


    if (isQueryNameEmpty || isQueryNameLong || isQueryNameShort) {
      setErrorText(isQueryNameEmpty ? constant.FIELD_BLANK : isQueryNameLong ? constant.MAX_QUERY_LEN_ERROR : isQueryNameShort ? constant.MIN_QUERY_LEN_ERROR : '')
      checkQueryNameValidity = false;
    }
    if (event === constant.SAVE_AS && isSameQueryName) {
      setErrorText(isSameQueryName ? constant.UNIQUE_QUERY_ERROR : '')
      checkQueryNameValidity = false;
    }

    if ( (isDescEmpty || isDescShort || isDescLong)) {
      if(queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && isDescEmpty){
        setDescErrorText(isDescEmpty ? constant.FIELD_BLANK:'')
        checkDescriptionValidity = false;
      }
      else if(!isDescEmpty && (isDescShort || isDescLong) ){
      setDescErrorText(isDescShort ? constant.MIN_DESC_LEN_ERROR : isDescLong ? constant.MAX_DESC_LEN_ERROR : '')
      checkDescriptionValidity = false;  
      }
      
    }
    if (queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase() && (isQueryEmpty || isQueryShort)) {
      setQueryErrorText(isQueryEmpty ? constant.FIELD_BLANK : isQueryShort ? constant.MIN_SQL_FIELD_ERROR : '')
    }
    if (isStatusEmpty) {
      setStatusErrorText(isStatusEmpty ? constant.FIELD_BLANK : '')
    }

    if ((event === constant.SAVE && queryStatusTxt.toLowerCase() === constant.SAVED.toLowerCase()) && checkQueryNameValidity && checkDescriptionValidity && !isStatusEmpty && checkSelectedFilterOptionValidity === true) {
      setFormValid(true);
      formStatus = true;
    }
    else if ((event === constant.SAVE_AS && queryStatusTxt.toLowerCase() === constant.SAVED.toLowerCase()) && checkQueryNameValidity &&checkDescriptionValidity && !isStatusEmpty && checkSelectedFilterOptionValidity === true) {
      setFormValid(true);
      formStatus = true;
    }
    else if ((event === constant.SAVE && queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase())
      && checkQueryNameValidity && !isStatusEmpty && checkDescriptionValidity && !isQueryEmpty
      && !isQueryShort && isFilterValid) {
      setFormValid(true);
      formStatus = true;
    }
    else if ((event === constant.SAVE_AS && queryStatusTxt.toLowerCase() !== constant.SAVED.toLowerCase())
      && checkQueryNameValidity && !isStatusEmpty && checkDescriptionValidity && !isQueryEmpty
      && !isQueryShort && isFilterValid) {
      setFormValid(true);
      formStatus = true;
    }
    else {
      setFormValid(false);
      formStatus = false;
    }
    return formStatus
  }
  //#endregion

  //#region Submit query details
  //This function create or update query detail
  async function SubmitQueryDetail(action: string) {
    var formStatus = false;
    var filterIndex = 0
    formStatus = validateForm(action);
    if (formStatus === true) {
      var status: any
      if (action === constant.SAVE_AS) {
        //console.log('queryInputs.id', queryInputs.id)
        queryInputs.id = constant.DEFAULT_GUID;
        //console.log('queryInputs.id', queryInputs.id)
        queryInputs._campaignFilterList.forEach(filter => {
          filter.id = constant.DEFAULT_GUID;
          if (filter.campaignLogicOptions !== undefined && filter.campaignLogicOptions.length > 0) {

            filter.campaignLogicOptions.forEach(logic => {
              logic.id = constant.DEFAULT_GUID;
            });
          }
        })
      }
      queryInputs._campaignFilterList.forEach(filter => {
        if ((filter.fieldName === undefined || filter.fieldName.length === 0) && (filter.filterDisplay === undefined || filter.filterDisplay.length === 0) &&
          (filter.campaignFilterIds === undefined || filter.campaignFilterIds.length === 0) && (filter.dataType === undefined || filter.dataType.length === 0)) {
          queryInputs._campaignFilterList.splice(filterIndex, 1);
        }
        filterIndex++
      });
      if (queryInputs.id === null || queryInputs.id === undefined || queryInputs.id === '' || queryInputs.id === constant.DEFAULT_GUID) {
        status = await campaignQueryUtils.SaveQueryDetail(queryInputs).then((res) => {
          var campaignQueryData = campaignQueryUtils.setQueryDataModel(res);
          if (res.status >= 200 && res.status < 300) {
            setQuerySubmitted(true)
            setQueryDetail(campaignQueryData)
            setPrevQueryName(campaignQueryData.queryName)
            setRowsData(campaignQueryData._campaignFilterList)
            queryInputs.id = campaignQueryData.id;
            queryInputs.updatedOn=campaignQueryData.updatedOn;
            queryInputs.requestedBy=campaignQueryData.requestedBy;
            queryInputs._campaignFilterList = campaignQueryData._campaignFilterList
            setQueryID(campaignQueryData.id)
            updateQueryDetail()
            setQueryID(campaignQueryData.id)
            setPrevQueryName(campaignQueryData.queryName)
            setFormSaved(true)
            dispatch(toggleFormSaved());
            history.replace({pathname:'',state:{entityId:campaignQueryData.id,type:'3'}})
            if (action === constant.SAVE_AS){
            setSaveAsTriggered(true)
            }
            toast.success(constant.QUERY_SUCCESS_MESSAGE, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
            setTimeout(() => {
            setIsCompleteSave(false);
            setSaveAsTriggered(false);
            },5000)
            
          }
          else {
            setFormSaved(false)
            toast.error(constant.ERROR_MESSAGE, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
          }
        }).catch(exception => {
          setFormSaved(false)
          toast.error(exception.data, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: constant.AUTO_CLOSE
          });
        })
      }
      else {
        queryInputs._campaignFilterList.forEach(filter => {
          if (filter.id === null || filter.id === undefined) {
            filter.id = constant.DEFAULT_GUID;
            if (filter.campaignLogicOptions !== undefined && filter.campaignLogicOptions.length > 0) {
              filter.campaignLogicOptions.forEach(logic => {
                logic.id = constant.DEFAULT_GUID;
              });
            }
          }
        })

        status = await campaignQueryUtils.UpdateQueryInfo(queryInputs).then((res) => {
          var campaignQueryData = campaignQueryUtils.setQueryDataModel(res);
         // console.log(campaignQueryData)
          setQuerySubmitted(res)
          if (res.status >= 200 && res.status < 300) {
            //NoteRef.current.saveNoteFromParent();
            setIsCompleteSave(true);
            setQuerySubmitted(true)
            setQueryDetail(campaignQueryData)
            setPrevQueryName(campaignQueryData.queryName)
            setRowsData(campaignQueryData._campaignFilterList)
            queryInputs.id = campaignQueryData.id
            queryInputs.updatedOn=campaignQueryData.updatedOn;
            queryInputs.requestedBy=campaignQueryData.requestedBy;
            queryInputs._campaignFilterList = campaignQueryData._campaignFilterList
            setQueryID(campaignQueryData.id)
            updateQueryDetail()
            setQueryID(campaignQueryData.id)
            setPrevQueryName(campaignQueryData.queryName)
            setFormSaved(true)
            dispatch(toggleFormSaved());
            toast.success(constant.QUERY_UPDATE_SUCCESS_MESSAGE, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
            setIsCompleteSave(false);
            setSaveAsTriggered(false);
          }
          else {
            setFormSaved(false)
            toast.error(constant.ERROR_MESSAGE, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: constant.AUTO_CLOSE
            });
          }
        }).catch(exception => {
          setFormSaved(false)
          toast.error(exception.data, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: constant.AUTO_CLOSE
          });

        });
      }
    }
  }
  //#endregion

  //#region Delete query detail, 
  //only by user if he has access for delete the query.
  function allowDelete() {
    if (queryInputs.id !== undefined && queryInputs.id.trim() !== '') {
      campaignQueryUtils.DeleteQueryById(queryInputs.id).then((res) => {
        if (res.status !== undefined && (res.status >= 200 && res.status < 300)) {
          toast.success(constant.QUERY_DELETE_SUCCESS_MESSAGE, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: constant.AUTO_CLOSE
          });
          routeChange();
        }
        else {
          setShowDeleteConfirmation(false)
          toast.error(constant.ERROR_MESSAGE_LOAD, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: constant.AUTO_CLOSE
          });
        }
      }).catch(exception => {
        setShowDeleteConfirmation(false)
        toast.error(exception.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
      })
    }
  }
  //#endregion

  //#region get query info function to load query detail
  useEffect(() => {
    getQueryInfo();
    highlightMenu();
  }, [window.onloadeddata])
  //#endregion



  //#region This function called every time, if there is change occur on controls on parent form.
  //It will updat model every time, when there is change occur on parent form controls.
  const onFieldChange = (name: string, value: string | Date | boolean | number) => {
    setFormSaved(false)
    setQueryDetail({
      ...queryInputs,

      [name]: value,
    })
  }
  //#endregion




  //#region Field Logic change event
  //This function is called when there is change on Logic dropdown on child component(dynamic filter control)
  const onDetailFieldLogicChange = (fieldName, fieldValue: string[], index, campaignLogic: []) => {
    const rowsInput = [...rowsData];
    if (campaignLogic !== undefined && campaignLogic.length > 0) {
      campaignLogic.forEach(logicItem => {
        var checkExist = fieldValue.find(a => a.toUpperCase() === logicItem.logic.toUpperCase());
        if (checkExist !== undefined) {
          let filterlogic = { id: logicItem.id, logic: logicItem.logic }
          filterArr.push(filterlogic)
        }
      })
    }
    fieldValue.forEach(a => {
      var s = filterArr.find(b => b.logic.toUpperCase() === a.toUpperCase());
      if (s === undefined) {
        let filterlogic = { id: constant.DEFAULT_GUID, logic: a }
        filterArr.push(filterlogic)
      }
    });
    rowsInput[index][fieldName] = fieldValue;
    var jsondata = JSON.stringify(filterArr)
    //console.log('campaignFilterLogic', rowsInput[index]['campaignFilterLogic'])
    rowsInput[index]['campaignFilterLogic'] = filterArr;
    if (rowsInput[index]['id'] === '' || rowsInput[index]['id'] === undefined || rowsInput[index]['id'] === null) {
      rowsInput[index]['id'] = constant.DEFAULT_GUID;
    }
    setRowsData(rowsInput);
    updateQueryDetail();
    setFormSaved(false)
  };
  //#endregion

  //#region Field type change event
  //This function is called when there is change on field type dropdown on child component (dynamic filter control).
  const onDetailFieldChange = (fieldName, fieldValue, index, selectedOption: string) => {
    var rowsInput = [...rowsData];
    rowsInput[index][fieldName] = fieldValue;
    setRowsData([...rowsData, rowsInput]);
    validateFilterRows(index, fieldName, fieldValue)
    setFormSaved(false)
  };
  //#endregion

  //#region event to add new row 
  //This function is called when user click on add new button of filter to add new row on child component(dynamic filter control).
  const addTableRows = () => {
    rowsData.push(filter)
    const rowsInput = {
      ...rowsData
    }
    setRowAdded(true)
    setRowsData([...rowsData])
    updateQueryDetail();
    setFormSaved(false)
  }
  //#endregion

  //#region Delete Table Row
  //Delete filter row from child component(dynamic filter control).
  const deleteTableRows = (index) => {
    const rowsInput = [...rowsData];
    rowsInput.splice(index, 1);
    setRowsData([...rowsInput]);
    const querySetting = { ...queryInputs }
    querySetting._campaignFilterList = [...rowsInput]
    setQueryDetail(querySetting);
    setFormSaved(false)
  }
  //#endregion

  //#region Text changed event for sdynamic filter component
  //This function manage changes on text input on filter component(dynamic filter control)
  const handleChange = (index, evnt, propName) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    var seperatedValue = ''
    
    if (rowsInput[index]['dataType']?.toUpperCase() === constant.DROPDOWN_LIST_ID.toUpperCase()) {
       seperatedValue = value.split(';').join(';')
      seperatedValue = seperatedValue.replaceAll(',,', ';').replaceAll(', ', ';').replaceAll(',', ';').replaceAll(';;', ';');
     console.log('seperatedValue', seperatedValue)
    }
    rowsInput[index][propName] = rowsInput[index]['dataType']?.toUpperCase() === constant.DROPDOWN_LIST_ID.toUpperCase() ? seperatedValue : value;
    setRowsData([...rowsInput]);
    updateQueryDetail();
    setFormSaved(false)
  }
  //#endregion
  //#region Validate filter row before submit
  //This function update the error message accordingly from where the function is called.
  const validateFilterRows = (index, propName, value) => {
    var rowsInput = [...rowsData];
    rowsInput[index][propName] = value;
    setRowsData([...rowsInput]);
    updateQueryDetail();
    setFormSaved(false)
  }
  //#endregion

  //#region update query detail model \
  //This function update query model after every update on control
  const updateQueryDetail = () => {
    //console.log('queryInputs', queryInputs)
    const querySetting = { ...queryInputs }
    querySetting._campaignFilterList = rowsData
    setQueryDetail(querySetting);
    setFormSaved(false)
  }
  //#endregion

  // useEffect(() => {
  //   setResponseStatus(true)
  // }, [])

  //#endregion Redirect and routchange
  //Below code is used when user click on back icon or click cancel on screen.

  const redirect = () => {
    let path = '/Campaign/Queries';
    history.push(path);
    myContext.formSavedStatus=true;
    //myContext.showExitConfirmation = false;
  }
  const routeChange = () => {
    if (isFormSaved && myContext.noteSavedStatus) {
      redirect();
    }
    else {
      setShowExitConfirmation(true)
     // myContext.showExitConfirmation = true;
    }
  }
  //#endregion

  //#region Highligh menu on navbar on page refresh
  function highlightMenu() {
    var tabLink = document.querySelectorAll(".cstHeaderMenu");
    tabLink.forEach(function (item) {
      item.classList.remove("active");
    });

    tabLink.forEach(function (item) {
      if (item.id === 'Queries') {
        item.classList.add("active");
      }
    });
    var activeMenu = 'Queries';
    setHighlightedMenu(activeMenu)
  }
  //#endregion

  async function testQueryModal(){
    const queryfilter = { ...queryInputs }
    for (let index = 0; index < queryfilter._campaignFilterList.length; index++) {
      const filter = queryfilter._campaignFilterList[index];
      filter.isTestDataItemEmpty=[false,false];
      filter.isTestLogicEmpty=false
      if(filter.campaignFilterIds?.length>0){
        //console.log('1')
        var option=querySelectedCondition.map((option)=>({
          label: option.label,
          value: option.value,
          isInactive:option.isInactive
        }))?.filter(e => filter.campaignFilterIds.toString().split(",").some(g => g === e.value))
        queryfilter._campaignFilterList[index].logicDropdown=[]
        queryfilter._campaignFilterList[index].logicDropdown.push(option)
        setQueryDetail(queryfilter);
      }
      else{
        //console.log('2')
        queryfilter._campaignFilterList[index].logicDropdown=[]
        setQueryDetail(queryfilter);
      }

        if(filter.dataType?.toLowerCase() === constant.Data_Type_Dropdown_List && filter.expression.length>0){
          //console.log('3') 
          var arrCampaignIds=filter.expression.toString().replace(/;$/, "").split(";")
            var optionlist=arrCampaignIds.map((option)=>({
              label: option,
              value: option
            }))
            queryfilter._campaignFilterList[index].dynamicDropDowns=[]
          queryfilter._campaignFilterList[index].dynamicDropDowns.push(optionlist)
          setQueryDetail(queryfilter);
        }
      else if(filter.dataType?.toLowerCase() === constant.Data_Type_Dropdown_SQL && filter.expression.length>0){
        //console.log('4')
        var res= await campaignQueryUtils.getDataForDynamicQuery(filter.expression)
          var optionList=res.map((option)=>({
            label:option.value,
            value:option.id
          }));
          queryfilter._campaignFilterList[index].dynamicDropDowns=[]
          queryfilter._campaignFilterList[index].dynamicDropDowns.push(optionList)
          setQueryDetail(queryfilter);
     }
     else{
      //console.log('5')
      queryfilter._campaignFilterList[index].dynamicDropDowns=[]
      setQueryDetail(queryfilter);
    }
    }
    setConsolidateErrorMessage('')
    setTestQueryRecordCount('0')
    setShowQueryTestModal(true)
    setTestQueryRecords([])
  }

  const testQuery = async () => {
    var isModelValidated = false;
    var validResultFilter = []
    const queryfilter = { ...queryInputs }
    if (queryfilter.queryStatement!="") {
      for (let index = 0; index < queryfilter._campaignFilterList.length; index++) {
        // console.log(index)
        if (testRunQuery.filterList[index].logic === undefined || testRunQuery.filterList[index].logic === null || testRunQuery.filterList[index].logic === "") {
          queryfilter._campaignFilterList[index].isTestLogicEmpty = true;
          isModelValidated = false;
        }
        else {
          queryfilter._campaignFilterList[index].isTestLogicEmpty = false;
          isModelValidated = true;
        }
        if (testRunQuery.filterList[index].dataItem === undefined || testRunQuery.filterList[index].dataItem === null || testRunQuery.filterList[index].dataItem === "" || testRunQuery.filterList[index].dataItem === [] || testRunQuery.filterList[index].dataItem.length === 0 || testRunQuery.filterList[index].dataItem === [NaN]) {
          //console.log('1')
          queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] = true;
          queryfilter._campaignFilterList[index].isTestDataItemEmpty[1] = true;
          isModelValidated = false;
        }
        else if (testRunQuery.filterList[index].dataItem !== undefined && testRunQuery.filterList[index].dataItem !== null && testRunQuery.filterList[index].dataItem !== "" && testRunQuery.filterList[index].dataItem !== []) {
          //console.log('2')
          if (testRunQuery.filterList[index].type?.toLowerCase() === constant.Data_Type_Number_Range.toLowerCase()
            || testRunQuery.filterList[index].type?.toLowerCase() === constant.Data_Type_Currency_Range.toLowerCase()
            || testRunQuery.filterList[index].type?.toLowerCase() === constant.Data_Type_Date_Range.toLowerCase()) {
            queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] = checkEmpty(testRunQuery.filterList[index].dataItem[0]);
            queryfilter._campaignFilterList[index].isTestDataItemEmpty[1] = checkEmpty(testRunQuery.filterList[index].dataItem[1]);
            isModelValidated = (queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] === false && queryfilter._campaignFilterList[index].isTestDataItemEmpty[1] === false) ? true : false;
          }
          else {
            queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] = checkEmpty(testRunQuery.filterList[index].dataItem[0]);
            queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] = false
            isModelValidated = (queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] === false) ? true : false;
          }
        }

        // else if(testRunQuery.filterList[index].dataItem !== undefined ||testRunQuery.filterList[index].dataItem.length> 0)
        // {
        //   console.log('2')
        //   var dataItemValues=testRunQuery.filterList[index].dataItem.toString().split(',')
        //   queryfilter._campaignFilterList[index].isTestDataItemEmpty[0]=dataItemValues[0]===undefined?true:dataItemValues[0]===null?true:dataItemValues[0]===''?true:false
        //   queryfilter._campaignFilterList[index].isTestDataItemEmpty[1]=dataItemValues[1]===undefined?true:dataItemValues[1]===null?true:dataItemValues[1]===''?true:false
        //   isModelValidated = false;
        // }
        else {
          //console.log('3')
          queryfilter._campaignFilterList[index].isTestDataItemEmpty[0] = false;
          queryfilter._campaignFilterList[index].isTestDataItemEmpty[1] = false;
          isModelValidated = true;
        }

        validResultFilter.push(isModelValidated)

        setQueryDetail(queryfilter);
      }
      var filterValidity = validResultFilter.find(e => e === false)
      isModelValidated = filterValidity === false ? false : true;
      setConsolidateErrorMessage(isModelValidated ? '' : constant.FILTER_CRITERIA_CONSOLIDATED_ERROR)
      if (isModelValidated) {
        testRunQuery.download = false
        var testResult = await campaignQueryUtils.getTestResultFromQuery(testRunQuery);
        setTestQueryRecordCount(testResult.resultCount)
        
        //console.log('testResult', testResult)
      }
      //console.log('SelectedFilterData', queryfilter)
    }
  }

   const exportToCSV= async()=>{

testRunQuery.download = true
var testQueryRecords = await campaignQueryUtils.getTestResultFromQuery(testRunQuery);
const url = window.URL.createObjectURL(new Blob([testQueryRecords]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Test Query Result.csv');
  document.body.appendChild(link);
  link.click();
  // Converts your Array<Object> to a CsvOutput string based on the configs
  // const csvConfig = mkConfig({filename:'Test Query Result', fieldSeparator: ',',useKeysAsHeaders: true });
  // const csv = generateCsv(csvConfig)(testQueryRecords);
  // download(csvConfig)(csv);
   }

  return (
    <>
      <Row>
        <Column lg={12} md={12}>
          <Button icon='up-indication' outlined={true} onClick={routeChange} color="black">
          </Button>
          {queryInputs.queryName}</Column>
      </Row>
      <Row className="bg-light">
        <Column lg={12} md={12}>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Column lg={12}>
              <Row>
                <Column lg={9} md={9}>
                  <Panel collapsible={false}
                    id={'QueryDetails'}
                    title={label.QUERY_DETAIL}
                    className={'cstPanel'}
                    collapsed={false}>
                    <Column lg={12} md={12}>
                      <Row>
                        <Column lg={12} className='cstDetailSection'>
                          <Row >
                            <Column lg={12} className={isQueryNameTooLong || isQueryNameinvalid || isQueryNameSame || isQueryNameTooShort ? 'feedback-row' : ''}>
                              <TextInputWithLabelInline
                                name='txtQueryName'
                                label={label.QUERY_NAME}
                                value={queryInputs.queryName}
                                isEditable
                                placeholder={label.NAME}
                                type='text'
                                labelClass='inlineLable'
                                onChange={(event) => onFieldChange('queryName', event.currentTarget.value)}
                                tabIndex={1}
                                lglabel={2}
                                mdlabel={2}
                                lgText={10}
                                mdText={10}
                                isRequired
                                isInvalid={isQueryNameinvalid || isQueryNameSame || isQueryNameTooLong || isQueryNameTooShort}
                                isEmpty={isQueryNameinvalid}
                                isSame={isQueryNameSame}
                                isTooLong={isQueryNameTooLong}
                                isTooShort={isQueryNameTooShort}
                                className='texbox-input cstCollection'
                                errorText={strQueryError}
                                maxLength={150}
                              />
                            </Column>
                          </Row>

                          <Row className='filterRow bottom-margin-filter' style={!isDescTooShort && !isDescEmpty?{height:'50px'}:{}}>
                            <Column lg={2} md={2} className='nopadding'><Label text={label.QUERY_DESCRIPTION} isRequired={isRequired} className={'inlineLable'} /></Column>
                            <Column lg={10} md={10} className={isDescTooShort || isDescEmpty ? 'nopadding-left feedback-row' : 'nopadding-left'}>
                              <TextField
                                name='txtDescription'
                                title={queryInputs.description}
                                value={queryInputs.description}
                                disabled={false}
                                style={{ fontSize: "14px" }}
                                placeholder={label.DESCRIPTION}
                                onChange={(event) => onFieldChange('description', event.currentTarget.value)}
                                tabIndex={2}
                                isInvalid={isDescTooShort || isDescEmpty}
                                className='texbox-input cstCollection'
                                isTooShort={isDescTooShort}
                                isEmpty={isDescEmpty}
                                errorText={strDescError}
                                maxLength={250}
                                isRequired={isRequired}
                              />
                            </Column>
                          </Row>
                          <Row className='filterRow bottom-margin-filter'>
                            <Column lg={2} md={2} className='nopadding'><Label text={"SQL Query string"} isRequired={isRequired} className={'inlineLable'} /></Column>
                            <Column lg={10} md={10} className={isQueryEmpty || isQueryTooShort ? 'top-margin-filter nopadding-left feedback-row' : 'top-margin-filter nopadding-left'}>
                              <TextField
                                id={'txtQueryString'}
                                value={queryInputs.queryStatement}
                                onChange={(event) => onFieldChange('queryStatement', event.currentTarget.value)}
                                disabled={false}
                                size='large'
                                isInvalid={isQueryEmpty || isQueryTooShort}
                                placeholder={label.Query}
                                isEmpty={isQueryEmpty}
                                isTooShort={isQueryTooShort}
                                style={{ fontSize: "14px" }}
                                tabIndex={3}
                                className={"cstCollection"}
                                errorText={stQueryError}
                                isRequired={isRequired}
                              />
                            </Column>

                          </Row>
                          <Row className='filterRow'>
                            <Column lg={2} md={2} className={'nopadding'}><Label text={label.FILTER_CRITERIA} className={'inlineLable'} /></Column>
                            <Column lg={10} md={10} className='filter-row-height'>
                              <Row>
                                <Column lg={12} md={12} className='filter-row-height'>
                                  <Row className='filter-row-height'>
                                    <Column lg={1} className='hidden'></Column>
                                    <Column lg={2} className={''}><Label text={label.DATABASE_FIELD}  className={'inlineLable margin-Left-22'} /></Column>
                                    <Column lg={5} className={''}><Label text={label.LOGIC}  className={'inlineLable'} /></Column>
                                    <Column lg={2} className={''}><Label text={label.FRIENDLY_NAME}  className={'inlineLable margin-Left-22'} /></Column>
                                    <Column lg={2} className={''}><Label text={label.FIELD_TYPE}  className={'inlineLable margin-Left-22'} /></Column>
                                    <Column lg={1}></Column>
                                  </Row>
                                  <Row className='filter-row-height'>
                                    <Column lg={12} md={12} className='filter-row-height'>
                                      {isRowAdded && <QueryFilterRow rowsData={rowsData} onDetailFieldChange={onDetailFieldChange} onDetailFieldLogicChange={onDetailFieldLogicChange} handleChange={handleChange} deleteTableRows={deleteTableRows} count={count} multiSelectedOptions={multiSelectedOptions} />}
                                    </Column>
                                  </Row>
                                </Column>
                                <Column lg={12} md={12} className='filter-row-height bottom-margin-filter top-margin-filter nopadding'>
                                  { isUserPermitToViewAddRule && <button className="btn btn-outline-primary cstCollection" tabIndex={55} onClick={addTableRows} disabled = {isAddRuleDisabled} >{label.ADD_RULE}</button> }
                                </Column>
                              </Row>
                            </Column>
                          </Row>
                          <Row className='filterRow'>
                            <Column lg={2} md={2} className={'nopadding'}><Label text={label.UPDATED} className={'inlineLable'} /></Column>
                            <Column lg={10} md={10}>
                              <Row>
                                <Column lg={6} md={6} className={'nopadding'}>
                                  <Label text={label.UPDATED_BY+ label.COLON} className={'inlineLable nopadding'} />
                                  <Label text={queryInputs.requestedBy} className={'inlineLable querylabel'} />
                                </Column>
                                <Column lg={6} md={6}>
                                  <Label text={label.UPDATED_ON+ label.COLON} className={'inlineLable'} />
                                  <Label text={queryInputs.updatedOn !== null ? moment(queryInputs.updatedOn).format("MM/DD/YYYY") : ''} className={'inlineLable querylabel'} />
                                </Column>
                              </Row>
                            </Column>
                          </Row>
                          <Row>
                            <Column lg={12} className={isStatusSelected ? 'feedback-row' : ''}>
                              <DropDownWithLabelInline
                                id='txStatus'
                                label={label.STATUS}
                                options={QueryStatusOption}
                                labelClass='inlineLable'
                                inputProps={{ className: 'inputStyle cstFilterInput texbox-input cstCollection', tabIndex: 56 }}
                                defaultSelected={QueryStatusOption.filter(({ value }) => value === (queryInputs === undefined ? '' : queryInputs.status === undefined ? '' : queryInputs.status === null ? '' : queryInputs.status.toUpperCase()))}
                                onChange={
                                  (values) => {
                                    var statusLabel = QueryStatusOption.find((option) => option.value === values[0]) === undefined ? '' : QueryStatusOption.find((option) => option.value === values[0]).label
                                    setQueryStatusTxt(statusLabel)
                                    setIsRequired(statusLabel === constant.SAVED ? false : true)
                                    onFieldChange('status', values[0])
                                  }
                                }
                                labelSize={2}
                                placeholder={label.PLEASE_SELECT}
                                dropDownSize={4}
                                isInvalid={isStatusSelected}
                                multiple={false}
                                tabIndex={56}
                                invalidWarning={stStatusError}
                                isRequired
                              />
                            </Column>
                          </Row>
                          <Row className='filterRow'>
                            <Column lg={6} md={6} className='filter-row-height bottom-margin-filter top-margin-filter'>
                              <button className="btn btn-outline-primary footer-Button cstCollection" tabIndex={57} onClick={(event) => { SubmitQueryDetail('Save') }} type='button' disabled = {!userPermitToSave}>{label.SAVE.toUpperCase()}</button> &nbsp; &nbsp;
                              {isUserPermitToViewSaveAs &&  <button className="btn btn-outline-tertiary footer-Button cstCollection"  tabIndex={58} onClick={(event) => { SubmitQueryDetail('Save As') }} disabled ={isSaveAsDisabled}>{label.SAVE_AS.toUpperCase()}</button>}  &nbsp; &nbsp;
                              <button className="btn btn-outline-tertiary footer-Button cstCollection" disabled={queryID === constant.DEFAULT_GUID || !isUserPermitToDeleteQuery} tabIndex={59} onClick={() => { setShowDeleteConfirmation(true) }} type='button' >{label.DELETE.toUpperCase()}</button> &nbsp; &nbsp;
                            </Column>
                            <Column lg={6} md={6} className='filter-row-height bottom-margin-filter top-margin-filter text-right'>
                              <button className="btn btn-outline-tertiary footer-Button cstCollection" tabIndex={60} onClick={redirect}>{label.CANCEL.toUpperCase()}</button> &nbsp;
                             {isUserPermitToViewTestQuery &&  <button tabIndex={61} disabled={!isFormSaved || isTestQueryDisabled} className="btn btn-outline-primary footer-Button cstCollection" style={{width:'23%'}} 
                              onClick={testQueryModal}
                              >{label.TEST_QUERY.toUpperCase()}</button> }
                            </Column>
                          </Row>
                        </Column>
                      </Row>
                    </Column>
                  </Panel>
                </Column>
                <Column lg={3} className='cstNotePanel'>
                {/* refs={NoteRef} */}
                {/* <NoteContext.Provider value={notesSessionFlag}> */}
                  <NotesPanel collapsible subject = {ab_subject}>

                  </NotesPanel>
                  {/* </NoteContext.Provider> */}
                </Column>
              </Row>
            </Column>
            <br />
          </ErrorBoundary>
        </Column>
      </Row>

      <ConfirmModal
        modaltitle={label.DELETE_QUERY}
        showConfirmation={showDeleteConfirmation}
        setConfirmation={(showDeleteConfirmation) => setShowDeleteConfirmation(showDeleteConfirmation)}
        body={constant.CONFIRM_QUERY_DELETION}
        closeButtonClick={() => setShowDeleteConfirmation(false)}
        successButtonClick={() => allowDelete()}
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />

      <ConfirmModal
        modaltitle={label.CONFIRM}
        showConfirmation={showExitConfirmation}
        setConfirmation={(showExitConfirmation) => setShowExitConfirmation(showExitConfirmation)}
        body={constant.CONFIRM_EXIT}
        closeButtonClick={() => setShowExitConfirmation(false)}
        successButtonClick={() => redirect()}
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />

    <Modal
        title={label.FILTER_CRITERIA}
        show={showTestQueryModal}
        backdrop={'static'}
        toggle={() => {setShowQueryTestModal(!showTestQueryModal) }}
        showHeaderCloseButton={true}
        className={'modal-content-class'}
        closeButton={{
          size: "small",
          //icon: 'cancel',
          iconStyle: { color: "gray" },
          outlined: true,
          color: 'link',
          onClick() { setShowQueryTestModal(false) },
          children: <button className={"btn btn-outline-tertiary"}>{label.CANCEL.toUpperCase()}</button>
        }}
        middleButton={{
          size: "small",
          //icon: "check",
          iconStyle: { color: "177db8" },
          outlined: true,
          color: 'link',
          onClick() {testQuery()},
          children: <button className={"btn btn-outline-primary"} >{label.RUN_QUERY.toUpperCase()}</button>
        }}
        successButton={{
          size: "small",
          //icon: "check",
          iconStyle: { color: "177db8" },
          outlined: true,
          color: 'link',
          onClick() {exportToCSV() },
          children: <button disabled={testQueryRecordCount!= '0'?false:true} className={"btn btn-outline-primary"} >{label.EXPORT_CSV.toUpperCase()}</button>
        }}
        buttonsAlignment={"right"}
        
        body={<DynamicFilter queryInputs={queryInputs} runTestQueryModel={setTestQueryModal} recordCount={testQueryRecordCount} setRecordCount={setTestQueryRecordCount} setRecordList={setTestQueryRecords} errorMsg={consolidateErrorMessage} />}
      ></Modal>
    </>


  )
}

export default CampaignQueryDetail